import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import SimpleCanvasPanel from "./SimpleCanvasPanel";
import {useDimensions} from "../../helpers/CommonHelper";

// import useDimensions from "react-cool-dimensions";

export default function DrawableImage(props) {
    const {
        src = 'https://storage.ru-srv1.ipst.englishpatient.org/files/50575525bfd20dedd552276990a7fc82891b4043e2dd1bf1d638f67654ba1ceb.jpeg',
        onDimensionsLoaded = (d) => {

        },
        lines = [],
        strokeWidth = 3,
        brushColor = 'red',
        debounceInterval = 300,
        onLinesChange = (newLines) => {

        }
    } = props;

    // const {observe, unobserve, width, height, entry} = useDimensions({
    //     onResize: ({observe, unobserve, width, height, entry}) => {
    //         console.log('resized!');
    //     }
    // });

    const [observer, {width, height}] = useDimensions();

    const [imageSize, setImageSize] = useState(undefined);

    let k = 1;
    if (imageSize != undefined) {
        k = imageSize.height / imageSize.width;
    }

    let st = {};
    if (width != undefined && height != undefined) {
        st = {width, height}
    }

    useEffect(() => {
        let {src, onDimensionsLoaded} = props;
        let img = new Image();
        img.onload = () => {
            let d = {
                width: img.width,
                height: img.height
            }
            setImageSize(d);
            onDimensionsLoaded(d);
        }
        img.src = src;
    }, []);

    console.log('HookedDrawableImage: render: src = ', src);

    return (
        <Wrapper ref={observer}>

            <MyImage src={src}/>

            <InnerPlaceholder style={{width: width, height: k * width}}>
                <RealInnerRelativePlaceholder>
                    <SimpleCanvasPanel
                        {...props}
                        lines={lines}
                        strokeWidth={strokeWidth}
                        brushColor={brushColor}
                        width={width}
                        height={k * width}
                        onLinesChange={onLinesChange}
                    />
                </RealInnerRelativePlaceholder>
            </InnerPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: whitesmoke;
`;


const InnerPlaceholder = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
`;

const RealInnerRelativePlaceholder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const MyImage = styled.img`
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 0px;
  left: 0px;
`;
