import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const MaterialsAPI = {

    async getUserMaterials(creatorId) {
        let pld = (await axios.get(`${API_ENDPOINT}/v2/materials`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async createMaterial(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/v2/materials`, {
            ...data
        })).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async updateMaterial(data) {
        let pld = (await axios.put(`${API_ENDPOINT}/v2/materials`, data)).data;
        return pld.result;
    },

    async deleteMaterial(id) {
        let pld = (await axios.delete(`${API_ENDPOINT}/v3/materials/${id}`)).data;
        return pld?.result;
    },

    async getIdiomsMaterials() {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/idioms-materials`)).data;
        return pld?.result;
    },

    async getDictionaryMaterials() {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/dictionary-materials`)).data;
        return pld?.result;
    },

}

export default MaterialsAPI;
