import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const LessonsAPI = {

    async getGroupLessons(id) {
        let arr = (await axios.get(`${API_ENDPOINT}/v3/class/${id}/lessons`)).data.result;
        return arr;
    },

    async getMyLessons(id) {
        let arr = (await axios.get(`${API_ENDPOINT}/v3/my-lessons`)).data.result;
        return arr;
    },

    async getLesson(id) {
        let arr = (await axios.get(`${API_ENDPOINT}/v3/lessons/${id}`)).data.result;
        return arr;
    },

    async createLesson(groupId, data) {
        if (groupId == undefined || data == undefined) {
            throw 'groupId or data is not provided';
        }
        let group = (await axios.post(`${API_ENDPOINT}/v3/class/${groupId}/lessons`, {
            ...data
        })).data.result;
        return group;
    },

    async updateLesson(data) {
        let group = (await axios.put(`${API_ENDPOINT}/v3/lessons/${data?.id}`, {
            ...data
        })).data.result;
        return group;
    },

    async deleteLesson(id) {
        await axios.delete(`${API_ENDPOINT}/v3/lessons/${id}`);
    }
}

export default LessonsAPI;
