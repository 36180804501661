import {useLayoutEffect, useRef, useState} from "react";
import moment from 'moment'
import {translate} from "../utils/Translate";
import sound from "../assets/sounds/clock-tick_100bpm_C.wav"

export const RU_MONTHS_ARRAY = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
];

export function useDimensions() {
    const ref = useRef();
    const [dimensions, setDimensions] = useState({});
    useLayoutEffect(() => {
        try {
            setDimensions(ref.current.getBoundingClientRect().toJSON())
        } catch (exc) {

        }
    }, [ref.current]);
    return [ref, dimensions];
}


const CommonHelper = {

    linkTo(patientUrl, inNewTab = false) {
        let url = window.location.origin + window.location.pathname + '#' + patientUrl;
        if (inNewTab == false) {
            window.location.href = url;
        } else {
            let win = window.open(url, '_blank');
            win.focus();
        }
    },

    getRuMonthByTimestamp(t) {
        let m = +moment(t).format('M') - 1;
        return RU_MONTHS_ARRAY[+m];
    },

    getCurrentUserRole(state) {
        if (state == undefined) {
            return false;
        }
        let {currentUserId, usersMap} = state.users;
        let currentUser = usersMap.get(currentUserId);
        if (currentUser == undefined) {
            return undefined;
        }
        return currentUser.userRole;
    },

    getRuNameOfRole(role) {
        let map = {
            admin: 'Админ',
            teacher: translate('teacher'),
            student: translate('student')
        }
        return map[role];
    },

    getUserName(user) {
        if (user == undefined) {
            return 'Guest';
        }
        let {firstName, lastName, name} = user;
        let fn = (firstName == undefined || firstName == '') ? '' : firstName;
        let ln = (lastName == undefined || lastName == '') ? '' : lastName;
        if (fn == '' && ln == '') {
            return user.email;
        }
        return `${fn} ${ln}`;
    },

    convertSecondstoTime(given_seconds) {
        let dateObj = new Date(given_seconds * 1000);
        let hours = dateObj.getUTCHours();
        let minutes = dateObj.getUTCMinutes();
        let seconds = dateObj.getSeconds();

        if (window.isNaN(seconds) == true) {
            return '';
        }

        let timeString = hours.toString().padStart(2, '0')
            + ':' + minutes.toString().padStart(2, '0')
            + ':' + seconds.toString().padStart(2, '0');
        return timeString;
    },

    pauseAllAudioPlayersExceptForOne(url) {
        try {
            let arr = document.getElementsByTagName('audio');
            for (let a of arr) {
                try {
                    if (a.src == url) {
                        continue;
                    }
                    a.pause();
                } catch (ee2) {

                }
            }
        } catch (exc) {

        }
    },

    cachedAllAudios() {
        window['audio_treadmill'] = new Audio(sound);
    },

    getCachedAudio(key = 'audio_treadmill') {
        if (window[key] != undefined) {
            return window[key];
        } else {
            this.cachedAllAudios();
            return window[key];
        }
    },

    playAudio(type, priority) {
        let audio = this.getCachedAudio('audio_treadmill');
        if (audio == undefined) {
            return;
        }
        try {
            audio.volume = 0.1;
            audio.play();
        } catch (exc) {

        }
    },


};

export default CommonHelper;
