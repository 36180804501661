import styled from 'styled-components';
import {BROWN, LIGHT_BROWN, MAIN_ORANGE_COLOR} from "./Colors";

const DEFAULT_INPUT_HEIGHT = 28;
const DEFAULT_INPUT_FONT_SIZE = 14;

export const Textarea = styled.textarea`
    width: 100%;
    
    box-sizing: border-box;
    min-height: 6em;
    line-height: ${DEFAULT_INPUT_HEIGHT}px;
    border: 1px solid whitesmoke;
    border-radius: 4px;
    outline: none;
    font-size: ${DEFAULT_INPUT_FONT_SIZE}px;
    :focus{
      border-color: ${MAIN_ORANGE_COLOR};
    }
`;
