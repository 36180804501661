import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import InstructionsAPI from "../../../api/InstuctionsAPI";
import ViewSlideTool from "../../slides/tools/ViewSlideTool";

export default function InstructionsPanel(props) {
    const {
        mode = 'teacher'
    } = props;

    const [slides, setSlides] = useState([]);
    const [loading, setLoading] = useState(true);

    const [expandedId, setExpandedId] = useState(undefined);

    useEffect(() => {
        if (mode == 'teacher') {
            InstructionsAPI.getTeacherSlides().then(pld => {
                console.log('InstructionsPanel: pld = ', pld);
                setSlides(pld);
                setLoading(false);
            });
        }
        if (mode == 'student') {
            InstructionsAPI.getStudentSlides().then(pld => {
                console.log('InstructionsPanel: pld = ', pld);
                setSlides(pld);
                setLoading(false);
            });
        }
    }, []);

    return (
        <Wrapper>

            <SlidesList>

                {slides.map((a, i) => {
                    let isExpanded = (expandedId == a.id);
                    return (
                        <SlideItem key={a.id} expanded={isExpanded} onClick={() => {
                            if (isExpanded == false) {
                                setExpandedId(a.id);
                            }
                        }}>
                            <SlideName style={{cursor: (isExpanded == true) ? 'default' : 'pointer'}} onClick={() => {
                                if (isExpanded == true) {
                                    setExpandedId(undefined);
                                }
                            }}>
                                {a.name}
                            </SlideName>
                            {isExpanded == false ? null :
                                <div>
                                    <ViewSlideTool
                                        canSwitchTabs={false}
                                        {...a}
                                        canEdit={false}
                                    />
                                </div>
                            }
                        </SlideItem>
                    )
                })}

            </SlidesList>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  padding: 20px;
  box-sizing: border-box;
  width: min(calc(100vw - 300px - 40px), 820px);
  margin: 0 auto;
  @media(max-width: 820px){
    width: 100vw;
  }
`;

const SlidesList = styled.div`

`;

const SlideName = styled.div`
  font-weight: bold;
`;

const SlideItem = styled.div`
  padding: 20px;
  box-sizing: border-box;
  border: 4px solid #FFA71B;
  border-radius: 4px;
  background: white;

  margin-bottom: 20px;
`;