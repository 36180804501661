import {useLayoutEffect, useRef, useState} from "react";
import moment from 'moment'

const UIHelper = {

    isProUniMode() {
        try {
            return (window.location.href.indexOf('prouni') > -1);
        } catch (exc) {

        }
        return false;
    },

    isDictionaryAvailable() {
        let goodItems = ['prouni', 'entsu', 'likool', 'localhost'];
        let f = false;
        let s = window.location.href;
        if (s.indexOf('ru-tsu') > -1){
            return false;
        }
        for (let i in goodItems){
            let g = goodItems[i];
            if (s.indexOf(g) > -1){
                f = true;
            }
        }
        return f;
    }

};

export default UIHelper;
