import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import * as actions from '../../../redux/actions/CopyPasterActions'

import paste_img from '../../../assets/img/paste.svg'
import {saveBasketItem} from "../../basket/ContentBasketPanel";

export default function PasteTool(props) {
    const {
        onSlideItemAdd = slideItem => {

        },
        onExerciseIdAdd = exerciseId => {

        },
        onDrochkaExerciseIdAdd = drochkaExerciseId => {

        },
        onTestIdAdd = testId => {

        },
        onTreadmillIdAdd = testId => {

        },
        type = 'SLIDE_ITEM',

        customPasterComponent = null

    } = props;
    const dispatch = useDispatch();
    const {
        bufferExerciseId,
        bufferTestId,
        bufferDrochkaExerciseId,
        bufferSlideItem,
        bufferTreadmillId
    } = useMappedState(useCallback(state => {
        return {
            bufferTreadmillId: state.buffer.treadmillId,
            bufferExerciseId: state.buffer.exerciseId,
            bufferTestId: state.buffer.testId,
            bufferDrochkaExerciseId: state.buffer.drochkaExerciseId,
            bufferSlideItem: state.buffer.slideItem,
        }
    }));

    if ((
        (type == 'SLIDE_ITEM' && bufferSlideItem != undefined) ||
        (type == 'TREADMILL' && bufferTreadmillId != undefined) ||
        (type == 'EXERCISE' && bufferExerciseId != undefined) ||
        (type == 'DROCHKA_EXERCISE' && bufferDrochkaExerciseId != undefined) ||
        (type == 'TEST' && bufferTestId != undefined)) == false) {
        return null;
    }

    return (
        <Wrapper onClick={() => {
            if (type == 'SLIDE_ITEM') {
                onSlideItemAdd(bufferSlideItem);
                dispatch(actions.copyToBuffer('SLIDE_ITEM', null));
            }
            if (type == 'EXERCISE') {
                onExerciseIdAdd(bufferExerciseId);
                dispatch(actions.copyToBuffer('EXERCISE', null));
            }
            if (type == 'DROCHKA_EXERCISE') {
                onDrochkaExerciseIdAdd(bufferDrochkaExerciseId);
                dispatch(actions.copyToBuffer('DROCHKA_EXERCISE', null));
            }
            if (type == 'TEST') {
                onTestIdAdd(bufferTestId);
                dispatch(actions.copyToBuffer('TEST', null));
            }
            if (type == 'TREADMILL') {
                onTreadmillIdAdd(bufferTreadmillId);
                dispatch(actions.copyToBuffer('TREADMILL', null));
            }

        }}>
            {customPasterComponent}
            {customPasterComponent != undefined ? null :
                <BufferPastePlaceholder>
                    <PastImage src={paste_img}/>
                    <Span>
                        Вставить
                    </Span>
                </BufferPastePlaceholder>
            }
        </Wrapper>
    );
}

const BufferPastePlaceholder = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PastImage = styled.img`
  width: 60px;
  cursor: pointer;
  opacity: 0.75;

  :hover {
    opacity: 1;
  }
`;

const Span = styled.div`
  color: #ffc02c;
  margin-left: 10px;
`;

const Wrapper = styled.div`

`;