import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import Select from 'react-select'

import themes_text from './themes';
import {GreenButton} from "../../ira/ui/Buttons";

const ITEMS = themes_text.split('\n').filter(x => (x != undefined && x.length > 15)).map(x => x.split(' | ')).filter(x => (x.length == 4)).map(x => {
    return {
        theme: x[0],
        module: x[1],
        level: x[2],
        unit: x[3]
    }
});

console.log('-> ITEMS = ', ITEMS);

const THEMES = ITEMS.map(x => x.theme).reduce((st, x) => st.add(x), Set()).toArray();

const getOptions = arr => {
    return arr.map(x => ({label: x, value: x}));
}

export default function ThemesSelectorTool(props) {

    const {
        onSave = d => {

        }
    } = props;

    const [theme, setTheme] = useState(props.theme);
    const [module, setModule] = useState(props.module);
    const [level, setLevel] = useState(props.level);
    const [unit, setUnit] = useState(props.unit);

    const themesOptions = getOptions(THEMES);
    const modulesOptions = getOptions(theme == undefined ? [] : ITEMS.filter(x => (x.theme == theme)).map(xx => xx.module).reduce((st, x) => st.add(x), Set()).toArray());
    const levelsOptions = getOptions(module == undefined || theme == undefined ? [] : ITEMS.filter(x => (x.module == module || x.theme == theme)).map(xx => xx.level).reduce((st, x) => st.add(x), Set()).toArray());
    const unitsOptions = getOptions(level == undefined || theme == undefined || module == undefined ? [] : ITEMS.filter(x => (x.module == module && x.theme == theme && x.level == level)).map(xx => xx.unit).reduce((st, x) => st.add(x), Set()).toArray());


    console.log('ITEMS = ', ITEMS);
    console.log('modulesOptions = ', modulesOptions);

    useEffect(() => {
        onSave({
            theme,
            module,
            level,
            unit
        })
    }, [theme, module, level, unit])

    return (
        <Wrapper>
            <Row>
                <Select
                    placeholder={'Theme'}
                    value={(theme == undefined) ? undefined : themesOptions.filter(xx => (xx.value == theme))[0]}
                    options={themesOptions}
                    onChange={x => {
                        setTheme(x?.value);
                        setModule(undefined);
                        setLevel(undefined);
                        setUnit(undefined);
                    }}
                />
            </Row>


            <Row>
                <Select
                    value={(module == undefined) ? undefined : modulesOptions.filter(xx => (xx.value == module))[0]}
                    placeholder={'Module'}
                    options={modulesOptions}
                    onChange={x => {
                        setModule(x?.value);
                        setLevel(undefined);
                        setUnit(undefined);
                    }}
                />
            </Row>


            <Row>
                <Select
                    value={(level == undefined) ? undefined : levelsOptions.filter(xx => (xx.value == level))[0]}
                    placeholder={'Level'}
                    options={levelsOptions}
                    onChange={x => {
                        setUnit(undefined);
                        setLevel(x?.value);
                    }}
                />
            </Row>


            <Row>
                <Select
                    value={(unit == undefined) ? undefined : unitsOptions.filter(xx => (xx.value == unit))[0]}
                    placeholder={'Unit'}
                    options={unitsOptions}
                    onChange={x => {
                        setUnit(x?.value);
                    }}
                />
            </Row>

            {/*<div>*/}
            {/*    <GreenButton onClick={() => {*/}
            {/*        onSave({*/}
            {/*            theme,*/}
            {/*            module,*/}
            {/*            level,*/}
            {/*            unit*/}
            {/*        })*/}
            {/*    }} >*/}
            {/*        Save*/}
            {/*    </GreenButton>*/}
            {/*</div>*/}




        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Row = styled.div`
  margin-bottom: 10px;
`;