    import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
// import {SimpleUploadWrapper} from "@rogvold/patient-components";
// import SimpleMaterialsSearchDialog from "../../../materials/panels/SimpleMaterialsSearchDialog";

export default function VideoSelector(props) {
    const {
        onSelect = u => {

        }
    } = props;

    const [url, setUrl] = useState(undefined);
    const [dialogVisible, setDialogVisible] = useState(false);

    return (
        <Wrapper>

            {/*<Field>*/}
            {/*    <Label>*/}
            {/*        Video URL*/}
            {/*    </Label>*/}
            {/*    <Input value={url} onChange={evt => {*/}
            {/*        setUrl(evt.target.value);*/}
            {/*    }}/>*/}
            {/*</Field>*/}

            <Field>

                {/*<SimpleUploadWrapper onUploaded={url => {*/}
                {/*    setUrl(url);*/}
                {/*    onSelect(url);*/}
                {/*}}>*/}
                {/*    <button className={'ui basic button'}>*/}
                {/*        <i className={'icon upload'}></i> загрузить*/}
                {/*    </button>*/}
                {/*</SimpleUploadWrapper>*/}


            </Field>

            <Field>
                <span style={{cursor: 'pointer'}} onClick={() => {
                    setDialogVisible(true);
                }}>
                    добавить из видеотеки
                </span>
            </Field>

            {dialogVisible == false ? null :

                <>

                    {/*<SimpleMaterialsSearchDialog*/}
                    {/*    onClose={() => {*/}
                    {/*        setDialogVisible(false);*/}
                    {/*    }}*/}
                    {/*    onMaterialClick={(mId, m) => {*/}
                    {/*        // console.log('SimpleMaterialsSearchDialog: m = ', m);*/}
                    {/*        setUrl(m.url);*/}
                    {/*        onSelect(m.url);*/}
                    {/*    }}/>*/}
                    {/*    */}
                </>


            }


            {/*<SaveButton onClick={() => {*/}
            {/*    onSelect(url);*/}
            {/*}}>*/}
            {/*    Select*/}
            {/*</SaveButton>*/}

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Label = styled.div`
  margin-bottom: 5px;
  font-weight: bold;
`;

const SaveButton = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  background: blue;
  border-radius: 4px;
  color: white;
  box-sizing: border-box;

  :hover {
    opacity: 0.75;
  }
`;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: 40px;
`;

const Field = styled.div`
  margin-bottom: 20px;
`;