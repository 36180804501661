import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import TeacherTemplate from "../templates/TeacherTemplate";
import LottieLoader from "../loaders/LottieLoader";
import loader_json from "../../assets/lottie/under_construction.json";
import GroupsPanel from "../groups/panels/GroupsPanel";
import StudentTasksPanel from "../lessons/panels/StudentTasksPanel";

export default function StudentHomeworkApp(props) {
    const {userId} = useMappedState(useCallback(state => {
        return {
            userId: state.users.currentUserId
        }
    }))

    return (
        <TeacherTemplate active={'homework'}>

            <StudentTasksPanel
                userId={userId}
            />

        </TeacherTemplate>
    );
}

const Wrapper = styled.div`

`;

const ConstructionPlaceholder = styled.div`
  height: 80vh;
  text-align: center;
`;