import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from 'react';
import styled from 'styled-components';
import QuestionnairesAPI from "../../../api/QuestionnairesAPI";
import QuestionnaireViewTool from "../tools/QuestionnaireViewTool";
import {Set} from 'immutable'
import ls from "local-storage";

export default function QuestionnairePanel(props) {
    const {
        id,
        userId
    } = props;
    const [questionnaire, setQuestionnaire] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [selectedAnswersSet, setSelectedAnswersSet] = useState(Set());
    const [initialized, setInitialized] = useState(false);

    const getQuestionnaireAnswersMap = useCallback(() => {
        let ansMap = ls('LOCAL_STORAGE_KEY_NAME');
        if (ansMap == undefined) {
            ls('LOCAL_STORAGE_KEY_NAME', {[id]: {}});
            ansMap = {};
        }
        return ansMap;
    }, [id]);

    const initFromLocalStorage = useCallback(() => {
        let ansMap = getQuestionnaireAnswersMap();
        console.log('ansMap = ', ansMap);
        let answersMap = (ansMap[id] == undefined) ? {} : ansMap[id];
        let st = Set();
        for (let key in answersMap) {
            st = st.add(key);
        }
        setSelectedAnswersSet(st);
        setInitialized(true);
    }, [id]);

    const saveAnswer = useCallback((newIdsList) => {
        let ansMap = getQuestionnaireAnswersMap();
        if (ansMap[id] == undefined) {
            ansMap[id] = {};
        }
        ansMap[id] = {};
        for (let i in newIdsList) {
            ansMap[id][newIdsList[i]] = 1;
        }
        ls('LOCAL_STORAGE_KEY_NAME', ansMap);
        initFromLocalStorage();
    }, [id]);

    useEffect(() => {
        setLoading(true);
        QuestionnairesAPI.getQuestionnaire(id).then(q => {
            setQuestionnaire(q);
            setLoading(false);
            initFromLocalStorage();
        })
    }, [id]);

    if (questionnaire == undefined) {
        return null;
    }
    let {studentTask} = questionnaire;

    return (
        <Wrapper>

            {(studentTask == undefined || studentTask == '') ? null :
                <StudentTaskPlaceholder>
                    <div dangerouslySetInnerHTML={{__html: studentTask.replace(/\n/g, '<br/>')}}></div>
                </StudentTaskPlaceholder>
            }


            <QuestionnaireViewToolPlaceholder>
                <QuestionnaireViewTool selectedAnswersSet={selectedAnswersSet}
                                       {...questionnaire}
                                       questions={questionnaire.questions}
                                       userId={userId}
                                       onSelectAnswer={(newIdsList) => {
                                           console.log('onSelectAnswer: newIdsList = ', newIdsList);
                                           saveAnswer(newIdsList)
                                           // this.saveAnswer(newIdsList);
                                       }}
                                       onUserAnswerSaved={(userAnswer) => {
                                           console.log('onUserAnswerSaved: userAnswer = ', userAnswer);
                                           let oldQuestions = questionnaire.questions;
                                           let number = 0;
                                           for (let i in oldQuestions) {
                                               let q = oldQuestions[i];
                                               if (q.id == userAnswer.questionId) {
                                                   oldQuestions[i].userAnswer = userAnswer;
                                               }
                                           }
                                           let newQ = {
                                               ...questionnaire,
                                               questions: oldQuestions
                                           }
                                           // this.setState({
                                           //     questionnaire: newQ
                                           // });
                                       }}
                />
            </QuestionnaireViewToolPlaceholder>

        </Wrapper>
    );
}


const StudentTaskPlaceholder = styled.div`

`;

const QuestionnaireViewToolPlaceholder = styled.div`

`;

const Wrapper = styled.div`

`;

const SpinnerPlaceholder = styled.div`
  padding: 10px;
  text-align: center;
`;