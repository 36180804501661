import ls from 'local-storage'
import {nanoid} from 'nanoid';
import 'regenerator-runtime/runtime'

const DAEMON_INTERVAL = 3 * 1000;
const PING_INTERVAL = 15 * 1000;
export const DEFAULT_VF_ANALYTICS_ENDPOINT = `https://api.analytics.entsu.englishpatient.org`;
const SESSION_EXPIRATION_MS = 2 * 60 * 60 * 1000; // 2 hours session

const timeout = ms => new Promise(res => setTimeout(res, ms));

const getCleanArr = (arr, badKeys = []) => {
    let newArr = JSON.parse(JSON.stringify(arr));
    for (let i in newArr) {
        for (let key in newArr[i]) {
            if (badKeys.indexOf(key) > -1) {
                delete newArr[i][key];
            }
        }
    }
    return newArr;
};


async function postData(url = '', data = {}) {
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
}

class VerfactoTracker {

    static init(trackerId, options = {}) {
        if (window[`vf_tr_${trackerId}_initialized`] == true) {
            return;
        }
        let {
            serverEndpoint = `${DEFAULT_VF_ANALYTICS_ENDPOINT}/collect`,
            sessionExpirationMs = SESSION_EXPIRATION_MS,
            pingInterval = PING_INTERVAL,
            pingerEnabled = false
        } = options;
        window[`vf_tr_${trackerId}_initialized`] = true;

        let allTrackersIds = (window.vfAllTrackersIds == undefined) ? [] : window.vfAllTrackersIds;
        window.vfTrackerServerEndpoint = serverEndpoint;
        window.vfSessionExpirationMs = sessionExpirationMs;
        window.vfPingInervalMs = pingInterval;
        window[`vf_${trackerId}`] = new VerfactoTracker(trackerId, serverEndpoint);
        allTrackersIds = allTrackersIds.concat([`vf_${trackerId}`]);
        window.vfAllTrackersIds = allTrackersIds;
        let sessionId = VerfactoTracker.getLazySessionId();
        console.log(`INITIALIZING VF TRACKER: ID = ${trackerId}, session id = ${sessionId}`);
        if (window.vfDaemonInitialized == true) {
            return;
        }
        window.vfDaemonInitialized = true;
        VerfactoTracker.initDaemon().then(() => {

        });
    }

    static getLazySessionId() { // gets session id and refreshes the last activity timestamp
        if (ls('vf_last_activity_timestamp') == undefined) {
            ls('vf_last_activity_timestamp', +new Date());
            ls('vf_session_id', `t-${+new Date()}-${nanoid()}`);
        }
        let lastActivityTimestamp = ls('vf_last_activity_timestamp');
        let dt = +new Date() - lastActivityTimestamp;
        if (dt >= window.vfSessionExpirationMs) { // creating new session
            ls('vf_session_id', `t-${+new Date()}-${nanoid()}`);
        }
        ls('vf_last_activity_timestamp', +new Date());
        return ls('vf_session_id');
    }

    static getActiveTrackersIds() {
        let arr = (window.vfAllTrackersIds == undefined) ? [] : window.vfAllTrackersIds;
        return arr;
    }


    static async initDaemon() {


        while (true) {
            let lsEvents = (ls('vf_events_registry') == undefined) ? [] : ls('vf_events_registry');
            lsEvents = lsEvents.filter(a => (a.sent != true));
            if (lsEvents.length == 0) {
                await timeout(DAEMON_INTERVAL);
                continue;
            }
            let submitted = false;
            try {
                // await postData(window.vfTrackerServerEndpoint, {
                //     points: getCleanArr(lsEvents, ['sent'])
                // });
                submitted = true;
            } catch (exc) {

            }
            if (submitted) {
                VerfactoTracker.clearRegistryWithSubmittedIds(lsEvents.map(a => a.id));
            }
            await timeout(DAEMON_INTERVAL);
        }
    }

    static clearRegistryWithSubmittedIds(ids = []) {
        let lsEvents = (ls('vf_events_registry') == undefined) ? [] : ls('vf_events_registry');
        lsEvents = lsEvents.filter(a => (ids.indexOf(a.id) == -1));
        ls('vf_events_registry', lsEvents);
    }

    constructor(trackerId = 'test_tracker_id') {
        this.trackerId = trackerId;
        this.trackPageView();
    }

    static getLazyVerfactoGuestId() {
        let g = ls(`vf_guest_id`);
        if (g == undefined) {
            g = `vf-${+new Date()}-${nanoid()}`;
            ls(`vf_guest_id`, g);
        }
        return g;
    }

    identifyUser(systemUserId) {
        window.vfSystemUserId = systemUserId;
    }

    track(eventName, data) {
        if (eventName == undefined) {
            throw `Event name is not provided`;
        }
        let d = {
            t: +new Date(),
            sent: false,
            type: eventName,
            userId: window.vfSystemUserId,
            s_id: VerfactoTracker.getLazySessionId(),
            g_id: VerfactoTracker.getLazyVerfactoGuestId(),
            tracker_id: this.trackerId,
            ...data
        }
        let lsEvents = (ls('vf_events_registry') == undefined) ? [] : ls('vf_events_registry');
        ls('vf_events_registry', lsEvents.concat([d]));
    }

    trackPageView() {
        this.track('pageview', {
            url: window.location.href
        });
    }

    trackEvent(eventName, params = {}) {
        this.track(eventName, {
            name: eventName,
            ...params
        });
    }

    static track(eventName, params = {}) {
        try {
            // window[`vf_${trackerId}`].trackEvent(eventName, params);
            window[`vf_${window.VF_TRACKER_ID}`].trackEvent(eventName, params);
        } catch (exc) {

        }
    }

    static identify(trackerId, systemUserId) {
        try {
            window[`vf_${trackerId}`].identifyUser(systemUserId);
        } catch (exc) {

        }
    }

}

// window.VerfactoTracker = VerfactoTracker;
export default VerfactoTracker;