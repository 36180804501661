import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {API_ENDPOINT, TINY_MCE_API_KEY} from "../../../../constants/config";

import {Editor} from '@tinymce/tinymce-react';
import {BlueButton} from "../../../ira/ui/Buttons";
import LottieLoader from "../../../loaders/LottieLoader";

import lottie_json from '../../../../assets/lottie/graphic-designer.json'
import cit_img from "../../../../assets/img/citation.svg";
import SimpleCitationTool from "../../../citation/SimpleCitationTool";
import {Textarea} from "../../../ira/ui/Inputs";

export default function SlideTextEditor(props) {
    const {
        html = '',
        onChange = (newHtml, copyrightDescription) => {

        },
        loading = false
    } = props;

    const editorRef = useRef(null);
    const [loaded, setLoaded] = useState(false);
    const [copyrightDescription, setCopyrightDescription] = useState(props.copyrightDescription || '');
    const [citationModalVisible, setCitationModalVisible] = useState(false);

    return (
        <Wrapper>

            {loaded == true ? null :
                <div style={{height: 400}}>
                    <LottieLoader json={lottie_json}/>
                </div>
            }


            <EditorPlaceholder style={{display: (loaded == false ? 'none' : 'block')}}>
                <Editor
                    onInit={(evt, editor) => {
                        editorRef.current = editor;
                        setTimeout(() => {
                            setLoaded(true);
                        }, 100);
                    }}
                    initialValue={html}
                    contextmenu_never_use_native={true}
                    apiKey={TINY_MCE_API_KEY}
                    init={{
                        height: 500,
                        menubar: false,
                        contextmenu_never_use_native: true,
                        plugins: [
                            'contextmenu advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table code help wordcount'
                        ],
                        contextmenu: "paste | link image inserttable | cell row column deletetable",
                        toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'table | ' +
                            'media pageembed | ' +
                            'charmap emoticons | ' +
                            'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                />
            </EditorPlaceholder>

            <Row>
                <Label>
                    <span>
                        Информация об источниках
                    </span>
                    <CitImg src={cit_img} onClick={() => {
                        setCitationModalVisible(true);
                    }}/>
                </Label>
                <Textarea value={copyrightDescription} onChange={evt => {
                    setCopyrightDescription(evt.target.value);
                }}></Textarea>
            </Row>

            {loaded == false ? null :
                <SaveButtonPlaceholder>
                    <BlueButton onClick={() => {
                        if (editorRef.current) {
                            let content = editorRef.current.getContent();
                            console.log('content = ', content);
                            onChange(content, copyrightDescription);
                        }
                    }}>
                        <Spin visible={loading}/>
                        {loading == true ? null :
                            'Сохранить'
                        }
                    </BlueButton>
                </SaveButtonPlaceholder>
            }

            <Sidebar width={window.innerWidth} visible={citationModalVisible} onCloserClick={() => {
                setCitationModalVisible(false);
            }}>

                {citationModalVisible == false ? null :
                    <InnerPlaceholder>
                        <SimpleCitationTool
                            onQuote={s => {
                                setCopyrightDescription(s);
                                setCitationModalVisible(false);
                            }}
                        />
                    </InnerPlaceholder>
                }

            </Sidebar>

        </Wrapper>
    );
}

const InnerPlaceholder = styled.div`
  height: calc(100vh - 80px);
  width: calc(100vw - 100px);
`;

const CitImg = styled.img`
  opacity: 0.7;
  cursor: pointer;
  height: 20px;
  margin-left: 10px;

  :hover {
    opacity: 1;
  }
`;

const EditorPlaceholder = styled.div`

`;

const Row = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;

const Label = styled.div`
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`;

const SaveButtonPlaceholder = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`
  min-height: 400px;
`;