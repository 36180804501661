import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Tabs, {TabItem} from "../../ui/Tabs";
import CommentTool from "./CommentTool";
import GeneralAITool from "./GenealAITool";
import GrammarGapsTool from "./GrammarGapsTool";
import WordsGapsTool from "./WordsGapsTool";

export default function AiTools(props) {


    return (
        <Wrapper>

            <Tabs
                tabs={[
                    {
                        label: 'Комментирование ответа студента',
                        content: (
                            <TabItem>
                                <CommentTool/>
                            </TabItem>
                        )
                    },
                    {
                        label: 'Gen AI',
                        content: (
                            <TabItem>
                                <GeneralAITool />
                            </TabItem>
                        )
                    },

                    {
                        label: 'Grammar - Gaps',
                        content: (
                            <TabItem>
                                <GrammarGapsTool />
                            </TabItem>
                        )
                    },

                    {
                        label: 'Words - Gaps',
                        content: (
                            <TabItem>
                                <WordsGapsTool />
                            </TabItem>
                        )
                    },



                ]}
            />

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;