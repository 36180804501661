import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Textarea} from "../../ira/ui/Inputs";
import {YellowButton} from "../../ira/ui/Buttons";

export default function TypingAnswerTool(props) {
    const {
        onSave = s => {

        },
        loading = false,
        isPreview = false,
        correctAnswer
    } = props;
    const [answer, setAnswer] = useState(props.answer || '');
    useEffect(() => {
        setAnswer(props.answer);
    }, [props.answer]);

    // console.log('TypingAnswerTool: render: props.correctAnswer = ', props.correctAnswer);
    let cA = `${correctAnswer}`.trim();

    return (
        <Wrapper>
            <TextareaPlaceholder>
                <Textarea
                    disabled={isPreview}
                    placeholder={'Введите ваш ответ'}
                    value={answer}
                    onChange={evt => {
                        setAnswer(evt.target.value);
                    }}
                ></Textarea>
            </TextareaPlaceholder>

            {isPreview == true ? null :
                <AnswerButtonPlaceholder>
                    <YellowButton onClick={() => {
                        if (loading == true) {
                            return;
                        }
                        onSave(answer, (cA == answer)); // todo: refactor this breach
                    }}>
                        <Spin visible={loading} theme={'alfa-on-color'}/>
                        {loading == true ? null : 'Ответить'}
                    </YellowButton>
                </AnswerButtonPlaceholder>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const TextareaPlaceholder = styled.div`

`;

const AnswerButtonPlaceholder = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;