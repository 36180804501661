import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import TeacherTemplate from "../templates/TeacherTemplate";
import ContainersTreePanel from "../containers/panels/ContainersTreePanel";
import GroupsPanel from "../groups/panels/GroupsPanel";
import TeacherGroupStudentPanel from "../groups/panels/TeacherGroupStudentPanel";

export default function TeacherGroupStudentApp(props) {
    let {groupId, studentId} = props.match.params;

    return (
        <TeacherTemplate active={`group/${groupId}/student/${studentId}`}>
            <TeacherGroupStudentPanel
                id={groupId}
                studentId={studentId}
            />
            {/*<ContainersTreePanel/>*/}
        </TeacherTemplate>
    );
}

const Wrapper = styled.div`

`;