import {Map} from 'immutable'
import * as types from '../ActionTypes.js'
import {getGracefullyMergedContainersMap} from "./ContainersReducer";

const initialState = {
    loading: false,

    selectedExerciseId: undefined,

    exercisesMap: Map(),
    error: undefined

}

const ExercisesReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_EXERCISE:
        case types.UPDATE_EXERCISE:
        case types.DELETE_EXERCISE:
        case types.LOAD_EXERCISES:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_EXERCISE_FAIL:
        case types.UPDATE_EXERCISE_FAIL:
        case types.DELETE_EXERCISE_FAIL:
        case types.LOAD_EXERCISES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_EXERCISE_SUCCESS:
        case types.UPDATE_EXERCISE_SUCCESS:
            return {
                ...state,
                loading: false,
                exercisesMap: getGracefullyMergedContainersMap(state.exercisesMap, [action.exercise]),
                selectedExerciseId: action?.exercise?.id
            }


        case types.DELETE_EXERCISE_SUCCESS:
            return {
                ...state,
                loading: false,
                exercisesMap: state.exercisesMap.delete(action.id),
            }


        case types.LOAD_EXERCISES_SUCCESS:
            return {
                ...state,
                loading: false,
                exercisesMap: getGracefullyMergedContainersMap(state.exercisesMap, action.exercises)
            }

        case types.SELECT_EXERCISE:
            return {
                ...state,
                selectedExerciseId: action.id
            }

        case types.CREATE_LESSON:
        case types.DELETE_LESSON:
        case types.CREATE_GROUP:
        case types.DELETE_GROUP:
        case types.SELECT_LESSON:
            return {
                ...state,
                selectedExerciseId: undefined
            }


        default:
            return state;
    }

}

export default ExercisesReducer;
