import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const PostsAPI = {

    async getPost(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/posts/${id}`)).data;
        return pld;
    },

    async getAllPosts() {
        let pld = (await axios.get(`${API_ENDPOINT}/posts`)).data;
        return pld;
    },

    async createPost(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/posts`, data)).data;
        return pld;
    },

    async updatePost(data) {
        let pld = (await axios.put(`${API_ENDPOINT}/posts`, data)).data;
        return pld.result;
    },

    async deletePost(id) {
        await axios.delete(`${API_ENDPOINT}/posts/${id}`, {
            data: {id: id}
        })
    }
}

export default PostsAPI;
