import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {GreenButton} from "../../ira/ui/Buttons";
import * as testsActions from "../../../redux/actions/TestsActions";
import NiceModal from "../../modals/NiceModal";
import DrochkaInfoForm from "../../drochka_exercise/forms/DrochkaInfoForm";
import * as drockaExercisesActions from "../../../redux/actions/DrochkaExercisesActions";
import QuizForm from "../forms/QuizForm";
import Tabs, {TabItem} from "../../ui/Tabs";
import DrochkaItemsForm from "../../drochka_exercise/forms/DrochkaItemsForm";
import DrochkaExercisePanel from "../../drochka_exercise/panels/DrochkaExercisePanel";
import TestPanel from "./TestPanel";
import toast from "react-hot-toast";
import {translate} from "../../../utils/Translate";
import {Input} from "../../ira/ui/Inputs";
import tick_img from "../../../assets/img/green_check.svg";
import {hasIntersection} from "../../exercises/panels/AllExercisesPanel";
import CommonHelper from "../../../helpers/CommonHelper";
import share_img from "../../../assets/img/share_mini.svg";
import ExercisesFoldersPanel from "../../smart_folders/panels/ExecisesFoldersPanel";

export default function AllTestsPanel(props) {
    const {
        mode = 'my',
        selectMode = false,
        onSelect = x => {

        },
        selectedSet = Set()
    } = props;

    const dispatch = useDispatch();
    const [selectedId, setSelectedId] = useState();
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [searchText, setSearchText] = useState('');

    const {
        currentUser,
        tests = [],
        loading,
        isModerator
    } = useMappedState(useCallback(state => {
        let {currentUserId} = state.users;
        let currentUser = state.users.usersMap.get(currentUserId);
        let allExercises = state.tests.testsMap.toArray();
        console.log('tests: allExercises = ', allExercises);
        let tests = (mode == 'my') ? allExercises.filter(x => (x.ownerId == currentUserId)) : allExercises;

        let aGroups = state.accessGroups.accessGroupsMap.toArray().filter(x => ((x?.emails || []).indexOf(currentUser.email) == -1 && x?.creatorId != currentUserId));
        let aGroupsIds = aGroups.map(xx => xx?.id).filter(x => (x != undefined));
        if (mode == 'shared') {
            tests = tests.filter(x => hasIntersection((x?.accessGroupsIds || []), aGroupsIds));
        }
        if (mode == 'public') {
            tests = tests.filter(x => (x?.access == 'public' || x?.access == undefined));
            // tests = tests;
        }

        // tests = tests.sort((a, b) => (+b.timestamp - +a.timestamp));
        tests = tests.sort((a, b) => {
            let s1 = `${a.name}`.toLowerCase();
            let s2 = `${b.name}`.toLowerCase();
            if (s1 > s2) {
                return 1;
            }
            if (s1 < s2) {
                return -1;
            }
            return 0;
        });

        return {
            currentUser: currentUser,
            isModerator: currentUser != undefined && currentUser.isModerator,
            tests: tests,
            loading: state.tests.loading
        }
    }, [selectedId]));

    useEffect(() => {
        dispatch(testsActions.getAllTests()).then(() => {
            console.log('tests loaded!');
        });
    }, [mode]);

    let xTests = tests;
    if (searchText.length > 2) {
        xTests = xTests.filter(x => {
            let s = `${x.name || ''} ${x.description || ''} ${x.transcript || ''}`.toLowerCase();
            return (s.indexOf(searchText.toLowerCase()) > -1);
        });
    }

    let selectedTest = (selectedId == undefined) ? undefined : tests.filter(x => (x.id == selectedId))[0];
    console.log('render: mode, tests = ', mode, tests);

    return (
        <Wrapper>

            {!(tests.length == 0 && loading == true) ? null :
                <Code/>
            }

            {mode == 'my' ? null :
                <TopPlaceholder>
                    {/*{mode != 'my' ? null :*/}
                    {/*    <GreenButton onClick={() => {*/}
                    {/*        setAddModalVisible(true);*/}
                    {/*    }}>*/}
                    {/*        Создать тест*/}
                    {/*    </GreenButton>*/}
                    {/*}*/}
                    <SearchPlaceholder>
                        <Input
                            placeholder={'Search'}
                            value={searchText}
                            onChange={evt => {
                                setSearchText(evt.target.value);
                            }}
                        />
                    </SearchPlaceholder>
                </TopPlaceholder>
            }


            {mode == 'my' ? null :
                <ItemsPlaceholder>
                    {xTests.map((a, i) => {
                        let isSelected = selectedSet.has(a.id);
                        return (
                            <ExItem key={`ex_${a.id}_${i}`} onClick={() => {
                                setSelectedId(a.id);
                            }}>
                                <Name>
                                    {a.name}
                                </Name>
                                <Description>
                                    {a.description == undefined ? null : (`${a.description}`.length > 120 ? `${a.description}`.slice(0, 118) + '...' : '')}
                                </Description>
                                {selectMode == false ? null :
                                    <div style={{
                                        marginTop: 10,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}>
                                        {isSelected == false ? null :
                                            <img src={tick_img} style={{height: 18, marginRight: 5}}/>
                                        }
                                        <SelectSpan onClick={() => {
                                            onSelect(a);
                                        }}>
                                            {translate('select')}
                                        </SelectSpan>
                                    </div>
                                }
                            </ExItem>
                        )
                    })}
                </ItemsPlaceholder>
            }

            {mode != 'my' ? null :
                <>
                    <ExercisesFoldersPanel
                        type={'test'}
                        onAddExerciseClick={() => {
                            setAddModalVisible(true);
                        }}
                        onEditExerciseClick={(id) => {
                            setSelectedId(id);
                        }}
                        exerciseSelectMode={selectMode}
                        selectedExerciseSet={selectedSet}
                        onExerciseSelect={onSelect}

                    />
                </>
            }

            {addModalVisible == false ? null :
                <NiceModal onClose={() => {
                    setAddModalVisible(false);
                }}>

                    <InnerPlaceholder>
                        <QuizForm
                            loading={loading}
                            onSave={async d => {
                                let pld = await dispatch(testsActions.createTest({
                                    ...d,
                                    ownerId: currentUser?.id
                                }));
                                setAddModalVisible(false);
                                setSelectedId(pld?.exercise?.id);
                            }}
                        />
                    </InnerPlaceholder>

                </NiceModal>
            }

            {selectedTest == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedId(undefined);
                }}>

                    <InnerPlaceholder>
                        <Tabs
                            tabs={[
                                (mode != 'my' && window.location.href.indexOf('petr') == -1 && isModerator == false) ? undefined :
                                    {
                                        label: 'Информация',
                                        content: (
                                            <TabItem>
                                                <QuizForm
                                                    canDelete={true}
                                                    {...selectedTest}
                                                    loading={loading}
                                                    onSave={async d => {
                                                        await dispatch(testsActions.updateTest({
                                                            ...d,
                                                            id: selectedTest?.id
                                                        }));
                                                        toast('Изменения сохранены')
                                                    }}
                                                    canDelete={true}
                                                    onDelete={async () => {
                                                        await dispatch(testsActions.deleteTest(selectedTest?.id));
                                                        setSelectedId(undefined);
                                                    }}
                                                />
                                            </TabItem>
                                        )
                                    },
                                {
                                    label: 'Превью',
                                    content: (
                                        <TabItem>
                                            <TestPanel
                                                id={selectedTest?.id}
                                            />

                                            <ShareExecisePlaceholder>
                                                <ShareLinkSpan onClick={() => {
                                                    CommonHelper.linkTo(`/share/tests/${selectedTest?.id}`, true);
                                                }}>
                                                    <ShareImg src={share_img}/>
                                                    {translate('share_test')}
                                                </ShareLinkSpan>
                                            </ShareExecisePlaceholder>

                                        </TabItem>
                                    )
                                }
                            ].filter(x => (x != undefined))}
                        />
                    </InnerPlaceholder>

                </NiceModal>
            }

        </Wrapper>
    );
}

const ShareLinkSpan = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.75;

  :hover {
    opacity: 1;
  }
`;

const ShareExecisePlaceholder = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
  margin-top: 10px;
`;

const ShareImg = styled.img`
  margin-right: 5px;
  width: 16px;
  height: 16px;
`;

const Wrapper = styled.div`

`;

const SelectSpan = styled.span`
  cursor: pointer;
  font-size: 12px;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const TopPlaceholder = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const SearchPlaceholder = styled.div`
  width: 240px;
`;

const InnerPlaceholder = styled.div`
  width: 920px;
  box-sizing: border-box;
  padding: 5px;
  height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  overflow-y: auto;

  @media (max-width: 720px){
    width: 80vw;
  }
`;

const Description = styled.div`
  font-size: 12px;
  opacity: 0.7;
`;

const Name = styled.div`
  font-weight: bold;
`;

const AvatarImage = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: whitesmoke;
  height: 240px;
  width: 100%;
  margin-bottom: 5px;
  @media (max-width: 1100px) {
    height: 180px;
  }
`;

const ExItem = styled.div`
  box-sizing: border-box;
  margin: 15px;
  background: white;
  padding: 15px;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.75;

  background: ${props => (props.selected == true ? 'blue' : 'white')};

  :hover {
    opacity: 1;
  }
`;

const Row = styled.div`
  margin-bottom: 15px;
`;

const ItemsPlaceholder = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`;