import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import {Textarea} from "../../ira/ui/Inputs";
import {BlueButton} from "../../ira/ui/Buttons";
import ChatGPTAPI from "../../../api/ChatGPTAPI";
import CommonHelper from "../../../helpers/CommonHelper";

export default function GeneralAITool(props) {
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState('');
    const [result, setResult] = useState(undefined);

    return (
        <Wrapper>

            <Row className={'field'}>
                <Label>
                    Инструкция для AI
                </Label>
                <Textarea value={text}
                          style={{
                              lineHeight: '20px',
                              minHeight: '60px'
                          }}
                          placeholder={''}
                          onChange={(evt) => {
                              setText(evt.target.value);
                              setResult(undefined);
                          }}/>
            </Row>


            {result != undefined || (text == '')? null :
                <Row>
                    {loading == true ? 'Загрузка...' :
                        <BlueButton onClick={async () => {
                            setLoading(true);
                            setResult(undefined);
                            let pld = await ChatGPTAPI.getGeneralText(text);
                            setResult(pld);
                            setLoading(false);
                            CommonHelper.playAudio();
                        }}>
                            Сгенерировать
                        </BlueButton>
                    }
                </Row>
            }


            {result == undefined ? null :
                <div>
                    <Label>
                        Результат:
                    </Label>
                    <Textarea value={result}
                              style={{
                                  lineHeight: '20px',
                                  minHeight: '300px'
                              }}
                              placeholder={''}
                    />

                    {/*<div dangerouslySetInnerHTML={{__html: result.replace(/\n/g, '<br/>')}}></div>*/}
                </div>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Row = styled.div`
  margin-bottom: 5px;
`;

const Label = styled.div`
  font-weight: bold;
`;