import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import SimpleFileUploader from "../../uploader/tools/SimpleFileUploader";
import {BlueButton, GreenButton} from "../../ira/ui/Buttons";

import Spin from 'arui-feather/spin'
import {getFixedUrl} from "../../exercises/tools/ExerciseCardItemView";

export default function DialogMessagesEditor(props) {
    const {
        loading = false,
        // messages = [],
        firstRoleName,
        secondRoleName,
        onChange = newMessages => {

        }
    } = props;

    const [messages, setMessages] = useState(props.messages || []);

    return (
        <Wrapper className={''}>

            <MessagesList>

                {messages.map((msg, k) => {
                    let isFirstRole = (k % 2 == 0);
                    return (
                        <MessageItem key={`${k}_${msg.id}_${msg.audioUrl}`}>
                            <RoleNamePlaceholder>
                                {`${+k + 1}) `}
                                {isFirstRole == true ? firstRoleName : secondRoleName}
                            </RoleNamePlaceholder>
                            <TextPlaceholder>
                                <Input placeholder={'Текст реплики'} value={msg.text} onChange={evt => {
                                    let newText = evt.target.value;
                                    let newMessages = messages.map((m, j) => {
                                        if (j == k) {
                                            return {
                                                text: newText,
                                                audioUrl: getFixedUrl(m.audioUrl),
                                                id: m.id,
                                                hint: m.hint
                                            }
                                        } else {
                                            return m;
                                        }
                                    });
                                    setMessages(newMessages);
                                }}/>
                            </TextPlaceholder>
                            <TextPlaceholder style={{marginTop: 5, marginBottom: 5}}>
                                <Input placeholder={'Текст подсказки'} value={msg.hint} onChange={evt => {
                                    let newHint = evt.target.value;
                                    let newMessages = messages.map((m, j) => {
                                        if (j == k) {
                                            return {
                                                text: m.text,
                                                audioUrl: m.audioUrl,
                                                id: m.id,
                                                hint: newHint
                                            }
                                        } else {
                                            return m;
                                        }
                                    });
                                    setMessages(newMessages);
                                }}/>
                            </TextPlaceholder>
                            <AudioPlaceholder>
                                {msg.audioUrl == undefined ? null :
                                    <Audio controls={true}>
                                        <source src={getFixedUrl(msg.audioUrl)}/>
                                    </Audio>
                                }
                                <div>
                                    <SimpleFileUploader
                                        placeholder={'загрузить аудио'}
                                        anotherPlaceholder={'загрузить другое аудио'}
                                        onSave={url => {
                                            let newMessages = messages.map((m, j) => {
                                                if (j == k) {
                                                    return {
                                                        text: m.text,
                                                        audioUrl: url,
                                                        id: m.id,
                                                        hint: m.hint
                                                    }
                                                } else {
                                                    return m;
                                                }
                                            });
                                            setMessages(newMessages);
                                        }}
                                    />
                                </div>
                            </AudioPlaceholder>
                            {k < messages.length - 1 ? null :
                                <DeletePlaceholder>
                                        <span style={{cursor: 'pointer', color: 'red', opacity: 0.5}} onClick={() => {
                                            setMessages(messages.slice(0, messages.length - 1));
                                        }}>
                                            удалить последнюю реплику
                                        </span>
                                </DeletePlaceholder>
                            }
                        </MessageItem>
                    )
                })}

            </MessagesList>


            <BottomControlsPlaceholder>
                <BlueButton onClick={() => {
                    let newMessages = messages.concat([{
                        id: `t-${+new Date()}`,
                        text: '',
                        hint: '',
                        audioUrl: undefined
                    }]);
                    setMessages(newMessages);
                }}>
                    Создать реплику
                </BlueButton>
            </BottomControlsPlaceholder>

            <BottomControlsPlaceholder>
                <GreenButton onClick={() => {
                    onChange(messages);
                }}>
                    <Spin visible={loading} theme={'alfa-on-color'} />
                    {loading == true ? null :
                        'Сохранить'
                    }
                </GreenButton>
            </BottomControlsPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const BottomControlsPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const MessagesList = styled.div`

`;

const MessageItem = styled.div`
  padding: 10px;
`;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding-left: 3px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  border: 1px solid whitesmoke;
  outline: none;

  :focus {
    border-color: blue;
  }
`;

const TextPlaceholder = styled.div`

`;

const AudioPlaceholder = styled.div`

`;

const Audio = styled.audio`
  box-sizing: border-box;
  width: 100%;
`;

const RoleNamePlaceholder = styled.div`
  font-weight: bold;
`;

const DeletePlaceholder = styled.div`
  text-align: right;
`;
