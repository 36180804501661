import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
// import {SimpleUploadWrapper} from "@rogvold/patient-components";

export default function ImageSelector(props) {
    const {
        onSelect = u => {

        }
    } = props;

    const [url, setUrl] = useState(undefined);

    return (
        <Wrapper>
            <Field>

                {/*<SimpleUploadWrapper onUploaded={url => {*/}
                {/*    setUrl(url);*/}
                {/*    onSelect(url);*/}
                {/*}}>*/}
                {/*    <button className={'ui basic button'}>*/}
                {/*        <i className={'icon upload'}></i> загрузить*/}
                {/*    </button>*/}
                {/*</SimpleUploadWrapper>*/}

            </Field>
        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Label = styled.div`
  margin-bottom: 5px;
  font-weight: bold;
`;

const SaveButton = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  background: blue;
  border-radius: 4px;
  color: white;
  box-sizing: border-box;

  :hover {
    opacity: 0.75;
  }
`;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: 40px;
`;

const Field = styled.div`
  margin-bottom: 20px;
`;