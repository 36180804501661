import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {useDispatch, useMappedState} from 'redux-react-hook'
import {Map, Set} from 'immutable';

import ls from 'local-storage'
import axios from 'axios'

import RecursiveContainersTree from "../tools/RecursiveContainersTree";

import * as actions from '../../../redux/actions/ContainersActions'
import * as slidesActions from '../../../redux/actions/SlidesActions'
import * as usersActions from '../../../redux/actions/UsersActions'

import Sidebar from 'arui-feather/sidebar'
import TaskPreviewPanel from "./TaskPreviewPanel";

import {translate} from "../../../utils/Translate";
import DropdownDotsMenu from "../../ui/DropdownDotsMenu";
import NiceModal from "../../modals/NiceModal";
import UpdateFolderForm from "../tools/UpdateFolderForm";
import {API_ENDPOINT} from "../../../constants/config";
import SimpleSlideForm from "../../slides/forms/SimpleSlideForm";

import {Code} from "react-content-loader";
import CommonHelper from "../../../helpers/CommonHelper";
import ContainersHelper from "../../../helpers/ContainersHelper";

function getRootContainers(containersMap, access, limboModules = [], usersMap = Map(), currentUserId) {
    let allContainers = containersMap.toArray();
    if (access == 'private'){
        allContainers = allContainers.filter(x => (x?.creatorId == currentUserId));
    }
    let allChildrenIdsSet = allContainers.reduce((st, c) => st.union((c?.childrenIds || []).reduce((sst, c_) => sst.add(c_), Set())), Set());
    let orphans = allContainers.filter(x => !allChildrenIdsSet.has(x.id)).filter(x => (x?.type == 'folder' && (x?.access == access || access == 'limbo')));
    if (access != 'limbo') {
        return orphans;
    }
    let usersIds = limboModules.map(x => x.userId).reduce((st, x) => st.add(x), Set()).toArray();
    let users = usersIds.map(xx => (usersMap.get(xx))).filter(x => (x != undefined));
    let arr = [];
    for (let i in users) {
        let u = users[i];
        let cont = {id: u.id, childrenIds: [], name: CommonHelper.getUserName(u), type: 'folder', creatorId: u.id, isNameContainer: true};
        let allChildrenIdsSet = allContainers.filter(xx => (xx.creatorId == u.id)).reduce((st, c) => st.union((c?.childrenIds || []).reduce((sst, c_) => sst.add(c_), Set())), Set());
        let orphans = allContainers.filter(xx => (xx.creatorId == u.id)).filter(x => !allChildrenIdsSet.has(x.id)).filter(x => (x?.type == 'folder' && (x?.access == access || access == 'limbo')));
        cont.childrenIds = orphans.map(xx => xx.id);
        arr.push(cont);
    }
    return arr.sort((a, b) => (b.childrenIds.length - a.childrenIds.length)).filter(x => (x.childrenIds.length > 0));
}

export default function ContainersTreePanel(props) {
    const {
        searchMode = false,
        filteredModulesIds = [],
        access = 'public', // private, public, hybrid
        accessGroupId // for privacy
    } = props;


    const dispatch = useDispatch();
    const [expandedSet, setExpandedSet] = useState((ls('expanded_new_containers') == undefined ? [] : ls('expanded_new_containers')).reduce((st, ss) => st.add(ss), Set()));

    const [loadingItemId, setLoadingItemId] = useState(undefined);
    const [selectedId, setSelectedId] = useState(undefined);

    const [addContainerVisible, setAddContainerVisible] = useState(false);
    const [parentContainerId, setParentContainerId] = useState(undefined);
    const [addType, setAddType] = useState('folder');
    const [selectedEditFolderId, setSelectedEditFolderId] = useState(undefined);
    const [selectedModuleFolderId, setSelectedModuleFolderId] = useState(undefined);

    const [creationAccess, setCreationAccess] = useState('private');

    const {
        containersMap,
        loading,
        rootContainers,
        getChildContainers,
        selectedSlide,
        selectedSlideId,
        language,
        currentUser,
        currentUserId,
        realFilteredModulesIds = [],
        isAdmin
    } = useMappedState(useCallback(state => {
        // let allModulesMap =
        let globalContainersMap = state.containers.containersMap;
        let realFilteredModulesIds = filteredModulesIds;
        let limboModules = [];
        let uMap = state.users.usersMap;
        console.log('---->>>>> uMap = ', uMap);
        if (access == 'limbo') {
            let dd_ = ContainersHelper.getLimboContainersAndModulesMap(globalContainersMap, state.slides.slidesMap);
            console.log('limbo: dd_ = ', dd_);
            globalContainersMap = dd_.containersMap;
            limboModules = dd_.modules;
            let searchSet = filteredModulesIds.reduce((st, x) => st.add(x), Set());
            realFilteredModulesIds = limboModules.map(x => x.id);
            if (searchMode == true) {
                realFilteredModulesIds = realFilteredModulesIds.filter(xx => searchSet.has(xx))
            }
        }
        let cMap = (searchMode == true) ? ContainersHelper.getSearchContainersMap(state.containers.containersMap, realFilteredModulesIds) : globalContainersMap;
        let rootContainers = getRootContainers(cMap, access, limboModules, state.users.usersMap, state.users.currentUserId);
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let isAdmin = (currentUser?.userRole == 'admin');
        return {
            realFilteredModulesIds: realFilteredModulesIds,
            currentUserId: state.users.currentUserId,
            currentUser: currentUser,
            language: state.language.language,
            rootContainers: rootContainers,
            loading: state.containers.loading || state.slides.loading,
            containersMap: cMap,
            isAdmin: isAdmin,
            selectedSlide: state.slides.slidesMap.get(state.slides.selectedSlideId),
            selectedSlideId: state.slides.selectedSlideId,
            getChildContainers: contId => {
                let c = cMap.get(contId);
                let u = rootContainers.filter(xx => (xx.id == contId))[0];
                if (c == undefined){
                    c = u;
                }
                if (c == undefined) {
                    return [];
                }
                let {childrenIds = []} = c;
                return childrenIds.map(x => cMap.get(x)).filter(x => (x != undefined))
            }
        }
    }, [searchMode, filteredModulesIds]));

    useEffect(() => {
        dispatch(slidesActions.loadSlides());
        dispatch(actions.getMyAllCompactContainers());
        if (access == 'limbo'){
            dispatch(usersActions.loadLimboUsers())
        }
    }, [access]);

    console.log('!!! rootContainers, access = ', rootContainers, access);

    let selectedBlock = containersMap.get(selectedId);
    let selectedEditFolder = containersMap.get(selectedEditFolderId);
    let selectedModuleFolder = containersMap.get(selectedModuleFolderId);
    let isGlobalLoading = (rootContainers.length == 0 && loading == true);

    console.log('ContainersTreePanel: render: filteredModulesIds = ', filteredModulesIds);
    console.log('ContainersTreePanel: render: searchMode = ', searchMode);

    return (
        <Wrapper>

            <TopNamePlaceholder>
                <TopLeft>
                    {translate(`${access}`, language)}
                </TopLeft>
                <TopRight>
                    {access != 'private' ? null :
                        <DropdownDotsMenu
                            items={[
                                {
                                    name: translate('create_subfolder', language),
                                    onClick: () => {
                                        setAddContainerVisible(true);
                                        setParentContainerId(undefined);
                                        setAddType('folder');
                                        setCreationAccess('private')
                                    }
                                }
                            ]}
                        />
                    }
                    {(access != 'limbo' || currentUserId != 'jnM2pCK62I' || currentUserId != 'Ao0iOinTto') ? null :
                        <DropdownDotsMenu
                            items={[
                                {
                                    name: translate('create_subfolder', language),
                                    onClick: () => {
                                        setAddContainerVisible(true);
                                        setParentContainerId(undefined);
                                        setAddType('folder');
                                        setCreationAccess('limbo')
                                    }
                                }
                            ]}
                        />
                    }
                </TopRight>
            </TopNamePlaceholder>

            {/*{isGlobalLoading == false ? null :*/}
            {/*    <Code/>*/}
            {/*}*/}

            <React.Fragment key={`x${searchMode}_${filteredModulesIds.join('_')}`}>
                <RecursiveContainersTree
                    searchMode={searchMode || (access == 'limbo')}
                    filteredModulesIds={realFilteredModulesIds}
                    containers={rootContainers}
                    expandedSet={expandedSet}
                    loadingItemId={loadingItemId}
                    getChildContainers={getChildContainers}
                    expandToggle={async exId => {
                        console.log('expandToggle: exId = ', exId);

                        let isExpanded = !expandedSet.has(exId);
                        let newExpandedSet = expandedSet.has(exId) ? expandedSet.delete(exId) : expandedSet.add(exId);
                        ls('expanded_new_containers', newExpandedSet.toArray());
                        setExpandedSet(newExpandedSet);
                        if (isExpanded) {
                            setLoadingItemId(exId);
                            await dispatch(actions.getChildren(exId)); // подгружаем по мере необходимости контент детей
                            setLoadingItemId(undefined);
                        }
                    }}
                    onContainerClick={cId => {
                        let c = containersMap.get(cId);
                        console.log('onContainerClick: c = ', c);
                        // тут, напрмиер, можно открыть превью контейнера
                        // я тут пример сделал - вы завяжите на логику отображения превью сайдбара
                        if (c?.type == 'module') {
                            setSelectedId(cId);
                        }
                    }}
                    onModuleClick={slideId => {
                        if (selectedSlideId == slideId) {
                            dispatch(slidesActions.selectSlide(undefined));
                        } else {
                            dispatch(slidesActions.selectSlide(slideId));
                        }
                    }}
                    renderRightBlockFunction={(cId, parId) => {
                        let c = containersMap.get(cId);
                        if (c == undefined) {
                            return;
                        }
                        let hasChildren = (c.childrenIds != undefined && c.childrenIds.length > 0);
                        return (
                            <DropdownDotsMenu
                                items={[
                                    (c.creatorId != currentUserId || access == 'limbo') ? null :
                                        {
                                            name: translate('create_subfolder', language),
                                            onClick: () => {
                                                setAddContainerVisible(true);
                                                setParentContainerId(cId);
                                                setAddType('folder');
                                            }
                                        },
                                    (c.creatorId != currentUserId || access == 'limbo') ? null :
                                        {
                                            name: translate('edit', language),
                                            onClick: () => {
                                                setSelectedEditFolderId(cId)
                                            }
                                        },
                                    (c.creatorId != currentUserId || access == 'limbo') ? null :
                                        {
                                            name: translate('create_module', language),
                                            onClick: () => {
                                                setSelectedModuleFolderId(cId);
                                            }
                                        },
                                    hasChildren == true || access == 'limbo' || (c.creatorId != currentUserId) ? null : {
                                        name: translate('delete_folder', language),
                                        onClick: async () => {
                                            if (window.alert('Are you sure?') == false) {
                                                return;
                                            }
                                            await dispatch(actions.deleteContainer(parId, cId));
                                        }
                                    },
                                    hasChildren == true ? null : {
                                        name: translate('share_folder', language),
                                        onClick: async () => {
                                            CommonHelper.linkTo(`/share/folder/${cId}`, true);
                                            // await dispatch(actions.deleteContainer(parId, cId));
                                        }
                                    },
                                    // (c.creatorId != currentUserId || access == 'limbo' || isAdmin == false) ? null :
                                    (currentUser?.email != 'lirmakym@mail.ru') ? null :
                                        {
                                            name: `Migration`,
                                            onClick: async () => {
                                                let mId = window.prompt('ID модуля');
                                                await axios.post(`${API_ENDPOINT}/v3/containers/${cId}/module/${mId}/add`);
                                                await dispatch(slidesActions.loadSlides()); // todo: optimize
                                                await dispatch(actions.getMyAllCompactContainers()); // todo: optimize
                                            }
                                        }

                                ].filter(x => (x != undefined))}
                            />
                        )
                    }}
                />
            </React.Fragment>


            <Sidebar visible={(selectedBlock != undefined)}
                     width={Math.min(960, window.innerWidth)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>
                {selectedBlock == undefined ? null :
                    <div>
                        <TaskPreviewPanel id={selectedId}/>
                    </div>
                }
            </Sidebar>

            {addContainerVisible == false ? null :
                <NiceModal onClose={() => {
                    setAddContainerVisible(false);
                    setParentContainerId(undefined);
                    setAddType(undefined);
                }}>

                    <AddContainerInner>

                        <Heading>
                            {translate('create_folder')}
                        </Heading>

                        <UpdateFolderForm
                            loading={loading}
                            onSave={async d => {
                                let pld = await dispatch(actions.createContainer(parentContainerId, {
                                    ...d,
                                    type: addType,
                                    access: creationAccess
                                }));
                                let newExpandedSet = expandedSet.add(pld?.container?.id).add(parentContainerId);
                                setExpandedSet(newExpandedSet);
                                setAddContainerVisible(false);
                                setParentContainerId(undefined);
                                setAddType(undefined);
                            }}
                        />
                    </AddContainerInner>

                </NiceModal>
            }

            {selectedEditFolder == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedEditFolderId(undefined);
                }}>

                    <AddContainerInner>

                        <Heading>
                            {translate('edit_folder')}
                        </Heading>

                        <UpdateFolderForm
                            {...selectedEditFolder}
                            loading={loading}
                            onSave={async d => {
                                let pld = await dispatch(actions.updateContainer({
                                    id: selectedEditFolder.id,
                                    ...d
                                }));
                                let newExpandedSet = expandedSet.add(pld?.container?.id).add(parentContainerId);
                                setExpandedSet(newExpandedSet);
                                setSelectedEditFolderId(undefined);
                            }}
                        />
                    </AddContainerInner>

                </NiceModal>
            }

            {selectedModuleFolder == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedModuleFolderId(undefined);
                }}>

                    <AddContainerInner>

                        <Heading>
                            {translate('create_module')}
                        </Heading>

                        <SimpleSlideForm
                            loading={loading}
                            onSave={async d => {
                                await dispatch(slidesActions.createSlide(d, selectedModuleFolder?.id));
                                await dispatch(actions.getMyAllCompactContainers());
                                setSelectedModuleFolderId(undefined);
                            }}
                        />

                    </AddContainerInner>

                </NiceModal>
            }


        </Wrapper>
    );
}


const Heading = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
`;

const AddContainerInner = styled.div`
  box-sizing: border-box;
  width: 420px;
  background: white;
  padding: 20px;
`;

const TopNamePlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  justify-content: space-between;
`;

const TopLeft = styled.div`
  font-weight: bold;
`;

const TopRight = styled.div`

`;

const Wrapper = styled.div`
  padding: 10px;
`;
