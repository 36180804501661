import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import {Set, Map} from 'immutable'

import folder_image from './folder.svg'
import {getFixedUrl} from "../../exercises/tools/ExerciseCardItemView";
import ava from "../../../assets/img/online-learning-using-tablet.png";

import edit_icon from '../../../assets/img/edit_icon.svg'
import delete_icon from '../../../assets/img/delete_icon.svg'
import mine_svg from "../../../assets/img/mine.svg";
import tick_img from "../../../assets/img/green_check.svg";
import {translate} from "../../../utils/Translate";

function getAllInternalExercisesIds(folders = []) {
    let arr = [];
    for (let i in folders) {
        arr = [...arr, ...(folders[i].exercisesIds || [])];
    }
    return arr.reduce((st, x) => st.add(x), Set()).toArray();
}

function getUniqueList(ids = []) {
    let st = Set();
    let arr = [];
    for (let i in ids) {
        if (st.has(ids[i]) == false) {
            st = st.add(ids[i]);
            arr.push(ids[i]);
        }
    }
    return arr;
}

export default function SmartFoldersTool(props) {
    const {
        canMove = true,
        folders = [],

        onExerciseSelect = ex => {

        },

        exerciseSelectMode = false,
        selectedExerciseSet = Set(),


        selectionExcludedFoldersIds = [],
        exercises = [],
        onCurrentFolderIdChange = newId => {

        },
        selectingMode = false,
        onFolderSelect = fId => {

        },
        onDeleteClick = fId => {

        },
        onEditClick = fId => {

        },

        onExerciseClick = exId => {

        },

        onExerciseMoveClick = exId => {

        },
        canEdit = true,
        loading = false,
    } = props;

    console.log('SmartFoldersTool: props: folders = ', folders);
    let foldersMap = folders.reduce((st, folder) => st.set(folder.id, folder), Map());

    const [currentFolderId, setCurrentFolderId] = useState(undefined);
    const [path, setPath] = useState([]);

    let allInternalExercisesIds = getAllInternalExercisesIds(folders);
    let internalSet = allInternalExercisesIds.reduce((st, x) => st.add(x), Set());
    let externalExercises = exercises.filter(ex => !internalSet.has(ex.id));
    let exercisesMap = exercises.reduce((st, ex) => st.set(ex.id, ex), Map());

    // useEffect(() => {
    //
    // }, []);
    const currentFolder = folders.find(folder => folder.id == currentFolderId);

    const currentExercises = (currentFolderId == undefined) ? externalExercises : getUniqueList((currentFolder?.exercisesIds || [])).map(x => exercisesMap.get(x)).filter(x => (x != undefined));
    const childFolders = folders.filter(folder => folder.parentFolderId === currentFolderId);
    console.log('--->>> currentFolder = ', currentFolder);
    console.log('childFolders = ', childFolders);
    let canDelete = (canEdit == true && childFolders.length == 0);

    useEffect(() => {
        setCurrentFolderId(undefined);
        setPath([]);
    }, [exercises.length]);

    const navigateToFolder = (folderId, folderName) => {
        if (selectingMode == false) {
            onCurrentFolderIdChange(folderId);
        }
        setCurrentFolderId(folderId);
        setPath([...path, {id: folderId, name: folderName}]);
    };

    const navigateBack = () => {
        const newPath = path.slice(0, -1);
        let newFolderId = newPath.length > 0 ? newPath[newPath.length - 1].id : undefined;
        if (selectingMode == false) {
            onCurrentFolderIdChange(newFolderId);
        }
        setCurrentFolderId(newFolderId);
        setPath(newPath);
    };

    return (
        <Wrapper style={{opacity: (loading == true) ? 0.5 : 1}}>

            {currentFolderId == undefined ? null :
                <TopPlaceholder>
                    <CrumbsPlaceholder>
                        <BackSpan onClick={() => navigateBack()}>
                            <BackButton style={{display: 'inline-block'}}>
                                {'Назад '}
                            </BackButton>
                        </BackSpan>
                        {path.map((folder, index) => (
                            <CrumbSpan key={folder.id} onClick={() => {
                                if (selectingMode == false) {
                                    onCurrentFolderIdChange(folder.id);
                                }
                                setCurrentFolderId(folder.id);
                                let currentFolderIndexInPath = path.findIndex(xx => (xx.id == folder.id));
                                let newPath = path.slice(0, currentFolderIndexInPath + 1);
                                setPath(newPath);
                                // setPath(path.filter(xx => (xx.id != folder.id)));
                            }}>
                                {index > -1 ? ' / ' : ''}{foldersMap.get(folder.id)?.name}
                            </CrumbSpan>
                        ))}
                        {canEdit == false ? null :
                            <EditImg src={edit_icon} onClick={() => {
                                onEditClick(currentFolderId);
                            }}/>
                        }
                        {canDelete == false ? null :
                            <DeleteImg src={delete_icon} onClick={() => {
                                if (window.confirm('Вы уверены?') == false) {
                                    return;
                                }
                                onDeleteClick(currentFolderId);
                                navigateBack();
                            }}/>
                        }

                    </CrumbsPlaceholder>
                    {(currentFolder?.description == undefined || currentFolder?.description == '') ? null :
                        <CurrentFolderDescription>
                            {currentFolder?.description}
                        </CurrentFolderDescription>
                    }
                </TopPlaceholder>
            }


            <ItemsPlaceholder key={`cc_v_${currentFolderId}`}>
                {childFolders.map(folder => (
                    <FolderItem key={`f_${folder.id}`} className={`folder_item`} id={folder.id} onClick={() => {
                        navigateToFolder(folder.id, folder.name);
                    }}>
                        <FolderTop>
                            <FolderIcon src={folder_image} onClick={() => navigateToFolder(folder.id, folder.name)}/>
                        </FolderTop>
                        <FolderBottom onClick={() => {

                        }}>
                            <span style={{
                                fontSize: (selectingMode == true) ? '14px' : '20px',
                                fontWeight: (selectingMode == true) ? 'normal' : 'bold'
                            }}>
                                {folder.name}
                            </span>

                            {(selectingMode == false || canMove == false || (selectionExcludedFoldersIds.indexOf(folder.id) > -1)) ? null :
                                <SelectFolderButton onClick={event => {
                                    onFolderSelect(folder.id);
                                    event.stopPropagation();
                                }}>
                                    выбрать
                                </SelectFolderButton>
                            }
                        </FolderBottom>
                    </FolderItem>
                ))}

                {currentExercises.map((exercise, i) => (
                    <ExItem key={`ex_${exercise.id}_${i}`} className={`ex_item`} id={exercise.id} onClick={() => {
                        onExerciseClick(exercise.id);
                    }}>
                        <ExTop>
                            <MoveIconPlaceholder className={'MoveIconPlaceholder'}>
                                <MoveIcon className={'MoveIcon'} src={folder_image} onClick={event => {
                                    onExerciseMoveClick(exercise.id);
                                    event.stopPropagation();
                                }}/>
                            </MoveIconPlaceholder>
                            <ExIcon src={getFixedUrl(exercise.avatar || exercise.imageUrl || ava)}/>
                        </ExTop>
                        <ExBottom>
                            {exercise.name}

                            {exerciseSelectMode == false ? null :
                                <div style={{
                                    marginTop: 10,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    {selectedExerciseSet.has(exercise.id) == false ? null :
                                        <img src={tick_img} style={{height: 18, marginRight: 5}}/>
                                    }
                                    <SelectSpan onClick={event => {
                                        onExerciseSelect(exercise);
                                        event.stopPropagation();
                                    }}>
                                        {translate('select')}
                                    </SelectSpan>
                                </div>
                            }

                            {(exercise?.items || [])?.filter(x => x?.hasMine === true).length === 0 || exercise?.items === undefined ? null :
                                <MineIcon title="В тренажере присутстуют мины" src={mine_svg}/>
                            }
                        </ExBottom>
                    </ExItem>
                ))}
            </ItemsPlaceholder>


        </Wrapper>
    );
}

const SelectSpan = styled.span`
  cursor: pointer;
  font-size: 12px;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const BackButton = styled.div`
  display: inline-block;
  line-height: 24px;
  background: #085BFF;
  color: white;
  cursor: pointer;
  font-size: 14px;
  font-family: Montserrat;
  border-radius: 1000px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bold;
`;

const MineIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 5px;
`;

const ItemsPlaceholder = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const SelectFolderButton = styled.div`
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;

  :hover {
    opacity: 0.6;
  }
`;

const EditImg = styled.img`
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  height: 20px;
  vertical-align: top;

  :hover {
    opacity: 0.8;
  }
`;

const DeleteImg = styled.img`
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  height: 17px;
  vertical-align: top;
  opacity: 0.7;

  :hover {
    opacity: 0.99;
  }
`;

const CrumbSpan = styled.span`
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const TopPlaceholder = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const CrumbsPlaceholder = styled.div`
  line-height: 24px;
`;

// add css shadow

const CurrentFolderDescription = styled.div`
  font-style: italic;
  margin-bottom: 10px;
`;

const BackSpan = styled.span`
  cursor: pointer;
`;

const FolderItem = styled.div`
  display: inline-block;
  margin: 10px;
  padding: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  //width: 120px;
  box-sizing: border-box;
`;

const FolderIcon = styled.img`
  cursor: pointer;
  width: 120px;

  :hover {
    opacity: 0.8;
  }
`;

const FolderTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FolderBottom = styled.div`
  text-align: center;
`;

const Wrapper = styled.div`

`;

const ExItem = styled.div`
  display: inline-block;
  margin: 10px;
  padding: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  //width: 120px;
  box-sizing: border-box;
`;

const ExIcon = styled.div`
  cursor: pointer;
  width: 100%;
  height: 260px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  :hover {
    opacity: 0.8;
  }
`;

const ExTop = styled.div`
  position: relative;

  :hover {
    .MoveIconPlaceholder {
      display: block;
    }
  }
`;

const MoveIconPlaceholder = styled.div`
  top: 3px;
  right: 3px;
  display: none;
  z-index: 10;
  position: absolute;
  background: white;
  padding: 5px;
`;

const MoveIcon = styled.img`

  height: 20px;
  cursor: pointer;
`;

const ExBottom = styled.div`
  text-align: center;
`;