import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import MaterialsAPI from "../../../api/MaterialsAPI";
import './style.css'
import NiceModal from "../../modals/NiceModal";
import {Input} from "../../ira/ui/Inputs";
import ViewIdiomTool from "../tools/ViewIdiomTool";
import {ALPHABET_LETTERS} from "../../../constants/config";

export default function AllWordsPanel(props) {
    let letters = ALPHABET_LETTERS;

    const [selectedWord, setSelectedWord] = useState(undefined);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedName, setSelectedName] = useState(undefined);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        setLoading(true);
        MaterialsAPI.getDictionaryMaterials().then(arr => {
            setItems(arr);
            setLoading(false);
        })
    }, []);

    let se = (searchQuery == undefined) ? '' : searchQuery;
    se = se.trim().toLowerCase();

    const words = useMemo(() => {
        let arr = [];
        let map = {};
        for (let i in items) {
            let name = items[i].name;
            let names = name.split(',').map((mm) => {
                return mm.trim();
            })
            for (let j in names) {
                map[names[j]] = 1;
            }
        }
        for (let key in map) {
            arr.push(key);
        }
        arr.sort((a, b) => {
            let n1 = a.toLowerCase();
            let n2 = b.toLowerCase();
            if (n1 > n2) {
                return 1;
            }
            if (n1 < n2) {
                return -1;
            }
            return 0;
        });
        return arr;
    }, [loading, items]);

    const getMaterialsByWord = useCallback((word) => {
        if (word == undefined) {
            return [];
        }
        word = word.toLowerCase();
        let materials = items.filter((m) => {
            let wrds = m.name.split(',').map((a) => {
                return (a.trim().toLowerCase())
            })
            return (wrds.indexOf(word) > -1);
        })
        return materials;
    }, [loading, items]);


    return (
        <Wrapper>

            <div className={'words_panel'}>

                <div>
                    <Input type="text" placeholder="Поиск слова..."
                           value={searchQuery}
                           onChange={evt => {
                               setSearchQuery(evt.target.value);
                           }}/>
                </div>

                <div className="alphabet_placeholder">
                    {letters.map((l, k) => {
                        return (
                            <div className={'letter_item'} key={l} onClick={() => {
                                try {
                                    document.getElementById('letter_' + l.toLowerCase()).scrollIntoView();
                                } catch (exc) {
                                    console.log('exc = ', exc);
                                }
                            }}>
                                {l.toUpperCase()}
                            </div>
                        )
                    })}
                </div>


                <div className={'list_placeholder'}>

                    {loading == false ? null :
                        <Code/>
                    }

                    {letters.map((l, lK) => {
                        let key = 'letter_' + l + '_' + lK;
                        let wrds = words.filter((w) => {
                            return ((w.toLowerCase()[0] == l) && (se == '' || (w.toLowerCase().indexOf(se) > -1)))
                        })

                        return (
                            <div className={'letter_section'} key={key}>

                                {wrds.length == 0 ? null :
                                    <div className={'letter_block'} id={'letter_' + l}>
                                        {l.toUpperCase()}
                                    </div>
                                }

                                <div className={'words_list'}>
                                    {wrds.map((word, wK) => {
                                        return (
                                            <div className={'word_item'} key={word} onClick={
                                                () => {
                                                    setSelectedWord(word);
                                                    // let mats = this.getMaterialsByWord(word);
                                                    // selectMaterial(mats[0].id, mats.map(m => m.id))
                                                    // this.setState({selectedWord: word})
                                                }
                                            }>
                                                <div className={'name'}>
                                                    {word}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                            </div>
                        )

                    })}

                </div>

                {selectedWord == undefined ? null :
                    <NiceModal onClose={() => {
                        setSelectedWord(undefined);
                    }}>
                        <IdiomPlaceholder className={'selected_idioms_panel'}>
                            <ViewIdiomTool
                                name={selectedWord}
                                items={getMaterialsByWord(selectedWord)}
                            />
                            {/*<GridMaterialsList materials={this.getIdiomsByName(selectedName)} onMaterialClick={(m) => {*/}
                            {/*    selectMaterial(m.id)*/}
                            {/*}}/>*/}
                        </IdiomPlaceholder>
                    </NiceModal>
                }

            </div>

        </Wrapper>
    );
}

const IdiomPlaceholder = styled.div`
  width: 820px;
  box-sizing: border-box;
  @media (max-width: 860px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
    
`;