import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
// import {useSelector} from "react-redux";
import SyncVideoPlayer from "./SyncVideoPlayer";

import tick from '../../assets/img/checkbox_tick.svg'
import void_tick from '../../assets/img/checkbox.svg'
import {useMappedState} from "redux-react-hook";

export default function FreeSyncVideoPlayer(props) {
    // const currentUser = useSelector(state => state.userReducer.user); // todo: get rid of redux here

    const {
        currentUser
    } = useMappedState(useCallback(state => {
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId)
        }
    }));

    let isTeacher = (currentUser?.userRole == 'teacher');

    const [following, setFollowing] = useState(true);

    return (
        <Wrapper>

            <PlayerPlaceholder
                style={{height: isTeacher ? '100%' : 'calc(100% - 50px)'}}
            >
                {following ?
                    <SyncVideoPlayer
                        {...props}
                        currentUser={currentUser}
                    />
                    :
                    <MyVideo controls={true}>
                        <source src={props.url}/>
                    </MyVideo>
                }

            </PlayerPlaceholder>

            {/*{!isTeacher &&*/}
            {/*<BottomPlaceholder>*/}
            {/*    <TickSpan onClick={() => {*/}
            {/*        setFollowing(!following);*/}
            {/*    }}>*/}
            {/*        <img style={{*/}
            {/*            width: 24, height: 24,*/}
            {/*            marginRight: 10*/}
            {/*        }}*/}
            {/*             src={following ? tick : void_tick}*/}
            {/*        />*/}
            {/*        <span>*/}
            {/*        Синхрон с преподавателем*/}
            {/*    </span>*/}
            {/*    </TickSpan>*/}
            {/*</BottomPlaceholder>*/}
            {/*}*/}

        </Wrapper>
    );
}

const MyVideo = styled.video`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

const PlayerPlaceholder = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const BottomPlaceholder = styled.div`
  height: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
`;

const TickSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    opacity: 0.75;
  }
`;