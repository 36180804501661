import React, {useState} from 'react';
import styled from 'styled-components';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {toolbarOptions} from '../forms/ExerciseCardForm.js';
import {GreenButton} from "../../ira/ui/Buttons";

export default function EssayAnswerTool(props) {
    const {
        isFinished = false,
        isPreview = false,
        isTeacher = false,
        onSave = d => {

        }

    } = props

    const [text, setText] = useState(props.text === undefined ? '' : props.text);

    return(
        <Wrapper>
            <TextAreaPlaceholder>
                <ReactQuill theme="snow"
                            value={text || ''}
                            modules={{toolbar: toolbarOptions}}
                            readOnly={isFinished}
                            onChange={s => {
                                if (isFinished === false) {
                                    setText(s);
                                }
                            }}>
                </ReactQuill>
            </TextAreaPlaceholder>

            {isPreview === true || isFinished === true ? null :
                <ButtonPlaceholder>
                    <GreenButton onClick={() => {
                        onSave({
                            text: text
                        })
                    }}>
                        сохранить
                    </GreenButton>
                </ButtonPlaceholder>
            }
        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 100%;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const TextAreaPlaceholder = styled.div`
  width: 100%;
`;

const ButtonPlaceholder = styled.div`
  width: fit-content;
`;