import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {diffChars} from 'diff'
import './diff.css'

function eFix(s) {
    if (s == undefined) {
        return undefined;
    }
    return s;
    // return s.replace(/ё/g, 'е').replace(/’/g, "'");
    // return s.replace(/ё/g, 'е').replace(/’/g, "'");
}

export default function TextsDiff(props) {
    const {
        text1 = '',
        text2 = ''
    } = props;


    const diff = diffChars(eFix(text1), eFix(text2));
    let words = diff.map((part, i) => {
        const color = part.added ? 'green' :
            part.removed ? 'red' : 'grey';
        const className = part.added ? 'ins' :
            part.removed ? 'del' : '';

        return <span className={className} key={`d${i}`}>{` ${part.value} `}</span>
    })

    return (
        <Wrapper className={'Difference'}>
            {words}
        </Wrapper>
    );
}

const Wrapper = styled.div`

`;