import firebase from 'firebase'

import {FIREBASE_CREDENTIALS} from "../constants/config";

const FirebaseAPI = {

    initFirebase() {
        if (!firebase.apps.length) {
            firebase.initializeApp(window.FIREBASE_CREDENTIALS || FIREBASE_CREDENTIALS);
        }
    },

    // end of simple board section

    getLazyRoomSettings(roomId, data) {
        console.log('getLazyRoomSettings: roomId, data = ', roomId, data);
        let defaultData = {
            ...data,
            lastUpdateTimestamp: +new Date()
        };
        return new Promise((resolve, reject) => {
            let ref = firebase.database().ref(`/RoomSettings/${roomId}`);
            ref.once('value').then(snapshot => {
                if (snapshot.exists()) {
                    let value = snapshot.val();
                    console.log('exists! value = ', value);
                    return resolve(value);
                }
                firebase.database().ref(`/RoomSettings/${roomId}`).set(defaultData).then(pld => {
                    resolve(defaultData);
                });
            });
        });
    },

    subscribeOnRoomSettingsChanges(roomId, onChangeCallback = () => {
    }, defaultD = {}) {
        return new Promise((resolve, reject) => {
            this.getLazyRoomSettings(roomId, defaultD).then(pld => {
                firebase.database().ref(`/RoomSettings/${roomId}`).on('value', (snapshot) => {
                    let v = snapshot.val();
                    onChangeCallback(v);
                });
                resolve({
                    data: pld,
                    unsubscribeFunction: () => {

                    }
                })
            });
        });
    },

    updateRoomSettingsData(roomId, newData) {
        console.log('updateRoomSettingsData: roomId, newData = ', roomId, newData);
        return new Promise((resolve, reject) => {
            firebase.database().ref(`/RoomSettings/${roomId}`).set({
                ...newData,
                lastUpdateTimestamp: +new Date()
            }).then(() => {
                resolve()
            }).catch(e => {
                reject(e);
            })
        });
    },

    // video sync

    getSyncVideoState(roomName) {
        return new Promise((resolve, reject) => {
            let ref = firebase.database().ref(`/SuperSyncVideo/${roomName}`).once('value').then((snapshot) => {
                resolve(snapshot.val());
            });
        })
    },

    getLazySyncVideo(roomName) {
        return new Promise((resolve, reject) => {
            firebase.database().ref(`/SuperSyncVideo`).child(roomName).transaction(currentImageData => {
                if (currentImageData === null) {
                    return {
                        playing: false,
                        position: 0
                    }
                }
            }, (err, commited) => {
                if (err != undefined) {
                    return reject(err)
                }
                resolve();
            })
        })
    },


    subscribeSyncVideoOnChanges(roomName, onChangeCallback) {
        console.log('subscribeSyncVideoOnChanges: roomName = ', roomName);
        return new Promise((resolve, reject) => {
            this.getLazySyncVideo(roomName).then(pld => {
                let starCountRef = firebase.database().ref('/SuperSyncVideo/' + roomName);
                starCountRef.on('value', function (snapshot) {
                    let v = snapshot.val();
                    onChangeCallback(v);
                });
                resolve(pld);
            });
        })
    },

    unsubscribeSyncVideoChanges(roomName) {
        return new Promise((resolve, reject) => {
            this.getLazySyncVideo(roomName).then(pld => {
                let starCountRef = firebase.database().ref('/SuperSyncVideo/' + roomName);
                starCountRef.off('value');
                resolve(pld);
            });
        })
    },

    updateSyncVideoState(roomName, playing, position) {
        console.log('updateSyncVideoState: roomName, playing, position = ', roomName, playing, position);
        return new Promise((resolve, reject) => {
            let starCountRef = firebase.database().ref('/SuperSyncVideo/' + roomName);
            let newRoomState = {
                playing: playing || false,
                position: position || 0
            };
            try{
                starCountRef.set(newRoomState);
            }catch(ee){
            }

            resolve(newRoomState);
        })
    },

    // chat room

    getLazyChatRoom(roomId, data) {
        console.log('getLazyChatRoom: roomId, data = ', roomId, data);
        let defaultData = {
            ...data,
            lastUpdateTimestamp: +new Date()
        };
        return new Promise((resolve, reject) => {
            let ref = firebase.database().ref(`/ChatRoom/${roomId}`);
            ref.once('value').then(snapshot => {
                if (snapshot.exists()) {
                    let value = snapshot.val();
                    console.log('exists! value = ', value);
                    return resolve(value);
                }
                firebase.database().ref(`/ChatRoom/${roomId}`).set(defaultData).then(pld => {
                    resolve(defaultData);
                });
            });
        });
    },

    subscribeOnRoomChat(roomId, onChangeCallback = () => {
    }, defaultD = {}) {
        return new Promise((resolve, reject) => {
            this.getLazyRoomSettings(roomId, defaultD).then(pld => {
                firebase.database().ref(`/ChatRoom/${roomId}`).on('value', (snapshot) => {
                    let v = snapshot.val();
                    onChangeCallback(v);
                });
                resolve({
                    data: pld,
                    unsubscribeFunction: () => {

                    }
                })
            });
        });
    },

    updateRoomChatData(roomId, newData) {
        console.log('updateRoomChatData: roomId, newData = ', roomId, newData);
        return new Promise((resolve, reject) => {
            firebase.database().ref(`/ChatRoom/${roomId}`).set({
                ...newData,
                lastUpdateTimestamp: +new Date()
            }).then(() => {
                resolve()
            }).catch(e => {
                reject(e);
            })
        });
    },

}

export default FirebaseAPI;
