import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

export default function UserTextInputTool(props) {
    const {
        time,
        isPreview = false,
        onAnswer = d => {

        }
    } = props;

    const [text, setText] = useState('');
    const textRef = useRef(text);
    textRef.current = text;

    useEffect(() => {
        setTimeout(() => {
            if (isPreview === false) {
                onAnswer(textRef.current);
                setText('');
            }
        }, 1000 * time)
    }, [props.id])

    return (
        <Wrapper>
            <Input autoComplete="off"
                   autoCorrect="off"
                   autoCapitalize="off"
                   spellCheck="false"
                   value={text}
                   autoFocus={!isPreview}
                   onChange={(evt) => {
                       setText(evt.target.value);
                   }}/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Input = styled.textarea`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  outline: none;
  font-size: 20px;
  border: 1px solid lightgrey;
  text-align: center;
  resize: none;
  padding: 10px;
  
  :focus{
    border: 2px solid blue;
  }
`;