import React from 'react';
import styled from 'styled-components';
import {FACELESS_AVATAR} from "../../../constants/config";

import CommonHelper from "../../../helpers/CommonHelper";

class StudentsList extends React.Component { // todo: replace with hook

    static defaultProps = {
        users: [],
        showClasses: false,
        searchQuery: '',
        onUserClick: (u, cl) => {

        }
    }

    static propTypes = {}

    state = {}

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    componentWillReceiveProps() {

    }

    render = () => {
        let {users, onUserClick, showClasses, searchQuery} = this.props;
        let sq = (searchQuery == undefined) ? '' : searchQuery.trim().toLowerCase();
        let shownUsers = users.filter(u => {
            if (sq == '') {
                return true;
            }
            let s = CommonHelper.getUserName(u).toLowerCase();
            return (s.indexOf(sq) > -1);
        })

        return (
            <Wrapper className={''}>

                <UsersList>
                    {shownUsers.map((u, k) => {
                        let {firstName, lastName, id, avatar, email, classes} = u;
                        let ava = (avatar == undefined) ? FACELESS_AVATAR : avatar;
                        return (
                            <UserItem key={`${id}_${k}`} onClick={() => {
                                onUserClick(u);
                            }}>

                                <Left>
                                    <Avatar style={{backgroundImage: `url('${ava}')`}}/>
                                </Left>

                                <Right>
                                    <Name>
                                        {CommonHelper.getUserName(u)}
                                    </Name>
                                    <Email>
                                        {email}
                                    </Email>
                                    {showClasses == false ? null :
                                        <ClassesPlaceholder>
                                            {classes.map((cl, j) => {
                                                return (
                                                    <ClassItem key={cl.id} onClick={() => {
                                                        CommonHelper.linkTo(`/groups/${cl.id}`);
                                                    }}>
                                                        {cl.name}
                                                    </ClassItem>
                                                )
                                            })}
                                        </ClassesPlaceholder>
                                    }

                                </Right>

                            </UserItem>
                        )
                    })}
                </UsersList>

            </Wrapper>
        )
    }

}

const Wrapper = styled.div`

`;


const UsersList = styled.div`
  width: 100%;
`;

const UserItem = styled.div`
  display: inline-block;
  vertical-align: top;
  margin: 5px;
  padding: 5px;
  background: white;
  box-sizing: border-box;
  width: calc(33.333% - 10px);
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  
  @media (max-width: 720px){
    width: 95%;
  }

`;

const leftWidth = 60;
const Left = styled.div`
  display: inline-block;
  vertical-align: top;
  width: ${leftWidth}px;
`;

const Right = styled.div`
  display: inline-block;
  vertical-align: top;
  width: calc(100% - ${leftWidth}px);
  padding-left: 5px;
  box-sizing: border-box;
  padding-top: 3px;
`;

const Name = styled.div`
  font-weight: bold;
`;

const Email = styled.div`
  opacity: 0.7;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ClassesPlaceholder = styled.div`

`;

const ClassItem = styled.div`
  display: inline-block;
  vertical-align: top;
  margin: 4px;
  padding: 3px;
  border-radius: 3px;
  border: 1px solid whitesmoke;
  background-color: white;
  cursor: pointer;

  :hover {
    background: wheat;
  }

  :first-of-type {
    margin-left: 0px;
  }
`;

const Avatar = styled.div`
  width: ${leftWidth}px;
  height: ${leftWidth}px;
  border-radius: 4px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: whitesmoke;
`;


//const mapStateToProps = (state) => {
//    return {
//        currentUserId: state.users.currentUserId,
//        loading: state.users.loading
//    }
//}

//const mapDispatchToProps = (dispatch) => {
//    return {
//    }
//}

//StudentsList = connect(mapStateToProps, mapDispatchToProps)(StudentsList)

export default StudentsList
