import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';

import axios from 'axios'

export default function SimpleCitationTool(props) {
    const {
        onQuote = url => {

        }
    } = props;

    useEffect(() => {

        if (window.addEventListener) {
            window.addEventListener("message", onMessage, false);
        } else if (window.attachEvent) {
            window.attachEvent("onmessage", onMessage, false);
        }

        function onMessage(event) {
            var data = event.data;

            if (typeof (window[data.func]) == "function") {
                window[data.func].call(null, data.message);
            }
        }

        window.citationHandler = (message) => {
            let quote = message;
            onQuote(quote);
        }
    }, []);

    return (
        <Wrapper>
            <FramePlaceholder>
                <MyIframe src={`https://patient-ip-app.netlify.app/?t=${+new Date()}`}/>
            </FramePlaceholder>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const FramePlaceholder = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  position: relative;
  margin: 0 auto;
`;


const MyIframe = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
`;