import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import {DEFAULT_TEXT_SLIDE_BACKGROUND} from "../config";

let getH = () => {
    try {
        return document.getElementsByClassName('treadmill_video_slide_placeholder')[0].clientHeight;
    } catch (exc) {

    }
}

let pauseMediaIfNeeded = (media, currentTime, mediaReplay, isPaused) => {
    if (media == undefined || currentTime == undefined || currentTime < 0 || isPaused == undefined) {
        return;
    }
    let mediaDuration = media.duration;

    if (isNaN(mediaDuration)) {
        media.onloadedmetadata = () => {
            pauseMediaIfNeeded(media, currentTime, mediaReplay, isPaused);
        };
        return;
    }

    if (isPaused == false) {
        if (mediaReplay?.shouldReplay == true) {
            let timesPlayed = Math.floor(currentTime / mediaDuration);

            if (mediaDuration * mediaReplay?.times > currentTime) {
                media.currentTime = currentTime - mediaDuration * timesPlayed;
                media.play();
            } else {
                media.currentTime = 0;
                media.pause();
            }

        } else {

            if (mediaDuration > currentTime) {
                media.currentTime = currentTime;
                media.play();
            } else {
                media.currentTime = 0;
                media.pause();
            }

        }
    } else {
        media.pause();
    }
}

export default function VideoSlide(props) {
    const {
        id,
        imageSrc,
        coverBackground = false,
        videoTime = 0,
        video = undefined,
        videoSrc,
        videoVolume = 10,
        audio = undefined,
        audioSrc,
        audioVolume = 10,
        currentTime,
        isPreview = false,
        isPaused = false,
        overlayBackground = `rgba(0, 0, 0, 0.4)`,
        backgroundColor = '#000',
        hasBackgroundColor = false,
        textBackgroundColor = '#000',
        hasCountDown = false,
        timeBeforeSlideEnd = 0,

        text = '',

        textColor = 'white',
        textSize = 12, // in percents

        textPosition = 'center' // top, center, bottom
    } = props;

    let actualTime = currentTime - +props.from;
    let videoElement = video ? video : document.getElementsByClassName('treadmill_video_slide_video')[0];
    let audioElement = audio ? audio : document.getElementsByClassName('treadmill_video_slide_audio')[0];
    let hasAudio = audioSrc !== undefined && audioSrc !== '';

    const [height, setHeight] = useState();

    useEffect(() => {
        if (height == undefined) {
            setHeight(getH());
        }
        if (audio) {
            if (audioSrc) {
                audioElement.src = audioSrc;
            } else {
                audioElement.pause();
            }
        }
        if (video) {
            let videoPlaceholder = document.getElementById('treadmill_video_placeholder');
            videoPlaceholder.appendChild(video);
            video.src = videoSrc;
            video.volume = videoVolume / 10;
        }
    }, []);
    
    if (video) {
        video.height = height;
    }

    let fontSize = height * textSize / 100.0;
    let lineHeight = 1.2 * fontSize;

    useEffect(() => {
        if (videoElement) {
            pauseMediaIfNeeded(videoElement, videoTime, props?.videoReplay, isPaused);
        }
        if (audioElement && audioSrc) {
            pauseMediaIfNeeded(audioElement, videoTime, props?.audioReplay, isPaused);
        }
    }, [videoTime, id]);

    useEffect(() => {
        if (video) {
            if (actualTime == 0 && isPaused == false) {
                video.play();
            } else {
                pauseMediaIfNeeded(videoElement, actualTime, props?.videoReplay, isPaused);
            }
        }
        if (audio && audioSrc) {
            if (actualTime == 0 && isPaused == false) {
                audio.play();
            }
            pauseMediaIfNeeded(audioElement, actualTime, props?.audioReplay, isPaused);
        }
    }, [videoElement, audioElement])

    useEffect(() => {
        if (audioElement) {
            audioElement.volume = audioVolume / 10;
        }
    }, [audioElement, audioVolume]);

    useEffect(() => {
        if (videoElement) {
            videoElement.volume = videoVolume / 10;
            if (videoVolume > 0) {
                videoElement.muted = false;
            } else {
                videoElement.muted = true;
            }
        }
    }, [videoElement, videoVolume])

    useEffect(() => {
        if (isPaused === false) {
            if (audioElement && audioSrc) {
                pauseMediaIfNeeded(audioElement, actualTime, props?.audioReplay, isPaused);
            }

            if (videoElement) {
                pauseMediaIfNeeded(videoElement, actualTime, props?.videoReplay, isPaused);
            }
        } else {
            videoElement?.pause();
            audioElement?.pause();
        }
    }, [isPaused, id])

    useEffect(() => {
        if (videoElement != undefined && props?.videoReplay?.shouldReplay === true) {
            videoElement.onended = () => {
                let totalVideoTime = Math.floor(videoElement.duration * props?.videoReplay?.times);
                let currTime = currentTime - +props.from;
                if (totalVideoTime > currTime) {
                    videoElement.currentTime = 0;
                    if (isPaused === false) {
                        videoElement.play();
                    }
                } else {
                    videoElement.pause();
                }
            }
        }
    }, [currentTime, videoElement])

    useEffect(() => {
        if (audioElement != undefined && props?.audioReplay?.shouldReplay === true) {
            audioElement.onended = () => {
                let totalAudioTime = Math.floor(audioElement.duration * props?.audioReplay?.times);
                let currTime = currentTime - +props.from;
                if (totalAudioTime > currTime) {
                    audioElement.currentTime = 0;
                    if (isPaused === false && audioSrc) {
                        audioElement.play();
                    }
                } else {
                    audioElement.pause();
                }
            }
        }
    }, [currentTime, audioElement])

    return (
        <Wrapper className='treadmill_video_slide_placeholder'>
            {coverBackground == false ? null :
                <Cover backgroundColor={backgroundColor} />
            }
            {imageSrc == undefined || imageSrc == '' ? null :
                <Image src={imageSrc} />
            }
            <VideoPlaceholder id='treadmill_video_placeholder'>
                {video != undefined ? null :
                    <Video
                        className={'treadmill_video_slide_video'}
                        controls={isPreview}
                        autoPlay={!isPaused && !isPreview}
                        playsInline
                        src={videoSrc}
                    />
                }
            </VideoPlaceholder>
            <TextLayerPlaceholder textPosition={textPosition}>
                <Text style={{
                    fontSize: `${fontSize}px`,
                    lineHeight: `${lineHeight}px`,
                    color: textColor,
                    backgroundColor: hasBackgroundColor ? textBackgroundColor : 'transparent'
                }}>
                    <div dangerouslySetInnerHTML={{__html: `${text}`}}></div>
                </Text>
                
            </TextLayerPlaceholder>
            {hasAudio === false || audio != undefined || isPreview ? null :
                <audio key={id} className={'treadmill_video_slide_audio'} autoPlay={!isPaused} preload='none' playsInline>
                    <source src={`${audioSrc + `#t=${videoTime}`}`}/>
                </audio>
            }
        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${props => (props.background == undefined ? DEFAULT_TEXT_SLIDE_BACKGROUND : props.background)};
`;

const VideoPlaceholder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;
`;

const Video = styled.video`
  height: 100%;
  //width: 100%;
  max-width: 100%;
  background: black;
  max-height: 100%;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  object-fit: contain;
  z-index: 0;
`;

const TextLayerPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  z-index: 5;
  justify-content: ${props => ({'top': 'flex-start', 'bottom': 'flex-end', 'center': 'center'}[props.textPosition])};
`;

const Text = styled.div`
  text-align: center;
  z-index: 10;
`;

const Image = styled.img`
  position: absolute;
  width: 100%;
  min-height: 100%;
  z-index: 1;
`;

const Cover = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${props => props.backgroundColor};
    position: absolute;
    z-index: 2;
`;