import React, {PropTypes} from 'react';
import CreateVideoPreviewPanel from '../panels/CreateVideoPreviewPanel'
import NiceModal from "../../../modals/NiceModal";

import styled from 'styled-components'

class SelectVideoScreenshotWrapper extends React.Component {

    static defaultProps = {
        onSave: () => {

        },
        url: 'https://drive.sabir.pro/videos/Ila26VkBEsYquL6UG560.mp4'
    }

    static propTypes = {}

    state = {
        dialogVisible: false
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    componentWillReceiveProps() {

    }

    render = () => {
        let {dialogVisible} = this.state;
        let {onSave, url} = this.props;
        // let aUrl = (url == undefined) ? undefined : ((url.indexOf('sabir.pro') > -1) ? url : `https://www.englishpatient.org/cached_video/?url=${url}`);
        let aUrl = url;

        return (
            <Wrapper className={'select_video_screenshot_wrapper'}>

                <div onClick={() => {
                    this.setState({
                        dialogVisible: true
                    });
                }}>
                    {this.props.children}
                </div>

                {dialogVisible == false ? null :
                    <NiceModal level={1000000} onClose={() => {
                        this.setState({
                            dialogVisible: false
                        });
                    }}>

                        <InnerWrapper className="create_video_preview_panel_dialog_wrapper">
                            <CreateVideoPreviewPanel url={aUrl} onSave={(imgUrl) => {
                                onSave(imgUrl);
                                this.setState({
                                    dialogVisible: false
                                });
                            }}/>
                        </InnerWrapper>


                    </NiceModal>
                }

            </Wrapper>
        )
    }

}

const Wrapper = styled.div`

`;

const InnerWrapper = styled.div`
  max-height: calc(90vh - 10px);
  overflow-y: auto;
  width: 720px;
  @media(max-width: 800px){
    width: 100%;
  }
`;

//const mapStateToProps = (state) => {
//    return {
//        currentUserId: state.users.currentUserId,
//        loading: state.users.loading
//    }
//}

//const mapDispatchToProps = (dispatch) => {
//    return {
//    }
//}

//SelectVideoScreenshotWrapper = connect(mapStateToProps, mapDispatchToProps)(SelectVideoScreenshotWrapper)

export default SelectVideoScreenshotWrapper
