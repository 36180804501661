import React, {CSSProperties, ReactElement, useEffect, useState} from 'react';
import styled from 'styled-components';
import '../style.css'

const getPositionFromDragEvent = (direction, event) => {
    if (direction == 'horizontal') {
        return event.clientX;
    } else {
        return event.clientY;
    }
}
/**
 * Set cursor and size of the separator
 */
const SeparatorDiv = styled.div`
  user-select: none;
  flex: none;
  ${props => props.direction == 'horizontal' ? 'height' : 'width'}: 100%;
  ${props => props.direction == 'horizontal' ? 'width' : 'height'}: 10px;
  cursor: ${props => props.direction == 'horizontal' ? 'e-resize' : 'n-resize'};
`

export const Separator = (props) => {

    const [mousePositionAtLastMouseEvent, setMousePositionAtLastMouseEvent] = useState(null);

    const getMovedDistance = (event) => {
        const mousePosition = getPositionFromDragEvent(props.direction, event);
        return mousePosition - mousePositionAtLastMouseEvent;
    }
    /**
     * Start dragging
     * Record the initial mouse position to calculate distance later
     */
    const mouseDownEventHandler = (event) => {
        const mousePosition = getPositionFromDragEvent(props.direction, event);
        setMousePositionAtLastMouseEvent(mousePosition);
        props.onDragStart();
    }
    /**
     * End dragging
     * Clear initial mouse position, which will stop mouse move handling
     */
    const mouseUpEventHandler = (event) => {
        if (mousePositionAtLastMouseEvent) {
            setMousePositionAtLastMouseEvent(null);
        }
    }
    /**
     * Calculate distance from the initial mouse position when start dragging
     * Send the distance to parent component
     */
    const mouseMoveEventHandler = (event) => {
        if (mousePositionAtLastMouseEvent !== null && mousePositionAtLastMouseEvent !== undefined) {
            const movedDistance = getMovedDistance(event);
            props.onDrag(movedDistance);
        }
    }
    /**
     * Listen to mouse move and mouse up at global because the position of the separator
     * will not be updated at mouse position instantly
     */
    useEffect(() => {
        document.body.addEventListener('mouseup', mouseUpEventHandler);
        document.body.addEventListener('mousemove', mouseMoveEventHandler);
        return () => {
            document.body.addEventListener('mouseup', mouseUpEventHandler);
            document.body.removeEventListener('mousemove', mouseMoveEventHandler);
        }
    }, [mousePositionAtLastMouseEvent]);

    return <SeparatorDiv
        style={props.style}
        className={props.className || 'x-separator'}
        direction={props.direction}
        onMouseDown={mouseDownEventHandler}
    >{props.children}</SeparatorDiv>
}