import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import Spin from 'arui-feather/spin'

import {GreenButton} from "../../ira/ui/Buttons";
import {Input} from "../../ira/ui/Inputs";
import {translate} from "../../../utils/Translate";

export default function SimpleSlideForm(props) {
    const {
        onSave = d => {

        },
        loading = false
    } = props;

    const [name, setName] = useState(props?.name ? props.name : '');


    return (
        <Wrapper>

            <Row>
                <Label>
                    {translate('module_name')}
                </Label>
                <Input value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Row>

            <BottomPlaceholder>
                <GreenButton onClick={() => {
                    onSave({name: name});
                }}>
                    <Spin visible={loading}/>
                    {!loading && translate('save')}
                </GreenButton>
            </BottomPlaceholder>

        </Wrapper>
    );
}

const BottomPlaceholder = styled.div`

`;

const Wrapper = styled.div`

`;

const Row = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;