import {combineReducers} from 'redux';

import UsersReducer from './UsersReducer.js';
import MaterialsReducer from './MaterialsReducer.js';
import GroupsReducer from './GroupsReducer.js';
import TestsReducer from './TestsReducer.js';
import SlidesReducer from './SlidesReducer.js';
import ContainersReducer from './ContainersReducer.js';
import TreadmillsReducer from './TreadmillsReducer.js';
import LanguageReducer from './LanguageReducer.js';
import PostsReducer from './PostsReducer.js';

import FoldersReducer from "./FoldersReducer";
import AccessGroupsReducer from "./AccessGroupsReducer";
import LessonsReducer from "./LessonsReducer";
import TasksReducer from "./TasksReducer";
import CopyPasteReducer from "./CopyPasteReducer";
import ExercisesReducer from "./ExercisesReducer";
import DrochkaExercisesReducer from "./DrochkaExercisesReducer";
import DialogsReducer from "./DialogsReducer";
import QuestionnairesReducer from "./QuestionnairesReducer";
import OxWordsReducer from "./OxWordsReducer";
import PanelSelectorReducer from "./CoursesLessonSelectorReducer";
import TreadmillScaleReducer from "./TreadmillScaleReducer";

export const reducer = combineReducers({

    buffer: CopyPasteReducer,

    language: LanguageReducer,
    users: UsersReducer,

    tests: TestsReducer,
    materials: MaterialsReducer,

    tasks: TasksReducer,
    exercises: ExercisesReducer,
    drochkaExercises: DrochkaExercisesReducer,

    lessons: LessonsReducer,
    dialogs: DialogsReducer,
    questionnaires: QuestionnairesReducer,

    oxwords: OxWordsReducer,

    slides: SlidesReducer,
    containers: ContainersReducer,
    folders: FoldersReducer,
    groups: GroupsReducer,

    treadmills: TreadmillsReducer,
    posts: PostsReducer,
    treadmillScale: TreadmillScaleReducer,
    accessGroups: AccessGroupsReducer,

    pageSelector: PanelSelectorReducer
});

