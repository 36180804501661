import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import TeacherTemplate from "../templates/TeacherTemplate";
import UpdateProfilePanel from "../profile/panels/UpdateProfilePanel";

export default function TeacherProfileApp(props) {


    return (
        <TeacherTemplate active={'profile'}>

            <Wrapper>
                <UpdateProfilePanel/>
            </Wrapper>


        </TeacherTemplate>
    );
}

const Wrapper = styled.div`
  width: 720px;
  background: white;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 20px;
  @media(max-width: 1020px){
    width: 100%;
  }
`;

const ConstructionPlaceholder = styled.div`
  height: 80vh;
  text-align: center;
`;