import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import * as actions from '../../../redux/actions/ContainersActions'
import {DEFAULT_VIDEO_PLACEHOLDER} from "../../../constants/config";
import ReactPlayer from "react-player";
import {useMappedState, useDispatch} from "redux-react-hook";

export default function TaskPreviewPanel(props) {
    const {
        id
    } = props;
    const dispatch = useDispatch();

    const {
        containersMap,
        loading,
        container,
        childrenContainers
    } = useMappedState(useCallback(state => {
        let cont = state.containers.containersMap.get(id);
        let childrenContainers = (cont?.childrenIds || []).map(x => state.containers.containersMap.get(x)).filter(x => (x != undefined));
        return {
            container: cont,
            childrenContainers: childrenContainers,
            loading: state.containers.loading
        }
    }));

    useEffect(() => {
        dispatch(actions.getContainer(id));
        dispatch(actions.getChildren(id));
    }, []);

    console.log('ContainersTreePanel: render: childrenContainers = ', childrenContainers);
    if (container == undefined) {
        return <span>loading...</span>;
    }


    return (
        <Wrapper>

            {childrenContainers.map((a, i) => {
                return (
                    <ChildItem key={a.id}>
                        {`${a.type} - ${a.subtype} - ${a.name}`}

                        {a.subtype != 'video' ? null :
                            <div style={{height: 400, width: '100%'}}>
                                <ReactPlayer
                                    width={'100%'}
                                    height={'100%'}
                                    light={a?.content?.avatar || a?.content?.fragment || DEFAULT_VIDEO_PLACEHOLDER}
                                    controls={true}
                                    url={a?.content?.url || a?.content?.video}/>

                            </div>
                        }

                        {a?.transcript && <div style={{overflow: "scroll", height: 150}}>
                            {a?.transcript}
                        </div>}


                        {/*логику вы поняли - тут остальные типы контента отрисуйте*/}
                        {/*
                            отрисовку элементарной единицы контента лучше вывести в отдельный компонент
                        */}

                    </ChildItem>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const ChildItem = styled.div`
  margin-bottom: 20px;
`;