import React, {useEffect, useState} from 'react';
import styled from 'styled-components'

export default function SettingsModalForm(props) {
    const {
        ledgerSettings = [],
        chosenLedgerSettings = [],
        tasksSettings = [],
        onSaveSettings = d => {

        }
    } = props;

    const [chosenSettings, setChosenSettings] = useState(chosenLedgerSettings);
    const [chosenTasksSettings, setChosenTasksSettings] = useState(tasksSettings[0]);

    useEffect(() => {
        console.log('chosen settings: ', chosenSettings);
    }, [chosenSettings])

    return (
        <Wrapper>
            <Title>
                Настройки отображения
            </Title>
            <TopPlaceholder>
                {ledgerSettings.map((x, i) => {
                    return (
                        <LinePlaceholder onClick={() => {
                            if (chosenSettings.indexOf(x) === - 1) {
                                setChosenSettings(chosenSettings.concat(x));
                            } else {
                                setChosenSettings(chosenSettings.filter(item => item !== x));
                            }
                        }}>
                            <Checkbox color={'#FFA71B'} value={chosenSettings.indexOf(x) >= 0}/>
                            <div>{x}</div>
                        </LinePlaceholder>
                    )
                })
                }
            </TopPlaceholder>

            {/*<Title>*/}
            {/*    Отображение заданий*/}
            {/*</Title>*/}
            {/*<BottomPlaceholder>*/}
            {/*    {tasksSettings.map(x => {*/}
            {/*        return (*/}
            {/*            <BottomLinePlaceholder onClick={() => setChosenTasksSettings(x)}>*/}
            {/*                <RadioButton color={'#FFA71B'} value={chosenTasksSettings === x}/>*/}
            {/*                <div>{x}</div>*/}
            {/*            </BottomLinePlaceholder>*/}
            {/*        )*/}
            {/*    })}*/}
            {/*</BottomPlaceholder>*/}

            <SaveButtonPlaceholder>
                <SaveButton onClick={() => {
                    onSaveSettings({
                        ledgerSettings: chosenSettings,
                        tasksSettings: chosenTasksSettings
                    })
                }}>Сохранить</SaveButton>
            </SaveButtonPlaceholder>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  font-size: 18px;
  user-select: none;
`;

const Title = styled.div`
  font-size: 22px;
  margin-top: 10px;
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  //border-bottom: 1px solid #ECECEC;
  padding-bottom: 10px;
  padding-top: 8px;
`;

const LinePlaceholder = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

const Checkbox = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50px;
  border: 2px solid ${props => (props.color === undefined ? 'grey' : props.color)};
  background-color: ${props => (props.value === true ? props.color : 'transparent')};
`;

const BottomPlaceholder = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const BottomLinePlaceholder = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

const RadioButton = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 10px;
  background-color: ${props => (props.value === true ? props.color : 'transparent')};
`;

const SaveButtonPlaceholder = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;

const SaveButton = styled.div`
  width: fit-content;
  border-radius: 1000px;
  background-color: #ff9900;
  color: white;
  padding: 6px 12px;
  font-size: 18px;
  cursor: pointer;
`;