import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

let regex = /\[\[[a-zA-Z0-9а-яА-Я,;|\u0590-\u05FF -\\"_']*\]\]/g;

/*
category1: [[one|two|three]]

category2: [[four|five|six]]

category3 [[seven|eight|nine]]

 */


function removeOne(items, x) {
    let arr = [];
    let f = false;
    for (let i in items) {
        let x_ = items[i];
        if (x_ == x && f == false) {
            f = true;
            continue;
        }
        arr.push(x_);
    }
    return arr;
}

function getRestWords(userAnswer, words) {
    let arr = [];
    // let items = userAnswer.split('|').filter(x => (x != ''));
    // let items = userAnswer.split('|').reduce((xArr, a) => ([...xArr, ...a]), []).map(xx => xx.split('_')).reduce((xArr, a) => ([...xArr, ...a]), []);
    let items = userAnswer.split('|').map(x => x.split('_')).reduce((xArr, a) => ([...xArr, ...a]), []);
    // console.log('getRestWords: items = ', items);
    let xArr = JSON.parse(JSON.stringify(items));
    for (let i in words) {
        let w = words[i];
        if (xArr.indexOf(w) == -1) {
            arr.push(w);
        } else {
            xArr = removeOne(xArr, w);
        }
    }
    return arr.sort((a, b) => {
        if (a > b) {
            return 1;
        }
        if (a < b) {
            return -1;
        }
        return 0;
    });
}

function getWords(text) {
    try{
        if (text == undefined || text.length < 3) {
            return [];
        }
        if (text.indexOf('[[') == -1 || text.indexOf(']]') == -1) {
            return [];
        }
        const found = text.match(regex).map(xx => xx.replace('[[', '').replace(']]', '')).map(xx => (getSortedArr(xx.split('|')).join('_')));
        return found;
    }catch(exc){
        console.log('getWords: text = ', text);
    }
    return [];
}

function getWordsVariants(text) {
    try {
        if (text == undefined || text.length < 3) {
            return [];
        }
        if (text.indexOf('[[') == -1 || text.indexOf(']]') == -1) {
            return [];
        }
        let found0 = text.match(regex).map(xx => xx.replace('[[', '').replace(']]', ''));
        const found = found0.map(xx => xx.split('|')).reduce((xArr, a) => ([...xArr, ...a]), []).map(xx => xx.split('&')).reduce((xArr, a) => ([...xArr, ...a]), []);
        return found;
    } catch (exc) {
        console.log('getWordsVariants: exc = ', exc);
    }
    return [];
}

function getSortedArr(arr) {
    return arr.sort((a, b) => {
        if (a.toLowerCase() > b.toLowerCase()) {
            return 1;
        }
        if (a.toLowerCase() < b.toLowerCase()) {
            return -1;
        }
        return 0;
    })
}

function getItems(text) {
    if (text.length < 3) {
        return [];
    }
    let words = getWords(text);
    let items = [];
    let currentItem = '';
    let startIndex = 0;
    let k = 0;
    for (let i = 1; i < text.length; i++) {
        let currChar = text[+i];
        let prevChar = text[+i - 1];
        if (`${prevChar}${currChar}` == '[[') {
            let ss = text.slice(+startIndex, +i - 1);
            items.push({
                type: 'out',
                text: ss
            });
        }
        if (`${prevChar}${currChar}` == ']]') {
            startIndex = +i + 1;
            items.push({
                type: 'word',
                text: words[k],
                index: k
            });
            k = +k + 1;
        }
    }
    items.push({
        type: 'out',
        text: text.slice(startIndex)
    })
    return items;
}

function getDefaultUserAnswer(s, words) {
    if (s == undefined || s.split('|').length != words.length) {
        return words.map(x => '').join('|');
    }
    return s;
}

function getUserWordByUserAnswer(s, index) {
    let arr = s.split('|');
    if (+index > arr.length - 1) {
        return ''
    }
    return arr[+index];
}

function isHebrew(text) {
    if (text === undefined || text.trim() === '') {
        return false;
    }
    let hebrewChars = /[\u0590-\u05FF]+/g;
    let s = text.replace(hebrewChars, '')
    return s.length < text.length
}

function checkAnswer(answer, text) {
    if (!answer || !text || answer.trim() == '' || text.trim() == '') {
        return false;
    }
    
    let textArr = text.split('_').sort();
    let answerArr = answer.split('_').sort();

    return JSON.stringify(textArr) == JSON.stringify(answerArr);
}

export default function DndGroupingExercise(props) {
    const {
        // text = 'This is a [[test|qq|ww]] exercise for the [[university]] team. This [[text]] will be parsed. This is a [[A]] [[A]].',
        text = '',
        onSave = (x, isCorrect) => {

        },
        canAnswer = true,
        showCorrectAnswer = false,
        loading = false
    } = props;
    const items = getItems(text);
    const words = getWords(text);
    const [userAnswer, setUserAnswer] = useState(getDefaultUserAnswer(props.userAnswer, words));
    const [updatedSomething, setUpdatedSomething] = useState(false);
    // console.log('DndGroupingExercise: props.userAnswer = ', props.userAnswer);
    console.log('DndGroupingExercise: words = ', words);

    useEffect(() => {
        setUserAnswer(getDefaultUserAnswer(props.userAnswer, getWords(props.text)));
    }, [props.text, props.userAnswer])

    let wordsVars = getWordsVariants(text);
    let bottomWords = useMemo(() => shuffle(getRestWords(userAnswer, wordsVars)), [userAnswer, text]);

    useEffect(() => {
        if (loading == true || props.canAnswer == false || updatedSomething == false) {
            return;
        }
        
        let answerWords = userAnswer.split('|');
        answerWords = answerWords.reduce((arr, x) => arr.concat(x.split('_')), []);
        let variants = getWordsVariants(text);
        let answeredAll = answerWords.length == variants.length;

        // console.log('useEffect: answeredAll = ', answeredAll);
        if (answeredAll == true) {
            let f = true;
            let xArr = userAnswer.split('|');
            for (let j in xArr) {
                if (checkAnswer(xArr[j], words[j]) == false) {
                    f = false;
                }
            }
            if (props.canAnswer == false) {
                return;
            }
            console.log('answeredAll: userAnswer, f = ', userAnswer, f);
            onSave(userAnswer, f);
        }
        console.log('answeredAll: userAnswer, f = ', userAnswer);
    }, [userAnswer, props.canAnswer, updatedSomething]);

    console.log('DndGroupingExercise: render: userAnswer = ', userAnswer);

    return (
        <Wrapper>

            <TopPlaceholder style={{textAlign: isHebrew(text) ? 'right' : 'left'}} dir={isHebrew(text) ? 'rtl' : 'ltr'}>
                {items.map((a, i) => {
                    let {type} = a;
                    let uWord = (a.type == 'word') ? getUserWordByUserAnswer(userAnswer, a.index) : '';
                    let isCorrect = checkAnswer(uWord, a.text);
                    return (
                        <ItemSpan key={i}>
                            {type == 'out' ? <span><span
                                dangerouslySetInnerHTML={{__html: `${a.text}`.replace(/\n/g, '<br/>')}}></span></span> : null}
                            {type == 'word' ? <Word
                                isCorrect={(showCorrectAnswer == false) ? undefined : isCorrect}
                                onDragOver={event => {
                                    event.preventDefault();
                                }}
                                onDrop={event => {
                                    if (loading == true) {
                                        return;
                                    }
                                    event.stopPropagation();
                                    event.preventDefault();
                                    let dw = window.draggingWord;
                                    if (wordsVars.indexOf(dw) == -1 || uWord.indexOf(dw) > -1 || canAnswer == false) {
                                        return;
                                    }
                                    let uArr = userAnswer.split('|');
                                    let newUArr = JSON.parse(JSON.stringify(uArr));
                                    let oldVal = newUArr[a.index];
                                    let newVal = getSortedArr(oldVal.split('_').filter(x => (x != dw && x != '')).concat([dw])).join('_');
                                    newUArr[a.index] = newVal;
                                    setUserAnswer(newUArr.join('|'));
                                    setUpdatedSomething(true);
                                }}
                                hasWord={uWord != ''}>{uWord == '' ? '---' : <span
                                onClick={() => {

                                }}
                            >{uWord.split('_').map((z, j) => {
                                return (
                                    <VaWordItem key={`z${j}_${z}`} onClick={() => {
                                        if (canAnswer == false || loading == true) {
                                            return;
                                        }
                                        let uArr = userAnswer.split('|');
                                        let newUArr = JSON.parse(JSON.stringify(uArr));
                                        let oldVal = newUArr[a.index];
                                        let newVal = getSortedArr(oldVal.split('_').filter(x => (x != z && x != ''))).join('_');
                                        newUArr[a.index] = newVal;
                                        setUserAnswer(newUArr.join('|'));
                                        setUpdatedSomething(true);
                                    }}>
                                        {z}
                                    </VaWordItem>
                                )
                            })}</span>}</Word> : null}
                        </ItemSpan>
                    )
                })}
            </TopPlaceholder>

            <BottomPlaceholder>

                {bottomWords.map((a, i) => {

                    return (
                        <BotWord
                            draggable={true}
                            onDrag={x => {
                                window.draggingWord = a;
                            }}
                            key={`${i}_${a}`}>
                            {a}
                        </BotWord>
                    )
                })}

            </BottomPlaceholder>

            {/*{answeredAll == false ? null :*/}
            {/*    <BottomAnswerPlaceholder>*/}
            {/*        <BlueButton onClick={() => {*/}
            {/*            */}
            {/*        }} >*/}
            {/*            Save*/}
            {/*        </BlueButton>*/}
            {/*    </BottomAnswerPlaceholder>*/}
            {/*}*/}

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const VaWordItem = styled.span`
  border: 1px solid grey;
  border-radius: 1000px;
  padding-left: 10px;
  padding-right: 10px;
  right: 10px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: alias;
`;

const BottomAnswerPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Word = styled.span`
  background: ${props => (props.isCorrect == undefined ? 'whitesmoke' : (props.isCorrect == true ? 'lightgreen' : 'coral'))};
  min-width: 60px;
  border-radius: 6px;
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;
  width: ${props => (props.hasWord == false ? `120px` : 'auto')};
  display: inline-block;
  text-align: center;
  margin: 5px;
`;

const ItemSpan = styled.span`
  margin-left: 5px;
  right: 5px;

`;

const TopPlaceholder = styled.div`
  padding: 10px;
`;

const BottomPlaceholder = styled.div`
  padding: 20px;
  text-align: center;
`;

const BotWord = styled.div`
  display: inline-block;
  margin: 5px;
  border-radius: 6px;
  border: 1px dashed grey;
  background-color: whitesmoke;
  padding-left: 15px;
  padding-right: 15px;
  cursor: grab;

  :hover {
    background-color: floralwhite;
  }
`;

function shuffle(array) {
    if (array == undefined) {
        return [];
    }
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}