import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {Set} from 'immutable';
import styled from 'styled-components';
import OverlayedItemEditor from "./OverlayedItemEditor";
import {GreenButton} from "../../ira/ui/Buttons";

import Spin from 'arui-feather/spin'
import {Textarea} from "../../ira/ui/Inputs";

import remove_img from '../../../assets/img/cross.svg';

export default function OverlayedTasksEditor(props) {
    const {
        url,
        onChange = newItems => {
            console.log('OverlayedTasksEditor: default onChange: newItems = ', newItems);
        },
        loading = false
    } = props;
    const videoRef = useRef();
    const [expandedSet, setExpandedSet] = useState(Set());
    const [items, setItems] = useState(props.items || []);

    return (
        <Wrapper>

            <TopPlaceholder>
                <VideoPlaceholder>
                    <Video controls={true}
                           ref={videoRef}
                    >
                        <source src={url}/>
                    </Video>
                </VideoPlaceholder>
            </TopPlaceholder>

            <ItemsPlaceholder>

                {items.map((a, i) => {
                    let {startSeconds} = a;
                    let isExpanded = expandedSet.has(a.id);
                    return (
                        <Item key={a.id}>
                            <ItemLeft>
                                <ItemLeftTopPlaceholder>
                                    <span style={{marginRight: 7}}>
                                        {`${+i + 1}. `}
                                    </span>
                                    <StartInput value={startSeconds}
                                                placeholder={'seconds'}
                                                onChange={evt => {
                                                    let s = evt.target.value;
                                                    let newItems = items.map((b, j) => (i == j ? ({
                                                        ...b,
                                                        startSeconds: s
                                                    }) : b));
                                                    setItems(newItems);
                                                }}
                                    />
                                </ItemLeftTopPlaceholder>
                                <ItemLeftBottomPlaceholder onClick={() => {
                                    let newItems = items.filter((xx, j) => (j != i));
                                    setItems(newItems);
                                }}>
                                    <RemoveImage src={remove_img}/>
                                    remove
                                </ItemLeftBottomPlaceholder>
                            </ItemLeft>
                            <ItemContent>
                                {isExpanded == false ? null :
                                    <InnerItemContent>
                                        <QuestionTextPlaceholder>
                                            <Textarea
                                                value={a.question}
                                                placeholder={'Your question'}
                                                onChange={evt => {
                                                    let s = evt.target.value;
                                                    let newItems = items.map((b, j) => (i == j ? ({
                                                        ...b,
                                                        question: s
                                                    }) : b));
                                                    setItems(newItems);
                                                }}
                                            ></Textarea>
                                        </QuestionTextPlaceholder>
                                        <OverlayedItemEditor
                                            options={a.options || []}
                                            onChange={newQuestions => {
                                                let newItems = items.map((b, j) => (i == j ? ({
                                                    ...b,
                                                    options: newQuestions
                                                }) : b));
                                                setItems(newItems);
                                            }}
                                        />
                                    </InnerItemContent>
                                }
                            </ItemContent>
                            <ItemRight>
                                <ExpandSpan onClick={() => {
                                    if (isExpanded) {
                                        setExpandedSet(expandedSet.delete(a.id));
                                    } else {
                                        setExpandedSet(expandedSet.add(a.id));
                                    }
                                }}>
                                    {isExpanded == true ? 'hide' : 'expand'}
                                </ExpandSpan>
                            </ItemRight>
                        </Item>
                    )
                })}

            </ItemsPlaceholder>

            <BottomPlaceholder>
                <AddSpan onClick={() => {
                    let from = 0;
                    let newId = `t-${+new Date()}`;
                    try {
                        from = videoRef.current.currentTime;
                    } catch (exc) {

                    }
                    setItems(items.concat([{
                        id: newId,
                        options: [],
                        startSeconds: from
                    }]));
                    setTimeout(() => {
                        setExpandedSet(expandedSet.add(newId));
                    }, 20);
                }}>
                    Add task
                </AddSpan>
            </BottomPlaceholder>

            <SavePlaceholder>
                <GreenButton onClick={() => {
                    if (loading == true) {
                        return;
                    }
                    let sItems = items.map((a, i) => ({...a, startSeconds: +a.startSeconds}));
                    onChange(sItems);
                }}>
                    <Spin visible={loading}/>
                    {loading == true ? null :
                        'Save'
                    }
                </GreenButton>
            </SavePlaceholder>

        </Wrapper>
    );
}

const AddSpan = styled.span`
  cursor: pointer;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const RemoveImage = styled.img`
  height: 16px;
  opacity: 0.4;
  cursor: pointer;
  margin-right: 10px;

  :hover {
    opacity: 1;
  }
`;

const BottomPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const SavePlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;


const InnerItemContent = styled.div`
  padding: 5px;
  box-sizing: border-box;
`;

const QuestionTextPlaceholder = styled.div`
  padding-bottom: 10px;
  margin-bottom: 10px;
`;


const ExpandSpan = styled.span`
  cursor: pointer;
  opacity: 0.5;
  font-style: italic;

  :hover {
    opacity: 1;
  }
`;

const ItemLeft = styled.div`
  margin-right: 20px;
`;

const ItemLeftTopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  align-items: center;
`;

const ItemLeftBottomPlaceholder = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

const StartInput = styled.input`
  box-sizing: border-box;
  width: 60px;
  text-align: center;
  height: 30px;
  background: white;
  outline: none;
  border: 1px solid whitesmoke;
  border-radius: 4px;

  :focus {
    border-color: blue;
  }
`;

const ItemContent = styled.div`
  flex: 1;
  box-sizing: border-box;
`;

const ItemRight = styled.div`
  margin-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
  border-bottom: 1px solid whitesmoke;
  padding: 10px;
  background: antiquewhite;
  border-radius: 4px;
`;

const ItemsPlaceholder = styled.div`
  box-sizing: border-box;
  padding: 10px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const TopPlaceholder = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid whitesmoke;
`;

const Video = styled.video`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

const VideoPlaceholder = styled.div`
  width: 100%;
  height: 320px;
  background: whitesmoke;
`;

