import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import {DEFAULT_TEXT_SLIDE_BACKGROUND} from "../config";

export default function ImageSlide(props) {
    const {
        imageSrc,
        overlayBackground = `rgba(0, 0, 0, 0.98)`
    } = props;

    return (
        <Wrapper>
            <BottomLayer>
                <BottomLayerInner src={imageSrc}>

                </BottomLayerInner>
            </BottomLayer>
            <MiddleLayer overlayBackground={overlayBackground}></MiddleLayer>
            <TopLayer>
                <TopLayerInner src={imageSrc}>
                    {props.children}
                </TopLayerInner>
            </TopLayer>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: white;
`;

const BottomLayer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

const TopLayer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

const BottomLayerInner = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
  background-image: ${props => (props.src == undefined ? 'none' : `url(${props.src})`)};
`;

const TopLayerInner = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${props => (props.src == undefined ? 'none' : `url(${props.src})`)};
`;

const MiddleLayer = styled.div`
  position: absolute;
  z-index: 2;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: ${props => props.overlayBackground};
`;

const Text = styled.div`

`;