import * as types from '../ActionTypes'
import {Map} from 'immutable';

const initialState = {
    loading: false,
    containersMap: Map(),

    selectedContainerId: undefined,
    error: undefined
}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

export function getGracefullyMergedContainersMap(oldMap, newContainers) {
    let newMap = oldMap;
    for (let i in newContainers) {
        let newCont = newContainers[i];
        let oldContainer = oldMap.get(newCont.id);
        if (newCont == undefined) {
            continue;
        }
        if (oldContainer != undefined) {
            newMap = newMap.set(oldContainer.id, {...oldContainer, ...newCont});
        } else {
            newMap = newMap.set(newCont.id, newCont);
        }
    }
    return newMap;
}

const ContainersReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.SMART_CREATE_CONTAINER:
        case types.SMART_UPDATE_CONTAINER:
        case types.SMART_DELETE_CONTAINER:
        case types.SMART_LOAD_CONTAINERS:
            return startLoading(state, action)

        case types.SMART_CREATE_CONTAINER_FAIL:
        case types.SMART_UPDATE_CONTAINER_FAIL:
        case types.SMART_DELETE_CONTAINER_FAIL:
        case types.SMART_LOAD_CONTAINERS_FAIL:
            return stopLoading(state, action)

        case types.SMART_UPDATE_CONTAINER_SUCCESS:
            return {
                ...state,
                loading: false,
                containersMap: state.containersMap.set(action.container.id, action.container)
            }

        case types.SMART_CREATE_CONTAINER_SUCCESS:
            let parent = state.containersMap.get(action?.parentId);
            let affectedContainers = [];
            if (parent != undefined) {
                let newIds = (parent?.childrenIds || []).concat([action.container.id]);
                affectedContainers = affectedContainers.concat([{...parent, childrenIds: newIds}]) // adding to parent
            }
            affectedContainers = affectedContainers.concat([action.container]);
            return {
                ...state,
                loading: false,
                containersMap: getGracefullyMergedContainersMap(state.containersMap, affectedContainers)
            }

        case types.SMART_DELETE_CONTAINER_SUCCESS:
            let dParent = state.containersMap.get(action?.parentId);
            let aContainers = [];
            if (dParent != undefined) {
                let newIds = (dParent?.childrenIds || []).filter(x => (x != action.id)); // removing from parent
                aContainers = aContainers.concat([{...dParent, childrenIds: newIds}]);
            }
            return {
                ...state,
                loading: false,
                containersMap: getGracefullyMergedContainersMap(state.containersMap, aContainers).delete(action.id)
            }

        case types.SMART_LOAD_CONTAINERS_SUCCESS:
            return {
                ...state,
                loading: false,
                containersMap: getGracefullyMergedContainersMap(state.containersMap, action.containers)
            }

        default:
            return state;

    }

}

export default ContainersReducer;