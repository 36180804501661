import React, {useState, useEffect, useRef} from 'react';

const ReactHelper = {

    useInterval(callback, delay) {
        const savedCallback = useRef();
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }

            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    },

    useEventListener(eventName, handler, element = window) {
        const savedHandler = useRef();
        useEffect(() => {
            savedHandler.current = handler;
        }, [handler]);

        useEffect(
            () => {
                const isSupported = element && element.addEventListener;
                if (!isSupported) return;
                const eventListener = event => savedHandler.current(event);
                element.addEventListener(eventName, eventListener);
                return () => {
                    element.removeEventListener(eventName, eventListener);
                };
            },
            [eventName, element]
        );
    },

    useDrag(ref, deps = [], options) {
        const {
            onPointerDown = () => {
            },
            onPointerUp = () => {
            },
            onPointerMove = () => {
            },
            onDrag = () => {
            },
        } = options;

        const [isDragging, setIsDragging] = useState(false);

        const handlePointerDown = (e) => {
            setIsDragging(true);

            onPointerDown(e);
        };

        const handlePointerUp = (e) => {
            setIsDragging(false);

            onPointerUp(e);
        };

        const handlePointerMove = (e) => {
            onPointerMove(e);

            if (isDragging) {
                onDrag(e);
            }
        };

        useEffect(() => {
            const element = ref.current;
            if (element) {
                element.addEventListener('pointerdown', handlePointerDown);
                element.addEventListener('pointerup', handlePointerUp);
                element.addEventListener('pointermove', handlePointerMove);

                return () => {
                    element.removeEventListener('mousedown', handlePointerDown);
                    element.removeEventListener('mouseup', handlePointerUp);
                    element.removeEventListener('mousemove', handlePointerMove);
                };
            }

            return () => {
            };
        }, [...deps, isDragging]);

        return {isDragging};
    },

    useDebounce(value, delay) {
        // State and setters for debounced value
        const [debouncedValue, setDebouncedValue] = useState(value);
        useEffect(
            () => {
                // Update debounced value after delay
                const handler = setTimeout(() => {
                    setDebouncedValue(value);
                }, delay);
                // Cancel the timeout if value changes (also on delay change or unmount)
                // This is how we prevent debounced value from updating if value is changed ...
                // .. within the delay period. Timeout gets cleared and restarted.
                return () => {
                    clearTimeout(handler);
                };
            },
            [value, delay] // Only re-call effect if value or delay changes
        );
        return debouncedValue;
    }


};

export default ReactHelper;
