import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const SlidesAPI = {

    async getMyDeletedSlides() {
        let arr = (await axios.get(`${API_ENDPOINT}/v3/slides/my-deleted`)).data.result;
        return arr;
    },

    async searchSlides(text) {
        let arr = (await axios.get(`${API_ENDPOINT}/search/slides`, {params: {q: text.trim()}})).data.result;
        arr = arr.filter(x => (x?.isDeleted != true));
        return arr.map(x => x.id);
    },

    async getSlide(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/slides/${id}`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async getAllSlides() {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/slides/short/all`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result.filter(x => (x?.isDeleted != true));
    },

    async getLimboSlides() {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/slides/short/limbo`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result.filter(x => (x?.isDeleted != true));
    },

    async getLimboUsers() {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/slides/short/limbo-optimized`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result.users;
    },


    async getContainerSlides(containerId) {
        console.log('getContainerSlides: containerId = ', containerId);
        let pld = (await axios.get(`${API_ENDPOINT}/v3/container/${containerId}/slides`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result.filter(x => (x?.isDeleted != true));
    },


    async createSlide(data, containerId) {
        let pld = (await axios.post(`${API_ENDPOINT}/v3/slides`, data, {
            params: {
                containerId: containerId
            }
        })).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async updateSlide(data, hasChanges = false) {
        let pld = (await axios.put(`${API_ENDPOINT}/v3/slides`, {...data, hasChanges: hasChanges})).data;
        return pld.result;
    },


    async deleteSlide(id) {
        await axios.delete(`${API_ENDPOINT}/v3/slides/${id}`, {
            data: {id: id}
        })
    },

    async createSlideFromTaskAndAddItToContainer(taskId, containerId, name) {
        await axios.post(`${API_ENDPOINT}/v3/tasks/${taskId}/copy-to-container/${containerId}`, {
            name: name
        });
    },

    async cloneSlideAndAddItToContainer(slideId, containerId, name) {
        await axios.post(`${API_ENDPOINT}/v3/slides/${slideId}/copy-to-container/${containerId}`, {
            name: name
        });
    },

    async getSlideBackups(slideId) {
        let arr = (await axios.get(`${API_ENDPOINT}/v3/slides/${slideId}/backups`)).data;
        return arr;
    },

    async createSlideBackup(slideId, name) {
        let pld = (await axios.post(`${API_ENDPOINT}/v3/slides/${slideId}/make-backup`, {name: name})).data;
        return pld;
    },

    async updateSlideBackupName(id, name) {
        let pld = (await axios.put(`${API_ENDPOINT}/v3/slides-backups`, {id: id, name: name})).data;
        return pld;
    },

    async deleteSlideBackup(id) {
        let pld = (await axios.delete(`${API_ENDPOINT}/v3/slides-backups/${id}`)).data;
        return pld;
    },

    async recoverSlide(slideId, containerId) {
        let pld = (await axios.post(`${API_ENDPOINT}/v3/slides/${slideId}/recover/${containerId}`)).data;
        return pld;
    },


}

export default SlidesAPI;
