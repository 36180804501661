import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import ExercisesAPI from "../../../api/ExercisesAPI";
import TopSquaresTool from "../tools/TopSquaresTool";
import ExerciseCardForm from "../forms/ExerciseCardForm";
import MaterialSearchPanel from "../../materials/panels/MaterialSearchPanel";
import {BlueButton} from "../../ira/ui/Buttons";

export default function ExerciseCardsEditorPanel(props) {
    const {
        exerciseId
    } = props;

    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedId, setSelectedId] = useState(undefined);
    const [bulkAddVideoVisible, setBulkAddVideoVisible] = useState(false);

    const [selectedBulkMaterials, setSelectedBulkMaterials] = useState([]);
    const [bulkLoading, setBulkLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        ExercisesAPI.getExercise(exerciseId).then(a => {
            setCards(a?.cards || []);
            setLoading(false);
            if (a?.cards != undefined && a?.cards.length > 0) {
                setSelectedId(a?.cards[0].id);
            }
        });
    }, []);

    let selectedCard = (selectedId == undefined) ? undefined : cards.filter(x => (x.id == selectedId))[0];

    if (cards.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    return (
        <Wrapper>

            <TopPlaceholder>
                <TopSquaresTool
                    cards={cards}
                    selectedId={selectedId}
                    onSelect={async xId => {
                        if (xId == selectedId) {
                            let bx = window.prompt('Введите номер, с которым произвести замену');
                            if (window.isNaN(bx) == true || +bx > +cards.length || +bx < 1) {
                                window.alert('Недопустимое значение');
                                return;
                            }
                            let c = cards.filter(xx => (xx.id == xId))[0];
                            let secondCard = cards[+bx - 1];
                            let secondNumber = (secondCard == undefined) ? undefined : secondCard.number;
                            setLoading(true);
                            await ExercisesAPI.updateExerciseCard({number: +c.number, id: secondCard.id});
                            await ExercisesAPI.updateExerciseCard({id: c.id, number: secondNumber});
                            let arr = (await ExercisesAPI.getExercise(exerciseId)).cards;
                            setCards(arr);
                            setLoading(false);
                        } else {
                            setSelectedId(xId);
                        }
                    }}
                    canAddCard={true}
                    onCardAdd={async () => {
                        if (loading == true) {
                            return;
                        }
                        setLoading(true);
                        await ExercisesAPI.createExerciseCard({
                            exerciseId: exerciseId,
                            number: +new Date(),
                            answerType: 'speaking'
                        });
                        let arr = (await ExercisesAPI.getExercise(exerciseId)).cards;
                        let newC = arr[arr.length - 1];
                        setCards(arr);
                        setSelectedId(newC?.id);
                        setLoading(false);
                    }}
                />
            </TopPlaceholder>

            {selectedCard == undefined ? null :
                <MainContentPlaceholder key={selectedId}>
                    <ExerciseCardForm
                        {...selectedCard}
                        loading={loading}
                        onChange={async d => {
                            setLoading(true);
                            await ExercisesAPI.updateExerciseCard({
                                id: selectedCard?.id,
                                ...d
                            });
                            let arr = (await ExercisesAPI.getExercise(exerciseId)).cards;
                            console.log('got cards = ', arr);
                            setCards(arr);
                            setLoading(false);
                        }}
                        onDelete={async () => {
                            if (loading == true) {
                                return;
                            }
                            setLoading(true);
                            await ExercisesAPI.deleteExerciseCard(selectedCard?.id);
                            let arr = (await ExercisesAPI.getExercise(exerciseId)).cards;
                            setCards(arr);
                            if (arr.length > 0) {
                                setSelectedId(arr[arr.length - 1].id);
                            }
                            setLoading(false);
                            // await ExercisesAPI.updateExerciseCard({
                            //     id: selectedCard?.id,
                            //     ...d
                            // });
                        }}
                        canDuplicate={true}
                        onDuplicate={async () => {
                            setLoading(true);
                            await ExercisesAPI.createExerciseCard({
                                exerciseId: exerciseId,
                                ...selectedCard,
                                number: +new Date()
                            });
                            let arr = (await ExercisesAPI.getExercise(exerciseId)).cards;
                            setCards(arr);
                            let newCard = arr[arr.length - 1];
                            setLoading(false);
                            setSelectedId(newCard?.id);
                        }}
                    />
                </MainContentPlaceholder>
            }

            <div style={{marginTop: 30}}>
                <span style={{cursor: 'pointer', textDecoration: 'underline', fontSize: 12, opacity: 0.5}} onClick={() => {
                    setBulkAddVideoVisible(true);
                }}>
                    массовое добавление карточек
                </span>
            </div>

            <Sidebar
                width={Math.min(1020, window.innerWidth)}
                visible={bulkAddVideoVisible} onCloserClick={() => {
                setBulkAddVideoVisible(false);
            }}>

                {bulkAddVideoVisible == false ? null :
                    <ModalSearchInnerPlaceholder>
                        <div style={{height: '100%', display: 'flex', flexDirection: 'row'}}>
                            <div style={{width: 600, maxHeight: `calc(100vh - 140px)`, height: `calc(100vh - 140px)`}}>
                                <MaterialSearchPanel
                                    type={'materials'}
                                    isMultiSelect={true}
                                    selectedIds={selectedBulkMaterials.map(x => x.id)}
                                    onSelect={v => {
                                        let isSelected = (selectedBulkMaterials.map(xx => xx.id).indexOf(v.id) > -1);
                                        let newSelectedMaterials = (isSelected == true) ? selectedBulkMaterials.filter(xx => (xx.id != v.id)) : selectedBulkMaterials.concat([v]);
                                        setSelectedBulkMaterials(newSelectedMaterials);
                                        // setVideoUrl(v.url);
                                        // setTranscript(v.transcript);
                                        // setText(v.transcript == undefined ? text : v.transcript);
                                        // setAddVideoVisible(false);
                                        // setVideoAvatar(v.avatar);
                                        // onChange({
                                        //     videoUrl: v.url,
                                        //     transcript: v.transcript,
                                        //     text: v.transcript == undefined ? text : v.transcript,
                                        //     videoAvatar: v.avatar,
                                        //     copyrightDescription: v.copyrightDescription == undefined ? '' : v.copyrightDescription
                                        // });
                                    }}
                                />
                            </div>
                            <div style={{flex: 1, paddingLeft: 10, paddingRight: 10}}>
                                {selectedBulkMaterials.map((a, i) => {
                                    return (
                                        <div key={`xx${a.id}_${i}`}>
                                            <div style={{fontWeight: 'bold', fontSize: 14, marginBottom: 5}}>
                                                {a.name}
                                            </div>
                                            <div>
                                                <span style={{
                                                    cursor: 'pointer',
                                                    color: 'red',
                                                    textDecoration: 'underline',
                                                    fontSize: 9
                                                }} onClick={() => {
                                                    setSelectedBulkMaterials(selectedBulkMaterials.filter(xx => (xx.id != a.id)));
                                                }}>
                                                    удалить
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })}
                                {selectedBulkMaterials.length == 0 ? null :
                                    <div style={{marginTop: 10}}>
                                        <BlueButton onClick={async () => {
                                            if (bulkLoading == true) {
                                                return;
                                            }
                                            setBulkLoading(true);
                                            for (let i in selectedBulkMaterials) {
                                                let v = selectedBulkMaterials[i];
                                                await ExercisesAPI.createExerciseCard({
                                                    exerciseId: exerciseId,
                                                    number: +new Date(),
                                                    answerType: 'speaking',
                                                    videoUrl: v.url,
                                                    transcript: v.transcript,
                                                    text: v.transcript,
                                                    videoAvatar: v.avatar,
                                                    copyrightDescription: v.copyrightDescription
                                                });
                                            }
                                            let arr = (await ExercisesAPI.getExercise(exerciseId)).cards;
                                            setCards(arr);
                                            setBulkLoading(false);
                                            setBulkAddVideoVisible(false);
                                            setSelectedBulkMaterials([]);
                                        }}>
                                            <Spin visible={bulkLoading}/>
                                            OK
                                        </BlueButton>
                                    </div>
                                }
                            </div>
                        </div>
                    </ModalSearchInnerPlaceholder>
                }

            </Sidebar>


        </Wrapper>
    );
}

const MainContentPlaceholder = styled.div`
  margin-top: 30px;
`;

const ModalSearchInnerPlaceholder = styled.div`
  height: calc(100vh - 140px);
`;

const TopPlaceholder = styled.div`

`;

const Wrapper = styled.div`
  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;