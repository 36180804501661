import React, {CSSProperties, ReactElement, useState, useEffect} from 'react';
import styled from 'styled-components';

export const Cell = styled(props => {
    const [initialWidth, setInitialWidth] = useState(null);
    const [initialHeight, setInitialHeight] = useState(null);

    useEffect(() => {
        setInitialHeight(props.initialHeight);
        setInitialWidth(props.initialWidth);
    }, []);

    return <div
        ref={props.onRef}
        style={{
            height: initialHeight,
            width: initialWidth,
            ...props.style,
        }}
        className={props.className}
    >
        {props.children}
    </div>
})`
  box-sizing: border-box;
  overflow: hidden;
`;