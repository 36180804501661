import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import {CircularProgressbarWithChildren, buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function ProgressTool(props) {
    const {
        progress = 0
    } = props;

    const [first, setFirst] = useState(false);
    let activeColor = '#A36EFF';
    let trailColor = 'rgba(163, 110, 255, 0.4)';

    if (progress >= 1.0) {
        activeColor = '#15B559';
    }

    useEffect(() => {
        setFirst(true);
    }, []);

    return (
        <Wrapper>
            <CircularProgressbarWithChildren value={first == false ? 0 : progress * 100.0}
                                             strokeWidth={12}
                                             styles={buildStyles({
                                                 pathColor: activeColor,
                                                 trailColor: trailColor,
                                                 pathTransitionDuration: 1.5
                                             })}
            >
                <div style={{fontWeight: 'bold', fontSize: 12, color: activeColor, textAlign: 'center'}}>
                    {`${Math.round(progress * 100.0)}%`}
                </div>
            </CircularProgressbarWithChildren>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 54px;
    height: 54px;
`;
