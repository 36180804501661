import React, {PropTypes} from 'react';
import styled from 'styled-components';
import YandexTranslateAPI from "../../../api/YandexTranslateAPI";

import Spin from 'arui-feather/spin'

import axios from 'axios'

import {Set} from 'immutable'
import add_plus_ from '../../../assets/img/add_plus_.png'
import plus_white from '../../../assets/img/plus_white.png'
import white_tick from '../../../assets/img/white_tick.png'
import tick_black from '../../../assets/img/tick_black.png'
import {API_ENDPOINT} from "../../../constants/config";

const renderMeaningComponent = (loadingSet, text, meaningsSet, plusColor, toggleMeaning = txt => {
}) => {
    let added = meaningsSet.has(text);
    if (loadingSet.has(text) == true) {
        return (
            <span style={{marginLeft: 5}}>
                <Spin invertColor={(plusColor == 'white') ? true : false}/>
            </span>
        );
    }
    return (
        <span onClick={() => {
            toggleMeaning(text);
        }}>
            {added == true ?

                <span>
                    {plusColor != 'white' ? null :
                        <AlreadyAddedCheckImage
                            src={white_tick}/>
                    }

                    {plusColor != 'black' ? null :
                        <AlreadyAddedCheckImage
                            src={tick_black}/>
                    }

                </span> :

                <AddMeaningButton>
                    {plusColor != 'white' ? null :
                        <AlreadyAddedCheckImage
                            src={plus_white}/>
                    }
                    {plusColor != 'black' ? null :
                        <AlreadyAddedCheckImage
                            src={add_plus_}/>
                    }
                </AddMeaningButton>
            }
        </span>
    )
}

class YandexTranslatePanel extends React.Component {

    static defaultProps = {
        word: undefined,
        userId: undefined,
        lessonId: undefined,
        selectable: true,
        plusColor: 'white'
    }

    static propTypes = {}

    state = {
        loading: false,
        defs: [],

        textDefs: [],

        error: undefined,

        items: [],
        saving: false,

        loadingSet: Set()
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let {word, onAdded = () => {

        }} = this.props;
        this.setState({
            loading: true,
            error: undefined,
            defs: [],
            textDefs: [],
            searchQuery: word
        });
        YandexTranslateAPI.translateWord(word).then(dfs => {
            console.log('loaded defs = ', dfs);
            this.setState({
                defs: dfs,
                loading: false
            }, () => {
                this.loadUserMeanings(word);
                if (dfs == undefined || dfs.length == 0) {
                    this.setState({
                        loading: true
                    });
                    YandexTranslateAPI.translateTextAlternative(word).then(textDefs => {
                        console.log('got textDefs = ', textDefs);
                        this.setState({
                            loading: false,
                            textDefs: textDefs
                        });
                        onAdded();
                    }).catch(err => {
                        console.log('translateTextAlternative: error occured! err = ', err);
                        this.setState({
                            textDefs: [],
                            loading: false
                        });
                    });
                }
            })
        }).catch(err => {
            this.setState({
                loading: false,
                error: err,
                defs: []
            });
        })
    }

    componentWillReceiveProps() {

    }

    loadUserMeanings = (word) => {
        let {userId} = this.props;
        if (userId == undefined) {
            return;
        }
        this.setState({
            saving: true
        });
        axios.get(`${API_ENDPOINT}/vocabulary/user/${userId}/word`, {
            params: {
                word: word
            }
        }).then(d => d.data).then(items => {
            this.setState({
                items: items,
                saving: false
            });
        })
    }

    toggleMeaning = (mean) => {
        console.log('addMeaning: mean = ', mean);
        let {loadingSet, items} = this.state;
        let {userId, word, lessonId} = this.props;
        // let meaningsSet = items.reduce((st, item) => (st.merge(item.meanings.reduce((sst, m) => sst.add(m), Set()))), Set())

        this.setState({
            saving: true,
            loadingSet: loadingSet.add(mean)
        });
        axios.post(`${API_ENDPOINT}/vocabulary/user/${userId}`, {
            userId: userId,
            lessonId: lessonId,
            name: word,
            meanings: [mean]
        }).then(d => d.data).then(items => {
            this.setState({
                saving: false,
                loadingSet: loadingSet.delete(mean)
            }, () => {
                this.loadUserMeanings(word);
            });
        })
    }

    render = () => {
        let {loading, defs, items, saving, loadingSet, invertedPlus, textDefs} = this.state;
        let {word, selectable, plusColor} = this.props;
        if (loading == true) {
            return <Spin visible={true}/>
        }
        if ((word != undefined) && (word.trim() != '') && (defs.length == 0) && (textDefs.length == 0)) {
            return (
                <NotFoundPlaceholder>
                    <div className="text">
                        Не найдено результатов по запросу "<b>{word}</b>"
                    </div>
                    <div>
                        {/*<img src={require('../../../assets/no_results_found_white.jpg')}/>*/}
                    </div>
                </NotFoundPlaceholder>
            );
        }
        let meaningsSet = items.reduce((st, item) => (st.merge(item.meanings.reduce((sst, m) => sst.add(m), Set()))), Set())

        return (
            <Wrapper className={''}>

                {defs.map((def, k) => {
                    let {tr} = def;
                    let aPos = def.pos;
                    return (
                        <DefItem className={'def_item'} key={k}>

                            <APos className="a_pos">
                                {aPos}
                            </APos>

                            <TrList className={'tr_list'}>
                                {tr.map((trans, j) => {
                                    let {pos, mean, text, ex} = trans;
                                    return (
                                        <TrItem className={'tr_item'} key={j}>
                                            <Text className={'text'}>
                                                {+j + 1}. {' '} {text}
                                                {renderMeaningComponent(loadingSet, text, meaningsSet, plusColor, s => {
                                                    this.toggleMeaning(s);
                                                })}
                                            </Text>

                                            {((ex == undefined) || (ex.length == 0)) ? null :
                                                <ExPlaceholder className={'ex_placeholder'}>
                                                    {ex.map((exItem, o) => {
                                                        return (
                                                            <div className={'ex_item'} key={o}>
                                                                <ExItemText className={'ex_item_text'}>
                                                                    {exItem.text}
                                                                </ExItemText> {' '}
                                                                {((exItem.tr == undefined) || (exItem.length == 0)) ? null :
                                                                    <ExItemTrs className={'ex_item_trs'}>
                                                                        - {exItem.tr.map(ttt => ttt.text).join(', ')}
                                                                    </ExItemTrs>
                                                                }
                                                            </div>
                                                        )
                                                    })}
                                                </ExPlaceholder>
                                            }

                                            {((mean == undefined) || (mean.length == 0)) ? null :
                                                <Mean className={'mean'}>
                                                    Синонимы: {mean.map(mm => mm.text).join(', ')}
                                                </Mean>
                                            }

                                        </TrItem>
                                    )
                                })}
                            </TrList>

                        </DefItem>
                    )
                })}

                {(textDefs == undefined || textDefs.length == 0) ? null :
                    <TextDefsPlaceholder>
                        {textDefs.map((td, k) => {
                            return (
                                <TextDefItem key={k}>
                                    {+k + 1}. {' '} {td}
                                    {renderMeaningComponent(loadingSet, td, meaningsSet, plusColor, s => {
                                        this.toggleMeaning(s);
                                    })}
                                </TextDefItem>
                            )
                        })}
                    </TextDefsPlaceholder>
                }

                <div style={{marginTop: 10, padding: 10}} >
                    Реализовано с помощью сервиса
                    {' '}
                    <LinkA href={'http://api.yandex.ru/dictionary'} target={'_blank'} >
                        «API «Яндекс.Словарь»
                    </LinkA>
                </div>

            </Wrapper>
        )
    }

}

const LinkA = styled.a`
    color: inherit !important;
`;

const Wrapper = styled.div`
  text-align: left;
  font-size: 16px;
  line-height: 24px;
`;

const TextDefsPlaceholder = styled.div`

`;

const TextDefItem = styled.div`
  margin-bottom: 10px;
`;

const NotFoundPlaceholder = styled.div`
  text-align: center;
  padding: 20px;
  text-align: center;
`;

const DefItem = styled.div`
  margin-bottom: 15px;
`;

const APos = styled.div`
  font-weight: bold;
  opacity: 0.8;
  font-size: 16px;
  margin-bottom: 5px;
`;

const TrList = styled.div`
  padding-left: 10px;
`;

const TrItem = styled.div`
  margin-bottom: 10px;
  padding-left: 20px;
`;

const Text = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

const ExPlaceholder = styled.div`
  padding-left: 20px;
`;

const ExItemText = styled.span`
  font-weight: bold;
`;

const ExItemTrs = styled.span`
  font-style: italic;
  opacity: 0.8;
`;

const Mean = styled.div`
  font-style: italic;
  opacity: 0.8;
  font-size: 12px;
  padding-left: 40px;
`;

const AddMeaningButton = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
  cursor: pointer;
  opacity: 0.6;

  :hover {
    opacity: 1;
  }
`;

const AlreadyAddedCheckImage = styled.img`
  display: inline-block;
  //vertical-align: top;
  margin-left: 10px;
  cursor: pointer;
  opacity: 0.6;
  width: 14px;
  height: 14px;

  :hover {
    opacity: 1;
  }
`;


export default YandexTranslatePanel
