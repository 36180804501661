import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import VocWordsEditor from "../../prouni/tools/VocWordsEditor";
import SmartGrammarSelector from "../../prouni/tools/SmartGrammarSelector";

export default function AccountingForm(props) {
    const {
        onChange = (words, grammarItems) => {

        }
    } = props;

    const [words, setWords] = useState(props.words || []);
    const [grammarItems, setGrammarItems] = useState(props.grammarItems || []);

    useEffect(() => {
        onChange(words, grammarItems);
    }, [words, grammarItems]);

    return (
        <Wrapper>

            <Label style={{borderBottom: '1px solid lightgrey', marginBottom: 10}}>
                Учет пройденного:
            </Label>

            <Row>
                <Label>
                    Ключевые слова:
                </Label>
                <VocWordsEditor
                    hasTypes={false}
                    canEdit={true}
                    items={words}
                    onChange={arr => {
                        setWords(arr);
                    }}
                />
            </Row>

            <Row>
                <Label>
                    Целевые грамматические конструкции:
                </Label>
                <SmartGrammarSelector
                    level={'any'}
                    value={grammarItems}
                    onChange={x => {
                        setGrammarItems(x);
                    }}
                />
            </Row>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  padding: 10px;
  box-sizing: border-box;
  background: whitesmoke;
  border: 1px dashed grey;
`;


const Row = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;