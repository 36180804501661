import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'
import {getGracefullyMergedContainersMap} from "./ContainersReducer";

const initialState = {
    loading: false,

    selectedLessonId: undefined,

    lessonsMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const LessonsReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_LESSON:
        case types.UPDATE_LESSON:
        case types.DELETE_LESSON:
        case types.LOAD_LESSONS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_LESSON_FAIL:
        case types.UPDATE_LESSON_FAIL:
        case types.DELETE_LESSON_FAIL:
        case types.LOAD_LESSONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_LESSON_SUCCESS:
        case types.UPDATE_LESSON_SUCCESS:
            return {
                ...state,
                loading: false,
                lessonsMap: getGracefullyMergedContainersMap(state.lessonsMap, [action.lesson]),
                selectedLessonId: action?.lesson?.id
            }


        case types.DELETE_LESSON_SUCCESS:
            return {
                ...state,
                loading: false,
                lessonsMap: state.lessonsMap.delete(action.id),
                selectedLessonId: undefined
            }

        case types.LOAD_LESSONS_SUCCESS:
            return {
                ...state,
                loading: false,
                lessonsMap: getGracefullyMergedContainersMap(state.lessonsMap, action.lessons)
            }

        case types.SELECT_LESSON:
            return {
                ...state,
                selectedLessonId: action.id
            }

        default:
            return state;
    }

}

export default LessonsReducer;
