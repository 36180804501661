import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const GroupsAPI = {

    async addToClass(userId, invitationCode) { // for student
        console.log('addToClass: userId, invitationCode = ', userId, invitationCode);
        let link = (await axios.post(`${API_ENDPOINT}/add_to_class`, {
            userId: userId,
            invitationCode: invitationCode
        })).data;
        if (link?.error != undefined) {
            throw link?.error?.message;
        }
        let arr = await this.getMyGroups();
        return arr;
    },

    async removeFromClass(userId, classId) {
        await axios.post(`${API_ENDPOINT}/remove_user_from_class`, {
            userId: userId,
            classId: classId
        })
        let arr = await this.getMyGroups();
        return arr;
    },


    async getMyGroups(userId) {
        let arr = (await axios.get(`${API_ENDPOINT}/v3/classes`, {
            params: {
                userId: userId
            }
        })).data;
        return arr;
    },

    async createGroup(name) {
        let group = (await axios.post(`${API_ENDPOINT}/v3/classes`, {
            name: name
        })).data;
        return group;
    },

    async updateGroup(data) {
        let group = (await axios.put(`${API_ENDPOINT}/v3/classes`, {
            ...data
        })).data;
        return group;
    },


    async getGroupWithStudents(id) {
        let group = (await axios.get(`${API_ENDPOINT}/classes/${id}/with-students`)).data;
        return group;
    },

    getAllGroups() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/groups/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    // createGroup(data) {
    //     return new Promise((resolve, reject) => {
    //         axios.post(`${API_ENDPOINT}/groups/create`, data).then(d => d.data).then(ex => {
    //             if (ex.error != undefined) {
    //                 return reject(ex.error);
    //             }
    //             resolve(ex);
    //         }).catch(err => reject(err));
    //     });
    // },

    // updateGroup(data) {
    //     return new Promise((resolve, reject) => {
    //         axios.post(`${API_ENDPOINT}/groups/update`, data).then(d => d.data).then(ex => {
    //             if (ex.error != undefined) {
    //                 return reject(ex.error);
    //             }
    //             resolve(ex);
    //         }).catch(err => reject(err));
    //     });
    // },

    deleteGroup(id) {
        return new Promise((resolve, reject) => {
            axios.delete(`${API_ENDPOINT}/v3/class/${id}`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default GroupsAPI;
