import * as types from '../ActionTypes.js'
import SlidesAPI from '../../api/SlidesAPI.js';

export function selectSlide(slideId) {
    return {
        type: types.SELECT_SLIDE,
        id: slideId
    }
}

let createSlide_ = () => {
    return {
        type: types.CREATE_SLIDE
    }
}
let createSlideSuccess = (slide) => {
    return {
        type: types.CREATE_SLIDE_SUCCESS,
        slide: slide
    }
}
let createSlideFailed = (error) => {
    return {
        type: types.CREATE_SLIDE_FAIL,
        error: error
    }
}

//thunk
export function createSlide(data, containerId) {
    return (dispatch, getState) => {
        dispatch(createSlide_());
        return SlidesAPI.createSlide(data, containerId).then(
            slide => dispatch(createSlideSuccess(slide)),
            error => dispatch(createSlideFailed(error))
        )
    }
}

//eca
let updateSlide_ = () => {
    return {
        type: types.UPDATE_SLIDE
    }
}
let updateSlideSuccess = (slide) => {
    return {
        type: types.UPDATE_SLIDE_SUCCESS,
        slide: slide
    }
}
let updateSlideFailed = (error) => {
    return {
        type: types.UPDATE_SLIDE_FAIL,
        error: error
    }
}

//thunk
export function updateSlide(data, hasChanges = true) {
    return (dispatch, getState) => {
        dispatch(updateSlide_());
        return SlidesAPI.updateSlide(data, hasChanges).then(
            slide => dispatch(updateSlideSuccess(slide)),
            error => dispatch(updateSlideFailed(error))
        )
    }
}

export function recoverSlide(sliderId, containerId) {
    return (dispatch, getState) => {
        dispatch(updateSlide_());
        return SlidesAPI.recoverSlide(sliderId, containerId).then(
            d => dispatch(updateSlideSuccess({...d.slide, isDeleted: false})),
            error => dispatch(updateSlideFailed(error))
        )
    }
}



let loadSlides_ = () => {
    return {
        type: types.LOAD_SLIDES
    }
}
let loadSlidesSuccess = (slides) => {
    return {
        type: types.LOAD_SLIDES_SUCCESS,
        slides: slides
    }
}
let loadSlidesFailed = (error) => {
    return {
        type: types.LOAD_SLIDES_FAIL,
        error: error
    }
}

//thunk
export function loadSlides() {
    return (dispatch, getState) => {
        dispatch(loadSlides_());
        return SlidesAPI.getAllSlides().then(
            slides => dispatch(loadSlidesSuccess(slides)),
            error => dispatch(loadSlidesFailed(error))
        )
    }
}

export function loadLimboSlides() {
    return (dispatch, getState) => {
        dispatch(loadSlides_());
        return SlidesAPI.getLimboSlides().then(
            slides => dispatch(loadSlidesSuccess(slides)),
            error => dispatch(loadSlidesFailed(error))
        )
    }
}

export function loadDeletedSlides() {
    return (dispatch, getState) => {
        dispatch(loadSlides_());
        return SlidesAPI.getMyDeletedSlides().then(
            slides => dispatch(loadSlidesSuccess(slides)),
            error => dispatch(loadSlidesFailed(error))
        )
    }
}



export function getSlide(id) {
    return (dispatch, getState) => {
        dispatch(loadSlides_());
        return SlidesAPI.getSlide(id).then(
            a => dispatch(loadSlidesSuccess([a])),
            error => dispatch(loadSlidesFailed(error))
        )
    }
}

let deleteSlide_ = () => {
    return {
        type: types.DELETE_SLIDE
    }
}
let deleteSlideSuccess = (id) => {
    return {
        type: types.DELETE_SLIDE_SUCCESS,
        id: id
    }
}
let deleteSlideFailed = (error) => {
    return {
        type: types.DELETE_SLIDE_FAIL,
        error: error
    }
}

//thunk
export function deleteSlide(id) {
    return (dispatch, getState) => {
        dispatch(deleteSlide_());
        return SlidesAPI.deleteSlide(id).then(
            () => dispatch(deleteSlideSuccess(id)),
            error => dispatch(deleteSlideFailed(error))
        )
    }
}