import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';
import {useInterval} from "../treadmills_exercises/treadmill/tools/TreadmillPlayerTool";
import {DEFAULT_VIDEO_PLACEHOLDER} from "../../constants/config";

const getXFragments = (fragments = [], duration) => {
    if (duration == undefined) {
        return fragments;
    }
    let arr = [];
    for (let i in fragments) {
        let f = fragments[i];
        if (i == (fragments.length - 1)) {
            arr = arr.concat([
                {
                    ...f,
                    to: Math.min(+duration - 0.25, +f.to)
                }
            ]);
        } else {
            arr = arr.concat([f]);
        }
    }
    return arr;
}

export default function MosesPlayer(props) {
    const {
        fragments = [],
        url,
        avatar,
        onLoaded = dur => {

        }
    } = props;

    const [selectedNumber, setSelectedNumber] = useState(undefined);
    const videoRef = useRef();

    // console.log('MosesPlayer: render: xFragments = ', xFragments);

    const [videoDuration, setVideoDuration] = useState(undefined);
    let xFragments = getXFragments(fragments, videoDuration);
    const selectedFragment = (selectedNumber == undefined) ? undefined : xFragments[selectedNumber];

    // console.log('MosesPlayer: render: fragments = ', fragments);

    useInterval(() => {
        if (videoDuration == undefined || window.isNaN(videoDuration)) {
            try {
                let dd = videoRef.current.duration;
                console.log('videoDuration = ', videoDuration);
                if (dd != undefined) {
                    setVideoDuration(dd);
                    onLoaded(dd);
                }
            } catch (exc0) {

            }
        }
        if (fragments == undefined || fragments.length == 0 || selectedFragment == undefined) {
            return;
        }
        let {from, to} = selectedFragment;
        try {
            let currPosition = videoRef.current.currentTime;
            if (+currPosition > +to) {
                videoRef.current.currentTime = from;
            }
            if (+currPosition < +from) {
                videoRef.current.currentTime = from;
            }
        } catch (exc) {

        }
    }, 100);

    useEffect(() => {
        if (selectedFragment != undefined) {
            try {
                videoRef.current.currentTime = selectedFragment.from;
            } catch (exc) {
            }
        }
    }, [selectedNumber]);

    let noDuration = (videoDuration == undefined || videoDuration == 0 || window.isNaN(videoDuration));

    return (
        <Wrapper>

            <VideoPlaceholder>
                <Video controls={true} ref={videoRef}
                       poster={avatar || DEFAULT_VIDEO_PLACEHOLDER}
                >
                    <source src={url}/>
                </Video>
            </VideoPlaceholder>

            {(fragments == undefined || fragments.length == 0) ? null :
                <ToolsPlaceholder loading={noDuration}>
                    {noDuration ? null :
                        <React.Fragment>
                            {xFragments.map((a, i) => {
                                let {to, from} = a;
                                let dur = +to - +from;
                                let sDur = `${dur.toFixed(1)}s`;
                                let isSelected = (selectedNumber != undefined && +i == +selectedNumber);
                                let leftPerc = from * 100.0 / videoDuration;
                                let widthPer = dur * 100.0 / videoDuration;
                                return (
                                    <FragmentItem key={`fr_${i}_${a.id}_${from}_${to}`}
                                                  left={leftPerc}
                                                  width={widthPer}
                                    >
                                        <FragmentInner
                                            selected={isSelected}
                                            onClick={() => {
                                                if (isSelected == true) {
                                                    setSelectedNumber(undefined);
                                                } else {
                                                    setSelectedNumber(i);
                                                }
                                            }}>
                                            {sDur}
                                        </FragmentInner>
                                    </FragmentItem>
                                )
                            })}
                        </React.Fragment>
                    }
                </ToolsPlaceholder>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Video = styled.video`
  box-sizing: border-box;
  background: lightgrey;
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.04);
`;

const VideoPlaceholder = styled.div`
  width: 100%;
  flex: 1;
  position: relative;
`;

const ToolsPlaceholder = styled.div`
  height: 32px;
  border-top: 1px solid grey;
  position: relative;
  width: 100%;
  background: ${props => (props.loading == true ? 'white' : 'rgba(0, 0, 0, 0.04)')};
  opacity: ${props => (props.loading == true ? 0.3 : 1)};
`;

const FragmentItem = styled.div`
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: ${props => props.left}%;
  width: ${props => props.width}%;
  box-sizing: border-box;
  padding: 3px;
`;

const FragmentInner = styled.div`
  border: 1px solid lightgrey;
  background: ${props => (props.selected == true ? '#FFA71B' : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  cursor: pointer;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  :hover {
    opacity: 0.75;
  }

`;
