import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'
import {getGracefullyMergedContainersMap} from "./ContainersReducer";

const initialState = {
    loading: false,

    dialogsMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const DialogsReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_DIALOG:
        case types.UPDATE_DIALOG:
        case types.DELETE_DIALOG:
        case types.LOAD_DIALOGS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_DIALOG_FAIL:
        case types.UPDATE_DIALOG_FAIL:
        case types.DELETE_DIALOG_FAIL:
        case types.LOAD_DIALOGS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_DIALOG_SUCCESS:
        case types.UPDATE_DIALOG_SUCCESS:
            return {
                ...state,
                loading: false,
                dialogsMap: getGracefullyMergedContainersMap(state.dialogsMap, [action.dialog])
            }


        case types.DELETE_DIALOG_SUCCESS:
            return {
                ...state,
                loading: false,
                dialogsMap: state.dialogsMap.delete(action.id),
            }


        case types.LOAD_DIALOGS_SUCCESS:
            return {
                ...state,
                loading: false,
                dialogsMap: getGracefullyMergedContainersMap(state.dialogsMap, action.dialogs)
            }


        default:
            return state;
    }

}

export default DialogsReducer;
