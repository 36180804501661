import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

const CHAMBERS_NUMBER = 10;

const getUserName = (user) => {
    if (user == undefined) {
        return undefined;
    }
    let gs = x => (x == undefined ? '' : x);
    let {email, firstName, lastName} = user;
    if (firstName == undefined) {
        return email;
    }
    return `${gs(firstName)} ${gs(lastName)}`;
}

export default function ChambersEditor(props) {
    const {
        allUsers = [],
        chambers = [],
        onChange = arr => {

        }
    } = props;

    const items = Array.from({length: CHAMBERS_NUMBER}).map((a, i) => ({
        label: `#${+i + 1}`,
        id: `group_${i}`,
        usersIds: []
    }));

    let xChambers = (chambers == undefined || chambers.length != CHAMBERS_NUMBER) ? items : chambers;

    return (
        <Wrapper>
            {allUsers.map((u, i) => {
                return (
                    <UserRow key={u.id}>
                        <Name>
                            {getUserName(u)}
                        </Name>
                        <ItemsPlaceholder>
                            {items.map((x, j) => {
                                let ch = xChambers.filter(xx => (xx.id == x.id))[0];
                                let usersIds = (ch == undefined || ch.usersIds == undefined) ? [] : ch.usersIds;
                                let isSelected = (usersIds.indexOf(u.id) > -1);
                                let wChambers = xChambers.map(a => ({
                                    ...a,
                                    usersIds: (a.usersIds == undefined ? [] : a.usersIds).filter(c => (c != u.id))
                                }))
                                return (
                                    <GroupCircle key={x.id} selected={isSelected} onClick={() => {
                                        let newIds = (isSelected == true) ? usersIds.filter(z => (z != u.id)) : usersIds.concat([u.id]);
                                        let newChambers = wChambers.map((qq, w) => (qq.id == ch.id) ? ({
                                            ...qq,
                                            usersIds: newIds
                                        }) : qq);
                                        onChange(newChambers);
                                    }}>
                                        {+j + 1}
                                    </GroupCircle>
                                )
                            })}
                        </ItemsPlaceholder>
                    </UserRow>
                )
            })}
        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const GroupCircle = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 1000px;
  display: flex;
  font-size: 13px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
  margin-right: 2px;
  border: ${props => (props.selected == true ? '1px solid #8C63A9' : '1px solid lightgrey')};
  background: ${props => (props.selected == true ? '#8C63A9' : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  font-weight: ${props => (props.selected == true ? 'bold' : 'default')};
`;

const UserRow = styled.div`
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 6px;
  box-sizing: border-box;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Name = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;

const ItemsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

`;
