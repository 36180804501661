import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import TeacherTemplate from "../templates/TeacherTemplate";
import GroupsPanel from "../groups/panels/GroupsPanel";

export default function TeacherGroupsApp(props) {

    return (
        <TeacherTemplate active={'groups'}>

            <GroupsPanel/>

        </TeacherTemplate>
    );
}

const Wrapper = styled.div`

`;
