import * as types from '../ActionTypes.js'
import TreadmillsAPI from '../../api/TreadmillsAPI.js';

let updateScale_ = () => {
    return {
        type: types.UPDATE_SCALE
    }
}
let updateScaleSuccess = (scale) => {
    return {
        type: types.UPDATE_SCALE_SUCCESS,
        scale: scale
    }
}
let updateScaleFailed = (error) => {
    return {
        type: types.UPDATE_SCALE_FAIL,
        error: error
    }
}

//thunk
export function updateTreadmillScale(data) {
    return (dispatch, getState) => {
        dispatch(updateScale_());
        return TreadmillsAPI.updateTreadmillScale(data.userId, data.scale).then(
            scale => dispatch(updateScaleSuccess(scale)),
            error => dispatch(updateScaleFailed(error))
        )
    }
}

let loadScale_ = () => {
    return {
        type: types.LOAD_SCALE
    }
}
let loadScaleSuccess = (scale) => {
    return {
        type: types.LOAD_SCALE_SUCCESS,
        scale: scale
    }
}
let loadScaleFailed = (error) => {
    return {
        type: types.LOAD_SCALE_FAIL,
        error: error
    }
}

//thunk
export function loadTreadmillScale(userId) {
    return (dispatch, getState) => {
        dispatch(loadScale_());
        return TreadmillsAPI.getTreadmillScale(userId).then(
            scale => dispatch(loadScaleSuccess(scale)),
            error => dispatch(loadScaleFailed(error))
        )
    }
}