import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const TreadmillsAPI = {

    async getTreadmill(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/treadmills/${id}`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async getTreadmillsByIds(ids) {
        let arr = [];
        for (let i in ids) {
            let a = await this.getTreadmill(ids[i]);
            arr = arr.concat([a]);
        }
        arr = arr.filter(x => (x != undefined));
        return arr;
    },


    async getAllTreadmills() {
        let pld = (await axios.get(`${API_ENDPOINT}/treadmills`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        let arr = pld.result || [];
        arr = arr.filter(x => (x != undefined));
        return arr;
    },

    async getUserTreadmills(userId) {
        let pld = (await axios.get(`${API_ENDPOINT}/user-treadmills/${userId}`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },


    async createTreadmill(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/treadmills`, data)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async getTreadmillsByUds(ids = []) {
        let pld = (await axios.post(`${API_ENDPOINT}/treadmills/by-ids`, {ids: ids})).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async updateTreadmill(data) {
        let pld = (await axios.put(`${API_ENDPOINT}/treadmills`, data)).data;
        return pld.result;
    },

    async deleteTreadmill(id) {
        await axios.delete(`${API_ENDPOINT}/treadmills/${id}`, {
            data: {id: id}
        })
    },

    async saveTreadmillAnswer(treadmillId, userId, data) {
        let pld = (await axios.post(`${API_ENDPOINT}/v3/treadmills/${treadmillId}/save-answer?userId=${userId}`, {
            options: data
        })).data;
        return pld;
    },

    async getTreadmillAnswer(treadmillId, userId) {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/treadmills/${treadmillId}/user-answer?userId=${userId}`)).data;
        return pld;
    },

    async deleteTreadmillAnswer(treadmillId, userId) {
        await axios.delete(`${API_ENDPOINT}/v3/treadmills/${treadmillId}/user-answer?userId=${userId}`);
    },

    async updateTreadmillScale(userId, scale) {
        let pld = (await axios.post(`${API_ENDPOINT}/kv/users/${userId}/items/treadmill_scale`, {
            data: scale
        })).data;
        return pld;
    },

    async getTreadmillScale(userId) {
        let pld = (await axios.get(`${API_ENDPOINT}/kv/users/${userId}/items/treadmill_scale`)).data;
        return pld;
    }

}

export default TreadmillsAPI;
