import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {FACELESS_AVATAR} from "../../../constants/config";
import CommonHelper from "../../../helpers/CommonHelper";

export default function SimpleUserViewTool(props) {
    const {id, avatar} = props;

    return (
        <Wrapper>
            <UserAvatar url={avatar || FACELESS_AVATAR}></UserAvatar>
            <UserName>
                {CommonHelper.getUserName(props)}
            </UserName>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UserName = styled.div`
  box-sizing: border-box;
  text-align: center;
  font-size: 12px;
  padding: 3px;

`;

const UserAvatar = styled.div`
  border-radius: 1000px;
  box-sizing: border-box;
  width: 45px;
  height: 45px;
  background: whitesmoke;
  border: 2px solid lightgrey;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${props => props.url});
`;