import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as actions from "../../../redux/actions/OxWordsActions";
import {GreenButton} from "../../ira/ui/Buttons";
import NiceModal from "../../modals/NiceModal";
import DrochkaInfoForm from "../../drochka_exercise/forms/DrochkaInfoForm";
import * as drockaExercisesActions from "../../../redux/actions/DrochkaExercisesActions";

export default function AllOxwordsPanel(props) {

    const {
        onCreated = () => {

        }
    } = props;

    const [selectedId, setSelectedId] = useState();
    const [addModalVisible, setAddModalVisible] = useState(false);

    const {
        currentUser,
        oxwords = [],
        loading
    } = useMappedState(useCallback(state => {
        let {currentUserId} = state.users;
        let currentUser = state.users.usersMap.get(currentUserId);
        let oxwords = state.oxwords.oxwordsMap.toArray();
        oxwords = oxwords.sort((a, b) => (+b.timestamp - +a.timestamp));
        return {
            currentUser: currentUser,
            oxwords: oxwords,
            loading: state.oxwords.loading
        }
    }, []));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.loadOxWords());
    }, []);

    let selectedOxword = (selectedId == undefined) ? undefined : oxwords.filter(x => (x.id == selectedId))[0];

    return (
        <Wrapper>

            <TopPlacehodler>
                <GreenButton onClick={() => {
                    setAddModalVisible(true);
                }}>
                    Add word
                </GreenButton>
            </TopPlacehodler>

            <ItemsPlaceholder>
                {oxwords.map((a, i) => {
                    let {grammarTags = [], subjectTags = []} = a;
                    return (
                        <ExItem key={`ex_${a.id}_${i}`} onClick={() => {

                        }}>

                            <Name onClick={() => {
                                setSelectedId(a.id);
                            }}>
                                {a.name}
                            </Name>
                            <TagsDiv>
                                <TagSpan >
                                    {a.pos}
                                </TagSpan>

                                <TagSpan >
                                    {a.level}
                                </TagSpan>
                                {/*{grammarTags.map(xx => (*/}
                                {/*    <TagSpan*/}
                                {/*        key={xx}>{xx}</TagSpan>))}*/}
                                {/*{subjectTags.map(xx => (*/}
                                {/*    <TagSpan*/}
                                {/*        key={xx}>{xx}</TagSpan>))}*/}
                            </TagsDiv>
                            <Description onClick={() => {
                                setSelectedId(a.id);
                            }}>
                                {a.description}
                            </Description>

                        </ExItem>
                    )
                })}
            </ItemsPlaceholder>

            {addModalVisible == false ? null :
                <NiceModal onClose={() => {
                    setAddModalVisible(false);
                }}>

                    Add new word

                    <InnerPlaceholder>
                        {/*<DrochkaInfoForm*/}
                        {/*    loading={loading}*/}
                        {/*    onSave={async d => {*/}
                        {/*        let pld = await dispatch(actions.createOxWord({*/}
                        {/*            ...d*/}
                        {/*        }));*/}
                        {/*        setAddModalVisible(false);*/}
                        {/*        setSelectedId(pld?.oxword?.id);*/}
                        {/*        onCreated(pld?.oxword);*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </InnerPlaceholder>

                </NiceModal>
            }

        </Wrapper>
    );
}

const InnerPlaceholder = styled.div`
  width: 920px;
  box-sizing: border-box;
  padding: 5px;
  height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  
  @media (max-width: 720px){
    width: 100%
  }
`;


const Description = styled.div`
  font-size: 12px;
  opacity: 0.7;
`;

const TagSpan = styled.span`
  margin-right: 5px;
  border: 1px solid whitesmoke;
  border-radius: 4px;
  line-height: 16px;
  font-size: 12px;
  opacity: 0.5;
  font-style: italic;
  padding-left: 8px;
  padding-right: 8px;
  background: #d3d3d363;
`;

const Wrapper = styled.div`

`;

const TagsDiv = styled.div`
  padding: 4px;
  padding-left: 0px;
  padding-right: 0px;
`;

const ExItem = styled.div`
  box-sizing: border-box;
  margin: 15px;
  background: white;
  padding: 15px;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.75;

  background: ${props => (props.selected == true ? 'blue' : 'white')};

  :hover {
    opacity: 1;
  }
`;

const Row = styled.div`
  margin-bottom: 15px;
`;

const ItemsPlaceholder = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`;


const TopPlacehodler = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  @media (max-width: 720px){
    weidth: 100%
  }
`;


const Name = styled.div`
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
`;

const ControlsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Item = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: white;
  border-radius: 4px;
`;