import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import TaskEditor from "../../lessons/tools/TaskEditor";
import KvdbAPI from "../../../api/KvdbAPI";

export default function KvdbTasksFeedPanel(props) {
    const {
        canEdit = false,
        name = 'test'
    } = props;

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        KvdbAPI.getValue(`feed_${name}`, {}).then(pld => {
            console.log('--->>> pld = ', pld);
            if (pld?.data == undefined) {
                setLoading(false);
                setData({});
                return;
            }
            setData(pld?.data);
            setLoading(false);
        });
    }, [name]);

    console.log('KvdbTasksFeedPanel: data = ', data);

    return (
        <Wrapper>

            <TaskEditor
                {...data}
                canEdit={canEdit}
                loading={loading}
                onChange={async d => {
                    console.log('onChange: d = ', d);
                    setLoading(true);
                    await KvdbAPI.saveValue(`feed_${name}`, {data: d});
                    setData(d);
                    setLoading(false);
                }}
            />

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;