import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import template_folder from '../../../assets/img/template_folder.svg'
import general_folder from '../../../assets/img/general_folder.svg'
import task_home from '../../../assets/img/task_home.svg'
import task_class from '../../../assets/img/task_class.svg'

import block_audio from '../../../assets/img/block_audio.svg'
import block_text from '../../../assets/img/block_text.svg'
import block_image from '../../../assets/img/block_image.svg'
import block_video from '../../../assets/img/block_video.svg'
import block_exercise from '../../../assets/img/block_exercise.svg'
import block_card from '../../../assets/img/block_card.svg'


export default function FolderTypeImage(props) {
    const {
        type,
        subtype
    } = props;

    let src = '';
    if (type == 'folder') {
        src = general_folder;
    }
    if (type == 'course') {
        src = template_folder;
    }
    if (type == 'module') {
        src = task_class;
    }
    if (type == 'task') {
        if (subtype == 'homework') {
            src = task_home;
        }
        if (subtype == 'class') {
            src = task_class;
        }
    }
    if (type == 'block') {
        if (subtype == 'text') {
            src = block_text;
        }
        if (subtype == 'video') {
            src = block_video;
        }
        if (subtype == 'image') {
            src = block_image;
        }
        if (subtype == 'audio') {
            src = block_audio;
        }
        if (subtype == 'exercise') {
            src = block_exercise;
        }
        if (subtype == 'card') {
            src = block_card;
        }
    }


    return (
        <Img src={src}/>
    );
}



const Img = styled.img`
  height: 14px;
  margin-right: 5px;
`;