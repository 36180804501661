import ForvoAPI from './ForvoAPI'

const SpeechAPI = {

    speak(text) {
        try {
            let msg = new SpeechSynthesisUtterance(text)
            let voices = window.speechSynthesis.getVoices()
            msg.voice = voices[0]
            window.speechSynthesis.speak(msg)
        } catch (exc) {
            console.log('can not speak: exc = ', exc);
        }
    },

    forvoSpeak(word) {
        let playMp3 = mp3Url => {
            try {
                let audio = new Audio(mp3Url);
                audio.play();
            } catch (exc) {
                console.log('caught exception = ', exc);
            }
        }
        let data = window.localStorage.getItem('speech_words_map');
        if (data == undefined || data == '') {
            window.localStorage.setItem('speech_words_map', JSON.stringify({}));
        }
        let map = JSON.parse(window.localStorage.getItem('speech_words_map'));
        try {
            if (map[word] == undefined) {
                ForvoAPI.getFirstPronunciation(word).then(item => {
                    map[word] = item;
                    window.localStorage.setItem('speech_words_map', JSON.stringify(map));
                    playMp3(item.url);
                });
            } else {
                let it = map[word];
                playMp3(it.url);
            }
        } catch (exc) {
            console.log('got exception = ', exc);
        }
    }


}

export default SpeechAPI
