import styled from 'styled-components';
import {BROWN, DARK_SIDE, LIGHT_BROWN, MAIN_ORANGE_COLOR} from "./Colors";

const DEFAULT_BACKGROUND = LIGHT_BROWN;


const DEFAULT_INPUT_HEIGHT = 28;
const DEFAULT_INPUT_FONT_SIZE = 14;

export const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: ${DEFAULT_INPUT_HEIGHT}px;
  line-height: ${DEFAULT_INPUT_HEIGHT}px;
  border: 1px solid whitesmoke;
  border-radius: 4px;
  outline: none;
  padding-left: 3px;
  font-size: ${DEFAULT_INPUT_FONT_SIZE}px;

  :focus {
    border-color: ${MAIN_ORANGE_COLOR};
  }
`;
