import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import useSpeechToText from 'react-hook-speech-to-text';
import {BlueButton, GreenButton} from "../../ira/ui/Buttons";

import loading_img from './loading-animation.gif'
import {DangerButton} from "../../ui/Button";

export default function SpeechInput(props) {
    const {
        onSave = txt => {
            console.log('onSave occured! txt = ', txt);
        },
        answer = ``
    } = props;
    const {
        error,
        interimResult,
        isRecording,
        results,
        startSpeechToText,
        stopSpeechToText,
        setResults
    } = useSpeechToText({
        continuous: true,
        useLegacyResults: false,
        speechRecognitionProperties: {
            lang: 'en-US',
            interimResults: true // Allows for displaying real-time speech results
        }
    });

    const [myTranscript, setMyTranscript] = useState(``);
    const [myLatestTranscript, setMyLatestTranscript] = useState();
    const transRef = useRef('');

    useEffect(() => {
        if (results.length > 0) {
            const latestResult = results[results.length - 1]
            const {transcript} = latestResult;
            let ss = `${transRef.current} ${transcript}`;
            transRef.current = ss;
            setMyTranscript(ss);
        }
    }, [results]);

    if (error) return <p>Web Speech API is not available in this browser 🤷‍</p>;

    console.log('results = ', results);
    let canSave = (myTranscript.length > 0 && isRecording == false);
    let canClean = (myTranscript.length > 0 && isRecording == false);

    return (
        <Wrapper>
            <div>
                <ControlsPlaceholder>

                    <ResultsPlaceholder>
                        {/*{results.map((result) => (*/}
                        {/*    <ResultItem key={result.timestamp}>{result.transcript}</ResultItem>*/}
                        {/*))}*/}
                        {interimResult && <ResultItem>{interimResult}</ResultItem>}
                    </ResultsPlaceholder>

                    {(answer == undefined || answer == '') ? null :
                        <div>
                            <span style={{opacity: 0.5}}>
                                {'Saved answer: '}
                            </span>
                            {' '}
                            <span style={{fontWeight: 'bold'}} >
                                {`${answer}`}
                            </span>
                        </div>
                    }


                    <div style={{color: 'orange', padding: 10}}>
                        {transRef.current}
                    </div>

                    <BlueButton onClick={isRecording ? () => {
                        onSave(`${transRef.current}`);
                        stopSpeechToText();
                        setTimeout(() => {
                            transRef.current = ``;
                            setMyTranscript('');
                        }, 50);
                    } : () => {
                        startSpeechToText();
                    }}>
                        {isRecording ? 'Stop Recording' : 'Start Recording'}
                    </BlueButton>

                    {isRecording && <span>recording...</span>}

                </ControlsPlaceholder>

                {/*<BottomControlsPlaceholder>*/}
                {/*    {canSave == false ? null :*/}
                {/*        <GreenButton onClick={() => {*/}
                {/*            onSave(myTranscript);*/}
                {/*        }}>*/}
                {/*            Save*/}
                {/*        </GreenButton>*/}
                {/*    }*/}
                {/*    {canClean == false ? null :*/}
                {/*        <DangerButton onClick={() => {*/}
                {/*            setMyTranscript(``);*/}
                {/*        }}>*/}
                {/*            Clear*/}
                {/*        </DangerButton>*/}
                {/*    }*/}

                {/*</BottomControlsPlaceholder>*/}

            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const BottomControlsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

const ResultsPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  opacity: 0.4;
`;

const ResultItem = styled.div`
  padding: 3px;
`;


const ControlsPlaceholder = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;