import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {BlueButton} from "../ira/ui/Buttons";
import {API_ENDPOINT} from "../../constants/config";

export default function RecognitionExampleComponent(props) {
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const [translations, setTranslations] = useState([]);

    return (
        <Wrapper>
            <div>
                <input placeholder={'url to file'} onChange={evt => {
                    setText(evt.target.value);
                }}/>
            </div>
            <BlueButton onClick={async () => {
                if (isValidHttpUrl(text) == false) {
                    return window.alert('Not correct url');
                }
                if (loading == true){
                    return;
                }
                setTranslations([]);
                setLoading(true);
                let pld = (await axios.post(`${API_ENDPOINT}/recognition/send`, {
                    url: text
                })).data;
                setTranslations(pld);
                setLoading(false);
            }}>
                <Spin visible={loading}/>
                {loading == true ? null : 'Get text'}
            </BlueButton>

            <ul>
                {translations.map((a, i) => {
                    return (
                        <li key={a.id}>
                            {a.text}
                        </li>
                    )
                })}
            </ul>


        </Wrapper>
    );
}

function isValidHttpUrl(string) {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

const Wrapper = styled.div`

`;