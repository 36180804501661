import * as types from '../ActionTypes.js'
import TreadmillsAPI from '../../api/TreadmillsAPI.js';

let createTreadmill_ = () => {
    return {
        type: types.CREATE_TREADMILL
    }
}
let createTreadmillSuccess = (treadmill) => {
    return {
        type: types.CREATE_TREADMILL_SUCCESS,
        treadmill: treadmill
    }
}
let createTreadmillFailed = (error) => {
    return {
        type: types.CREATE_TREADMILL_FAIL,
        error: error
    }
}

//thunk
export function createTreadmill(data) {
    return (dispatch, getState) => {
        dispatch(createTreadmill_());
        return TreadmillsAPI.createTreadmill(data).then(
            treadmill => dispatch(createTreadmillSuccess(treadmill)),
            error => dispatch(createTreadmillFailed(error))
        )
    }
}

//eca
let updateTreadmill_ = () => {
    return {
        type: types.UPDATE_TREADMILL
    }
}
let updateTreadmillSuccess = (treadmill) => {
    return {
        type: types.UPDATE_TREADMILL_SUCCESS,
        treadmill: treadmill
    }
}
let updateTreadmillFailed = (error) => {
    return {
        type: types.UPDATE_TREADMILL_FAIL,
        error: error
    }
}

//thunk
export function updateTreadmill(data) {
    return (dispatch, getState) => {
        dispatch(updateTreadmill_());
        return TreadmillsAPI.updateTreadmill(data).then(
            treadmill => dispatch(updateTreadmillSuccess(treadmill)),
            error => dispatch(updateTreadmillFailed(error))
        )
    }
}

let loadTreadmills_ = () => {
    return {
        type: types.LOAD_TREADMILLS
    }
}
let loadTreadmillsSuccess = (treadmills) => {
    return {
        type: types.LOAD_TREADMILLS_SUCCESS,
        treadmills: treadmills
    }
}
let loadTreadmillsFailed = (error) => {
    return {
        type: types.LOAD_TREADMILLS_FAIL,
        error: error
    }
}

//thunk
export function loadTreadmills() {
    return (dispatch, getState) => {
        dispatch(loadTreadmills_());
        return TreadmillsAPI.getAllTreadmills().then(
            treadmills => dispatch(loadTreadmillsSuccess(treadmills)),
            error => dispatch(loadTreadmillsFailed(error))
        )
    }
}

export function loadUserTreadmills(userId) {
    return (dispatch, getState) => {
        dispatch(loadTreadmills_());
        return TreadmillsAPI.getUserTreadmills(userId).then(
            treadmills => dispatch(loadTreadmillsSuccess(treadmills)),
            error => dispatch(loadTreadmillsFailed(error))
        )
    }
}


export function loadTreadmillsByIds(ids) {
    return (dispatch, getState) => {
        dispatch(loadTreadmills_());
        return TreadmillsAPI.getTreadmillsByIds(ids).then(
            treadmills => dispatch(loadTreadmillsSuccess(treadmills)),
            error => dispatch(loadTreadmillsFailed(error))
        )
    }
}

let deleteTreadmill_ = () => {
    return {
        type: types.DELETE_TREADMILL
    }
}
let deleteTreadmillSuccess = (id) => {
    return {
        type: types.DELETE_TREADMILL_SUCCESS,
        id: id
    }
}
let deleteTreadmillFailed = (error) => {
    return {
        type: types.DELETE_TREADMILL_FAIL,
        error: error
    }
}

//thunk
export function deleteTreadmill(id) {
    return (dispatch, getState) => {
        dispatch(deleteTreadmill_());
        return TreadmillsAPI.deleteTreadmill(id).then(
            () => dispatch(deleteTreadmillSuccess(id)),
            error => dispatch(deleteTreadmillFailed(error))
        )
    }
}