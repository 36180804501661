import * as types from '../ActionTypes.js'
import MaterialsAPI from '../../api/MaterialsAPI.js';
import {DICTIONARY_GROUP_ID, IDIOMS_GROUP_ID} from "../../constants/config";

export function selectMaterial(materialId) {
    return {
        type: types.SELECT_MATERIAL,
        id: materialId
    }
}

let createMaterial_ = () => {
    return {
        type: types.CREATE_MATERIAL
    }
}
let createMaterialSuccess = (material) => {
    return {
        type: types.CREATE_MATERIAL_SUCCESS,
        material: material
    }
}
let createMaterialFailed = (error) => {
    return {
        type: types.CREATE_MATERIAL_FAIL,
        error: error
    }
}

//thunk
export function createMaterial(data) {
    return (dispatch, getState) => {
        dispatch(createMaterial_());
        return MaterialsAPI.createMaterial(data).then(
            material => dispatch(createMaterialSuccess(material)),
            error => dispatch(createMaterialFailed(error))
        )
    }
}

//eca
let updateMaterial_ = () => {
    return {
        type: types.UPDATE_MATERIAL
    }
}
let updateMaterialSuccess = (material) => {
    return {
        type: types.UPDATE_MATERIAL_SUCCESS,
        material: material
    }
}
let updateMaterialFailed = (error) => {
    return {
        type: types.UPDATE_MATERIAL_FAIL,
        error: error
    }
}

//thunk
export function updateMaterial(data) {
    return (dispatch, getState) => {
        dispatch(updateMaterial_());
        return MaterialsAPI.updateMaterial(data).then(
            material => dispatch(updateMaterialSuccess(material)),
            error => dispatch(updateMaterialFailed(error))
        )
    }
}

let loadMaterials_ = () => {
    return {
        type: types.LOAD_MATERIALS
    }
}
let loadMaterialsSuccess = (materials) => {
    return {
        type: types.LOAD_MATERIALS_SUCCESS,
        materials: materials
    }
}
let loadMaterialsFailed = (error) => {
    return {
        type: types.LOAD_MATERIALS_FAIL,
        error: error
    }
}

//thunk
export function getMyMaterials() {
    return (dispatch, getState) => {
        dispatch(loadMaterials_());
        let currentUserId = getState().users.usersMap.get(getState().users.currentUserId);
        return MaterialsAPI.getUserMaterials(currentUserId).then(
            materials => dispatch(loadMaterialsSuccess(materials)),
            error => dispatch(loadMaterialsFailed(error))
        )
    }
}

export function getMaterial(id) {
    return (dispatch, getState) => {
        dispatch(loadMaterials_());
        return MaterialsAPI.getMaterial(id).then(
            a => dispatch(loadMaterialsSuccess([a])),
            error => dispatch(loadMaterialsFailed(error))
        )
    }
}

let deleteMaterial_ = () => {
    return {
        type: types.DELETE_MATERIAL
    }
}
let deleteMaterialSuccess = (id) => {
    return {
        type: types.DELETE_MATERIAL_SUCCESS,
        id: id
    }
}
let deleteMaterialFailed = (error) => {
    return {
        type: types.DELETE_MATERIAL_FAIL,
        error: error
    }
}

//thunk
export function deleteMaterial(id) {
    return (dispatch, getState) => {
        dispatch(deleteMaterial_());
        return MaterialsAPI.deleteMaterial(id).then(
            () => dispatch(deleteMaterialSuccess(id)),
            error => dispatch(deleteMaterialFailed(error))
        )
    }
}