import * as types from '../ActionTypes.js'
import ContainersAPI from '../../api/ContainersAPI.js';

let loadContainers_ = () => {
    return {
        type: types.SMART_LOAD_CONTAINERS
    }
}
let loadContainersSuccess = (containers) => {
    return {
        type: types.SMART_LOAD_CONTAINERS_SUCCESS,
        containers: containers
    }
}
let loadContainersFailed = (error) => {
    return {
        type: types.SMART_LOAD_CONTAINERS_FAIL,
        error: error
    }
}

//thunk
export function getMyAllCompactContainers() {
    return (dispatch, getState) => {
        dispatch(loadContainers_());
        return ContainersAPI.getAllShortContainers().then(
            containers => dispatch(loadContainersSuccess(containers)),
            error => dispatch(loadContainersFailed(error))
        )
    }
}

export function getContainer(id) {
    return (dispatch, getState) => {
        dispatch(loadContainers_());
        return ContainersAPI.getContainer(id).then(
            container => dispatch(loadContainersSuccess([container])),
            error => dispatch(loadContainersFailed(error))
        )
    }
}

export function getChildren(id) {
    return (dispatch, getState) => {
        dispatch(loadContainers_());
        return ContainersAPI.getChildren(id).then(
            containers => dispatch(loadContainersSuccess(containers)),
            error => dispatch(loadContainersFailed(error))
        )
    }
}

let createContainer_ = () => {
    return {
        type: types.SMART_CREATE_CONTAINER
    }
}
let createContainerSuccess = (container, parentId) => {
    return {
        type: types.SMART_CREATE_CONTAINER_SUCCESS,
        container: container,
        parentId: parentId
    }
}
let createContainerFailed = (error) => {
    return {
        type: types.SMART_CREATE_CONTAINER_FAIL,
        error: error
    }
}

//thunk
export function createContainer(parentId, data) {
    return (dispatch, getState) => {
        dispatch(createContainer_());
        return ContainersAPI.createContainer(parentId, data).then(
            container => dispatch(createContainerSuccess(container, parentId)),
            error => dispatch(createContainerFailed(error))
        )
    }
}

//eca
let updateContainer_ = () => {
    return {
        type: types.SMART_UPDATE_CONTAINER
    }
}
let updateContainerSuccess = (container) => {
    return {
        type: types.SMART_UPDATE_CONTAINER_SUCCESS,
        container: container
    }
}
let updateContainerFailed = (error) => {
    return {
        type: types.SMART_UPDATE_CONTAINER_FAIL,
        error: error
    }
}

//thunk
export function updateContainer(data) {
    return (dispatch, getState) => {
        dispatch(updateContainer_());
        return ContainersAPI.updateContainer(data).then(
            container => dispatch(updateContainerSuccess(container)),
            error => dispatch(updateContainerFailed(error))
        )
    }
}


let deleteContainer_ = () => {
    return {
        type: types.SMART_DELETE_CONTAINER
    }
}
let deleteContainerSuccess = (id, parentId) => {
    return {
        type: types.SMART_DELETE_CONTAINER_SUCCESS,
        id: id,
        parentId: parentId
    }
}
let deleteContainerFailed = (error) => {
    return {
        type: types.SMART_DELETE_CONTAINER_FAIL,
        error: error
    }
}

//thunk
export function deleteContainer(parentId, id) {
    return (dispatch, getState) => {
        dispatch(deleteContainer_());
        return ContainersAPI.deleteContainer(parentId, id).then(
            () => dispatch(deleteContainerSuccess(id, parentId)),
            error => dispatch(deleteContainerFailed(error))
        )
    }
}