import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CommonHelper from "../../../helpers/CommonHelper";
import LottieLoader from "../../loaders/LottieLoader";

import loading_lottie from '../../../assets/lottie/loading_group_lottie.json'
import GroupUsersPanel from "./GroupUsersPanel";

import * as actions from '../../../redux/actions/GroupsActions'
import * as tasksActions from '../../../redux/actions/TasksActions'

import ls from 'local-storage'

import ch_box_image from '../../../assets/img/ch_box.svg'
import {BlueButton} from "../../ira/ui/Buttons";
import UpdateGroupForm from "../forms/UpdateGroupForm";
import TeacherGroupLessonsPanel from "../../lessons/panels/TeacherGroupLessonsPanel";
import LogoDiv from "../../templates/LogoDiv";
import StudentGroupLessonsPanel from "../../lessons/panels/StudentGroupLessonsPanel";
import {translate} from "../../../utils/Translate";

import select_circle from '../../../assets/img/select-circle-area.svg'
import * as usersActions from "../../../redux/actions/UsersActions";
import NiceModal from "../../modals/NiceModal";
import StudentsList from "../../students/list/StudentsList";

export default function StudentGroupPanel(props) {
    const {
        id,
        tab
    } = props;

    const {
        loading,
        group,
        lessons,
        selectedSlide,
        usersMap
    } = useMappedState(useCallback(state => {
        return {
            loading: state.groups.loading,
            lessons: state.lessons.lessonsMap.toArray().filter(x => (x?.classId == id)).sort((a, b) => (+b.startTimestamp - +b.startTimestamp)),
            group: state.groups.groupsMap.get(id),
            selectedSlide: state.slides.slidesMap.get(state.slides.selectedSlideId),
            usersMap: state.users.usersMap
        }
    }));
    const dispatch = useDispatch();


    // const [tab, setTab] = useState(ls('gr_mode') == undefined ? 'tasks' : ls('gr_mode'));
    let hasAtLeastOneLesson = (lessons.length > 0);

    useEffect(() => {
        dispatch(tasksActions.getGroupTasks(id));
    }, [id]);

    let name = group?.name || '...';
    let invitationCode = group?.invitationCode || '';
    let isSomethingLoading = (loading == true && group == undefined);

    // let hasLogo = (hasAtLeastOneLesson && tab == 'tasks');
    let hasLogo = (tab == 'tasks');
    let shownUsers = (group?.users == undefined) ? [] : group.users.map(x => usersMap.get(x.id)).filter(xx => (xx != undefined));

    return (
        <Wrapper>

            <TopPlaceholder hasLogo={hasLogo}>


                <TopLeft>

                    <BackSpan
                        style={{marginLeft: (hasLogo == true) ? 15 : 0}}
                        onClick={() => {
                            CommonHelper.linkTo(`/`);
                        }}>
                        {translate('my_groups')}
                    </BackSpan>
                    <span style={{marginRight: 10}}>
                            {' / '}
                        </span>
                    <GSpan>
                            <span style={{fontWeight: 'bold', opacity: 0.65}}>
                                {name}
                            </span>
                    </GSpan>
                </TopLeft>
                <TopRight>
                    <BlueButton style={{marginLeft: 10}} onClick={() => {
                        let url = window.location.origin + window.location.pathname + '#' + `/online/${id}`;
                        window.open(url, '_blank').focus();
                    }}>
                        {translate('online_lesson')}
                    </BlueButton>
                </TopRight>
            </TopPlaceholder>

            <TabsPlaceholder>

                <StudentGroupLessonsPanel
                    id={id}
                    hasTopInfoBar={true}
                />

            </TabsPlaceholder>

            {isSomethingLoading == false ? null :
                <LottiePlaceholder>
                    <LottieLoader
                        json={loading_lottie}
                    />
                    <div>
                        {translate('loading')}
                    </div>
                </LottiePlaceholder>
            }



        </Wrapper>
    );
}



const StudentRow = styled.div`
  cursor: pointer;
  opacity: 0.6;
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid whitesmoke;
  :hover {
    opacity: 1;
  }
`;




const LottiePlaceholder = styled.div`
  height: calc(80vh - 120px);
  width: 100%;
  text-align: center;
`;

const BackSpan = styled.span`
  opacity: 0.7;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;

  @media (max-width: 1300px) {
    font-size: 12px;
  }

  :hover {
    opacity: 1;
  }
`;

const GSpan = styled.span`
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1200px) {
    font-size: 12px;
  }
`;

const TopPlaceholder = styled.div`
  background: white;
  height: 70px;
  //margin-bottom: 30px;
  box-sizing: border-box;
  padding-left: ${props => (props.hasLogo ? 0 : 15)}px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1200px) {
    font-size: 12px;
  }
`;

const TopRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TopRightTabItem = styled.div`
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${props => (props.selected == true ? '#085BFF' : 'black')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
  border-bottom: 6px solid ${props => (props.selected == true ? '#FFA71B' : 'white')};
  border-top: 6px solid white;
  box-sizing: border-box;
  height: 70px;

  @media (max-width: 1200px) {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (max-width: 720px){
    margin-left: 6px;
    margin-right: 6px;
  }
  
  :last-of-type {

  }

  :hover {
    color: #085BFF;
  }
`;

const Wrapper = styled.div`

`;

const Content = styled.div`
  width: 100%;
  padding-top: 5px;
`;

const TabsPlaceholder = styled.div`
  box-sizing: border-box;
  //padding: 15px;
`;

const ClassNamePlaceholder = styled.div`
  padding: 10px;
  text-align: center;
  font-size: 32px;
`;

const ClassCodePlaceholder = styled.div`
  padding: 0px;
  text-align: center;
  font-size: 18px;
  opacity: 0.7;
  font-weight: bold;
`;

const SpinnerPlaceholder = styled.div`
  text-align: center;
  padding: 10px;
`;

const OnlineLinkPlaceholder = styled.div`
  text-align: center;
  padding: 5px;
`;

const OnlineLinkSpan = styled.span`
  cursor: pointer;
  opacity: 0.5;

  :hover {
    text-decoration: underline;
    opacity: 1;
  }
`;