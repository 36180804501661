import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";
import CommonHelper from "../../../helpers/CommonHelper";

async function getUser(userId) {
    let key = `uu_w_${userId}`;
    if (window[key] != undefined) {
        let u = window[key];
        return u;
    }
    let pld = (await axios.get(`${API_ENDPOINT}/user/info/${userId}`)).data;
    window[key] = pld;
    return pld;
}

export default function UserNameSpan(props) {
    const {
        userId
    } = props;

    useEffect(() => {
        getUser(userId).then(x => {
            setUser(x);
        })
    }, [userId]);

    const [user, setUser] = useState(undefined);
    if (user == undefined) {
        return '...';
    }
    let name = CommonHelper.getUserName(user);

    return (
        <Wrapper>
            {name}
        </Wrapper>
    );
}

const Wrapper = styled.span`
  color: black;
  font-size: 12px;
  opacity: 0.7;
  padding: 3px;
  padding-left: 10px;
`;