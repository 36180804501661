import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const GroupStatsAPI = {

    async getGroupAnswersMap(classId) {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/classes/${classId}/grand-students-answers-map`)).data;
        return pld.result;
    },

    async updateTaskMark(taskId, studentId, data) {
        let pld = (await axios.post(`${API_ENDPOINT}/v3/tasks/${taskId}/students/${studentId}/save-mark`, {data: data})).data;
        return pld;
    },

    async getAllStudentMarks(studentId) {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/students/${studentId}/all-task-marks`)).data;
        return pld;
    },

    async getAllTaskMarks(taskId) {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/tasks/${taskId}/all-marks`)).data;
        return pld;
    },

    async getTaskMark(taskId, studentId) {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/tasks/${taskId}/students/${studentId}/mark`)).data;
        return pld;
    }

}

export default GroupStatsAPI;