import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import * as copyPasteActions from "../../redux/actions/CopyPasterActions";
import {useDispatch} from "redux-react-hook";
import {translate} from "../../utils/Translate";

export const getBasketItems = () => {
    let key = `content_basket`;
    let arr = window.localStorage.getItem(key);
    if (arr == undefined) {
        arr = [];
        window.localStorage.setItem(key, JSON.stringify([]));
    } else {
        arr = JSON.parse(arr);
    }
    return arr;
}

export const saveBasketItem = (type = 'video', data = {}) => {
    console.log('saveBasketItem occured! type, data = ', type, data);
    let key = `content_basket`;
    let arr = window.localStorage.getItem(key);
    if (arr == undefined || arr == '') {
        arr = [];
    } else {
        arr = JSON.parse(arr);
    }
    let d = {
        ...data,
        type: type,
        id: uuid(),
        t: +new Date(),
        name: data.name == undefined ? `${type} ${moment().format('DD.MM.YYYY HH:mm:ss')}` : data.name
    }
    let f = true;
    if (type != 'text') {
        let xUrl = data.url || (data.content == undefined ? undefined : data.content.url);
        xUrl = (xUrl == undefined) ? '' : xUrl;

        for (let i in arr) {
            let v = arr[i];
            if (JSON.stringify(v).indexOf(xUrl) > -1) {
                f = false;
            }
        }
    }
    if (f == false) {
        return;
    }
    arr = arr.concat([d]);
    window.localStorage.setItem(key, JSON.stringify(arr));
    let ev = new CustomEvent('add_content_to_basket', arr);
    window.dispatchEvent(ev);
}

export default function ContentBasketPanel(props) {
    const {
        onSelect = a => {

        },
        shouldCopyToRedux = true,
        filterType = undefined
    } = props;
    const [items, setItems] = useState(getBasketItems());
    const dispatch = useDispatch();

    if (items.length == 0) {
        return null;
    }

    let filteredItems = items.filter(a => (filterType == undefined || a.type == filterType)).sort((a, b) => (+b.t - +a.t));

    return (
        <Wrapper>

            <ListPlaceholder>
                {filteredItems.map((a, i) => {
                    return (
                        <Item key={`el_${a.id}_${a.t}`}>
                            <Name>
                                {a.name}
                            </Name>
                            <ItemContent>
                                {a.type != 'image' ? null :
                                    <Image src={a.url}/>
                                }
                                {a.type != 'video' ? null :
                                    <Video controls={1}>
                                        <source src={a.url}/>
                                    </Video>
                                }
                                {a.type != 'text' ? null :
                                    <Text>
                                        <div dangerouslySetInnerHTML={{__html: a.text}}></div>
                                    </Text>
                                }

                            </ItemContent>
                            <ControlsPlaceholder>
                                <ControlItem style={{color: 'red'}} onClick={() => {
                                    let newItems = getBasketItems().filter(x => (x.id != a.id));
                                    window.localStorage.setItem('content_basket', JSON.stringify(newItems));
                                    setItems(newItems);
                                    let ev = new CustomEvent('add_content_to_basket', []);
                                    window.dispatchEvent(ev);
                                }}>
                                    удалить
                                </ControlItem>
                                <ControlItem onClick={() => {
                                    onSelect(a);
                                    if (shouldCopyToRedux == false){
                                        return;
                                    }
                                    console.log('onSelect: a = ', a);
                                    let ev = new CustomEvent('select_basket_content', {detail: a});
                                    window.dispatchEvent(ev);
                                    dispatch(copyPasteActions.copyToBuffer('SLIDE_ITEM', {...a, content: {...a}}));
                                }}>
                                    {translate('select')}
                                </ControlItem>
                            </ControlsPlaceholder>
                        </Item>
                    )
                })}
            </ListPlaceholder>

            <div style={{
                marginTop: 50,
                cursor: 'pointer',
                textAlign: 'center',
                color: 'red',
                textDecoration: 'underline',
                fontSize: 12
            }}
                 onClick={() => {
                     window.localStorage.setItem('content_basket', JSON.stringify([]));
                     setItems([]);
                     let ev = new CustomEvent('add_content_to_basket', []);
                     window.dispatchEvent(ev);
                 }}
            >
                очистить корзину
            </div>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Text = styled.div`
    
`;

const ListPlaceholder = styled.div`
    
`;

const ItemContent = styled.div`
    
`;

const ControlsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const ControlItem = styled.div`
    cursor: pointer;
    opacity: 0.5;
    font-size: 12px;
    margin-left: 15px;
    margin-right: 15px;
    :hover{
      opacity: 0.99;
    }
`;

const Item = styled.div`
    border: 1px solid whitesmoke;
    box-sizing: border-box;
    margin-bottom: 15px;
    padding: 7px;
`;

const Video = styled.video`
    width: 360px;
    height: 240px;
`;

const Image = styled.img`
    height: 300px;
`;

const Name = styled.div`
    font-weight: bold;
`;
