import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {BlueButton} from "../../ira/ui/Buttons";

let regex = /\[\[[a-zA-Z0-9а-яА-ЯёЁ,;|\u0590-\u05FF -\\"_'’`]*\]\]/g;

function removeOne(items, x) {
    let arr = [];
    let f = false;
    for (let i in items) {
        let x_ = items[i];
        if (x_ == x && f == false) {
            f = true;
            continue;
        }
        arr.push(x_);
    }
    return arr;
}

function getRestWords(userAnswer, words) {
    let arr = [];
    let items = userAnswer.split('|').filter(x => (x != ''));
    let xArr = JSON.parse(JSON.stringify(items));
    for (let i in words) {
        let w = words[i];
        if (xArr.indexOf(w) == -1) {
            arr.push(w);
        } else {
            xArr = removeOne(xArr, w);
        }
    }
    return arr.sort((a, b) => {
        if (a > b) {
            return 1;
        }
        if (a < b) {
            return -1;
        }
        return 0;
    });
}

function getWords(text) {
    try {
        if (text == undefined || text.length < 3) {
            return [];
        }
        if (text.indexOf('[[') == -1 || text.indexOf(']]') == -1) {
            return [];
        }
        const found = text.match(regex).map(xx => xx.replace('[[', '').replace(']]', '')).map(xx => (xx.split('|')[0]));
        return found;
    } catch (exc) {
        console.log('getWords: text = ', text);
    }
    return [];
}

function getWordsVariants(text) {
    try {
        if (text == undefined || text.length < 3) {
            return [];
        }
        if (text.indexOf('[[') == -1 || text.indexOf(']]') == -1) {
            return [];
        }
        let found0 = text.match(regex).map(xx => xx.replace('[[', '').replace(']]', ''));
        const found = found0.map(xx => xx.split('|')).reduce((xArr, a) => ([...xArr, ...a]), []).map(xx => xx.split('&')).reduce((xArr, a) => ([...xArr, ...a]), []);
        return found;
    } catch (exc) {
        console.log('getWordsVariants: exc = ', exc);
    }
    return [];
}

function getItems(text) {
    console.log('text: ', text)
    if (text.length < 3) {
        return [];
    }
    let words = getWords(text);
    let items = [];
    let currentItem = '';
    let startIndex = 0;
    let k = 0;
    for (let i = 1; i < text.length; i++) {
        let currChar = text[+i];
        let prevChar = text[+i - 1];
        if (`${prevChar}${currChar}` == '[[') {
            let ss = text.slice(+startIndex, +i - 1);
            items.push({
                type: 'out',
                text: ss
            });
        }
        if (`${prevChar}${currChar}` == ']]') {
            startIndex = +i + 1;
            items.push({
                type: 'word',
                text: words[k],
                index: k
            });
            k = +k + 1;
        }
    }
    items.push({
        type: 'out',
        text: text.slice(startIndex)
    })
    console.log('words: ', items);
    return items;
}

function getDefaultUserAnswer(s, words) {
    if (s == undefined || s.split('|').length != words.length) {
        return words.map(x => '').join('|');
    }
    return s;
}

function getUserWordByUserAnswer(s, index) {
    let arr = s.split('|');
    if (+index > arr.length - 1) {
        return ''
    }
    return arr[+index];
}

function isHebrew(text) {
    if (text === undefined || text.trim() === '') {
        return false;
    }
    let hebrewChars = /[\u0590-\u05FF]+/g;
    let s = text.replace(hebrewChars, '')
    return s.length < text.length
}

export default function DndFillGapsExercise(props) {
    const {
        // text = 'This is a [[test|qq|ww]] exercise for the [[university]] team. This [[text]] will be parsed. This is a [[A]] [[A]].',
        text = '',
        onSave = (x, isCorrect) => {

        },
        canAnswer = true,
        showCorrectAnswer = false,
        loading = false
    } = props;
    const items = getItems(text);
    const words = getWords(text);
    const [userAnswer, setUserAnswer] = useState(getDefaultUserAnswer(props.userAnswer, words));
    const [updatedSomething, setUpdatedSomething] = useState(false);
    console.log('DndFillGapsExercise: props.userAnswer = ', props.userAnswer);

    useEffect(() => {
        setUserAnswer(getDefaultUserAnswer(props.userAnswer, getWords(props.text)));
    }, [props.text, props.userAnswer])

    let wordsVars = getWordsVariants(text);
    let bottomWords = getRestWords(userAnswer, wordsVars);

    useEffect(() => {
        if (loading == true || props.canAnswer == false || updatedSomething == false) {
            return;
        }
        let answeredAll = (userAnswer.split('|').filter(x => (x.length > 0)).length == words.length);
        if (answeredAll == true) {
            let f = true;
            let xArr = userAnswer.split('|');
            for (let j in xArr) {
                if (xArr[j] != words[j]) {
                    f = false;
                }
            }
            if (props.canAnswer == false) {
                return;
            }
            onSave(userAnswer, f);
        }
    }, [userAnswer, props.canAnswer, updatedSomething]);

    return (
        <Wrapper>

            <TopPlaceholder style={{textAlign: isHebrew(text) ? 'right' : 'left'}} dir={isHebrew(text) ? 'rtl' : 'ltr'}>
                {items.map((a, i) => {
                    let {type} = a;
                    console.log('a: ', a);
                    let uWord = (a.type == 'word') ? getUserWordByUserAnswer(userAnswer, a.index) : '';
                    let isCorrect = (uWord == a.text);
                    return (
                        <ItemSpan key={i}>
                            {type == 'out' ? <span><span
                                dangerouslySetInnerHTML={{__html: `${a.text}`.replace(/\n/g, '<br/>')}}></span></span> : null}
                            {type == 'word' ? <Word
                                isCorrect={(showCorrectAnswer == false) ? undefined : isCorrect}
                                onDragOver={event => {
                                    event.preventDefault();
                                }}
                                onDrop={event => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    let dw = window.draggingWord;
                                    if (wordsVars.indexOf(dw) == -1 || uWord == dw || canAnswer == false) {
                                        return;
                                    }
                                    let uArr = userAnswer.split('|');
                                    let newUArr = JSON.parse(JSON.stringify(uArr));
                                    newUArr[a.index] = dw;
                                    setUserAnswer(newUArr.join('|'));
                                    setUpdatedSomething(true);
                                }}
                                hasWord={uWord != ''}>{uWord == '' ? '---' : <span style={{cursor: 'alias'}}
                                                                                   onClick={() => {
                                                                                       if (canAnswer == false) {
                                                                                           return;
                                                                                       }
                                                                                       let uArr = userAnswer.split('|');
                                                                                       let newUArr = JSON.parse(JSON.stringify(uArr));
                                                                                       newUArr[a.index] = '';
                                                                                       setUserAnswer(newUArr.join('|'));
                                                                                       setUpdatedSomething(true);
                                                                                   }}
                            >{uWord}</span>}</Word> : null}
                        </ItemSpan>
                    )
                })}
            </TopPlaceholder>

            <BottomPlaceholder>

                {bottomWords.map((a, i) => {

                    return (
                        <BotWord
                            draggable={true}
                            onDrag={x => {
                                window.draggingWord = a;
                            }}
                            key={`${i}_${a}`}>
                            {a}
                        </BotWord>
                    )
                })}

            </BottomPlaceholder>

            {/*{answeredAll == false ? null :*/}
            {/*    <BottomAnswerPlaceholder>*/}
            {/*        <BlueButton onClick={() => {*/}
            {/*            */}
            {/*        }} >*/}
            {/*            Save*/}
            {/*        </BlueButton>*/}
            {/*    </BottomAnswerPlaceholder>*/}
            {/*}*/}

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const BottomAnswerPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Word = styled.span`
  background: ${props => (props.isCorrect == undefined ? 'whitesmoke' : (props.isCorrect == true ? 'lightgreen' : 'coral'))};
  min-width: 60px;
  border-radius: 6px;
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;
  width: ${props => (props.hasWord == false ? `120px` : 'auto')};
  display: inline-block;
  text-align: center;
  margin: 5px;
`;

const ItemSpan = styled.span`
  margin-left: 5px;
  right: 5px;

`;

const TopPlaceholder = styled.div`
  padding: 10px;
`;

const BottomPlaceholder = styled.div`
  padding: 20px;
  text-align: center;
`;

const BotWord = styled.div`
  display: inline-block;
  margin: 5px;
  border-radius: 6px;
  border: 1px dashed grey;
  background-color: whitesmoke;
  padding-left: 15px;
  padding-right: 15px;
  cursor: grab;

  :hover {
    background-color: floralwhite;
  }
`;