import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import {Input} from "../../ira/ui/Inputs";
import {BlueButton} from "../../ira/ui/Buttons";

import moment from 'moment'
import DateTimePicker from "../../ui/DateTimePicker";
import {translate} from "../../../utils/Translate";

export default function UpdateLessonForm(props) {
    const {
        loading = false,
        onSave = d => {

        },
        canDelete = false,
        onDelete = () => {

        }
    } = props;

    const [name, setName] = useState(props?.name || '');
    const [startTimestamp, setStartTimestamp] = useState(props?.startTimestamp || +moment().add(0, 'days'));

    console.log('UpdateLessonForm: render: canDelete = ', canDelete);

    return (
        <Wrapper>

            <Row>
                <Label>
                    Название урока (можно оставить пустым)
                </Label>
                <Input value={name} placeholder={''} onChange={evt => {
                    setName(evt.target.value);
                }}/>

            </Row>

            <Row>
                <Label>
                    {translate('lesson_date_and_time')}
                </Label>
                <CalPlaceholder>
                    <DateTimePicker
                        timestamp={startTimestamp}
                        onChange={t => {
                            // if (+t < +new Date()) {
                            //     return;
                            // }
                            setStartTimestamp(t);
                        }}
                    />
                </CalPlaceholder>
            </Row>

            <BottomRow>
                <BlueButton
                    style={{
                        marginBottom: 10
                    }}
                    onClick={() => {
                        onSave({
                            name: name,
                            startTimestamp: startTimestamp
                        })
                    }}>
                    {translate('save')}
                </BlueButton>

                {canDelete == false ? null :
                    <DeleteSpan onClick={() => {
                        onDelete();
                    }}>
                        {translate('delete_lesson')}
                    </DeleteSpan>
                }

            </BottomRow>

        </Wrapper>
    );
}

const CalPlaceholder = styled.div`
  height: 300px;
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`

`;

const Row = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
`;

const DeleteSpan = styled.span`
  cursor: pointer;
  color: red;
  opacity: 0.75;

  :hover {
    opacity: 1;
  }
`;