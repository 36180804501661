import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import toast from "react-hot-toast";
import help from '../../../../assets/img/help.svg';
import { Popover2 } from '@blueprintjs/popover2';
import {RedButton} from "../../../ira/ui/Buttons";

export default function UpdateTreadmillTaskSettings(props) {
    const {
        onSave = d => {

        },
        onClose = () => {

        }
    } = props;

    const [settingsInfo, setSettingsInfo] = useState(props.settings !== undefined ? props.settings : {
        recordMines: true,
        showInLedger: true,
        noLimit: false,
        trainingMode: false,
        attemptsLeft: 1
    });

    useEffect(() => {
        console.log('props.settings: ', props.settings);
        if (props.settings !== undefined) {
            setSettingsInfo(props.settings);
        }
    }, [props.settings])

    return (
        <Wrapper>
            <TitlePlaceholder>
                <div><b>Настройки тредмила</b></div>
            </TitlePlaceholder>

            <Setting style={{
                opacity: settingsInfo.noLimit || settingsInfo.attemptsLeft > 1 ? '0.5' : '1',
                cursor: settingsInfo.noLimit || settingsInfo.attemptsLeft > 1 ? 'default' : 'pointer'
            }} onClick={() => {
                if (settingsInfo.noLimit === false && +settingsInfo.attemptsLeft === 1) {
                    setSettingsInfo({
                        ...settingsInfo,
                        recordMines: !settingsInfo.recordMines
                    })
                }
            }}>
                <input type="checkbox" checked={settingsInfo.recordMines}
                       style={{cursor: settingsInfo.noLimit || settingsInfo.attemptsLeft > 1 ? 'default' : 'pointer'}}/>
                <div>Активировать "мины"</div>
            </Setting>

            <Setting style={{
                opacity: settingsInfo.noLimit || settingsInfo.attemptsLeft > 1 ? '0.5' : '1',
                cursor: settingsInfo.noLimit || settingsInfo.attemptsLeft > 1 ? 'default' : 'pointer'
            }} onClick={() => {
                if (settingsInfo.noLimit === false && +settingsInfo.attemptsLeft === 1) {
                    setSettingsInfo({
                        ...settingsInfo,
                        showInLedger: !settingsInfo.showInLedger
                    })
                }
            }}>
                <input type="checkbox" checked={settingsInfo.showInLedger}
                       style={{cursor: settingsInfo.noLimit || settingsInfo.attemptsLeft > 1 ? 'default' : 'pointer'}}/>
                <div>Учитывать результат тредмила в журнале</div>
            </Setting>

            <Setting onClick={() => {
                let newValue = !settingsInfo.trainingMode;
                let data = {
                    trainingMode: newValue
                };

                if (newValue == true) {
                    data.showInLedger = false;
                    data.noLimit = true;
                    data.recordMines = false;
                }
                setSettingsInfo({
                    ...settingsInfo,
                    ...data
                })
            }}>
                <input type="checkbox" checked={settingsInfo.trainingMode} style={{cursor: 'pointer'}}/>
                <div>Режим тренировки</div>
                <Popover2
                    interactionKind='hover'
                    content={
                        <div style={{padding: 15}}>
                            <span>Студент сможет перематывать и останавливать тредмил</span>
                        </div>
                    }
                    placement='auto'
                >
                    <Help src={help} style={{paddingLeft: 5}}/>
                </Popover2>
            </Setting>

            <TriesPlaceholder>
                <div><b>Количество попыток</b></div>
                <Setting onClick={() => {
                    if (settingsInfo.noLimit === false) {
                        setSettingsInfo({
                            ...settingsInfo,
                            noLimit: true,
                            recordMines: false,
                            showInLedger: false
                        })
                    } else {
                        setSettingsInfo({
                            ...settingsInfo,
                            noLimit: false
                        })
                    }
                }}>
                    <input type="checkbox" checked={settingsInfo.noLimit} style={{cursor: 'pointer'}}/>
                    <div>Без ограничений</div>
                </Setting>

                <ChangeTriesAmount style={{
                    pointerEvents: settingsInfo.noLimit ? 'none' : 'auto',
                    opacity: settingsInfo.noLimit ? '0.5' : '1'
                }}>
                    <TriesControls onClick={() => {
                        if (settingsInfo.attemptsLeft !== 1) {
                            if (settingsInfo.attemptsLeft === undefined) {
                                setSettingsInfo({
                                    ...settingsInfo,
                                    attemptsLeft: 1
                                })
                            } else {
                                setSettingsInfo({
                                    ...settingsInfo,
                                    attemptsLeft: +settingsInfo.attemptsLeft - 1
                                })
                            }
                        }
                    }
                    }>-</TriesControls>

                    <TriesInput value={settingsInfo.attemptsLeft} onChange={(evt) => {
                        let s = evt.target.value;
                        if ((s === '' || +s > 0) && +s < 100) {
                            setSettingsInfo({
                                ...settingsInfo,
                                attemptsLeft: s
                            })
                        }
                    }
                    }/>

                    <TriesControls onClick={() => {
                        if (settingsInfo.attemptsLeft === undefined) {
                            setSettingsInfo({
                                ...settingsInfo,
                                attemptsLeft: 1
                            })
                        } else {
                            setSettingsInfo({
                                ...settingsInfo,
                                attemptsLeft: +settingsInfo.attemptsLeft + 1
                            })

                        }
                    }
                    }>+</TriesControls>
                </ChangeTriesAmount>
            </TriesPlaceholder>

            <ButtonsPlaceholder>
                <Button onClick={async () => {
                    await onSave(settingsInfo);
                    onClose();
                }}>
                    сохранить
                </Button>

                <RedButton onClick={() => onClose()}>отмена</RedButton>
            </ButtonsPlaceholder>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  
`;

const TitlePlaceholder = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;

const Setting = styled.div`
  margin-bottom: 10px;
  width: fit-content;
  display: flex;
  align-content: center;
  gap: 10px;
  cursor: pointer;
`;

const Button = styled.div`
  height: 44px;
  padding: 0 30px;
  border: 1px solid grey;
  border-radius: 100px;
  color: grey;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  
  :hover {
    opacity: 0.7;
  }
`;

const ButtonsPlaceholder = styled.div`
  display: flex;
  justify-content: space-between;
  user-select: none;
`;

const TriesPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ChangeTriesAmount = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 40px;
`;

const TriesControls = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-content: center;
  border: 1px solid grey;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  
  :hover {
    opacity: 0.7;
  }
`;

const TriesInput = styled.input`
  width: 48px;
  height: 24px;
`;

const Help = styled.img`
  cursor: pointer;
  scale: 1.1;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;