import React, { Children, ReactElement, Ref, useRef } from 'react';

const createRefWithInitialSize = (direction, element) => {
    const boundingClientRect = element.getBoundingClientRect();
    if (direction == 'horizontal') {
        return {
            element,
            initialSize: boundingClientRect.width,
        }
    } else {
        return {
            element,
            initialSize: boundingClientRect.height
        }
    }
    
}
/**
 * Creates a ref that save the `dom element` and the `initial size` for a list of elements. * 
 * @param direction ["horizontal"|"vertical"] Direction to save initial size. `horizontal` uses `width` | `vertical` uses `height`.
 */
export const useRefsWithInitialSize = (direction) => {
    const refs = useRef(null);

    const getRef = (index) => {
        const current = refs.current;
        return current ? current[index] : null;
    }

    const setRef = (index, element) => {
        if (!element) return;
        
        const current = refs.current;
        refs.current = current ? [...current] : [];
        refs.current[index] = createRefWithInitialSize(direction, element);
    }

    const resetRef = (index) => {
        const current = refs.current;
        if (current && current[index] && current[index].element) {
            setRef(index, current[index].element)
        }
    }

    const childrenWithRef = (children) => {
        return Children.map(children, (child, index) => {
            const newProps = {};
            newProps.onRef = (ref) => setRef(index, ref);
            return React.cloneElement(child, newProps);
        })
    }

    return {
        getRef,
        setRef,
        resetRef,
        childrenWithRef,
    }
}