import axios from 'axios'
import ls from 'local-storage'
import {API_ENDPOINT} from "../constants/config";


const UsersAPI = {

    async getAllTeachers(){
        let pld = (await axios.get(`${API_ENDPOINT}/teachers/all`)).data.result;
        return (pld || []);
    },

    async getMe() {
        try {
            console.log('UsersAPI: getMe  ');
            let pld = (await axios.get(`${API_ENDPOINT}/users/me`, {
                headers: {
                    'Authorization': ls('auth_token')
                }
            })).data;
            console.log('got pld = ', pld);
            if (pld != undefined && pld.email != undefined) {
                ls('patient_me', pld);
            }
            return pld;
        } catch (exc) {

        }
        return undefined;
    },

    async getLazyMe() {
        let key = `patient_me`;
        let val = ls(key);
        if (val != undefined) {
            // todo: make call
            return val;
        }
        let me = await this.getMe();
        ls('patient_me', me);
        return me;
    },

    async login(email, password) {
        console.log('login: email, password = ', email, password);
        try {
            let pld = (await axios.post(`${API_ENDPOINT}/login`, {
                email: email,
                password: password
            })).data;
            console.log('-> pld = ', pld);
            if (pld.error != undefined) {
                throw 'invalid login or password';
            }
            ls('auth_token', pld.token);
            let me = await this.getMe();
            console.log('got me = ', me);
            return me;
        } catch (e) {
            console.log('exc = ', e);
        }
        throw 'Неверный логин или пароль';
    },

    async logout() {
        ls.remove('patient_me');
        ls.remove('auth_token');
    },

    async getUsersInRange(from, to) {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/users-in-range`, {
            params: {
                from: from,
                to: to
            }
        })).data.result;
        return pld;
    },

    async getUser(userId) {
        if (userId == undefined) {
            return undefined;
        }
        // let pld = (await axios.get(`${API_ENDPOINT}/user/${userId}`)).data;
        let pld = (await axios.get(`${API_ENDPOINT}/users/by_ids`, {
            params: {
                usersIds: [userId]
            }
        })).data;
        console.log('pld = ', pld);
        return pld[0];
    },

    async getUsersByIds(ids = []) {
        console.log('-!-!- getUsersByIds: ids = ', ids);
        let xIds = ids.filter(x => (x != undefined && x != 'undefined'));
        if (xIds.length == 0) {
            return [];
        }
        let pld = (await axios.get(`${API_ENDPOINT}/users/by_ids`, {
            params: {
                usersIds: xIds
            }
        })).data;
        return pld;
    },

    async updateUser(d) {
        let pld = (await axios.post(`${API_ENDPOINT}/user/update`, {
            ...d
        })).data;
        return pld;
    },

    async forceSetPassword(d) {
        let pld = (await axios.post(`${API_ENDPOINT}/v3/reset-force-pwd`, {
            ...d
        })).data;
        return pld;
    },

    async getUserByEmail(email) {
        // /user/by_email
        let pld = (await axios.get(`${API_ENDPOINT}/user/by_email`, {
            params: {
                email: email
            }
        })).data;
        return pld;
    }

}

export default UsersAPI;
