import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import TeacherTemplate from "../templates/TeacherTemplate";
import LottieLoader from "../loaders/LottieLoader";
import loader_json from "../../assets/lottie/under_construction.json";
import GroupsPanel from "../groups/panels/GroupsPanel";
import StudentTasksPanel from "../lessons/panels/StudentTasksPanel";
import UserVocabularyPanel from "../vocabulary/panels/UserVocabularyPanel";

export default function StudentVocabularyApp(props) {
    const {userId} = useMappedState(useCallback(state => {
        return {
            userId: state.users.currentUserId
        }
    }))

    return (
        <TeacherTemplate active={'vocabulary'}>

            <Wrapper>
                <UserVocabularyPanel
                    userId={userId}
                />
            </Wrapper>


        </TeacherTemplate>
    );
}

const Wrapper = styled.div`
  width: calc(100vw - 400px);
  margin: 0 auto;
  margin-top: 10px;
  @media(max-width: 720px){
    width: calc(100% - 20px);
  }
`;

const ConstructionPlaceholder = styled.div`
  height: 80vh;
  text-align: center;
`;