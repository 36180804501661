import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';

import * as lessonsActions from '../../redux/actions/LessonsActions'
import * as tasksActions from '../../redux/actions/TasksActions'

import GridOnlineLesson from "../online/GridOnlineLesson";
import GridOnlineLessonMobile from "../online/GridOnlineLessonMobile";
import {isMobile} from "polotno/utils/screen";
import {setBranding} from "../templates/LogoDiv";

export default function OnlineLessonApp(props) {
    let {id} = props.match.params;

    const {
        user,
        lessons = [],
        tasks = [],
        isTeacher
    } = useMappedState(useCallback(state => {
        let user = state.users.usersMap.get(state.users.currentUserId);
        let isTeacher = (user?.userRole == 'teacher');
        return {
            isTeacher: isTeacher,
            user: user,
            lessons: state.lessons.lessonsMap.toArray().filter(x => (x.classId == id)).sort((a, b) => (+b.startTimestamp - +a.startTimestamp)),
            tasks: state.tasks.tasksMap.toArray().filter(x => (x.classId == id)).sort((a, b) => (+b.startTimestamp - +a.startTimestamp)),
        }
    }));

    useEffect(() => {
        setBranding()
    }, []);

    const dispatch = useDispatch();
    const [updT, setUpdT] = useState(0);

    useEffect(() => {
        if (id != undefined) {
            dispatch(lessonsActions.getGroupLessons(id)).then(pld => {
                let sortedLessons = (pld?.lessons || []).filter(x => (x.classId == id)).sort((a, b) => (+b.startTimestamp - +a.startTimestamp));
            });
            dispatch(tasksActions.getGroupTasks(id));
        }
    }, [id]);

    const [cLessonId, setCLessonId] = useState();
    let isNarrowOnlineMode = (isTeacher == false && window.innerWidth < 8000);

    return (
        <Wrapper>
            {isMobile() == true ?

                <GridOnlineLessonMobile
                    isTeacher={isTeacher}
                    updT={updT}
                    currentUser={user}
                    groupId={id}
                    allLessons={lessons}
                    allTasks={tasks}
                    onCurrentLessonChange={lessonId => {
                        console.log('onCurrentLessonChange: lessonId = ', lessonId);
                        if (lessonId == undefined) {
                            return;
                        }
                        dispatch(tasksActions.getLessonTasks(lessonId)).then(() => {
                            if (cLessonId != lessonId) {
                                setUpdT(+new Date());
                                setCLessonId(lessonId);
                            }

                        });
                    }}
                />
                :
                <GridOnlineLesson
                    isTeacher={isTeacher}
                    updT={updT}
                    currentUser={user}
                    groupId={id}
                    allLessons={lessons}
                    allTasks={tasks}
                    onCurrentLessonChange={lessonId => {
                        console.log('onCurrentLessonChange: lessonId = ', lessonId);
                        if (lessonId == undefined) {
                            return;
                        }
                        dispatch(tasksActions.getLessonTasks(lessonId)).then(() => {
                            if (cLessonId != lessonId) {
                                setUpdT(+new Date());
                                setCLessonId(lessonId);
                            }
                        });
                    }}
                />
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  background: whitesmoke;
`;