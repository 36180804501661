import GroupsAPI from '../../api/GroupsAPI'

import * as types from '../ActionTypes'

let loadGroups_ = () => {
    return {
        type: types.LOAD_GROUPS
    }
}
let loadGroupsSuccess = (groups) => {
    return {
        type: types.LOAD_GROUPS_SUCCESS,
        groups: groups
    }
}
let loadGroupsFailed = (error) => {
    return {
        type: types.LOAD_GROUPS_FAIL,
        error: error
    }
}

//thunk
export function loadAllGroups(userId) {
    return (dispatch, getState) => {
        dispatch(loadGroups_());
        return GroupsAPI.getMyGroups(userId).then(
            groups => dispatch(loadGroupsSuccess(groups)),
            error => dispatch(loadGroupsFailed(error))
        )
    }
}

export function loadGroupById(id) {
    return (dispatch, getState) => {
        dispatch(loadGroups_());
        return GroupsAPI.getGroupWithStudents(id).then(
            group => dispatch(loadGroupsSuccess([group])),
            error => dispatch(loadGroupsFailed(error))
        )
    }
}

let createGroup_ = () => {
    return {
        type: types.CREATE_GROUP
    }
}
let createGroupSuccess = (group) => {
    return {
        type: types.CREATE_GROUP_SUCCESS,
        group: group
    }
}
let createGroupFailed = (error) => {
    return {
        type: types.CREATE_GROUP_FAIL,
        error: error
    }
}

//thunk
export function createGroup(data) {
    return (dispatch, getState) => {
        dispatch(createGroup_());
        return GroupsAPI.createGroup(data).then(
            group => dispatch(createGroupSuccess(group)),
            error => dispatch(createGroupFailed(error))
        )
    }
}

//thunk
export function addToGroup(invitationCode) {
    return (dispatch, getState) => {
        dispatch(loadGroups_());
        return GroupsAPI.addToClass(getState().users.currentUserId, invitationCode).then(
            groups => dispatch(loadGroupsSuccess(groups)),
            error => dispatch(loadGroupsFailed(error))
        )
    }
}

export function removeFromGroup(studentId, classId) {
    return (dispatch, getState) => {
        dispatch(loadGroups_());
        return GroupsAPI.removeFromClass(studentId, classId).then(
            groups => dispatch(loadGroupsSuccess(groups)),
            error => dispatch(loadGroupsFailed(error))
        )
    }
}


let updateGroup_ = () => {
    return {
        type: types.UPDATE_GROUP
    }
}
let updateGroupSuccess = (group) => {
    return {
        type: types.UPDATE_GROUP_SUCCESS,
        group: group
    }
}
let updateGroupFailed = (error) => {
    return {
        type: types.UPDATE_GROUP_FAIL,
        error: error
    }
}

//thunk
export function updateGroup(data) {
    return (dispatch, getState) => {
        dispatch(updateGroup_());
        return GroupsAPI.updateGroup(data).then(
            group => dispatch(updateGroupSuccess(group)),
            error => dispatch(updateGroupFailed(error))
        )
    }
}

let deleteGroup_ = () => {
    return {
        type: types.DELETE_GROUP
    }
}
let deleteGroupSuccess = (id) => {
    return {
        type: types.DELETE_GROUP_SUCCESS,
        id: id
    }
}
let deleteGroupFailed = (error) => {
    return {
        type: types.DELETE_GROUP_FAIL,
        error: error
    }
}

//thunk
export function deleteGroup(id) {
    return (dispatch, getState) => {
        dispatch(deleteGroup_());
        return GroupsAPI.deleteGroup(id).then(
            () => dispatch(deleteGroupSuccess(id)),
            error => dispatch(deleteGroupFailed(error))
        )
    }
}
