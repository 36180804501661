import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Sidebar from 'arui-feather/sidebar'

import {getBasketItems, saveBasketItem} from "./ContentBasketPanel";
import ContentBasketPanel from "./ContentBasketPanel";

export default function BasketDaemonBubble(props) {
    const [items, setItems] = useState(getBasketItems());
    const [modalVisible, setModalVisible] = useState(false);

    useEventListener('add_content_to_basket', () => {
        console.log('add_content_to_basket triggered');
        setItems(getBasketItems());
    });

    if (items.length == 0) {
        return null;
    }

    return (
        <Wrapper>
            {items.length == 0 ? null :
                <Bubble onClick={() => {
                    setModalVisible(true);
                }}>
                    {items.length}
                </Bubble>
            }
            <Sidebar visible={modalVisible} onCloserClick={() => {
                setModalVisible(false);
            }}>
                {modalVisible == false ? null :
                    <div>
                        <ContentBasketPanel onSelect={a => {
                            let ev = new CustomEvent('basket_content_select', a);
                            window.dispatchEvent(ev);
                        }}/>
                    </div>
                }
            </Sidebar>
        </Wrapper>
    );
}

const Wrapper = styled.div`
   position: fixed;
   z-index: 10000000000000000;
   bottom: 10px;
   right: 10px;
`;

const Bubble = styled.div`
    width: 40px;
   height: 40px;
   border-radius: 1000px;
   border: 1px solid lightgrey;
   background: white;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   cursor: pointer;
`;


export function useEventListener(eventName, handler, element = window) {
    // Create a ref that stores handler
    const savedHandler = useRef();

    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {
            // Make sure element supports addEventListener
            // On
            const isSupported = element && element.addEventListener;
            if (!isSupported) return;

            // Create event listener that calls handler function stored in ref
            const eventListener = event => savedHandler.current(event);

            // Add event listener
            element.addEventListener(eventName, eventListener);

            // Remove event listener on cleanup
            return () => {
                element.removeEventListener(eventName, eventListener);
            };
        },
        [eventName, element] // Re-run if eventName or element changes
    );
};
