import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const OxWordsAPI = {

    async getOxWord(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/oxwords/${id}`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async getOxWordsByIds(ids) {
        let arr = [];
        for (let i in ids) {
            let a = await this.getOxWord(ids[i]);
            arr = arr.concat([a]);
        }
        return arr;
    },

    async getAllOxWords() {
        let pld = (await axios.get(`${API_ENDPOINT}/oxwords`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async getUserOxWords(userId) {
        let pld = (await axios.get(`${API_ENDPOINT}/user/${userId}/oxwords`)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },


    async createOxWord(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/oxwords`, data)).data;
        if (pld.error != undefined) {
            throw pld.error
        }
        return pld.result;
    },

    async updateOxWord(data) {
        let pld = (await axios.put(`${API_ENDPOINT}/oxwords`, data)).data;
        return pld.result;
    },

    async deleteOxWord(id) {
        await axios.delete(`${API_ENDPOINT}/oxwords/${id}`, {
            data: {id: id}
        })
    }


}

export default OxWordsAPI;
