import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import axios from 'axios'

import {API_ENDPOINT, TINY_MCE_API_KEY} from "../../constants/config";

import {Editor} from '@tinymce/tinymce-react';
import {BlueButton} from "../ira/ui/Buttons";
import LottieLoader from "../loaders/LottieLoader";

import lottie_json from '../../assets/lottie/graphic-designer.json'

export default function RealtimeEditor(props) {
    const {
        html = '',
        onChange = newHtml => {

        },
        loading = false,
        roomId = 'test'
    } = props;

    const editorRef = useRef(null);
    const [loaded, setLoaded] = useState(false);

    return (
        <Wrapper>

            {loaded == true ? null :
                <div style={{height: 400}}>
                    <LottieLoader json={lottie_json}/>
                </div>
            }


            <EditorPlaceholder style={{display: (loaded == false ? 'none' : 'block')}}>
                <Editor
                    onInit={(evt, editor) => {
                        editorRef.current = editor;
                        setTimeout(() => {
                            setLoaded(true);
                        }, 100);
                    }}
                    initialValue={html}
                    apiKey={TINY_MCE_API_KEY}
                    init={{
                        rtc_encryption_provider: () => Promise.resolve({key: 'a secret key'}),
                        rtc_document_id: roomId,
                        rtc_token_provider: ({documentId}) =>
                            axios.post(`${API_ENDPOINT}/v3/tiny/jwt`)
                                .then(d => d.data)
                                .catch((error) => console.log('Failed to return a JWT\n' + error)),
                        height: 500,
                        menubar: false,
                        plugins: 'rtc',
                        toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'table | ' +
                            'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                />
            </EditorPlaceholder>

        </Wrapper>
    );
}

const EditorPlaceholder = styled.div`

`;

const SaveButtonPlaceholder = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`
  min-height: 400px;
`;