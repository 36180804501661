import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import CommonHelper from "../../../../helpers/CommonHelper";

let getH = () => {
    try {
        return document.getElementsByClassName('timer_wrapper')[0].clientHeight;
    } catch (exc) {

    }
}

const colors = {
    green: '#79fa5a',
    yellow: '#ffd241',
    red: '#f36363'
}

export default function RoundCountDown(props) {
    const {
        maxSize,
        timeTotal,
        timeLeft,
    } = props;

    const [size, setSize] = useState(undefined);
    let tt = document.getElementsByClassName("timer_tt")[0];
    let d = size === undefined ? 0 : (size - 8) * 2 * 3.14;

    useEffect(() => {
        if (size === undefined) {
            setSize(Math.floor(getH() / 2));
        }
    }, [])

    useEffect(() => {
        if (tt !== undefined) {
            tt.style.strokeDashoffset = d - d * timeLeft / timeTotal;
        }
    }, [timeLeft])

    let currentProgress = timeLeft / timeTotal * 100;
    let currentColor = currentProgress > 50 ? colors.green : currentProgress > 25 ? colors.yellow : colors.red;

    useEffect(() => {
        if (timeLeft > 0 && timeLeft <= 3) {
            CommonHelper.playAudio();
        }
    }, [timeLeft])

    return (
        <Wrapper className="timer_wrapper" $maxSize={maxSize}>
            {size === undefined || size.isNaN ? null :
                <Circle>
                    <Svg>
                        <InnerCircle cx={size} cy={size} r={size - 8}/>
                        <InnerCircle
                            style={{strokeDasharray: d, stroke: currentColor}}
                            cx={size}
                            cy={size}
                            r={size - 8}
                            className="timer_tt"/>
                    </Svg>
                </Circle>
            }
            <Text style={{width: getH(), height: getH()}}>
                <div>{timeLeft}</div>
            </Text>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  max-height: ${props => props.$maxSize ? props.$maxSize + 'px' : ''};
  max-width: ${props => props.$maxSize ? props.$maxSize + 'px' : ''};
`;

const Circle = styled.div`
  height: 100%;
  width: 100%;
  svg {
    transform: rotate(270deg);
  }

  circle:nth-child(2) {
    stroke-linecap: round;
  }
`;

const Svg = styled.svg`
  aspect-ratio: 1 /1 ;
  width: 100%;
  height: 100%;
`;

const InnerCircle = styled.circle`
  width: 100%;
  height: 100%;
  fill: transparent;
  stroke-width: 8;
  stroke: #efefef;
`;

const Text = styled.span`
  position: relative;
  top: -100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #797979;
  font-size: 24px;
  font-weight: bold;
`;