import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {Button} from "../ui/Button";
import axios from "axios";
import {API_ENDPOINT} from "../../constants/config";
import Spin from 'arui-feather/spin'

export default function CompareTool(props) {
    const [text1, setText1] = useState('');
    const [text2, setText2] = useState('');

    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(undefined);

    // console.log(dataScore);
    // console.log(data);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Эталон
                </Label>
                <Textarea value={text1} onChange={evt => {
                    setText1(evt.target.value);
                    setResult(undefined);
                }}/>
            </Field>

            <Field>
                <Label>
                    Ответ студента
                </Label>
                <Textarea value={text2} onChange={evt => {
                    setText2(evt.target.value);
                    setResult(undefined);
                }}/>
            </Field>

            {result != undefined ? null :
                <div>
                    <Button onClick={async () => {
                        setLoading(true);
                        let pld = (await axios.post(`${API_ENDPOINT}/v2/tools/compare`, {
                            text1: text1,
                            text2: text2
                        })).data;
                        setResult(pld);
                        setLoading(false);
                    }}>
                        <Spin visible={loading}/>
                        {loading == true ? null :
                            'Сравнить'
                        }

                    </Button>
                </div>
            }

            {result == undefined ?
                <ScorePlaceholder>
                    Введите эталонный текст и ответ студента
                </ScorePlaceholder> :
                <ScorePlaceholder>
                    {`Оценка: ${result?.resultScore}%`}
                </ScorePlaceholder>
            }


        </Wrapper>
    );
}

const ScorePlaceholder = styled.div`
  text-align: center;
  padding: 10px;
  font-size: 40px;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const ResultDiv = styled.div`
  margin-bottom: 30px;
`;

const Wrapper = styled.div`
  padding: 20px;
`;

const Field = styled.div`
  margin-bottom: 20px;
`;

const Textarea = styled.textarea`
  min-height: 8em;
  width: 100%;
  box-sizing: border-box;
  font-size: 20px;
`;