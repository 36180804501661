import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import GroupChatAPI from "../../../api/GroupChatAPI";
import {FACELESS_AVATAR} from "../../../constants/config";

import moment from 'moment'

import plane_img from '../../../assets/img/paper-plane.png'
import ajax_loader_img from '../../../assets/img/ajax-loader.gif'
import ReactHelper from "../../../helpers/ReactHelper";
import UserNameSpan from "./UserNameSpan";

import chat_pencil from '../../../assets/img/chat_pencil.svg'
import CommonHelper from "../../../helpers/CommonHelper";
import UIHelper from "../../../helpers/UIHelper";
import WrappedTranslatableParagraph from '../../translate/panels/WrappedTranslatableParagraph';

export default function RoomChatPanel(props) {
    const {
        roomId,
        canEditMessages = true,
        messageColor = 'black',
        canWrite = true
    } = props;
    const messageInput = useRef();
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [messages, setMessages] = useState([]);
    const [selectedMessageId, setSelectedMessageId] = useState(undefined);
    const [loadingMessageId, setLoadingMessageId] = useState(undefined);

    const {userId, usersMap} = useMappedState(useCallback(state => {
        return {
            userId: state.users.currentUserId,
            usersMap: state.users.usersMap
        }
    }))

    useEffect(() => {
        setLoading(true);
        GroupChatAPI.getGroupMessages(roomId, 0).then(arr => {
            setMessages(arr);
            console.log('got group messages: arr = ', arr);
            setLoading(false);
        });
    }, [roomId]);

    const batches = useMemo(() => {
        let batches = [];
        let xMessages = messages.map(xx => ({
            ...xx,
            isMine: (xx.userId == userId)
        })).filter(xx => (xx?.message != undefined && xx?.message.trim() != ''));
        if (xMessages.length == 0) {
            return [];
        }
        batches.push({
            messages: [xMessages[0]],
            isMine: xMessages[0].isMine,
            id: xMessages[0].id
        });
        console.log('xMessages = ', xMessages);
        for (let i = 1; i < xMessages.length; i++) {
            if (batches[batches.length - 1].userId == xMessages[i].userId) {
                batches[batches.length - 1].messages.push(xMessages[i]);
            } else {
                batches.push({
                    messages: [xMessages[i]],
                    isMine: xMessages[i].isMine,
                    id: xMessages[i].id
                });
            }
        }
        return batches;
    }, [roomId, messages]);

    ReactHelper.useInterval(() => {
        let now = +new Date();
        if (loadingMessageId != undefined) {
            return;
        }
        GroupChatAPI.getGroupMessages(roomId, +new Date() - 2 * 60 * 1000).then(arr => {
            if (loadingMessageId != undefined) {
                return;
            }
            if (arr.length > 0) {
                let mSt = arr.reduce((st, m) => st.add(m.id), Set());
                let newMessages = messages.filter(x => !(mSt.has(x.id))).concat(arr);
                setMessages(newMessages);
                setTimeout(() => {
                    try {
                        let objDiv = document.getElementById("batches_list");
                        objDiv.scrollTop = objDiv.scrollHeight;
                    } catch (e) {

                    }
                }, 100)
            }
        });
    }, 5000);

    console.log('render: -> messages = ', messages);

    return (
        <Wrapper>

            <BatchesList id={'batches_list'}>

                {/*{noMessages == false ? null :*/}
                {/*    <NoMessagesPlaceholder>*/}
                {/*        <NoMessagesImagePlaceholder>*/}
                {/*            <NoMessagesImage src={require('../../../assets/no_messages.png')}/>*/}
                {/*        </NoMessagesImagePlaceholder>*/}
                {/*        <NoMessagesText>*/}
                {/*            В этом чате еще никто не писал.*/}
                {/*            Будьте первым, кто отправит сообщение!*/}
                {/*        </NoMessagesText>*/}
                {/*    </NoMessagesPlaceholder>*/}
                {/*}*/}

                {batches.map((batch, k) => {
                    let {id, messages, isMine} = batch;
                    // let ava = (isMine == true) ? myAvatar : friendAvatar;
                    const ava = FACELESS_AVATAR;
                    let userId = messages[0].userId;
                    return (
                        <BatchItem key={id} isMine={isMine}>

                            <UserPlaceholder isMine={isMine} style={{backgroundImage: `url(${ava})`}}>

                            </UserPlaceholder>
                            <UserNameSpan userId={userId}/>

                            <MessagesPlaceholder>
                                <MessagesList>
                                    {messages.map((m, j) => {
                                        let isLoading = (m?.id == loadingMessageId && loadingMessageId != undefined);
                                        let selected = (m.id == selectedMessageId);
                                        if ((m.message == undefined || m.message == '')) {
                                            return null;
                                        }
                                        return (
                                            <MessageItem key={m.id}>
                                                <Text color={messageColor}>
                                                    {userId == undefined ?
                                                        <span>
                                                                {m.message} {' '}
                                                            </span>
                                                        :
                                                        <span>
                                                            {UIHelper.isDictionaryAvailable() == false ? m.message :
                                                                <WrappedTranslatableParagraph
                                                                    wordColor={messageColor}
                                                                    userId={userId}
                                                                    text={m.message}/>
                                                            }
                                                        </span>
                                                    }

                                                    {canEditMessages == false || isLoading == true ? null :
                                                        <PencilPlaceholder className={'sabir_chat_pencil_span'}
                                                                           onClick={async () => {
                                                                               setLoadingMessageId(m?.id);
                                                                               let newText = window.prompt('Отредактируйте сообщение', m.message);
                                                                               if (newText == undefined) {
                                                                                   newText = '';
                                                                               }
                                                                               let updMessage = await GroupChatAPI.updateMessage(m?.id, newText);
                                                                               // console.log('--->>>   updMessage = ', updMessage);
                                                                               // console.log('--->>>   old messages = ', messages);
                                                                               // let newMessages = messages.map(x => (x.id == updMessage.id ? JSON.parse(JSON.stringify(updMessage)) : JSON.parse(JSON.stringify(x))));
                                                                               // console.log('newMessages = ', newMessages);
                                                                               // setMessages(newMessages);
                                                                               setLoadingMessageId(undefined);
                                                                           }}>
                                                            ✎
                                                        </PencilPlaceholder>
                                                    }
                                                    {isLoading == false ? null :
                                                        <span>...</span>
                                                    }
                                                </Text>
                                            </MessageItem>
                                        )
                                    })}
                                </MessagesList>
                            </MessagesPlaceholder>

                        </BatchItem>
                    )
                })}
            </BatchesList>

            {canWrite == false ? null :
                <>
                    <InputPlaceholder>
                        <TextareaPlaceholder>
                            <Textarea onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    this.sendMessage();
                                }
                            }} ref={messageInput} placeholder="Ваше сообщение..."></Textarea>
                        </TextareaPlaceholder>
                        <ButtonPlaceholder>
                            <Button onClick={async () => {
                                let text = messageInput.current.value;
                                if (text == undefined || text.trim() == '' || sending == true) {
                                    return;
                                }
                                // this.sendMessage();
                                setSending(true);
                                await GroupChatAPI.sendMessageToGroup(roomId, userId, text);
                                let arr = await GroupChatAPI.getGroupMessages(roomId, +new Date() - 2 * 60 * 1000);
                                if (arr.length > 0) {
                                    let mSt = arr.reduce((st, m) => st.add(m.id), Set());
                                    let newMessages = messages.filter(x => !(mSt.has(x.id))).concat(arr);
                                    setMessages(newMessages);
                                    setTimeout(() => {
                                        try {
                                            let objDiv = document.getElementById("batches_list");
                                            objDiv.scrollTop = objDiv.scrollHeight;
                                        } catch (e) {

                                        }
                                    }, 100)
                                }
                                messageInput.current.value = '';
                                setSending(false);
                            }}>
                                <SendImage
                                    src={(sending == false) ? plane_img : ajax_loader_img}/>
                            </Button>

                        </ButtonPlaceholder>

                    </InputPlaceholder>
                    <div style={{lineHeight: '12px', fontSize: 10, textAlign: 'right'}}>



                        <TxtExportSpan onClick={() => {
                            let s = ``;
                            console.log('batches = ', batches);
                            for (let b of batches) {
                                let {messages = []} = b;
                                s = `${s}${messages.map(xx => xx.message).join('\n')}`
                                s = `${s}\n\n`;
                            }
                            // console.log('s = ', s);
                            let ss = ``;
                            for (let i in messages){
                                let m = messages[i];
                                let u = usersMap.get(m.userId);
                                ss = `${ss}\n${moment(m.timestamp).format('DD.MM.YYYY HH:mm:ss')} ${CommonHelper.getUserName(u)}: ${m.message}`
                            }
                            downloadFile('chat_export.txt', ss);
                        }}>
                            export to .txt
                        </TxtExportSpan>
                    </div>
                </>
            }

        </Wrapper>
    );
}

function downloadFile(filename, text) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

const TxtExportSpan = styled.span`
  opacity: 0.8;
  cursor: pointer;
  color: black;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  color: white;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-top: 20px;
`;

const inputBlockHeight = 80;
const userInfoWidth = 30;
const exportHeight = 20;

const BatchesList = styled.div`
  width: 100%;
  height: calc(100% - ${inputBlockHeight + exportHeight}px);
  max-height: calc(100% - ${inputBlockHeight + exportHeight}px);
  overflow-y: auto;
  padding-left: 3px;
  padding-right: 3px;
  box-sizing: border-box;
`;

const BatchItem = styled.div`
  text-align: ${props => (props.isMine == true ? 'left' : 'left')};
  display: block;
  margin-bottom: ${userInfoWidth}px;
`;

const UserPlaceholder = styled.div`
  display: inline-block;
  vertical-align: top;
  width: ${userInfoWidth}px;
  height: ${userInfoWidth}px;
  line-height: ${userInfoWidth}px;
  text-align: center;
  border-radius: 1000px;
  background-color: ${props => (props.isMine == true ? 'blue' : 'red')};
  box-sizing: border-box;
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  float: ${props => (props.isMine == true ? 'left' : 'left')}
`;

const MessagesPlaceholder = styled.div`
  display: inline-block;
  vertical-align: top;
  width: calc(100% - ${userInfoWidth}px);
  box-sizing: border-box;
  padding: 5px;
  padding-top: 0px;
`;

const MessagesList = styled.div`

`;

const MessageItem = styled.div`
  margin-top: 5px;
  border-top: 1px dotted rgba(255, 255, 255, 0.1);
  padding-top: 5px;

  :first-of-type {
    margin-top: 0px;
    border-top: none;
    padding-top: 0px;
  }
`;

const Text = styled.div`
  color: ${props => (props.color == undefined ? 'white' : props.color)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;


  &:hover .sabir_chat_pencil_span {
    display: inline-block !important;
  }

  .pencil_placeholder {
    display: none;
  }

  :hover {
    .pencil_placeholder {
      display: inline-block;
    }

  }

`;

const PencilPlaceholder = styled.span`
  display: none;
  margin-left: 5px;
  opacity: 0.7;
  cursor: pointer;
  font-size: 12px;

  :hover {
    opacity: 1;
  }
`;

const InputPlaceholder = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.7);
  width: 100%;
  height: ${inputBlockHeight}px;
  box-sizing: border-box;
`;

const buttonWidth = 60;

const TextareaPlaceholder = styled.div`
  width: calc(100% - ${buttonWidth}px);
  vertical-align: top;
  display: inline-block;
  box-sizing: border-box;
  height: 100%;
`;

const ButtonPlaceholder = styled.div`
  width: ${buttonWidth}px;
  vertical-align: top;
  display: inline-block;
  box-sizing: border-box;
  height: 100%;
  background-color: #314E66;
  color: white;
  line-height: ${inputBlockHeight}px;
  text-align: center;
`;

const Button = styled.button`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  line-height: ${inputBlockHeight}px;
  opacity: ${props => (props.disabled == true ? 0.5 : 1)};
  cursor: pointer;
  text-align: center;
  background-color: #314E66;
  outline: none;
  border: none;
  opacity: 0.9;

  :hover {
    opacity: 1;
  }
`;

const SendImage = styled.img`
  height: 40px;
  display: inline-block;
  vertical-align: middle;
`;

const Textarea = styled.textarea`
  border: none;
  height: 100%;
  min-height: 100%;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 18px;
  padding-left: 5px;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);

  ::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const NoMessagesPlaceholder = styled.div`
  text-align: center;
  opacity: 0.75;
  margin-top: 20px;
`;

const NoMessagesImagePlaceholder = styled.div`
  margin-bottom: 10px;
`;

const NoMessagesImage = styled.img`
  display: inline-block;
  width: 90px;
`;

const NoMessagesText = styled.div`
  color: white;
  padding: 10px;
  font-size: 14px;
`;