import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const GroupChatAPI = {

    async getGroupMessages(groupId, fromTimestamp) {
        let pld = (await axios.get(`${API_ENDPOINT}/v3/chat/group/${groupId}/since/${fromTimestamp}`)).data.result;
        return pld;
    },

    async sendMessageToGroup(groupId, userId, message) {
        let pld = (await axios.post(`${API_ENDPOINT}/v3/chat/group/${groupId}`, {
            userId: userId,
            message: message
        })).data.result;
        return pld;
    },

    async updateMessage(messageId, message) {
        let pld = (await axios.put(`${API_ENDPOINT}/v3/chat/group/message/${messageId}`, {
            message: message
        })).data.result;
        return pld;
    }


}

export default GroupChatAPI;
