import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import YouTube from 'react-youtube';

export default function YoutubeCutterTool(props) {
    const {
        youtubeId = 'f9yt1Sh-Is4'
    } = props;

    const [ready, setReady] = useState(false);
    const playerRef = useRef();


    return (
        <Wrapper>

            <PlayerPlaceholder>
                <YouTube
                    videoId={youtubeId}
                    opts={{
                        height: 420,
                        width: '100%',
                        controls: 0
                    }}
                    onReady={(event) => {
                        playerRef.current = event.target;
                        setReady(true);
                    }}
                />
            </PlayerPlaceholder>

            {ready == false ? null :
                <ControlsPlaceholder>

                    <span onClick={() => {
                        playerRef.current.playVideo();
                    }}>
                        play
                    </span>

                    <span onClick={() => {
                        playerRef.current.pauseVideo();
                    }}>
                        pause
                    </span>

                    <span onClick={() => {
                        let s = playerRef.current.getCurrentTime();
                        console.log('s = ', s);
                    }}>
                        get time
                    </span>


                </ControlsPlaceholder>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const PlayerPlaceholder = styled.div`
  width: 100%;
  height: 420px;
  background: pink;
`;

const ControlsPlaceholder = styled.div`
  background: lightgrey;
`;