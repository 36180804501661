import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const KvdbAPI = {

    async getValue(key, defaultValue) {
        let pld = (await axios.get(`${API_ENDPOINT}/kv/dictionary/${key}`)).data.result;
        if (pld?.value == undefined) {
            return defaultValue;
        }
        return pld?.value;
    },

    async saveValue(key, value) {
        await axios.put(`${API_ENDPOINT}/kv/dictionary/${key}`, {
            value: value
        });
    }
}

export default KvdbAPI;
