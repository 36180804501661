import * as types from '../ActionTypes'
import {Map} from 'immutable';

const initialState = {
    slideItem: undefined,
    exerciseId: undefined,
    drochkaExerciseId: undefined,
    testId: undefined
}

const CopyPasterReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.COPY_SLIDE_ITEM_TO_BUFFER:
            return {
                ...state,
                slideItem: action.slideItem
            }

        case types.COPY_EXERCISE_TO_BUFFER:
            return {
                ...state,
                exerciseId: action.exerciseId
            }

        case types.COPY_DROCHKA_EXERCISE_TO_BUFFER:
            return {
                ...state,
                drochkaExerciseId: action.drochkaExerciseId
            }

        case types.COPY_TEST_TO_BUFFER:
            return {
                ...state,
                testId: action.testId
            }

        case types.COPY_TREADMILL_TO_BUFFER:
            return {
                ...state,
                treadmillId: action.treadmillId
            }

        default:
            return state;

    }

}

export default CopyPasterReducer;