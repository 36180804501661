import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const DrochkaExercisesAPI = {

    async getAllDrochkaExercises() { // full
        let arr = (await axios.get(`${API_ENDPOINT}/v3/drochka-exercises/all`)).data.result;
        return arr;
    },

    async getDrochkaExercise(id) { // full
        let arr = (await axios.get(`${API_ENDPOINT}/v3/drochka-exercise/${id}`)).data.result;
        return arr;
    },

    async getDrochkaExercisesByIds(ids = []) { // full
        let arr = (await axios.post(`${API_ENDPOINT}/v3/drochka-exercises-by-ids`, {
            ids: ids
        })).data.result;
        return arr;
    },


    async saveDrochkaExerciseAnswer(exerciseId, cardId, answer) {
        let pld = (await axios.post(`${API_ENDPOINT}/v3/exercises/${exerciseId}/answers/card/${cardId}`, {
            answer: answer
        })).data.result;
        return pld;
    },

    async getDrochkaExerciseAnswers(exerciseId, userId) {
        let arr = (await axios.get(`${API_ENDPOINT}/v3/exercises/${exerciseId}/answers`, {
            params: {
                userId: userId
            }
        })).data.result;
        return arr;
    },

    async updateDrochkaExercise(data) {
        let arr = (await axios.put(`${API_ENDPOINT}/v3/drochka-exercise/${data.id}`, {
            ...data
        })).data.result;
        return arr;
    },

    async createDrochkaExercise(data) {
        let arr = (await axios.post(`${API_ENDPOINT}/v3/drochka-exercise`, {
            ...data
        })).data.result;
        return arr;
    }


}

export default DrochkaExercisesAPI;
