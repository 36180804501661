import React, {PropTypes} from 'react';
import styled from 'styled-components';

import Word from './Word'
import UIHelper from "../../../helpers/UIHelper";

const sar = a => (a == undefined ? [] : a);

class TranslatableText extends React.Component {

    static defaultProps = {
        text: `Hello, world!`,
        selectedWord: undefined,
        wordColor: 'black',
        onWordClick: w => {

        },
        extraContent: undefined,
        selectable: true,
        yandexVoiceEnabled: true
    }

    static propTypes = {}

    state = {}

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    componentWillReceiveProps() {

    }


    render = () => {
        let {text, selectedWord, extraContent, selectable, wordColor} = this.props;
        if (UIHelper.isDictionaryAvailable() == false){
            return (<span>{text}</span>)
        }
        if (text == undefined || text.trim() == '') {
            return null;
        }
        try {
            let re = /([^\s.,;:!?()[\]{}<>"]+)/g;
            let words = text.match(re);
            if (
                (words).length == 0) {
                return null;
            }
            let currentWordNumber = 0, currentWordPosition = 0, currentShit = '';
            let elements = [], flags = [];
            let s = text;
            while (s[0] != words[0][0]) {
                s = s.slice(1);
            }
            let prefix = text.slice(0, text.indexOf(words[0]));
            if (prefix != '') {
                elements.push(<Shit>{prefix}</Shit>);
                flags.push(0);
            }
            // console.log('render: s = ', s);
            // console.log('render: s.length = ', s.length);

            for (let i in s) {
                let char = s[i];
                let currentWordChar = words[currentWordNumber][currentWordPosition];
                if (char != currentWordChar) {
                    currentShit = currentShit + char;
                    // console.log('incrementing shit = ', currentShit);
                } else {
                    if (currentShit != '') {
                        elements.push(<Shit style={{
                            marginLeft: (currentShit[0] == ' ' ? "4px" : "0px"),
                            marginRight: (currentShit.length > 1 && currentShit[currentShit.length - 1] == ' ' ? "4px" : "0px")}}>
                            {currentShit}</Shit>);
                        flags.push(0);
                    }
                    if (sar(words[currentWordNumber]).length - 1 == currentWordPosition) {
                        elements.push(<Word style={{marginLeft: (flags[flags.at()] == 1 ? "4px" : "0px")}}
                                            selectedWord={selectedWord}
                                            wordColor={wordColor}
                                            word={words[currentWordNumber]} onWordClick={w => {
                            // console.log('onWordClick: w = ', w);
                            this.props.onWordClick(w);
                        }}/>);
                        flags.push(1);
                        if (sar(words).length - 1 == currentWordNumber) {
                            let end = '';
                            for (let j = +i + 1; +j < +s.length; j++) {
                                end = end + s[j];
                            }
                            if (end.length > 0) {
                                elements.push(<Shit style={{marginLeft: end[0] == ' ' ? "4px" : "0px"}}>{end}</Shit>);
                                flags.push(0);
                            }
                            break;
                        }
                        currentWordNumber = Math.min(+currentWordNumber + 1, sar(words).length - 1);
                        currentWordPosition = 0;
                    } else {
                        currentWordPosition = currentWordPosition + 1;
                    }
                    currentShit = '';
                }
            }
            return (
                <Wrapper className={''} style={{color: wordColor}}>

                    {extraContent == undefined ? null : extraContent}

                    {elements.map((El, k) => {
                        return (
                            <span key={k}>
                            {El}
                        </span>
                        )
                    })}

                </Wrapper>
            )
        } catch (exc) {
            return null;
        }


        return null;
    }

}

const Wrapper = styled.div`
  position: relative;
`;

const Shit = styled.div`
  display: inline-block;
`;

//const mapStateToProps = (state) => {
//    return {
//        currentUserId: state.users.currentUserId,
//        loading: state.users.loading
//    }
//}

//const mapDispatchToProps = (dispatch) => {
//    return {
//    }
//}

//TranslatableText = connect(mapStateToProps, mapDispatchToProps)(TranslatableText)

export default TranslatableText
