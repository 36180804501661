import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {BROWN, DARK_SIDE, LIGHT_BROWN, MAIN_ORANGE_COLOR, RED} from "./Colors";
import moment from 'moment';
import uuid from 'uuid';

const DEFAULT_BACKGROUND = LIGHT_BROWN;

export const Button = styled.div`
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    cursor: ${props => (props.disabled == true ? 'default' : 'pointer')};
    background: ${props => (props.disabled == true ? 'lightgrey' : MAIN_ORANGE_COLOR)};
    display: inline-block;
    vertical-align: top;
    border-radius: 4px;
    :hover{
      opacity: 0.8;
    }
`;

export const OutlinedButton = styled.div`
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: ${props => (props.disabled == true ? 'default' : 'pointer')};
    background: transparent;
    color: ${MAIN_ORANGE_COLOR};
    border: 1px solid ${MAIN_ORANGE_COLOR};
    border-radius: 4px;
    display: inline-block;
    vertical-align: top;
    :hover{
      opacity: 0.8;
    }
`;



export const DangerButton =  function (props) {
    const onClick = (props.onClick == undefined) ? () => {} : props.onClick;
    return (
        <DangerButtonStyle onClick={() => {
            if (window.confirm('Вы уверены') == false){
                return;
            }
            onClick();
        }} >
            {props.children}
        </DangerButtonStyle>
    );
}

const DangerButtonStyle = styled.div`
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    cursor: ${props => (props.disabled == true ? 'default' : 'pointer')};
    background: ${props => (props.disabled == true ? 'lightgrey' : RED)};
    display: inline-block;
    vertical-align: top;
    border-radius: 4px;
    :hover{
      opacity: 0.8;
    }
`;
