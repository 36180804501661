import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'

import styled from 'styled-components';
import {Code} from 'react-content-loader'
import ModulesListPanel from "./ModulesListPanel";
import {translate} from "../../../utils/Translate";
import * as actions from "../../../redux/actions/SlidesActions";

export default function LimboSlidesPanel(props) {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const {
        language,
        limboSlides = []
    } = useMappedState(useCallback(state => {
        return {
            language: state.language.language,
            limboSlides: state.slides.slidesMap.toArray()
                .filter(x => (x?.isLimbo == true)).sort((a, b) => {
                    let s1 = `${a.name}`.toLowerCase();
                    let s2 = `${b.name}`.toLowerCase();
                    if (s1 > s2) {
                        return 1;
                    }
                    if (s1 < s2) {
                        return -1;
                    }
                    return 0;
                })
        }
    }));

    useEffect(() => {
        setLoading(true);
        dispatch(actions.loadLimboSlides()).then(() => {
            setLoading(false);
        });
    }, []);

    if (loading == true && limboSlides.length == 0) {
        return (<Code/>)
    }
    let ids = limboSlides.map(x => x.id);
    if (limboSlides.length == 0) {
        return null;
    }
    console.log('LimboSlidesPanel: render: limboSlides = ', limboSlides);
    console.log('LimboSlidesPanel: render: ids = ', ids);

    return (
        <Wrapper>

            <TopLeft>
                Limbo 2
                {/*{translate(`limbo`, language)}*/}
            </TopLeft>

            <ListPlaceholder>
                <ModulesListPanel
                    modulesIds={ids}
                    onModuleClick={cId => {
                        dispatch(actions.selectSlide(cId));
                    }}
                />
            </ListPlaceholder>

        </Wrapper>
    );
}

const ListPlaceholder = styled.div`
  padding-left: 10px;
`;

const TopLeft = styled.div`
  font-weight: bold;
`;

const Wrapper = styled.div`
  padding-left: 10px;
  box-sizing: border-box;
`;