import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import {Textarea} from "../../ui/Textarea";
import {Button} from "../../ui/Button";

import SimpleFileUploader from "../../uploader/tools/SimpleFileUploader";

import Sidebar from 'arui-feather/sidebar'
import SearchOrCreateMaterialsPanel from "../../materials/panels/SearchOrCreateMaterialsPanel";

export default function SimpleVideoRecordEditorTool(props) {

    const {
        onSave = d => {

        }
    } = props;

    const [taskText, setTaskText] = useState(props.taskText == undefined ? '' : props.taskText);
    const [videoUrl, setVideoUrl] = useState(props.videoUrl == undefined ? '' : props.videoUrl);
    const [imageUrl, setImageUrl] = useState(props.imageUrl == undefined ? '' : props.imageUrl);

    const [selectVideoModalVisible, setSelectVideoModalVisible] = useState(false);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Текст задания
                </Label>
                <Textarea value={taskText} onChange={evt => {
                    setTaskText(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Видео
                </Label>
                {(videoUrl == undefined || videoUrl == '') ?
                    <div>
                        <span style={{cursor: 'pointer'}} onClick={() => {
                            setSelectVideoModalVisible(true);
                        }}>
                            выбрать видео
                        </span>
                    </div> :
                    <div>
                        <Video controls={true}>
                            <source src={videoUrl}/>
                        </Video>
                        <div style={{marginTop: 5}}>
                            <span style={{color: 'red', cursor: 'pointer'}} onClick={() => {
                                setVideoUrl('');
                            }}>
                                удалить
                            </span>
                        </div>
                    </div>
                }
            </Field>

            <Field>
                <Label>
                    Картинка
                </Label>
                {(imageUrl == undefined || imageUrl == '') ?
                    null :
                    <ImagePlaceholder>
                        <Image src={imageUrl}/>
                        <div style={{marginTop: 5}}>
                            <span style={{color: 'red', cursor: 'pointer'}} onClick={() => {
                                setImageUrl('');
                            }}>
                                удалить
                            </span>
                        </div>
                    </ImagePlaceholder>
                }
                <div>
                    <div>
                        Изображение
                    </div>
                    <SimpleFileUploader
                        onSave={s => {
                            setImageUrl(s);
                        }}
                    />
                </div>
            </Field>

            <Field>
                <Button onClick={() => {
                    onSave({
                        taskText: taskText,
                        videoUrl: videoUrl,
                        imageUrl: imageUrl
                    })
                }}>
                    Сохранить
                </Button>
            </Field>


            <Sidebar
                width={Math.min(820, window.innerWidth)}
                visible={selectVideoModalVisible}
                onCloserClick={() => {
                    setSelectVideoModalVisible(false);
                }}>


                {selectVideoModalVisible == false ? null :
                    <div style={{paddingLeft: 20, paddingRight: 20, height: `calc(100vh - 120px)`, width: 720}}>
                        <SearchOrCreateMaterialsPanel onSelect={m => {
                            setVideoUrl(m.url);
                            setSelectVideoModalVisible(false);
                        }}/>
                    </div>
                }

            </Sidebar>


        </Wrapper>
    );
}

const ImagePlaceholder = styled.div`
  margin-bottom: 10px;
`;

const Image = styled.img`
  width: 100%;
`;

const Video = styled.video`
  width: 100%;
  height: 400px;
`;

const Wrapper = styled.div`

`;

const Field = styled.div`
  margin-bottom: 14px;
`;

const Label = styled.div`
  margin-bottom: 5px;
  font-weight: bold;
`;