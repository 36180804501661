import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import UsersAPI from "../../../api/UsersAPI";
import {LineChart, XAxis, YAxis, CartesianGrid, Line} from 'recharts'

function getDays(users) {
    let map = {};
    for (let i in users) {
        let u = users[i];
        let {timestamp} = u;
        let time = +moment(+timestamp).startOf('day');
        let key = `t-${time}`;
        if (map[key] == undefined) {
            map[key] = {
                key: key,
                t: +time,
                number: 0
            }
        }
        map[key].number = +map[key].number + 1;
    }
    return Object.keys(map).map(x => map[x]).sort((a, b) => (+a.t - +b.t));
}

export default function UsersChartTool(props) {
    const {
        from,
        to,
        userRole = 'teacher'
    } = props;
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (to < from) {
            return;
        }
        setLoading(true);
        UsersAPI.getUsersInRange(from, to).then(arr => {
            setUsers(arr);
            setLoading(false);
        })
    }, [from, to, userRole]);

    if (loading == true) {
        return (
            <Code/>
        )
    }
    if (users.length == 0) {
        return null;
    }
    let xUsers = (userRole == 'teacher') ? users.filter(x => (x.userRole == 'teacher')) : users.filter(x => (x.userRole != 'teacher'));

    const days = getDays(xUsers);
    let accDays = [];
    if (days.length > 0) {
        accDays = JSON.parse(JSON.stringify(days));
        for (let i = 1; i < days.length; i++) {
            accDays[i].number = +accDays[i - 1].number + days[i].number;
        }
    }

    return (
        <Wrapper>

            <Heading>
                По дням
            </Heading>
            <ChartPlaceholder>
                <LineChart width={700} height={380} data={days} connectNulls={true}
                           margin={{top: 5, right: 0, left: 20, bottom: 5}}>
                    <XAxis dataKey="t" tickCount={25}
                           tickFormatter={a => {
                               return (moment(a).format('DD.MM.YYYY'))
                           }}
                    />
                    <YAxis/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey={'number'} stroke="#D92A3E" strokeWidth={2} animationDuration={1}
                          dot={false}/>
                </LineChart>
            </ChartPlaceholder>

            <Heading>
                Нарастающим итогом
            </Heading>
            <ChartPlaceholder>
                <LineChart width={700} height={380} data={accDays} connectNulls={true}
                           margin={{top: 5, right: 0, left: 20, bottom: 5}}>
                    <XAxis dataKey="t" tickCount={25}
                           tickFormatter={a => {
                               return (moment(a).format('DD.MM.YYYY'))
                           }}
                    />
                    <YAxis/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey={'number'} stroke="#D92A3E" strokeWidth={2} animationDuration={1}
                          dot={false}/>
                </LineChart>
            </ChartPlaceholder>

        </Wrapper>
    );
}

const Heading = styled.div`

`;

const ChartPlaceholder = styled.div`
  width: 100%;
  height: 420px;
`;

const Wrapper = styled.div`

`;