import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Spin from 'arui-feather/spin'
import axios from 'axios'

import ls from 'local-storage'
import {API_ENDPOINT} from "../../../constants/config";

import {BlueButton} from "../../ira/ui/Buttons";
import {Input} from "../../ira/ui/Inputs";
import {translate} from "../../../utils/Translate";

export default function MoodleLoginForm(props) {
    const {
        onSave = d => {

        }
    } = props;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);


    return (
        <Wrapper>

            <Field>
                <Label>
                    Moodle Email
                </Label>
                <InputPlaceholder>
                    <Input value={email} onChange={evt => {
                        setEmail(evt.target.value);
                    }}/>
                </InputPlaceholder>

            </Field>

            <Field>
                <Label>
                    Moodle Password
                </Label>
                <InputPlaceholder>
                    <Input type={'password'} value={password} onChange={evt => {
                        setPassword(evt.target.value);
                    }}/>
                </InputPlaceholder>

            </Field>

            <Field style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <BlueButton onClick={async () => {
                    setLoading(true);
                    try {
                        let pld = (await axios.post(`${API_ENDPOINT}/moodle/login`, {
                            email: email,
                            password: password
                        })).data.result;
                        if (pld.error != undefined) {
                            setLoading(false);
                            return window.alert('неправильный логин или пароль');
                        }
                        console.log('pld = ', pld);
                        ls('moodle_token', pld.token);
                        onSave(pld);
                    } catch (exc) {

                    }
                    setLoading(false);
                }}>
                    <Spin visible={loading}/>
                    {loading == true ? null : translate('save')
                    }

                </BlueButton>
            </Field>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;


const Field = styled.div`
  font-size: 14px;
  margin-bottom: 14px;
`;

const Label = styled.div`

`;

const InputPlaceholder = styled.div`

`;
