import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import ReactStars from 'react-stars'

import {Textarea} from "../../ira/ui/Inputs";
import Sidebar from "arui-feather/sidebar";
import Tabs, {TabItem} from "../../ui/Tabs";

import medal_img from '../../../assets/img/medal.svg'

const ITEMS = [
    '🤩 Distinction!',
    '😀 Merit!',
    '👍 Well done!'
]

const RU_ITEMS = [
    '🤩 Замечательно!',
    '😀 Отлично!',
    '👍 Молодец!'
];

export default function TeacherAssesAndCommentTool(props) {
    const {
        rating = 0,
        onRatingChange = (x, type) => {
            console.log('on rating change occured! x = ', x);
        },
        hasTextComment = false,
        textComment = '',
        onTextCommentChange = s => {

        },
        isPassed = false,
        type = 'score'
    } = props;

    const [modalOpen, setModalOpen] = useState(false);
    console.log('TeacherAssesAndCommentTool: rating = ', rating);
    console.log('TeacherAssesAndCommentTool: type = ', type);
    let merItems = (window.location.href.indexOf('ru-tsu') > -1) ? RU_ITEMS : ITEMS;

    return (
        <Wrapper>

            <TopTapSwitchers>
                <SwitchTabItem
                    selected={type == 'rating'}
                    onClick={() => {
                        onRatingChange(rating, 'rating');
                    }}>
                    Оценка
                </SwitchTabItem>
                <SwitchTabItem
                    selected={type == 'binary'}
                    onClick={() => {
                        onRatingChange(isPassed, 'binary');
                    }}>
                    Зачёт/незачёт
                </SwitchTabItem>
            </TopTapSwitchers>

            <div style={{marginTop: 10, marginBottom: 10}}>
                {type != 'rating' ? null :
                    <TabItem>
                        <StarsPlaceholder>
                            <ReactStars
                                count={5}
                                value={rating}
                                onChange={r => {
                                    console.log('new rating r = ', r);
                                    onRatingChange(r, 'rating');
                                }}
                                size={24}
                                color2={'#ffd700'}/>
                        </StarsPlaceholder>
                        <div style={{textAlign: 'center', fontWeight: 'bold', fontSize: 20}}>
                            {`${rating}`}
                        </div>
                    </TabItem>
                }

                {type != 'binary' ? null :
                    <TabItem>

                        <BinPlaceholder>
                            <SecScorePlaceholder>
                                <SecItem selected={isPassed} onClick={() => {
                                    onRatingChange(true, 'binary');
                                }}>
                                    Зачёт
                                </SecItem>
                                <SecItem selected={!isPassed} onClick={() => {
                                    onRatingChange(false, 'binary');
                                }}>
                                    Незачёт
                                </SecItem>
                            </SecScorePlaceholder>
                        </BinPlaceholder>


                    </TabItem>
                }
            </div>


            {hasTextComment == false ? null :
                <TextareaPlaceholder>
                    <Textarea value={textComment}
                              placeholder={'Добавить комментарий'}
                              onChange={evt => {
                                  let s = evt.target.value;
                                  onTextCommentChange(s);
                              }}/>
                </TextareaPlaceholder>
            }

            {hasTextComment == false ? null :
                <SubControlsPlaceholder>
                    <SpanB onClick={() => {
                        setModalOpen(true);
                    }}>
                        <span>
                            Выбрать похвалу
                        </span>
                        <img src={medal_img} style={{height: 28, marginLeft: 10}}/>

                    </SpanB>
                </SubControlsPlaceholder>
            }


            <Sidebar visible={modalOpen} onCloserClick={() => {
                setModalOpen(false);
            }}>
                <h3>
                    Выберите похвалу из списка
                </h3>
                <div>
                        {merItems.map((a, i) => {
                        return (
                            <MeritRow key={i} style={{marginBottom: 5}} onClick={() => {
                                if (textComment == undefined || textComment == '') {
                                    onTextCommentChange(a);
                                } else {
                                    onTextCommentChange(`${textComment}\n${a}`);
                                }
                                setModalOpen(false);
                            }}>
                                {a}
                            </MeritRow>
                        )
                    })}
                </div>

            </Sidebar>

        </Wrapper>
    );
}

const TopTapSwitchers = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-bottom: 10px;
`;

const SwitchTabItem = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: ${props => (props.selected == true ? '3px solid orange' : '3px solid transparent')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
`;

const MeritRow = styled.div`
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  border-radius: 4px;

  :hover {
    opacity: 0.7;
  }
`;

const BinPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const SecScorePlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  border-radius: 1000px;
  height: 30px;
  border: 1px solid whitesmoke;
`;

const SecItem = styled.div`
  height: 30px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${props => (props.selected == true ? '#FFC02C' : 'transparent')};
  color: ${props => (props.selected == true ? 'white' : 'black')};

  :first-of-type {
    border-right: 1px solid whitesmoke;
    border-top-left-radius: 1000px;
    border-bottom-left-radius: 1000px;
  }

  :last-of-type {
    border-top-right-radius: 1000px;
    border-bottom-right-radius: 1000px;
  }
`;

const TopSectionPlaceholder = styled.div`
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid whitesmoke;
`;

const SubControlsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;

`;

const SpanB = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  opacity: 0.8;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  box-sizing: border-box;
`;

const TextareaPlaceholder = styled.div`
  box-sizing: border-box;
  margin-bottom: 5px;
`;

const StarsPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 5px;
`;