import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import voidBox from '../../../../assets/img/ch_void.svg'
import selBox from '../../../../assets/img/ch_box.svg'

import {Input} from "../../../ui/Input";

function isHebrew(text) {
    if (text === undefined || text.trim() === '') {
        return false;
    }
    let hebrewChars = /[\u0590-\u05FF]+/g;
    let s = text.replace(hebrewChars, '')
    return s.length < text.length
}

export default function OneOptionEditorTool(props) {
    const {
        text = '', isCorrect = false, onChange = d => {

        }
    } = props;


    return (
        <Wrapper>
            <Input
                style={{flex: 1, textAlign: isHebrew(text) ? 'right' : 'left'}}
                dir={isHebrew(text) ? 'rtl' : 'ltr'}
                value={text}
                onChange={evt => {
                onChange({
                    text: evt.target.value
                });
            }}/>
            <ChBoxPlaceholder>
                <BoxImg src={(isCorrect == true) ? selBox : voidBox} onClick={() => {
                    onChange({
                        isCorrect: !isCorrect
                    });
                }}/>
                <span style={{marginLeft: 5}}>
                    верно
                </span>
            </ChBoxPlaceholder>
        </Wrapper>
    );
}

const BoxImg = styled.img`
  width: 18px;
  height: 18px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
`;

const ChBoxPlaceholder = styled.div`
  margin-left: 10px;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  display: flex;
`;