import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import ContainersTreePanel from "../../containers/panels/ContainersTreePanel";
import * as actions from '../../../redux/actions/SlidesActions'
import * as copyPasteActions from '../../../redux/actions/CopyPasterActions'
import * as selectorActions from '../../../redux/actions/CoursesLessonSelectorActions'

import ViewSlideTool from "../../slides/tools/ViewSlideTool";
import LottieLoader from "../../loaders/LottieLoader";

import useDimensions from "react-cool-dimensions";

import select_lottie_json from '../../../assets/lottie/select_slide_lottie.json'
import lottie_disk_loading_json from '../../../assets/lottie/lottie_disk_loading.json'
import close_img from '../../../assets/img/close_x.svg'
import author_img from '../../../assets/img/author.svg'
import share_mini from '../../../assets/img/share_mini.svg'
import clone_mini from '../../../assets/img/sheep.svg'
import copyright_cc_img from '../../../assets/img/copyright_cc.svg'
import CommonHelper from "../../../helpers/CommonHelper";
import {translate} from "../../../utils/Translate";
import NiceModal from "../../modals/NiceModal";
import SlideItemEditTool from "../../lessons/tools/SlideItemEditTool";
import SlidesAPI from "../../../api/SlidesAPI";
import {Input} from "../../ira/ui/Inputs";
import PrivateContainerFolderSelectorPanel from "../../containers/panels/PrivateContainerFolderSelectorPanel";
import toast from "react-hot-toast";
import * as slidesActions from "../../../redux/actions/SlidesActions";
import * as containersActions from "../../../redux/actions/ContainersActions";
import SharedModulesTreePanel from "../../access_groups/panels/SharedModuesTreePanel";
import TeacherGroupLessonsPanel from "../../lessons/panels/TeacherGroupLessonsPanel";

import Spin from 'arui-feather/spin'
import LimboSlidesPanel from "../../containers/panels/LimboSlidesPanel";
import DeletedSlidesPanel from "../../containers/panels/DeletedSlidesPanel";
import UserInfoSpan from "../../chat/panels/UserInfoSpan";
import UsersAPI from "../../../api/UsersAPI";

export default function CoursesViewPanel(props) {

    const {

        hasSearch = true,
        showShareButton = false,
        height = '100vh',
        leftWidth = window.innerWidth >= 720 ? '360px' : '100vw',
        showRightIfNotSelected = true,
        hasTopInfoBar = false,
        selectedSlideTopContent = null,
        canCopyToBuffer = false,

        creatorMode = false

    } = props;

    const {
        selectedSlide,
        loading,
        selectedSlideId,
        buffer,
        currentUserId,
        currentUserIsTeacher,
        language,
        sharedAllowEdit,
        isModerator,
        coursesPanelState,
        leftPanelState,
        rightPanelState
    } = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let selSlide = (state.slides.selectedSlideId == undefined) ? undefined : state.slides.slidesMap.get(state.slides.selectedSlideId);
        let accessGroupsIds = (selSlide?.accessGroupsIds || []);
        let accGroups = accessGroupsIds.map(x => state.accessGroups.accessGroupsMap.get(x)).filter(x => (x != undefined));
        let sharedAllowEdit = accGroups.reduce((f, x) => (f || (x?.allowEdit == true)), false);
        return {
            selectedSlideId: state.slides.selectedSlideId,
            selectedSlide: selSlide,
            isModerator: currentUser != undefined && currentUser.isModerator,
            loading: state.slides.loading,
            buffer: state.buffer,
            currentUserId: state.users.currentUserId,
            currentUserRole: currentUser?.userRole,
            currentUserIsTeacher: (currentUser?.userRole == 'teacher'),
            language: state.language.language,
            sharedAllowEdit: sharedAllowEdit,
            coursesPanelState: state.pageSelector.coursesPanelState,
            leftPanelState: state.pageSelector.leftPanelState,
            rightPanelState: state.pageSelector.middlePanelState
        }
    }));

    const [authorInfoVisible, setAuthorInfoVisible] = useState(false);
    const [sourcesInfoVisible, setSourcesInfoVisible] = useState(false);

    const [searching, setSearching] = useState(false);
    const [isSearchMode, setIsSearchMode] = useState(false);

    const dispatch = useDispatch();
    const {observe, unobserve, width} = useDimensions({});

    const [searchText, setSearchText] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [searchT, setSearchT] = useState(0);

    const [filteredIds, setFilteredIds] = useState([]);

    const [editItemId, setEditItemId] = useState();
    const [cloneModalVisible, setCloneModalVisible] = useState(false);
    const [cloningName, setCloningName] = useState('');
    const [localLoading, setLocalLoading] = useState(false);


    console.log('CoursesViewPanel: creatorMode = ', creatorMode);

    useEffect(() => {
        return () => {
            // dispatch(actions.selectSlide(undefined));
            try {
                unobserve();
            } catch (exc) {

            }
        }
    }, [])

    useEffect(() => {
        if (selectedSlideId != undefined) {
            dispatch(actions.getSlide(selectedSlideId)).then(pld => {
                console.log('!!!-> pld = ', pld);
                let arr = (pld.slides || []);
                if (arr.length > 0) {
                    window.prouniSelectedSlide = arr[0];
                }
            });
        }
    }, [selectedSlideId]);

    useEffect(() => {
        dispatch(selectorActions.showPanelSelector('courses'));
        dispatch(selectorActions.showPanel('left'))
    }, []);

    useEffect(() => {
        console.log('search query changed! searchQuery = ', searchQuery);
        if (searchQuery.length < 2) {
            return;
        }
        setSearching(true);
        setIsSearchMode(false);
        setFilteredIds([]);
        SlidesAPI.searchSlides(searchQuery).then(ids => {
            setFilteredIds(ids);
            setIsSearchMode(true);
            setSearching(false);
        })
    }, [searchQuery, searchT]);

    // let isLoaderVisible = (selectedSlideId != undefined && selectedSlide?.items == undefined && loading == true);
    let isLoaderVisible = (loading == true);
    let rightWidth = 500;
    try {
        rightWidth = width - +`${leftWidth}`.replace('px', '');
    } catch (x) {

    }

    let shouldShowRight = (selectedSlide != undefined || (showRightIfNotSelected == true));
    console.log('render: selectedSlide = ', selectedSlide);
    console.log('render: currentUserId = ', currentUserId);
    console.log('!!!render: isSearchMode = ', isSearchMode);
    console.log('!!!render: sharedAllowEdit = ', sharedAllowEdit);
    let currItems = selectedSlide?.items || [];
    let selectedItem = (editItemId == undefined) ? undefined : currItems.filter(x => (x.id == editItemId))[0];
    let selectedSlideHasAccessGroups = (selectedSlide != undefined && selectedSlide?.accessGroupsIds != undefined && selectedSlide?.accessGroupsIds.length > 0);

    return (
        <Wrapper ref={observe}>

            <PanelSelector
                style={{display: (coursesPanelState == false) || window.innerWidth >= 720 ? 'none' : 'block'}}>

                <PanelVisibleButton
                    style={{/*position: 'absolute',*/
                        left: '3%',
                        paddingTop: '2px',
                        display: window.innerWidth >= 720 || (rightPanelState == false) ? 'none' : 'block'
                    }}
                    onClick={() => {
                        dispatch(selectorActions.showPanel('left'))
                    }}>

                    <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.999999 13.732C-0.333335 12.9622 -0.333333 11.0377 1 10.2679L17.5 0.741669C18.8333 -0.0281315 20.5 0.93412 20.5 2.47372L20.5 21.5263C20.5 23.0659 18.8333 24.0281 17.5 23.2583L0.999999 13.732Z"
                            fill="#FFA71B"/>
                    </svg>

                </PanelVisibleButton>

                <PanelVisibleButton
                    style={{/*position: 'absolute', */marginLeft: '92%', paddingTop: '2px',
                        display: /*(selectedSlideId == undefined) ||*/ window.innerWidth >= 720 || (leftPanelState == false) ? 'none' : 'block'
                    }}

                    onClick={() => {
                        dispatch(selectorActions.showPanel('middle'))
                    }}>

                    <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M20 10.268C21.3333 11.0378 21.3333 12.9623 20 13.7321L3.5 23.2583C2.16667 24.0281 0.5 23.0659 0.5 21.5263L0.5 2.47372C0.5 0.934118 2.16667 -0.0281308 3.5 0.74167L20 10.268Z"
                            fill="#FFA71B"/>
                    </svg>


                </PanelVisibleButton>

            </PanelSelector>

            <Left height={height} width={(shouldShowRight == true) ? leftWidth : '100%'}
                  style={{display: leftPanelState == true || window.innerWidth >= 720 ? 'block' : 'none'}}>

                {hasSearch == false ? null :
                    <TopSearchPlaceholder>

                        <SearchInput
                            disabled={searching}
                            placeholder={'Search...'}
                            value={searchText}
                            onChange={evt => {
                                if (searching == true) {
                                    return;
                                }
                                setSearchText(evt.target.value);
                                if (filteredIds.length > 0) {
                                    setFilteredIds([]);
                                    setIsSearchMode(false);
                                    setSearching(false);
                                }
                                // if (isSearchMode) {
                                //     setIsSearchMode(false);
                                // }
                            }}
                            onKeyPress={event => {
                                if (searching == true) {
                                    return;
                                }
                                if (event.key === 'Enter') {
                                    console.log('onKeyPress: Enter!!');
                                    setSearchQuery(searchText);
                                    setSearchT(+new Date());
                                }
                            }}
                        />
                        {searching == false ? null :
                            <div style={{position: 'absolute', right: 15, top: 10}}>
                                <Spin visible={true}/>
                            </div>
                        }
                    </TopSearchPlaceholder>
                }

                <React.Fragment key={`x${isSearchMode}${searchT}`}>
                    <ContainersTreePanel
                        searchMode={isSearchMode}
                        filteredModulesIds={filteredIds}
                        access={'private'}
                    />

                    <ContainersTreePanel
                        searchMode={isSearchMode}
                        filteredModulesIds={filteredIds}
                        access={'public'}
                    />

                    <ContainersTreePanel
                        searchMode={isSearchMode}
                        filteredModulesIds={filteredIds}
                        access={'limbo'}
                    />

                    {/*<LimboSlidesPanel/>*/}

                    <SharedModulesTreePanel
                        searchMode={isSearchMode}
                        filteredModulesIds={filteredIds}
                    />
                    <DeletedSlidesPanel/>

                </React.Fragment>

            </Left>

            {shouldShowRight == false ? null :

                <Right height={height}
                       style={{display: (rightPanelState == true) || window.innerWidth >= 720 ? 'block' : 'none'}}>

                    {selectedSlide == undefined || hasTopInfoBar == false ? null :

                        <SelectedSlideNamePlaceholder>
                            <SelectedSlideTextSpan>
                                {selectedSlide?.name}
                            </SelectedSlideTextSpan>
                            <CloseImg src={close_img} onClick={() => {
                                dispatch(actions.selectSlide(undefined));
                            }}>

                            </CloseImg>
                        </SelectedSlideNamePlaceholder>
                    }
                    <SelectedSlidePlaceholder maxHeight={height}
                                              hasTopInfoBar={hasTopInfoBar}
                    >

                        {selectedSlideId != undefined ?
                            <SelInner width={Math.min(rightWidth - 40, 720)}>

                                {isLoaderVisible &&
                                <NotSelectedPlaceholder
                                    width={Math.min(rightWidth - 20, 600)}
                                >
                                    <LottieLoader
                                        json={lottie_disk_loading_json}
                                    />
                                    <div>
                                        {translate('loading')}
                                    </div>
                                </NotSelectedPlaceholder>
                                }

                                {loading == true ? null : selectedSlideTopContent}

                                <ViewSlideTool
                                    currentUserId={currentUserId}
                                    canSeeCopyrightWarning={true}
                                    loading={loading}
                                    isModerator={isModerator}
                                    canRecover={(selectedSlide?.isDeleted == true)}
                                    canEdit={((!(selectedSlide?.isOccupied == true && selectedSlide?.occupantId != currentUserId)) && (selectedSlide?.isDeleted != true) && ((creatorMode && (selectedSlide?.userId == currentUserId || sharedAllowEdit == true)) || window.location.href.indexOf('petr') > -1 || isModerator == true))}
                                    creatorMode={creatorMode && (selectedSlide?.userId == currentUserId)}
                                    useTranslatableTranscript={false}
                                    canShare={showShareButton}
                                    buffer={buffer}
                                    {...selectedSlide}
                                    canCopyToBuffer={canCopyToBuffer}
                                    onCopySlideItemToBuffer={slideItem => {
                                        dispatch(copyPasteActions.copyToBuffer('SLIDE_ITEM', slideItem));
                                    }}
                                    onCopyExerciseToBuffer={exId => {
                                        dispatch(copyPasteActions.copyToBuffer('EXERCISE', exId));
                                    }}
                                    onCopyDrochkaExerciseToBuffer={exId => {
                                        dispatch(copyPasteActions.copyToBuffer('DROCHKA_EXERCISE', exId));
                                    }}
                                    onCopyTestToBuffer={testId => {
                                        dispatch(copyPasteActions.copyToBuffer('TEST', testId));
                                    }}
                                    onCopyTreadmillToBuffer={trId => {
                                        dispatch(copyPasteActions.copyToBuffer('TREADMILL', trId));
                                    }}
                                    onChange={async d => {
                                        console.log('onChange: d = ', d);
                                        if (selectedSlide?.userId != currentUserId && sharedAllowEdit == false) {
                                            return;
                                        }
                                        if (selectedSlide?.isOccupied == true && selectedSlide?.occupantId != currentUserId) {
                                            return window.alert('Occupied!');
                                        }
                                        if (d.recoverContainerId != undefined) {
                                            await dispatch(actions.recoverSlide(selectedSlide?.id, d.recoverContainerId))
                                            await dispatch(containersActions.getMyAllCompactContainers());
                                            await dispatch(slidesActions.loadSlides());
                                        } else {
                                            await dispatch(actions.updateSlide({...d, id: selectedSlide?.id}));
                                        }
                                    }}

                                    onEditItemClick={eId => {
                                        setEditItemId(eId);
                                    }}

                                    onDelete={async () => {
                                        if (window.confirm('Вы уверены?') == false) {
                                            return;
                                        }
                                        await dispatch(actions.deleteSlide(selectedSlideId));
                                    }}

                                    onOccupyToggle={async () => {
                                        console.log('onOccupyToggle occured! ');
                                        console.log('selectedSlide?.occupantId = ', selectedSlide?.occupantId);
                                        console.log('currentUserId = ', currentUserId);
                                        if (selectedSlide?.occupantId != currentUserId && selectedSlide?.isOccupied == true) {
                                            let occUser = await UsersAPI.getUser(selectedSlide?.occupantId);
                                            if (occUser != undefined) {
                                                return window.alert(`Occupied by "${CommonHelper.getUserName(occUser)}"`);
                                            }
                                        }
                                        if (selectedSlide?.isOccupied != true) {
                                            await dispatch(actions.updateSlide({
                                                id: selectedSlideId,
                                                isOccupied: true,
                                                occupationToggleTimestamp: +new Date(),
                                                occupantId: currentUserId
                                            }));
                                        } else {
                                            await dispatch(actions.updateSlide({
                                                id: selectedSlideId,
                                                isOccupied: false,
                                                occupationToggleTimestamp: +new Date(),
                                                occupantId: currentUserId
                                            }));
                                        }
                                    }}
                                    canToggleOccupy={(selectedSlideHasAccessGroups == true) && (hasTopInfoBar == false) && (selectedSlide?.isDeleted != true) && ((selectedSlide?.occupantId == currentUserId || selectedSlide?.isOccupied != true))}

                                />

                                <BottomSharePlaceholder>
                                    <ShareSpan style={{marginLeft: 25, marginRight: 25}} onClick={() => {
                                        setAuthorInfoVisible(true);
                                    }}>
                                        <ShareImg src={author_img}/>
                                        <span>Об авторе</span>
                                    </ShareSpan>

                                    {(selectedSlide?.usedMaterialsDescription == undefined || selectedSlide?.usedMaterialsDescription == '') ? null :
                                        <ShareSpan style={{marginLeft: 25, marginRight: 25}} onClick={() => {
                                            setSourcesInfoVisible(true);
                                        }}>
                                            <ShareImg src={copyright_cc_img}/>
                                            <span>Источники</span>
                                        </ShareSpan>
                                    }

                                    {selectedSlide?.isDeleted == true || ((selectedSlide?.isLimbo == true) && (selectedSlide?.allowCloning != true) && (selectedSlide?.userId != currentUserId)) ? null :
                                        <ShareSpan onClick={() => {
                                            setCloningName(`${selectedSlide?.name} (Copy)`);
                                            setCloneModalVisible(true);
                                        }}>
                                            <ShareImg src={clone_mini}/>
                                            <span>{translate('clone_module')}</span>
                                        </ShareSpan>
                                    }

                                    {(showShareButton == false || selectedSlide?.isDeleted == true) ? null :

                                        <ShareSpan style={{marginLeft: 25}} onClick={() => {
                                            CommonHelper.linkTo(`/share/modules/${selectedSlide?.id}`, true);
                                        }}>
                                            <ShareImg src={share_mini}/>
                                            <span>
                                                {translate('share_module')}
                                            </span>
                                        </ShareSpan>

                                    }
                                </BottomSharePlaceholder>


                            </SelInner>
                            :
                            <NotSelectedPlaceholder>
                                <LottieLoader
                                    json={select_lottie_json}
                                />
                                <div>
                                    {translate('select_module_from_the_left_list')}
                                </div>
                            </NotSelectedPlaceholder>
                        }


                    </SelectedSlidePlaceholder>

                </Right>
            }

            {selectedItem == undefined ? null :
                <NiceModal onClose={() => {
                    setEditItemId(undefined);
                }}>

                    <ItemEditModalInner
                        style={{
                            width: (selectedItem.type == 'image' ? 'calc(100vw - 90px)' : '820px'),
                            height: (selectedItem.type == 'image' ? 'calc(100vh - 90px)' : 'auto'),
                            maxHeight: (selectedItem.type == 'image' ? 'calc(100vh - 90px)' : '90vh'),
                            overflowY: (selectedItem.type == 'image' ? 'none' : 'auto')
                        }}
                    >
                        <SlideItemEditTool
                            loading={loading}
                            type={selectedItem?.type}
                            item={selectedItem}
                            mode={'edit'}
                            onChange={async (updItem, shouldClose = true) => {
                                let newItems = currItems.map((a, i) => (a.id == selectedItem.id ? updItem : a));
                                await dispatch(actions.updateSlide({id: selectedSlide?.id, items: newItems}));
                                if (shouldClose == true) {
                                    setEditItemId(undefined);
                                }
                            }}
                        />
                    </ItemEditModalInner>


                </NiceModal>
            }

            {(cloneModalVisible == false || selectedSlide == undefined) ? null :
                <NiceModal onClose={() => {
                    console.log('-->> onClose');
                    setCloneModalVisible(false);
                }}>
                    <FolderSelectorInnerPlaceholder>

                        <InnerHeading>
                            Укажите название и выберите в какую папку вы хотите сохранить модуль.
                        </InnerHeading>

                        <CloningNamePlaceholder>
                            <Input value={cloningName} placeholder={translate('title')} onChange={evt => {
                                setCloningName(evt.target.value);
                            }}/>
                        </CloningNamePlaceholder>

                        <PrivateContainerFolderSelectorPanel
                            onFolderSelect={async cId => {
                                if (localLoading == true) {
                                    return;
                                }
                                setLocalLoading(true);
                                await SlidesAPI.cloneSlideAndAddItToContainer(selectedSlide?.id, cId, cloningName);
                                setLocalLoading(false);
                                toast('Модуль сдублирован и добавлен в "Моё"');
                                setCloneModalVisible(false);
                                await dispatch(slidesActions.loadSlides());
                                await dispatch(containersActions.getMyAllCompactContainers());
                            }}
                        />

                    </FolderSelectorInnerPlaceholder>

                </NiceModal>
            }

            {(authorInfoVisible == false || selectedSlide == undefined) ? null :
                <NiceModal onClose={() => {
                    console.log('-->> onClose');
                    setAuthorInfoVisible(false);
                }}>
                    <FolderSelectorInnerPlaceholder style={{width: 320}}>

                        <InnerHeading style={{textAlign: 'center', fontWeight: 'bold', marginBottom: 20}}>
                            Информация об авторе
                        </InnerHeading>

                        <div style={{textAlign: 'center'}}>
                            <UserInfoSpan
                                {...selectedSlide}
                            />
                        </div>

                    </FolderSelectorInnerPlaceholder>

                </NiceModal>
            }

            {(sourcesInfoVisible == false || selectedSlide == undefined || (selectedSlide?.usedMaterialsDescription == undefined || selectedSlide?.usedMaterialsDescription == '')) ? null :
                <NiceModal onClose={() => {
                    console.log('-->> onClose');
                    setSourcesInfoVisible(false);
                }}>
                    <SourcesInnerPlaceholder>

                        <InnerHeading style={{textAlign: 'center', fontWeight: 'bold', marginBottom: 20}}>
                            Информация об источниках
                        </InnerHeading>

                        <div>
                            <div
                                dangerouslySetInnerHTML={{__html: selectedSlide?.usedMaterialsDescription.replace(/\n/g, '<br/><br/>')}}></div>
                        </div>


                    </SourcesInnerPlaceholder>

                </NiceModal>
            }


        </Wrapper>
    );
}

const TopLeft = styled.div`
  font-weight: bold;
`;

const SearchInput = styled.input`
  box-sizing: border-box;
  padding-left: 10px;
  background: white;
  width: 100%;
  border: none;
  border-bottom: 1px solid whitesmoke;
  height: 28px;
  line-height: 28px;

  :focus {
    border-bottom-color: #FFA518;
    outline: none;
  }
`;

const TopSearchPlaceholder = styled.div`
  margin-bottom: 0px;
  box-sizing: border-box;
  padding: 5px;
  background: whitesmoke;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;

  /*  @media (max-width: 720px){
      width: 90vw;
    }*/
`;

const CloningNamePlaceholder = styled.div`
  padding: 2px;
  box-sizing: border-box;
  margin-top: 5px;
`;

const PanelVisibleButton = styled.div`
  z-index: 9999;
`;

const ItemEditModalInner = styled.div`
  width: 820px;
  box-sizing: border-box;
`;

const BottomSharePlaceholder = styled.div`
  padding: 10px;
  padding-left: 0px;
  padding-right: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 720px) {
    margin-bottom: 95px;
  }
`;

const ShareSpan = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  opacity: 0.5;
  font-size: 12px;

  :hover {
    opacity: 1;
  }
`;

const FolderSelectorInnerPlaceholder = styled.div`
  width: 420px;
  max-height: calc(80vh - 20px);
  overflow-y: auto;

  @media (max-width: 720px) {
    width: 100%;
  }
`;

const SourcesInnerPlaceholder = styled.div`
  width: 720px;
  max-height: calc(80vh - 20px);
  overflow-y: auto;
  @media (max-width: 820px) {
    width: 100%;
    box-sizing: border-box;
  }
`;


const InnerHeading = styled.div`
  @media (max-width: 720px) {
    margin-top: 30px;
    margin-left: 10px;
  }
`;


const ShareImg = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 5px;
`;

const SelectedSlideNamePlaceholder = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  box-sizing: border-box;
  height: 50px;
  max-height: 50px;
  z-index: 4;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid whitesmoke;
  background: #fcfcfc;

  @media (max-width: 720px) {
    padding-left: 7%;
    padding-right: 7%;
  }
`;

const SelectedSlideTextSpan = styled.span`
  font-weight: bold;
  font-size: 16px;
  @media (max-width: 1200px) {
    font-size: 12px;
  }
`;

const CloseImg = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;

  :hover {
    opacity: 0.75;
  }
`;

const NotSelectedPlaceholder = styled.div`
  width: ${props => props.width}px;
  margin: 0 auto;
  height: 400px;
  text-align: center;
  padding-bottom: 20px;
`;

const SelInner = styled.div`
  position: relative;
  width: ${props => props.width}px;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  background: white;
  border-radius: 4px;
  @media (max-width: 1200px) {
    font-size: 12px;
  }
  @media (max-width: 1400px) {
    width: 100%;
  }
`;

const SelectedSlidePlaceholder = styled.div`
  max-height: ${props => props.maxHeight};
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 30px;
  padding-top: ${props => (props.hasTopInfoBar ? 55 : 30)}px;

  @media (max-width: 720px) {
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

const PanelSelector = styled.div`
  background-color: whitesmoke;
  width: 100%;
  height: 30px;

  /*@media (min-width: 720px){
    display: none;
  }*/
`;

const Left = styled.div`
  max-height: ${props => props.height};
  height: ${props => props.height};
  overflow-y: auto;
  box-sizing: border-box;
  width: ${props => (props.width)};
  background: white;
  border-right: 1px solid rgba(133, 147, 168, 0.51);
  border-left: 1px solid rgba(133, 147, 168, 0.51);
  padding-bottom: 30px;
`;

const Right = styled.div`
  position: relative;
  flex: 1;
  box-sizing: border-box;
  padding: 0px;
  max-height: ${props => props.height};
  height: ${props => props.height};
  @media (max-width: 720px) {
    width: 100%;
  }
`;