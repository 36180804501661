import React from 'react';
import styled from 'styled-components';
import {GridWrapper} from './GridWrapper';

const ColsWrapperDiv = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`;

export const ColsWrapper = (props) => {
    return <ColsWrapperDiv>
        {GridWrapper({
            ...props,
            direction: 'horizontal'
        })}
    </ColsWrapperDiv>
}
