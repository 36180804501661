import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {useInterval} from "../../treadmills_exercises/treadmill/tools/TreadmillPlayerTool";
import RecordComponent from "../../record/RecordComponent";
import {translate} from "../../../utils/Translate";

const getCurrentCard = (items, t) => {
    let tk = 0;
    for (let i in items) {
        if (i == 0) {
            items[0].fromStart = 0;
        } else {
            items[i].fromStart = +items[i - 1].fromStart + +items[i - 1].time;
        }
    }
    // console.log('getCurrentCard: items = ', items);
    let fItems = items.filter(x => ((+x.fromStart + +x.time) > (+t / 1000.0)));
    // console.log('getCurrentCard: fIems = ', fItems);
    return fItems[0];
}

export default function CardsSlideShowTool(props) {
    const {
        answerUrl = undefined,
        items = [],
        dt = 100,
        onAnswer = url => {

        }
    } = props;
    let itemsWithIds = items.map((a, i) => ({...a, id: i}));

    // console.log('--->>> answerUrl = ', answerUrl);

    const recorderRef = useRef();
    const t = useRef(0);
    const t0 = useRef(+new Date());
    const [currentCardId, setCurrentCardId] = useState(itemsWithIds[0]);
    const [active, setActive] = useState(answerUrl == undefined);
    const [timeFromStart, setTimeFromStart] = useState(0);

    // console.log('CardsSlideShowTool: itemsWithIds = ', itemsWithIds);
    // useEffect(() => {
    //     if (answerUrl != undefined && active == true){
    //         setActive(false);
    //     }
    // }, [answerUrl]);

    useInterval(() => {
        if (active == false) {
            // console.log('SKIP!')
            return;
        }
        // try{
        //     console.log('+new Date() - +window.PREV_T = ', +new Date() - +window.PREV_T);
        //     window.PREV_T = +new Date();
        // }catch(zx){
        //
        // }
        t.current = +t.current + +dt;
        // let cIt = getCurrentCard(itemsWithIds, t.current);
        let cIt = getCurrentCard(itemsWithIds, +new Date() - +t0.current);
        if (cIt == undefined) {
            setActive(false);
            try {
                recorderRef.current.stopRecording();
            } catch (exc) {

            }
        } else {
            if (cIt?.id != currentCardId) {
                console.log('NEW CURRENT CARD! t.current, cIt?.id = ', t.current, cIt?.id);
                setCurrentCardId(cIt?.id);
            }
        }
        // setTimeFromStart(t.current);
        setTimeFromStart(+new Date() - +t0.current);
    }, dt);

    if (itemsWithIds.length == 0) {
        return null;
    }

    let currentItem = (currentCardId == undefined) ? undefined : itemsWithIds.filter(xx => (xx.id == currentCardId))[0];
    let totalTime = itemsWithIds.reduce((sm, a) => (+sm + +a?.time), 0);
    let progress = (totalTime == 0) ? 0 : 0.1 * timeFromStart / totalTime;

    // console.log('currentItem = ', currentItem);

    return (
        <Wrapper>

            {active == false ?
                <TheEndSection>
                    {translate('the_end')}
                </TheEndSection>
                :
                <TopInnerPlaceholder>

                    <SlidesPlaceholder src={currentItem?.imageUrl}>

                    </SlidesPlaceholder>

                    {(currentItem?.text == undefined || currentItem?.text == '') ? null :
                        <CurrentTextPlaceholder>
                            {currentItem?.text}
                        </CurrentTextPlaceholder>
                    }
                </TopInnerPlaceholder>
            }

            {active == false ? null :
                <ProgressbarPlaceholder>
                    <ProgressInner style={{width: `${progress}%`}}></ProgressInner>
                </ProgressbarPlaceholder>
            }

            <RecorderPlaceholder>
                <RecordComponent
                    showStopButton={false}
                    ref={recorderRef}
                    autoRecord={answerUrl == undefined}
                    autoSave={true}
                    recordedUrl={answerUrl}
                    onSave={url => {
                        console.log('onRecorded! url = ', url);
                        onAnswer(url);
                    }}
                    onStartRecording={() => {
                        try {
                            t.current = 0;
                            t0.current = +new Date();
                            setActive(true);
                        } catch (exc) {

                        }
                    }}
                />
            </RecorderPlaceholder>

        </Wrapper>
    );
}

const ProgressbarPlaceholder = styled.div`
  position: relative;
  background: whitesmoke;
  height: 20px;
`;

const ProgressInner = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  background: #018137;
`;

const TopInnerPlaceholder = styled.div`
  height: 420px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  background: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const CurrentTextPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 32px;
  background: black;
  color: white;
  font-weight: bold;
  height: 100px;
  justify-content: center;
`;

const SlidesPlaceholder = styled.div`
  box-sizing: border-box;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("${props => props.src}");
  flex: 1;
`;

const RecorderPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  width: 100%;
`;

const TheEndSection = styled.div`
  width: 100%;
  height: 420px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 34px;
  font-weight: bold;
  background: rgb(255, 167, 27);
  color: white;
`;

