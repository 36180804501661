import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import TranslatableParagraph from "./TranslatableParagraph";

export default function WrappedTranslatableParagraph(props) {
    const {
        text = ''
    } = props;
    const {
        currentUser,
        isTeacher
    } = useMappedState(useCallback(state => {
        let user = state.users.usersMap.get(state.users.currentUserId);
        let isTeacher = (user?.userRole == 'teacher');
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            isTeacher: isTeacher
        }
    }));

    if (text == undefined || text == '') {
        return null;
    }

    return (
        <Wrapper>
            <TranslatableParagraph
                text={text}
                userId={currentUser?.id}
                {...props}
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`

`;