import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from 'react';

import styled from 'styled-components';
import {BlueButton} from "../ira/ui/Buttons";
import TeacherSettingsAPI from "../../api/TeacherSettingsAPI";

export default function KeyValueExample(props) {
    const {key = 'example', userId = 'jnM2pCK62I', data = {
        test: 1,
        foo: 'bar'
    }} = props;

    const [item, setItem] = useState();

    useEffect(() => {
        TeacherSettingsAPI.getUserSettings(userId, key).then(d => {
            setItem(d);
        });
    }, []);

    return (
        <Wrapper>

            <h2>
                {`Save settings ${key}`}
            </h2>

            <BlueButton onClick={async () => {
                let pld = await TeacherSettingsAPI.saveUserSettings(userId, key, data);
                setItem(pld);
                window.alert('saved!')
            }} >
                Save
            </BlueButton>

            <pre style={{padding: 10}} >
                {JSON.stringify(item, null, 4)}
            </pre>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;