import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import KvdbAPI from "../../../api/KvdbAPI";
import ReactHelper from "../../../helpers/ReactHelper";
import Sidebar from "arui-feather/sidebar";
import DateTimePicker from "../../ui/DateTimePicker";

const gs = a => (+a < 10 ? `0${a}` : `${a}`);

export default function DeadlineCountdownEditorPanel(props) {
    const {
        name = 'test',
        onDeadline = () => {

        }
    } = props;

    const [loading, setLoading] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [deadlineTimestamp, setDeadlineTimestamp] = useState(undefined);
    const [timeString, setTimeString] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        KvdbAPI.getValue(`countdown_${name}`, {}).then(pld => {
            if (pld.deadlineTimestamp == undefined) {
                setLoading(false);
                return;
            }
            setDeadlineTimestamp(+pld.deadlineTimestamp);
            setLoading(false);
        });
    }, [name]);

    ReactHelper.useInterval(() => {
        if (deadlineTimestamp == undefined) {
            return;
        }
        let delta = +deadlineTimestamp - (+new Date());
        if (delta < 0) {
            onDeadline();
            return;
        }
        let total = Math.abs(delta);
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        let s = `${gs(hours)}:${gs(minutes)}:${gs(seconds)}`;
        setTimeString(s);
    }, 1000);

    if (loading == true){
        return '...';
    }

    if ((deadlineTimestamp == undefined || timeString == undefined) && editModalVisible == false) {
        return (
            <TimeString onClick={() => {
                setEditModalVisible(true);
            }}>
                {'deadline is not set'}
            </TimeString>
        )
    }

    return (
        <Wrapper>
            <TimeString onClick={() => {
                setEditModalVisible(true);
            }}>
                {timeString}
            </TimeString>

            <Sidebar visible={editModalVisible} onCloserClick={() => {
                setEditModalVisible(false);
            }}>

                {editModalVisible == false ? null :
                    <div style={{height: 400}} >
                        <DateTimePicker
                            timestamp={deadlineTimestamp}
                            onChange={async t => {
                                setDeadlineTimestamp(t);
                                await KvdbAPI.saveValue(`countdown_${name}`, {
                                    deadlineTimestamp: t
                                });
                                // setEditModalVisible(false);
                            }}
                        />
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const TimeString = styled.div`
  cursor: pointer;
`;