import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Button} from "../../ui/Button";

import remove_img from '../../../assets/img/delete_icon.svg'
import Sidebar from "arui-feather/sidebar";
import {Input} from "../../ui/Input";
import WordTypesSelector from "./WordTypesSelector";
import {GreenButton} from "../../ira/ui/Buttons";

export default function VocWordsEditor(props) {
    const {
        items = [],
        onChange = newItems => {

        },
        canEdit = false,
        hasTypes = false
    } = props;

    const [addVisible, setAddVisible] = useState(false);

    const [newWordTypes, setNewWordTypes] = useState([]);
    const [newWord, setNewWord] = useState();

    useEffect(() => {
        if (addVisible) {
            setNewWordTypes([]);
        }
    }, [addVisible]);

    console.log('VocWordsEditor: render: items = ', items);

    return (
        <Wrapper>

            <ListPlaceholder>
                {items.map((a, i) => {
                    let {types = []} = a;
                    return (
                        <ListItem key={`x${i}_${a.word}`}>
                            <ItemLeft>
                                <WordPlaceholder>
                                    {a.word}
                                </WordPlaceholder>
                                <TypesPlaceholder>
                                    {types.map((b, j) => {
                                        return (
                                            <TypeItem key={`${b}-${j}`}>
                                                {b}
                                            </TypeItem>
                                        )
                                    })}
                                </TypesPlaceholder>
                            </ItemLeft>
                            {canEdit == false ? null :
                                <ItemRight>
                                    <RemoveImg src={remove_img} onClick={() => {
                                        let newItems = items.filter(xx => ((xx.word == undefined ? '' : xx.word) != a.word));
                                        onChange(newItems);
                                    }}/>
                                </ItemRight>
                            }
                        </ListItem>
                    )
                })}
            </ListPlaceholder>

            {canEdit == false ? null :
                <BottomPlaceholder>
                    <AddSpan onClick={() => {
                        setAddVisible(true);
                        setNewWord('');
                        setNewWordTypes([]);
                    }}>
                        Добавить
                    </AddSpan>
                </BottomPlaceholder>
            }


            <Sidebar width={800} visible={addVisible} onCloserClick={() => {
                setAddVisible(false);
            }}>

                {addVisible == false ? null :
                    <SidebarInner>

                        <Row>
                            <Input value={newWord}
                                   placeholder={'Введите слово или фразу'}
                                   onChange={evt => {
                                       setNewWord(evt.target.value);
                                   }}/>
                        </Row>

                        {hasTypes == false ? null :
                            <Row>
                                <WordTypesSelector
                                    types={newWordTypes}
                                    onChange={arr => {
                                        setNewWordTypes(arr);
                                    }}
                                />
                            </Row>
                        }

                        <Row>
                            <GreenButton onClick={() => {
                                let newItems = items.concat([
                                    {
                                        word: newWord,
                                        types: newWordTypes
                                    }
                                ]);
                                onChange(newItems);
                                setAddVisible(false);
                            }}>
                                Добавить
                            </GreenButton>
                        </Row>

                    </SidebarInner>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const AddSpan = styled.span`
  opacity: 0.5;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const SidebarInner = styled.div`

`;

const Row = styled.div`
  margin-bottom: 10px;
`;

const RemoveImg = styled.img`
  height: 14px;
  opacity: 0.5;
  cursor: pointer;
  :hover{
    opacity: 1;
  }
`;

const WordPlaceholder = styled.div`

`;

const TypeItem = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 6px;
  border: 1px solid whitesmoke;
  background: antiquewhite;
  height: 20px;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
`;

const TypesPlaceholder = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ListPlaceholder = styled.div`

`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  padding-bottom: 5px;
`;

const ItemLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ItemRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BottomPlaceholder = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

const TopPlaceholder = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;