import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
// import TranslatableParagraph from "../translate/panels/TranslatableParagraph";
import MonthCalendar from "../calendars/MonthCalendar";
import DateTimePicker from "../ui/DateTimePicker";

import {ReactMic} from 'react-mic';
import RecordComponent from "../record/RecordComponent";
import ExercisePanel from "../exercises/panels/ExercisePanel";
import TestPanel from "../tests/panels/TestPanel";
import DrochkaExercisePanel from "../drochka_exercise/panels/DrochkaExercisePanel";

// import Diff from 'text-diff'
import TextsDiff from "../exercises/tools/TextsDiff";
import PolotnoTool from "../polotno/tools/PolotnoTool";
import {BlueButton} from "../ira/ui/Buttons";
import PhotoEditorPhotoPicker from "../photos/tools/PhotoEditorPhotoPicker";
import FreeSyncVideoPlayer from "../players/FreeSyncVideoPlayer";
import YoutubeCutterTool from "../youtube/tools/YoutubeCutterTool";
import PrivateContainerFolderSelectorPanel from "../containers/panels/PrivateContainerFolderSelectorPanel";
import RealtimeEditor from "../tiny/RealtimeEditor";
import SimpleFileUploader from "../uploader/tools/SimpleFileUploader";
import LangSwitcherDropdown from "../../utils/LangSwitcherDropdown";
import MaterialSearchPanel from "../materials/panels/MaterialSearchPanel";
import DrochkaItemsForm from "../drochka_exercise/forms/DrochkaItemsForm";
import DrochkaInfoForm from "../drochka_exercise/forms/DrochkaInfoForm";
import UpdateAvatarTool from "../avatar/tools/UpdateAvatarTool";
import AllActiveUsersStatsTool from "../stats/tools/AllActiveUsersStatsTool";
import MosesEditorTool from "../moses/MosesEditorTool";
import DialogExercisePanel from "../dialogs/panels/DialogExercisePanel";
import DeadlineCountdownEditorPanel from "../countdown/panels/DeadlineCountdownEditorPanel";
import KvdbTasksFeedPanel from "../online/panels/KvdbTasksFeedPanel";
import QuestionnairePanel from "../questionnaires/panels/QuestionnairePanel";
import AccessGroupsDropdownPanel from "../access_groups/panels/AccessGroupsDropdownPanel";
import QuestionEditorTool from "../questionnaires/tools/QuestionEditorTool";
import QuestionsEditorTool from "../questionnaires/tools/QuestionsEditorTool";
import RoomChatPanel from "../chat/panels/RoomChatPanel";

import {Rnd} from 'react-rnd'
import OverlayedTasksEditor from "../overlayed_tasks/tools/OverlayedTasksEditor";

// 1eNYVvEFjV

import SelectVideoScreenshotWrapper from "../video/preview/buttons/SelectVideoScreenshotWrapper";
import NewBulkUploader from "../bulk_uploader/NewBulkUploader";
import ThemesSelectorTool from "../prouni/tools/ThemesSelectorTool";
import DownloadMp3Span from "../mp3/tools/DownloadMp3Span";
import DndFillGapsExercise from "../dnd/tools/DndFillGapsExercise";
import ExerciseCardForm from "../exercises/forms/ExerciseCardForm";
import InputFillGapsExercise from "../dnd/tools/InputFillGapsExercise";
import CounterExample from "../examples/CounterExample";
import RecognitionExampleComponent from "../examples/RecognitionExampleComponent";
import KeyValueExample from "../examples/KeyValueExample";
import EmphasisEditor from "../emphasis/tools/EmphasisEditor";
import DiffHelper from "../../helpers/DiffHelper";

export default function DevApp(props) {
    const [t, setT] = useState(+new Date());

    const [recording, setRecording] = useState(false);
    const polotnoRef = useRef();
    const [words, setWords] = useState([]);

    const [text1, setText1] = useState('');
    const [text2, setText2] = useState('');

    const score = DiffHelper.getScore(text1, text2);

    return (
        <Wrapper>

            {/*<div style={{width: '100vw'}} >*/}
            {/*    <BulkMaterialsPanel/>*/}
            {/*</div>*/}

            {/*<RecognitionExampleComponent/>*/}
            <KeyValueExample/>

            {/*<NewBulkUploader/>*/}

            {/*<InputFillGapsExercise/>*/}

            {/*/>*/}

            {/*<ExerciseCardForm/>*/}

            {/*<DownloadMp3Span*/}
            {/*    url={'https://files.entsu.ru/files/f1331aeb-1d3b-4381-b815-572064506439_480p.mp4'}*/}
            {/*/>*/}

            {/*<SelectVideoScreenshotWrapper onSave={url => {*/}
            {/*    console.log('frame onSave: url = ', url);*/}
            {/*}} >*/}
            {/*    select frame*/}
            {/*</SelectVideoScreenshotWrapper>*/}

            {/*<HighlightEditorTool*/}
            {/*    selWords={words}*/}
            {/*    text={'This is a test message!'}*/}
            {/*    onChange={x => {*/}
            {/*        console.log('onChange: x = ', x);*/}
            {/*        setWords(x);*/}
            {/*    }}*/}
            {/*/>*/}

            {/*<ThemesSelectorTool />*/}

            {/*/>*/}

            {/*<TextNlpTool text={`If I have a glass rod as I have here and I rub it then it gets this positive charge that we just discussed. So here is this rod and I rub it with some silk, and it will get positively charged. What happens now to an object that I bring close to this rod and I will start off with taking a conductor. And the reason why I choose a conductor is that conductors have a small fraction of their electrons which are not bound to atoms but which can freely move around in the conductor. That's characteristic for a conductor, for metals. That's not the case with nonconductors. There the all electrons are fixed to individual atoms. So here we have a certain fraction of electrons that can wander around.`}/>*/}

            {/*<div style={{width: 800, height: 600, background: 'whitesmoke'}} >*/}
            {/*    <RowsWrapper  >*/}
            {/*        <Row>*/}
            {/*            <ColsWrapper  >*/}
            {/*                <Col className={'my_col'} />*/}
            {/*                <Col className={'my_col'} />*/}
            {/*            </ColsWrapper>*/}
            {/*        </Row>*/}
            {/*        <Row className={'my_row'} >*/}
            {/*            this is a bottom row*/}
            {/*        </Row>*/}
            {/*    </RowsWrapper>*/}

            {/*    <ColsWrapper >*/}
            {/*        <Col className={'my_col'} >*/}
            {/*            <RowsWrapper >*/}
            {/*                <Row className={'my_row'} />*/}
            {/*                <Row className={'my_row'} />*/}
            {/*            </RowsWrapper>*/}
            {/*        </Col>*/}
            {/*        <Col className={'my_col'} />*/}
            {/*    </ColsWrapper>*/}
            {/*</div>*/}

            {/*<OverlayedTasksEditor*/}

            {/*/>*/}

            {/*<Rnd*/}
            {/*    default={{*/}
            {/*        x: 0,*/}
            {/*        y: 0,*/}
            {/*        width: 320,*/}
            {/*        height: 200,*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <div style={{width: '100%', height: '100%', backgroundColor: 'orange'}} >*/}
            {/*        Rnd*/}
            {/*    </div>*/}
            {/*</Rnd>*/}

            {/*<div style={{width: 820}} >*/}
            {/*    <RoomChatPanel roomId={'sabir'} />*/}
            {/*</div>*/}

            {/*<AccessGroupsDropdownPanel/>*/}

            {/*<QuestionnairePanel*/}
            {/*    id={'BoNbl2zbOw'}*/}
            {/*/>*/}
            {/*<KvdbTasksFeedPanel*/}
            {/*    canEdit={true}*/}
            {/*/>*/}

            {/*<div style={{height: `calc(100vh - 200px)`, width: `calc(100vw - 200px)`}}>*/}
            {/*    <PolotnoTool*/}
            {/*        ref={polotnoRef}*/}
            {/*    />*/}
            {/*</div>*/}

            {/*<button onClick={() => {*/}
            {/*    polotnoRef.current.setImageForEditing('https://www.englishpatient.org/english-patient-files/ad5d70aa-b199-487d-8647-12c49a40697d.jpeg');*/}

            {/*}} >*/}
            {/*    ref ref*/}
            {/*</button>*/}

            {/*<div style={{width: 620, height: 'calc(80vh - 20px)', overflowY: 'hidden'}} >*/}
            {/*    <MaterialSearchPanel*/}
            {/*        type={'cards'}*/}
            {/*    />*/}
            {/*</div>*/}

            {/*<DialogExercisePanel*/}
            {/*    id={'nZHjbTRIqM'}*/}
            {/*/>*/}

            {/*<MosesEditorTool*/}
            {/*    url={'https://www.englishpatient.org/e-patient-videos-converted/12d4ab94-c97a-4fb0-9ff1-aae8cec89c70/12d4ab94-c97a-4fb0-9ff1-aae8cec89c70_480p.mp4'}*/}
            {/*/>*/}

            {/*<AllActiveUsersStatsTool/>*/}

            <input value={text1} onChange={evt => {
                setText1(evt.target.value);
            }}/>

            <input value={text2} onChange={evt => {
                setText2(evt.target.value);
            }}/>

            <pre dangerouslySetInnerHTML={{__html: JSON.stringify(score, null, 2)}}>

            </pre>

            {/*<EmphasisEditor*/}
            {/*    text={'англо-русском словаре, что неизбежно приводит к изучению англо-русского языка.'}*/}
            {/*    positions={positions}*/}
            {/*    onChange={x => {*/}
            {/*        console.log('onChange: x = ', x);*/}
            {/*        setPositions(x);*/}
            {/*    }}*/}
            {/*/>*/}


            {/*<UpdateAvatarTool/>*/}

            {/*<LangSwitcherDropdown/>*/}

            {/*<RealtimeEditor/>*/}
            {/*<SimpleFileUploader/>*/}

            {/*<PrivateContainerFolderSelectorPanel/>*/}

            {/*<ExercisePanel*/}
            {/*    id={'4B6JJSqYAw'}*/}
            {/*/>*/}

            {/*<TextsDiff*/}
            {/*    text1={'test dfd'}*/}
            {/*    text2={'test ttg'}*/}
            {/*/>*/}

            {/*<TestPanel*/}
            {/*    id={'1mBXrPZEsj'}*/}
            {/*/>*/}

            {/*<TestPanel*/}
            {/*    id={'Y8JcU4RonD'}*/}
            {/*/>*/}

            {/*<DrochkaExercisePanel id={'bnosCWJrIf'}/>*/}

            {/*<TranslatableParagraph/>*/}

            {/*<div style={{*/}
            {/*    width: 800*/}
            {/*}} >*/}
            {/*    <YoutubeCutterTool/>*/}
            {/*</div>*/}


            {/*<BlueButton onClick={() => {*/}
            {/*    // let json = polotnoRef.current.getJSON();*/}
            {/*    // console.log('json = ', json);*/}
            {/*    let dataUrl = polotnoRef.current.getDataUrl();*/}
            {/*    console.log('dataUrl = ', dataUrl);*/}
            {/*}}>*/}
            {/*    Export*/}
            {/*</BlueButton>*/}
        </Wrapper>
    );
}

const Wrapper = styled.div`

`;