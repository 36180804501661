import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import TreadmillsAPI from "../../../api/TreadmillsAPI";
import SmartTreadmillPlayerTool from "../treadmill/tools/SmartTreadmillPlayerTool";
import ExercisesAPI from "../../../api/ExercisesAPI";
import exercisesAPI from "../../../api/ExercisesAPI";
import TeacherSettingsAPI from "../../../api/TeacherSettingsAPI";
import {saveBasketItem} from "../../basket/ContentBasketPanel";
import {useMappedState} from "redux-react-hook";
import TreadmillPlayerTool from "../treadmill/tools/TreadmillPlayerTool";

function treadmillResult(answers, data, settings) {
    let textAns = answers.filter(x => x.mark !== undefined);
    let mark = textAns.reduce((sum, x) => (+sum + +x.mark), 0) / textAns.length;
    let mineAns = answers.filter(x => x.hasPassed !== undefined);
    let minesPassed = mineAns.filter(x => x.hasPassed === true);
    let hasPassed = minesPassed.length > 0 || mineAns.length === 0;
    let result = {
        mark: Number.isNaN(mark) ? 0 : mark,
        hasMines: mineAns.length > 0,
        minesAmount: mineAns.length,
        minesPassed: minesPassed.length,
        hasPassed: hasPassed,
        treadmillTime: data.treadmillTime === undefined || data.treadmillTime === '' ? 0 : +data.treadmillTime,
        totalAudioTime: data.totalAudioTime === undefined || data.totalAudioTime === '' ? 0 : +data.totalAudioTime,
        settings: settings,
        date: Date.now()
    }
    return result;
}

const newItems = (data) => {
    if (data == undefined || data.items == undefined || data.items.length == 0) {
        return data
    }

    let items = data.items;
    let newItems = []
    let from = 0;
    for (let item of items) {
        if (item.removeBlock == undefined || item.removeBlock == false) {
            newItems = newItems.concat({
                ...item,
                from: from,
                to: +from + +item.duration
            })
            from += +item.duration
        }
    }
    return {
        ...data,
        items: newItems
    }
}

const getTimesCompleted = (settings) => {
    if (settings == undefined || settings.length == 0) {
        return 0;
    }

    let times = 0;

    for (let setting of settings) {
        times += setting.data == undefined || setting.data.timesCompleted == undefined ? 0 : +setting?.data?.timesCompleted;
    }

    return times;
}

export default function TreadmillPanel(props) {
    const {
        isPreview = false,
        isOnlineMode = false,
        shareMode = false,
        selectedTaskId,
        id,
        userId, // user that answers on this exercise
        setCanClose = d => {

        }
    } = props;

    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [answersLoading, setAnswersLoading] = useState(false);
    const [userAnswers, setUserAnswers] = useState(undefined);
    const [answering, setAnswering] = useState(false);
    const [mineAnswers, setMineAnswers] = useState([]);
    const [showResult, setShowResult] = useState(false);
    const [tries, setTries] = useState(0);
    const [settings, setSettings] = useState();

    const {
        currentUser
    } = useMappedState(useCallback(state => {
        let uId = state.users.currentUserId;
        return {
            currentUser: state.users.usersMap.get(uId)
        }
    }));

    const [isStarted, setIsStarted] = useState(false);

    useEffect(() => {
        setLoading(true);
        let set;
        TreadmillsAPI.getTreadmill(id).then(d => {
            setData(d);
            if (isPreview === true || shareMode === true) {
                setLoading(false);
                return;
            }
            setAnswersLoading(true);
            if (currentUser.userRole !== 'teacher' && selectedTaskId !== undefined) {
                TeacherSettingsAPI.getAllUserSettings(isOnlineMode ? currentUser.id : userId).then(a => {
                    set = a?.filter(x => x.type === `t-${id}-${selectedTaskId}`)[0].data;
                    let allTreadmillSettings = a?.filter(x => x.type.includes(`t-${id}-`));
                    let timesCompleted = getTimesCompleted(allTreadmillSettings);
                    setSettings(set);
                    setTries(timesCompleted);
                    if (timesCompleted >= 2) {
                        setData(newItems(d))
                    }
                });
            } else {
                setSettings(undefined);
            }
            TreadmillsAPI.getTreadmillAnswer(id, isOnlineMode ? currentUser.id : userId).then(ans => {
                setAnswersLoading(false);
                console.log('got: ', ans);
                if (ans !== '' && ans?.options?.items?.length > 0) {
                    setUserAnswers(ans.options);
                } else {
                    setUserAnswers(undefined);
                }
                setLoading(false);
            });
        });
    }, [id]);

    if (data == undefined && loading == true) {
        return (
            <div style={{padding: 10}}>
                <Code/>
            </div>
        )
    }

    if (data == undefined) {
        return null;
    }

    return (
        <Wrapper>
            <SmartTreadmillPlayerTool
                {...data}
                shareMode={shareMode}
                currentUser={currentUser}
                settings={settings}
                userAnswers={userAnswers}
                loading={answering || answersLoading || loading}
                isPreview={isPreview}
                showRes={showResult}
                userId={userId}
                onStart={async () => {
                    setUserAnswers(undefined);
                    setCanClose(false);
                }}
                onAnswer={async d => {

                }}
                allowClosing={() => {
                    setCanClose(true);
                }}
                onFinish={async d => {
                    console.log('SmartTreadmillPlayerTool: onFinish: d = ', d);
                    setAnswering(true);
                    let result = {};
                    if (d.length > 0) {
                        result = treadmillResult(d, data, settings);
                    }
                    setUserAnswers({
                        result: result,
                        items: d.length === 0 ? [{completed: true}] : d
                    });
                    let pld;
                    try {
                        if (settings === undefined) {
                            let data = {
                                attemptsLeft: 0,
                                noLimit: false,
                                recordMines: true,
                                showInLedger: true,
                                timesCompleted: 1
                            }
                            pld = (await TeacherSettingsAPI.saveUserSettings(userId, `t-${id}-${selectedTaskId}`, data)).data;
                            setSettings(pld)
                        } else if (settings?.noLimit === false) {
                            let data = {
                                ...settings,
                                timesCompleted: settings.timesCompleted == undefined ? 1 : +settings?.timesCompleted + 1,
                                attemptsLeft: settings === undefined ? 0 : +settings?.attemptsLeft - 1
                            };
                            pld = (await TeacherSettingsAPI.saveUserSettings(userId, `t-${id}-${selectedTaskId}`, data)).data;
                            setSettings(pld);
                        } else {
                            let data = {
                                ...settings,
                                timesCompleted: settings.timesCompleted == undefined ? 1 : +settings?.timesCompleted + 1
                            }
                            pld = (await TeacherSettingsAPI.saveUserSettings(userId, `t-${id}-${selectedTaskId}`, data)).data;
                            setSettings(pld);
                        }
                        await TreadmillsAPI.saveTreadmillAnswer(id, userId, {
                            result: result,
                            items: d.length === 0 ? [{completed: true}] : d
                        });
                    } catch (exc) {

                    }

                    if (tries + 1 === 2) {
                        setData(newItems(data))
                    }
                    setTries(tries => tries + 1);

                    setAnswering(false);
                }}
                onMarkUpdate={async d => {
                    // console.log('ExerciseViewTool: onMarkUpdate: d = ', d);
                    // setAnswering(true);
                    //
                    // let ans = await ExercisesAPI.updateTeacherAnswer(d);
                    // let newAnswers = userAnswers.filter(xx => (xx.cardId != ans.cardId)).concat([ans]);
                    //
                    // setUserAnswers(newAnswers);
                    // setAnswering(false);
                }}
                onDeleteAnswers={async () => {
                    setAnswering(currentUser.id !== userId);
                    if (currentUser.id !== userId) {
                        let data = (await TeacherSettingsAPI.getUserSettings(userId, `t-${id}-${selectedTaskId}`)).data;
                        if (data !== undefined && (data?.recordMines === true || data?.showInLedger === true)) {
                            data = {
                                ...data,
                                attemptsLeft: 1
                            }
                            await TeacherSettingsAPI.saveUserSettings(userId, `t-${id}-${selectedTaskId}`, data);
                        }
                    }
                    try {
                        await TreadmillsAPI.deleteTreadmillAnswer(id, userId);
                    } catch (exc) {

                    }
                    setUserAnswers(undefined);
                    setAnswering(false);
                }}
            />
        </Wrapper>
    );
}

const StartPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;