import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import SmartQuizQuestionViewer from "./SmartQuizQuestionViewer";

export default function SimpleQuizQuestionsViewer(props) {
    const {
        questions = []
    } = props;

    return (
        <Wrapper>
            <QuestionsList>
                {questions.map((q, i) => {
                    return (
                        <QuestionItem key={q.id}>
                            <div>
                                <SmartQuizQuestionViewer {...q} />
                            </div>
                        </QuestionItem>
                    )
                })}
            </QuestionsList>
        </Wrapper>
    );
}

const QuestionsList = styled.div`

`;

const QuestionItem = styled.div`
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  padding: 10px;
  background: white;
  box-sizing: border-box;
  border-radius: 4px;
`;

const Wrapper = styled.div`

`;