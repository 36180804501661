import {SET_LANGUAGE} from '../ActionTypes';
import ls from 'local-storage'

const initialState = {
    language: ls('language') ? ls('language') : 'RU'
}

const LanguageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.payload
            }
        default:
            return state;
    }
}

export default LanguageReducer;