export const processOptions = (options = {}) => {
    let {
        top = true,
        bottom = true,
        left = true,
        right = true,
        disabled = false,
    } = options;

    if (disabled) {
        top = bottom = left = right = false;
    }

    return {
        top, bottom, left, right,
    };
}