import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import ls from 'local-storage'
import styled from 'styled-components';
import ReactHelper from "../../helpers/ReactHelper";

const getInitialNumber = (defaultNumber = 10) => {
    let num = ls('simple_counter_number');
    if (num == undefined) {
        ls('simple_counter_number', defaultNumber)
        num = defaultNumber;
    }
    return num;
}

export default function CounterExample(props) {
    const {exerciseId = 'sabir'} = props;
    const [startTimestamp, setStartTimestamp] = useState(ls(`counter_${props.exerciseId}`) || +new Date());
    const [updT, setUpdT] = useState(0);

    useEffect(() => {
        let key = `counter_${exerciseId}`;
        let val = ls(key);
        console.log('key, val = ', key, val);
        if (val == undefined){
            let now = +new Date();
            ls(key, now);
            setStartTimestamp(now);
        }else{
            setStartTimestamp(val);
        }
    }, [exerciseId]);

    ReactHelper.useInterval(() => {
        setUpdT(+new Date());
    }, 1000);

    const [number, setNumber] = useState(getInitialNumber(props.number || 10));

    useEffect(() => {
        ls('simple_counter_number', number);
    }, [number]);

    let dt = +new Date() - +startTimestamp;

    return (
        <Wrapper>

            {`DT = ${Math.floor(dt / 1000.0)} seconds`}

            <div>

                {`number: ${number}`}

                <br />
                <span onClick={() => {
                    setNumber(+number + 1);
                }} >
                add
            </span>
            </div>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  padding: 20px;
  font-size: 30px;
`;