import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {Set} from 'immutable'

import downChevron from '../../../assets/img/downArrow.svg'
import ContainerTypeImage from "./ContainerTypeImage";
import ModulesListPanel from "../panels/ModulesListPanel";
import {getSortedSlides} from "../../apps/ShareApp";

export default function RecursiveContainersTree(props) {
    const {

        filteredModulesIds = [],
        searchMode = false,

        showModulesList = true,

        hoverHighlight = true,

        parentContainerId,
        loadingItemId = undefined,
        expandedSet = Set(),
        containers = [],
        getChildContainers = cId => [],
        expandToggle = cId => {

        },
        onContainerClick = cId => {

        },
        renderRightBlockFunction = (cId, parId) => {

        },
        onModuleClick = id => {

        },
        isSharedTree = false
    } = props;

    if (containers == undefined || containers.length == 0) {
        return null;
    }
    let sortedContainers = getSortedSlides(containers);

    console.log('showModulesList = ', showModulesList);
    console.log('RecursiveContainersTree: searchMode = ', searchMode);

    return (
        <Wrapper>

            <ContainersListPlaceholder>
                {sortedContainers.map((c, i) => {
                    let isExpanded = expandedSet.has(c.id);
                    let childContainers = getChildContainers(c.id);
                    let isLoading = (loadingItemId == c.id);
                    return (
                        <ContainerItem
                            loading={isLoading}
                            key={c.id}>
                            <ContainerInfoPlaceholder className={`${hoverHighlight && 'container_info_placeholder'}`}>

                                <ItemCenterNameSection onClick={() => {
                                    if (c.type != 'task') {
                                        expandToggle(c.id);
                                    }
                                }}>
                                    {(c.type == 'block' || (childContainers.length == 0 && (c?.modulesIds == undefined || c?.modulesIds.length == 0)) || (showModulesList == false && childContainers.length == 0)) ? null :
                                        <ChevronImg src={downChevron}
                                                    style={{transform: `rotate(${isExpanded ? 180 : 0}deg)`}}
                                                    onClick={() => {
                                                        if (c.type == 'task') {
                                                            expandToggle(c.id);
                                                        }
                                                    }}
                                        />
                                    }
                                    <ContainerTypeImage {...c} />

                                    <NameTextPlaceholder onClick={() => {
                                        onContainerClick(c?.id);
                                    }}>
                                        {!c?.name ? 'No name' : c?.name}
                                        {((showModulesList == false || searchMode == true) || ((c?.modulesIds == undefined ? [] : c?.modulesIds).length == 0) || (isExpanded == true)) ? null :
                                            <BubbleSpan>
                                                {`${c?.modulesIds.length}`}
                                            </BubbleSpan>
                                        }

                                    </NameTextPlaceholder>
                                </ItemCenterNameSection>

                                <ControlsPlaceholder>
                                    {renderRightBlockFunction(c?.id, parentContainerId)}
                                    {/*<span>
                                        ребята, сюда добавьте кнопку с копированием, выпадающим списком
                                    </span>*/}
                                </ControlsPlaceholder>

                            </ContainerInfoPlaceholder>

                            {isExpanded == false ? null :
                                <ChildrenPlaceholder>

                                    <RecursiveContainersTree
                                        {...props}
                                        parentContainerId={c?.id}
                                        containers={childContainers}
                                    />

                                    {(showModulesList == false || c?.modulesIds == undefined || c?.modulesIds.length == 0) ? null :
                                        <div style={{paddingLeft: 19}}>
                                            <ModulesListPanel
                                                {...c}
                                                modulesIds={searchMode == false ? c.modulesIds : c.modulesIds.filter(xx => (filteredModulesIds.indexOf(xx) > -1))}
                                                isSharedTree={isSharedTree}
                                                onModuleClick={onModuleClick}
                                            />
                                        </div>
                                    }

                                </ChildrenPlaceholder>
                            }

                        </ContainerItem>
                    )
                })}
            </ContainersListPlaceholder>

        </Wrapper>
    );
}

const BubbleSpan = styled.span`
  padding-left: 4px;
  padding-right: 4px;
  color: white;
  font-weight: bold;
  background: #FFA71B;
  border-radius: 4px;
  margin-left: 4px;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Wrapper = styled.div`

`;

const ControlsPlaceholder = styled.div`

`;

const ItemCenterNameSection = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 3px;
`;

const NameTextPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ChevronImg = styled.img`
  margin-right: 5px;
`;

const ContainerInfoPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
`;

const ContainersListPlaceholder = styled.div`

`;

const ContainerItem = styled.div`
  opacity: ${props => (props.loading == true ? 0.7 : 1)};
  margin-bottom: 10px;
  margin-top: 10px;

  .container_info_placeholder {
    :hover {
      background: whitesmoke;
      border-radius: 4px;
    }
  }
`;

const ChildrenPlaceholder = styled.div`
  box-sizing: border-box;
  //padding-left: 20px;
  padding-left: 13px;
  width: 100%;
`;
