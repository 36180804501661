import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import ls from 'local-storage'

import {BlueButton, GreenButton} from "../../ira/ui/Buttons";
import check_img from '../../../assets/img/green_check.svg'
import {translate} from "../../../utils/Translate";
import {getFixedUrl} from "../../exercises/tools/ExerciseCardItemView";

export default function QuestionnaireViewTool(props) {
    const {
        selectedAnswersSet = Set(),
        onSelectAnswer = () => {

        },
        id
    } = props;

    console.log('QuestionnaireViewTool: render: props.questions = ', props.questions);

    const [step, setStep] = useState('initial');
    const [questions, setQuestions] = useState(props.questions || []);
    const [selectedQuestionId, setSelectedQuestionId] = useState((props.questions || []).length == 0 ? undefined : props.questions[0].id);

    let selectedQuestion = (selectedQuestionId == undefined) ? undefined : questions.filter(q => (q.id == selectedQuestionId))[0];

    const getAnswerItemForRender = useCallback((ans, ansId) => {
        let selectedQuestion = (selectedQuestionId == undefined) ? undefined : questions.filter(q => (q.id == selectedQuestionId))[0];
        let isSelected = selectedAnswersSet.has(ansId);
        return (
            <AnswerItem selected={isSelected} key={ansId}>
                <AnswerContentPlaceholder>
                    <AnswerText>
                        {ans?.answer}
                    </AnswerText>
                    <AnswerRuText>
                        {ans?.ruAnswer}
                    </AnswerRuText>
                </AnswerContentPlaceholder>
                <AnswerControlsPlaceholder>
                    {isSelected == false ?
                        <BlueButton onClick={() => {
                            let newSet = selectedAnswersSet;
                            let ansList = selectedQuestion?.answers || [];
                            for (let i in ansList) {
                                let a = ansList[i];
                                let aId = (a.id == undefined) ? `${selectedQuestionId}_${i}` : a.id;
                                newSet = newSet.delete(aId);
                            }
                            newSet = newSet.delete(`${selectedQuestionId}_not_interested`);
                            newSet = newSet.delete(`${selectedQuestionId}_personal_opinion`);
                            newSet = newSet.add(ansId);
                            let arr = newSet.toArray();
                            console.log('arr = ', arr);
                            onSelectAnswer(arr);
                            // this.setState({
                            //     selectedQuestionId: this.getNextQuestionId()
                            // });
                        }}>
                            {translate('select')}
                        </BlueButton> :
                        <div>
                            <CheckIcon src={check_img}/>
                            {/*<span style={{verticalAlign: 'middle'}} >*/}
                            {/*выбрано*/}
                            {/*</span>*/}
                        </div>
                    }
                </AnswerControlsPlaceholder>
            </AnswerItem>
        )
    }, [selectedAnswersSet, selectedQuestionId, step, questions]);

    const isQuestionAnswerSelected = useCallback((q) => {
        let answers = q?.answers || [];
        let res = answers.reduce((f, a, i) => {
            if (f == true) {
                return true
            }
            let aId = (a.id == undefined) ? `${q.id}_${i}` : a.id;
            return selectedAnswersSet.has(aId);
        }, false);
        if (selectedAnswersSet.has(`${q.id}_not_interested`) == true || selectedAnswersSet.has(`${q.id}_personal_opinion`) == true) {
            return true;
        }
        return res;
    }, [selectedAnswersSet])

    const getNextQuestionId = useCallback(() => {
        let n = 0;
        for (let i in questions) {
            if (questions[i].id == selectedQuestionId) {
                n = i;
            }
        }
        if (n < questions.length - 1) {
            return questions[(+n + 1)].id
        }
        return selectedQuestionId;
    }, [selectedQuestionId, questions]);

    const drillsQuestions = useMemo(() => {
        return questions.filter(q => {
            let f = isQuestionAnswerSelected(q);
            if (f == false) {
                return false;
            }
            if (selectedAnswersSet.has(`${q?.id}_not_interested`) == true) {
                return false;
            }
            return true;
        })
    }, [selectedAnswersSet, questions]);

    const isReadyForSecondPhase = useMemo(() => {
        let f = true;
        for (let i in questions) {
            let q = questions[i];
            // if (this.isQuestionAnswerSelected(q) == false){
            if (isQuestionAnswerSelected(q) == true) {
                return true;
                // f = false;
            }
        }
        return false
        // return f;
    }, [questions, selectedAnswersSet]);

    const trainingQuestions = useMemo(() => {
        return questions.filter(q => {
            let f = isQuestionAnswerSelected(q);
            if (f == false) {
                return false;
            }
            if (selectedAnswersSet.has(`${q?.id}_not_interested`) == true) {
                return false;
            }
            return true;
        })
    }, [questions, selectedAnswersSet]);

    const renderTrainingAnswer = useCallback(() => {
        if (selectedQuestion == undefined) {
            return null;
        }
        let answers = (selectedQuestion?.answers || []).filter((ans, k) => selectedAnswersSet.has(`${selectedQuestionId}_${k}`));
        if (answers.length > 0) {
            let ans = answers[0];
            return (
                <div key={selectedQuestionId}>
                    <TrainingAnswer>
                        {ans.answer}
                    </TrainingAnswer>
                    <TrainingRuAnswer>
                        {ans.ruAnswer}
                    </TrainingRuAnswer>
                    <TrainingAudioPlaceholder>
                        <audio controls={true}>
                            <source src={getFixedUrl(ans.audioUrl)}/>
                        </audio>
                    </TrainingAudioPlaceholder>
                </div>
            )
        }
        if (selectedAnswersSet.has(`${selectedQuestionId}_personal_opinion`) == true) {
            return (
                <H3>
                    У вас собственный ответ
                </H3>
            )
        }
        // return null;
        return (
            <H3>
                Вы пропустили данный вопрос.
            </H3>
        )
    }, [selectedAnswersSet, questions, selectedQuestionId]);

    return (
        <Wrapper className={''}>

            <VeryTopPlaceholder>
                {step != 'training' ? null :
                    <div>
                        <BlueButton size={'small'} onClick={() => {
                            setStep('initial');
                        }}>
                            Вернуться к вопросам
                        </BlueButton>
                    </div>
                }
                {step != 'drills' ? null :
                    <div>
                        <BlueButton size={'small'} onClick={() => {
                            setStep('training');
                        }}>
                            Вернуться к подготовке
                        </BlueButton>
                    </div>
                }
            </VeryTopPlaceholder>

            {step != 'initial' ? null :
                <InitialStepPlaceholder>
                    <MiniCardsPlaceholder>
                        {questions.map((q, k) => {
                            let answered = isQuestionAnswerSelected(q);
                            return (
                                <MiniCardItem answered={answered} selected={(q.id == selectedQuestionId)} key={q.id}
                                              onClick={() => {
                                                  setSelectedQuestionId(q?.id);
                                              }}>
                                    {(+k + 1)}
                                    {q?.userAnswer == undefined ? null :
                                        <Circle></Circle>
                                    }
                                </MiniCardItem>
                            )
                        })}
                    </MiniCardsPlaceholder>
                    {selectedQuestion == undefined ? null :
                        <SelectedQuestionPlaceholder key={selectedQuestionId}>
                            <SelectedQuestionQuestion>
                                <H1>
                                    {selectedQuestion?.question}
                                </H1>
                                <H2>
                                    {selectedQuestion?.ruQuestion}
                                </H2>
                            </SelectedQuestionQuestion>
                            <SelectedQuestionVideoPlaceholder>
                                <SelectedQuestionVideo controls={true}>
                                    <source src={getFixedUrl(selectedQuestion?.url)}/>
                                </SelectedQuestionVideo>
                            </SelectedQuestionVideoPlaceholder>

                            <AnswersList>
                                {(selectedQuestion?.answers || []).concat([
                                    {
                                        id: `${selectedQuestionId}_personal_opinion`,
                                        answer: `I have my personal opinion on that.`,
                                        ruAnswer: `У меня есть собственное мнение.`,
                                    },
                                    {
                                        id: `${selectedQuestionId}_not_interested`,
                                        answer: `I'm not interested in that.`,
                                        ruAnswer: `Мне это неинтересно.`,
                                    }
                                ]).map((ans, k) => {
                                    let ansId = (ans.id == undefined) ? `${selectedQuestionId}_${k}` : ans.id;
                                    return getAnswerItemForRender(ans, ansId, k);
                                })}
                            </AnswersList>
                        </SelectedQuestionPlaceholder>
                    }
                </InitialStepPlaceholder>
            }

            {step != 'training' ? null :
                <InitialStepPlaceholder key={selectedQuestionId}>
                    <MiniCardsPlaceholder>
                        {trainingQuestions.map((q, k) => {
                            return (
                                <MiniCardItem selected={(q.id == selectedQuestionId)} key={q.id}
                                              onClick={() => {
                                                  setSelectedQuestionId(q?.id);
                                              }}>
                                    {(+k + 1)}
                                </MiniCardItem>
                            )
                        })}
                    </MiniCardsPlaceholder>

                    <TrainingStepPlaceholder>
                        <Left>
                            <H4>
                                Вопрос
                            </H4>
                            <H3>
                                {selectedQuestion.question}
                            </H3>
                            <MiniVideoPlaceholder>
                                <SelectedQuestionVideo controls={true}>
                                    <source src={getFixedUrl(selectedQuestion.url)}/>
                                </SelectedQuestionVideo>
                            </MiniVideoPlaceholder>
                        </Left>
                        <Right>
                            <H4>
                                Ответ
                            </H4>
                            <div>
                                {renderTrainingAnswer()}
                            </div>
                        </Right>
                    </TrainingStepPlaceholder>

                </InitialStepPlaceholder>
            }

            {step != 'drills' ? null :
                <InitialStepPlaceholder key={selectedQuestionId}>
                    <MiniCardsPlaceholder>
                        {drillsQuestions.map((q, k) => {
                            return (
                                <MiniCardItem answered={q?.userAnswer != undefined}
                                              selected={(q?.id == selectedQuestionId)} key={q.id}
                                              onClick={() => {
                                                  setSelectedQuestionId(q?.id);
                                              }}>
                                    {(+k + 1)}
                                </MiniCardItem>
                            )
                        })}
                    </MiniCardsPlaceholder>

                    <SelectedQuestionQuestion>
                        <H1>
                            {selectedQuestion?.question}
                        </H1>
                        <H2>
                            {selectedQuestion?.ruQuestion}
                        </H2>
                    </SelectedQuestionQuestion>
                    <SelectedQuestionVideoPlaceholder>
                        <SelectedQuestionVideo controls={true}>
                            <source src={getFixedUrl(selectedQuestion?.url)}/>
                        </SelectedQuestionVideo>
                    </SelectedQuestionVideoPlaceholder>

                    <UserRecordingPlaceholder key={selectedQuestionId}>
                        {/*<QuestionnaireUserAnswerPanel questionId={selectedQuestionId} userId={userId}*/}
                        {/*                              onUserAnswerSaved={(userAnswer) => {*/}
                        {/*                                  console.log('onUserAnswerSaved: userAnswer = ', userAnswer);*/}
                        {/*                                  onUserAnswerSaved(userAnswer);*/}
                        {/*                              }}/>*/}
                    </UserRecordingPlaceholder>

                </InitialStepPlaceholder>
            }

            <StepsSwitcherPlaceholder>
                {((isReadyForSecondPhase == false) && (step == 'initial')) ?
                    <H2>
                        Ответьте хотя бы на один вопрос, после чего вы сможете перейти к следующей фазе.
                    </H2>
                    :
                    <div>
                        {step != 'initial' ? null :
                            <GreenButton onClick={() => {
                                let trQuestions = trainingQuestions;
                                let selId = (trQuestions.length == 0) ? undefined : trQuestions[0].id;
                                setSelectedQuestionId(selId);
                                setStep('training');
                            }}>
                                Перейти к подготовке
                            </GreenButton>
                        }
                    </div>
                }


                {step != 'training' ? null :
                    <div>
                        <GreenButton onClick={() => {
                            let trQuestions = trainingQuestions;
                            let selId = (trQuestions.length == 0) ? undefined : trQuestions[0].id;
                            setStep('drills');
                            setSelectedQuestionId(selId);
                        }}>
                            Перейти к тестированию
                        </GreenButton>
                    </div>
                }
            </StepsSwitcherPlaceholder>

        </Wrapper>
    );
}

const MiniCardsPlaceholder = styled.div`
  text-align: center;
  margin-bottom: 5px;
`;

const CheckIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const Circle = styled.div`
  //width: 5px;
  height: 4px;
  border-radius: 1000px;
  background-color: firebrick;
  position: absolute;
  top: -3px;
  right: 0px;
  left: 0px;
`;

const SelectedQuestionPlaceholder = styled.div`

`;

const UserRecordingPlaceholder = styled.div`
  padding-top: 5px;
`;

const StepsSwitcherPlaceholder = styled.div`
  text-align: center;
  padding: 10px;
`;

const VeryTopPlaceholder = styled.div`
  text-align: center;
  margin-bottom: 5px;
`;

const MiniCardItem = styled.div`
  display: inline-block;
  vertical-align: top;
  padding: 4px;
  box-sizing: border-box;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  width: 28px;
  height: 28px;
  margin: 1px;
  margin-bottom: 4px;
  border: 1px solid whitesmoke;
  border-radius: 3px;
  position: relative;
  background-color: ${props => (props.selected == true ? '#0066cc' : (props.answered == true ? 'green' : 'white'))};
  color: ${props => ((props.selected == true || props.answered == true) ? 'white' : 'black')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
`;

const AnswersList = styled.div`
  margin-top: 10px;
`;

const SelectedQuestionQuestion = styled.div`
  padding-top: 10px;
`;

const SelectedQuestionVideoPlaceholder = styled.div`
  width: 100%;
  height: 360px;
  background-color: black;
`;

const SelectedQuestionVideo = styled.video`
  width: 100%;
  height: 100%;
`;

const H1 = styled.div`
  line-height: 24px;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
`;

const H2 = styled.div`
  line-height: 16px;
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
`;

const AnswerItem = styled.div`
  position: relative;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid lightgrey;
  margin-bottom: 5px;
  background-color: ${props => (props.selected == true ? 'whitesmoke' : 'white')};
`;

const AnswerContentPlaceholder = styled.div`
  display: inline-block;
  vertical-align: top;
  height: 100%;
  width: calc(100% - 180px);
  box-sizing: border-box;
`;

const AnswerControlsPlaceholder = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 180px;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  text-align: right;
`;

const AnswerText = styled.div`
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const AnswerRuText = styled.div`
  font-size: 14px;
  font-style: italic;
`;

const Wrapper = styled.div`
  position: relative;
  padding: 10px;
  background-color: white;
`;

const Left = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  box-sizing: border-box;
  padding-right: 5px;
  border-right: 1px solid whitesmoke;
`;

const Right = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  box-sizing: border-box;
  padding-left: 5px;
`;

const MiniVideoPlaceholder = styled.div`
  width: 100%;
  height: 200px;
  background-color: black;
`;

const H3 = styled.div`
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
`;

const H4 = styled.div`
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
  border-bottom: 1px solid whitesmoke;
`;

const TrainingStepPlaceholder = styled.div`
  padding-top: 10px;
`;

const InitialStepPlaceholder = styled.div`

`;

const TrainingAnswer = styled.div`
  margin-bottom: 10px;
`;

const TrainingRuAnswer = styled.div`
  margin-bottom: 10px;
  font-style: italic;
  opacity: 0.7;
`;

const TrainingAudioPlaceholder = styled.div`

`;