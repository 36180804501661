import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import {Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import ls from 'local-storage'

import small_green_check from '../../../assets/img/green_check.svg'
import drochka_ex_pic from '../../../assets/img/block_card.svg'
import ex_pic from '../../../assets/img/block_exercise.svg'
import test_pic from '../../../assets/img/block_text.svg'

import woman_waiting_json from '../../../assets/img/woman_waiting.json'

import * as usersActions from '../../../redux/actions/UsersActions'
import * as drochkaExercisesActions from '../../../redux/actions/DrochkaExercisesActions'
import * as exercisesActions from '../../../redux/actions/ExercisesActions'
import * as testsActions from '../../../redux/actions/TestsActions'
import * as groupsActions from '../../../redux/actions/GroupsActions'
import * as lessonsActions from '../../../redux/actions/LessonsActions'
import * as tasksActions from '../../../redux/actions/TasksActions'
import CommonHelper from "../../../helpers/CommonHelper";
import GroupStatsAPI from "../../../api/GroupStatsAPI";
import downChevron from "../../../assets/img/downArrow.svg";
import NiceModal from "../../modals/NiceModal";
import ExercisePanel from "../../exercises/panels/ExercisePanel";
import DrochkaExercisePanel from "../../drochka_exercise/panels/DrochkaExercisePanel";
import SimpleUserViewTool from "../tools/SimpleUserViewTool";
import TasksHelper from "../../../helpers/TasksHelper";
import LottieLoader from "../../loaders/LottieLoader";
import {translate} from "../../../utils/Translate";

let gn = (x, fld) => (x == undefined || x[fld] == undefined ? [] : x[fld]);

export default function GroupLedgerPanel(props) {
    const {id} = props;
    const dispatch = useDispatch();


    const [expandedLessonsSet, setExpandedLessonsSet] = useState((ls(`ledger_${id}_lessons_expanded`) ? ls(`ledger_${id}_lessons_expanded`) : []).reduce((st, exId) => st.add(exId), Set()));
    const [expandedTasksSet, setExpandedTasksSet] = useState((ls(`ledger_${id}_tasks_expanded`) ? ls(`ledger_${id}_tasks_expanded`) : []).reduce((st, exId) => st.add(exId), Set()));

    const [data, setData] = useState(undefined);
    const [selectedUserId, setSelectedUserId] = useState(undefined);
    const [selectedExerciseId, setSelectedExerciseId] = useState();
    const [selectedDrochkaExerciseId, setSelectedDrochkaExerciseId] = useState();

    const {
        group,
        loading,
        lessons,
        getLessonTasks,
        exercisesMap,
        drochkaExercisesMap,
        testsMap,
        allTasks,
        students
    } = useMappedState(useCallback(state => {
        let group = state.groups.groupsMap.get(id);
        let students = (group?.users || []).map(xx => state.users.usersMap.get(xx?.id)).filter(xx => (xx != undefined));
        return {
            group: state.groups.groupsMap.get(id),
            loading: state.groups.loading || state.users.loading || state.lessons.loading || state.tasks.loading || state.users.loading,
            lessons: state.lessons.lessonsMap.toArray().filter(x => (x.classId == id)).sort((a, b) => (+b.timestamp - +a.timestamp)),
            getLessonTasks: lId => state.tasks.tasksMap.toArray().filter(xx => (xx.lessonId == lId)).sort((a, b) => (+a.timestamp - +b.timestamp)),
            exercisesMap: state.exercises.exercisesMap,
            drochkaExercisesMap: state.drochkaExercises.drochkaExercisesMap,
            testsMap: state.tests.testsMap,
            allTasks: state.tasks.tasksMap.toArray().filter(xx => (xx.classId == id)),
            students: students
        }
    }));

    useEffect(() => {
        dispatch(groupsActions.loadGroupById(id)).then(pld => {
            console.log('-->> loadGroupById: pld = ', pld);
            if (pld?.groups != undefined && pld?.groups[0] != undefined) {
                let studentsIds = (pld?.groups[0].users || []).map(xx => xx.id);
                console.log('-->>> studentsIds = ', studentsIds);
                dispatch(usersActions.loadUsersByIds(studentsIds));
            }
        });
        dispatch(lessonsActions.getGroupLessons(id)).then(pld => {
            setExpandedLessonsSet((pld?.lessons || []).reduce((st, xx) => st.add(xx.id), Set()))
        });
        dispatch(tasksActions.getGroupTasks(id)).then(pld => {
            let {tasks = []} = pld;
            let allExercisesIds = tasks
                .reduce((arr, tsk) => arr.concat(tsk?.exercisesIds || tsk?.exercisesIds), [])
                .reduce((st, exId) => st.add(exId), Set()).toArray();
            let allDrochkaExercisesIds = tasks
                .reduce((arr, tsk) => arr.concat(tsk?.drochkaExercisesIds || tsk?.drochkaExercisesIds), [])
                .reduce((st, exId) => st.add(exId), Set()).toArray();
            let allTestsIds = tasks
                .reduce((arr, tsk) => arr.concat(tsk?.testsIds || tsk?.testsIds), [])
                .reduce((st, exId) => st.add(exId), Set()).toArray();
            dispatch(drochkaExercisesActions.getExercisesByIds(allDrochkaExercisesIds));
            dispatch(exercisesActions.getExercisesByIds(allExercisesIds));
            dispatch(testsActions.getTestsByIds(allTestsIds));
        });
    }, [id]);

    useEffect(() => {
        ls(`ledger_${id}_tasks_expanded`, expandedTasksSet.toArray());
    }, [expandedTasksSet]);

    useEffect(() => {
        ls(`ledger_${id}_lessons_expanded`, expandedLessonsSet.toArray());
    }, [expandedLessonsSet]);

    useEffect(() => {
        if (id != undefined) {
            GroupStatsAPI.getGroupAnswersMap(id).then(x => {
                console.log('ans data = ', x);
                setData(x);
            })
        }
    }, [id]);

    if (group == undefined) {
        return (
            <Code/>
        )
    }

    // let students = (group?.users == undefined) ? [] : group?.users;
    console.log('students = ', students);
    console.log('--->>> data = ', data);
    let selectedExercise = exercisesMap.get(selectedExerciseId);
    let selectedDrochkaExercise = drochkaExercisesMap.get(selectedDrochkaExerciseId);
    let selectedUser = (selectedUserId == undefined) ? undefined : (group?.users || []).filter(a => (a.id == selectedUserId))[0];

    if (loading == true) {
        return (
            <div style={{width: '100%', height: `calc(100vh - 120px)`, backgroundColor: 'white'}}>
                <LottieLoader json={woman_waiting_json}/>
            </div>
        )
    }

    return (
        <Wrapper>

            <LeftPlaceholder>
                <TopPlaceholder>
                    <LeftWideBox style={{height: 90}}>
                        {`Уроков: ${lessons.length}`}
                        <br/>
                        {`Заданий: ${allTasks.length}`}
                        <br/>
                        {`Учеников: ${students.length}`}
                    </LeftWideBox>
                </TopPlaceholder>

                <LessonsList>
                    {lessons.map((lesson, i) => {
                        let isExpanded = (expandedLessonsSet.has(lesson?.id));
                        let tasks = getLessonTasks(lesson?.id);
                        return (
                            <LessonRow key={`${lesson?.id}_${i}`} expanded={isExpanded}>
                                <LessonTopPlaceholder>
                                    <LeftWideBox
                                        style={{height: 40}}
                                        onClick={() => {
                                            if (isExpanded == true) {
                                                setExpandedLessonsSet(expandedLessonsSet.delete(lesson?.id));
                                            } else {
                                                setExpandedLessonsSet(expandedLessonsSet.add(lesson?.id));
                                            }
                                        }}>
                                        <LessonNamePlaceholder>
                                            {tasks.length == 0 ? null :
                                                <ChevronImg src={downChevron}
                                                            style={{transform: `rotate(${isExpanded ? 180 : 0}deg)`}}
                                                />
                                            }
                                            <LessonName>
                                                {lesson?.name || translate('no_name')}
                                            </LessonName>
                                        </LessonNamePlaceholder>
                                    </LeftWideBox>

                                </LessonTopPlaceholder>

                                {isExpanded == false ? null :
                                    <LessonTasksPlaceholder>

                                        {tasks.map((task, j) => {
                                            let isExpanded = (expandedTasksSet.has(task?.id));
                                            let {exercisesIds = [], testsIds = [], drochkaExercisesIds = []} = task;
                                            let exercisesItems = exercisesIds.map(x => ({id: x, type: 'exercise'}))
                                                .concat(drochkaExercisesIds.map(x => ({id: x, type: 'drochka'})))
                                                .concat(testsIds.map(x => ({id: x, type: 'test'})));

                                            return (
                                                <TaskRow key={`task_${task.id}_${j}`} expanded={isExpanded}>
                                                    <TaskTopPlaceholder>
                                                        <LeftWideBox
                                                            style={{paddingLeft: 10, height: 40}}
                                                            onClick={() => {
                                                                if (isExpanded == true) {
                                                                    setExpandedTasksSet(expandedTasksSet.delete(task?.id));
                                                                } else {
                                                                    setExpandedTasksSet(expandedTasksSet.add(task?.id));
                                                                }
                                                            }}>
                                                            <TaskNamePlaceholder>
                                                                {exercisesItems.length == 0 ? null :
                                                                    <ChevronImg src={downChevron}
                                                                                style={{transform: `rotate(${isExpanded ? 180 : 0}deg)`}}
                                                                    />
                                                                }
                                                                <TaskName
                                                                    style={{cursor: (exercisesItems.length > 0 ? 'pointer' : 'default')}}>
                                                                    {task?.name || translate('no_name')}
                                                                </TaskName>
                                                            </TaskNamePlaceholder>


                                                        </LeftWideBox>
                                                    </TaskTopPlaceholder>

                                                    {isExpanded == false ? null :
                                                        <TaskExercisesPlaceholder>
                                                            {exercisesItems.map((item, z) => {
                                                                let pic = ex_pic;
                                                                let ex = (item.type == 'exercise') ? exercisesMap.get(item.id) : undefined;
                                                                if (item.type == 'drochka') {
                                                                    ex = drochkaExercisesMap.get(item.id);
                                                                    pic = drochka_ex_pic;
                                                                }
                                                                if (item.type == 'test') {
                                                                    ex = testsMap.get(item.id);
                                                                    pic = test_pic;
                                                                }
                                                                return (
                                                                    <ExerciseRow key={`${item.type}_${item.id}`}>

                                                                        <LeftWideBox
                                                                            style={{paddingLeft: 25, height: 40}}
                                                                            onClick={() => {

                                                                            }}>
                                                                            <ExerciseName onClick={() => {
                                                                                if (item.type == 'test') {
                                                                                    CommonHelper.linkTo(`/tests/${item.id}`, true);
                                                                                }
                                                                                if (item.type == 'exercise') {
                                                                                    setSelectedExerciseId(item.id);
                                                                                }
                                                                                if (item.type == 'drochka') {
                                                                                    setSelectedDrochkaExerciseId(item.id);
                                                                                }
                                                                            }}>
                                                                                <ExerciseTypePic src={pic}/>
                                                                                <span>
                                                                                    {ex?.name || item?.id}
                                                                                </span>
                                                                            </ExerciseName>
                                                                        </LeftWideBox>

                                                                    </ExerciseRow>
                                                                )
                                                            })}
                                                        </TaskExercisesPlaceholder>
                                                    }

                                                </TaskRow>
                                            )
                                        })}

                                    </LessonTasksPlaceholder>
                                }

                            </LessonRow>
                        )
                    })}
                </LessonsList>

            </LeftPlaceholder>

            <RightPlaceholder>
                <RightPlaceholderInner>
                    <RightPlaceholderInnerScrollable>

                        <div style={{width: students.length * boxWidth}}>

                            <TopPlaceholder>
                                {students.map((a, i) => {
                                    return (
                                        <Box key={a.id}
                                             style={{height: 90, cursor: 'pointer'}}
                                             onClick={() => {
                                                 CommonHelper.linkTo(`/groups/${id}/students/${a.id}`, true);
                                             }}
                                        >
                                            <SimpleUserViewTool {...a}/>
                                        </Box>
                                    )
                                })}
                            </TopPlaceholder>

                            <LessonsList>
                                {lessons.map((lesson, i) => {
                                    let isExpanded = (expandedLessonsSet.has(lesson?.id));
                                    let tasks = getLessonTasks(lesson?.id);

                                    return (
                                        <LessonRow key={`${lesson?.id}_${i}`} expanded={isExpanded}>
                                            <LessonTopPlaceholder>

                                                {students.map((a, j) => {

                                                    return (
                                                        <Box key={a.id}>
                                                            {''}
                                                        </Box>
                                                    )
                                                })}

                                            </LessonTopPlaceholder>

                                            {isExpanded == false ? null :
                                                <LessonTasksPlaceholder>

                                                    {tasks.map((task, j) => {
                                                        let isExpanded = (expandedTasksSet.has(task?.id));
                                                        let {
                                                            exercisesIds = [],
                                                            testsIds = [],
                                                            drochkaExercisesIds = []
                                                        } = task;
                                                        let exercisesItems = exercisesIds.map(x => ({
                                                            id: x,
                                                            type: 'exercise'
                                                        }))
                                                            .concat(drochkaExercisesIds.map(x => ({
                                                                id: x,
                                                                type: 'drochka'
                                                            })))
                                                            .concat(testsIds.map(x => ({id: x, type: 'test'})));

                                                        return (
                                                            <TaskRow key={`task_${task.id}_${j}`} expanded={isExpanded}>
                                                                <TaskTopPlaceholder>

                                                                    {students.map((a, j) => {
                                                                        let avr = TasksHelper.getTaskAverageMark(data, task, a.id);

                                                                        return (
                                                                            <Box key={`${a.id}_${task.id}`}>
                                                                                {/*{`${avr}`}*/}
                                                                                {avr == 0 ? '-' :
                                                                                    <span style={{fontWeight: 'bold'}}>
                                                                                        {avr < 0 ? <span
                                                                                            style={{fontSize: 10}}>{'✔️'}</span> : `${(+avr).toFixed(1)}`}
                                                                                    </span>
                                                                                }
                                                                            </Box>
                                                                        )
                                                                    })}

                                                                </TaskTopPlaceholder>

                                                                {isExpanded == false ? null :
                                                                    <TaskExercisesPlaceholder>
                                                                        {exercisesItems.map((item, z) => {
                                                                            return (
                                                                                <ExerciseRow
                                                                                    key={`${item.type}_${item.id}`}>

                                                                                    {students.map((a, j) => {
                                                                                        let arr = (data == undefined || data[a.id] == undefined) ? [] : data[a.id];
                                                                                        let mark = arr.filter(xx => (xx?.exerciseId == item?.id))[0];
                                                                                        let isNegative = (mark?.mark != undefined && mark?.mark < 0);
                                                                                        return (
                                                                                            <Box key={a.id}>
                                                                                                <span onClick={() => {
                                                                                                    if (mark == undefined) {
                                                                                                        return;
                                                                                                    }
                                                                                                    if (item.type == 'test') {
                                                                                                        CommonHelper.linkTo(`/tests/${item.id}/${a.id}`, true);
                                                                                                    }
                                                                                                    if (item.type == 'exercise') {
                                                                                                        setSelectedExerciseId(item.id);
                                                                                                    }
                                                                                                    if (item.type == 'drochka') {
                                                                                                        setSelectedDrochkaExerciseId(item.id);
                                                                                                    }
                                                                                                    setSelectedUserId(a.id);
                                                                                                }}>
                                                                                                    {isNegative ?
                                                                                                        <CheckImg
                                                                                                            src={small_green_check}/> :
                                                                                                        <span>
                                                                                                        {(mark?.mark) ?
                                                                                                            <MarkSpan>{(+mark?.mark).toFixed(1)}</MarkSpan> : '-'}
                                                                                                    </span>
                                                                                                    }
                                                                                                </span>
                                                                                            </Box>
                                                                                        )
                                                                                    })}

                                                                                </ExerciseRow>
                                                                            )
                                                                        })}
                                                                    </TaskExercisesPlaceholder>
                                                                }

                                                            </TaskRow>
                                                        )
                                                    })}

                                                </LessonTasksPlaceholder>
                                            }

                                        </LessonRow>
                                    )
                                })}
                            </LessonsList>

                        </div>

                    </RightPlaceholderInnerScrollable>

                </RightPlaceholderInner>

            </RightPlaceholder>

            {selectedExercise == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedExerciseId(undefined);
                }}>

                    <SelectedExerciseModalInner>

                        {selectedUser == undefined ? null :
                            <SelectedUserPlaceholder>
                                <SimpleUserViewTool
                                    {...selectedUser}
                                />
                            </SelectedUserPlaceholder>
                        }

                        <ExercisePanel
                            {...selectedExercise}
                            userId={selectedUserId}
                        />
                    </SelectedExerciseModalInner>

                </NiceModal>
            }

            {selectedDrochkaExercise == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedDrochkaExerciseId(undefined);
                }}>

                    <SelectedExerciseModalInner>

                        {selectedUser == undefined ? null :
                            <SelectedUserPlaceholder>
                                <SimpleUserViewTool
                                    {...selectedUser}
                                />
                            </SelectedUserPlaceholder>
                        }

                        <DrochkaExercisePanel
                            {...selectedDrochkaExercise}
                            userId={selectedUserId}
                        />
                    </SelectedExerciseModalInner>

                </NiceModal>
            }

        </Wrapper>
    );
}

const boxWidth = 140;

const SelectedUserPlaceholder = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid lightgrey;
`;

const SelectedExerciseModalInner = styled.div`
  width: 820px;
  box-sizing: border-box;
  max-height: calc(90vh - 40px);
  overflow-y: auto;
`;

const CheckImg = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const MarkSpan = styled.span`
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const ChevronImg = styled.img`
  margin-right: 5px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const ExerciseTypePic = styled.img`
  margin-right: 5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  opacity: 0.7;

  :hover {
    opacity: 0.9;
  }
`;

const ExerciseName = styled.div`
  font-size: 11px;
  line-height: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const RightPlaceholderInner = styled.div`
  width: 100%;
  box-sizing: border-box;
  //position: relative;
  //background: wheat;
  //max-width: 100%;
  //overflow-x: auto;
  position: absolute;
`;

const RightPlaceholderInnerScrollable = styled.div`
  position: relative;
  max-width: 100%;
  overflow-x: auto;
`;

const LeftPlaceholder = styled.div`
  width: 240px;
  box-sizing: border-box;
`;

const RightPlaceholder = styled.div`
  flex: 1;
  position: relative;
`;

const LessonName = styled.div`
  font-weight: bold;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const LessonNamePlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding-left: 5px;
`;

const TaskNamePlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TaskName = styled.div`
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;


const Wrapper = styled.div`
  background: white;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ExerciseRow = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  :hover {
    background: whitesmoke;
  }
`;

const TaskExercisesPlaceholder = styled.div`
  box-sizing: border-box;
`;

const TaskRow = styled.div`

`;

const LessonsList = styled.div`

`;

const LessonTasksPlaceholder = styled.div`

`;

const LessonTopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-sizing: border-box;

  border-bottom: 1px dashed whitesmoke;
`;

const TaskTopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
`;

const LessonRow = styled.div`
  box-sizing: border-box;
  margin-bottom: 5px;
  width: 100%;
  border: ${props => (props.expanded == true ? '1px dotted whitesmoke' : 'none')};
  background: ${props => (props.expanded == true ? '#f5f5f55c' : 'transparent')};
`;

const UserName = styled.div`
  box-sizing: border-box;
  text-align: center;
  font-size: 12px;
  padding: 3px;

`;

const UserAvatar = styled.div`
  border-radius: 1000px;
  box-sizing: border-box;
  width: 45px;
  height: 45px;
  background: whitesmoke;
  border: 2px solid lightgrey;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${props => props.url});
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 5px;
  border-bottom: 1px solid whitesmoke;
`;

const LeftWideBox = styled.div`
  width: 240px;
  min-width: 240px;
  max-width: 60px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid whitesmoke;
`;

const Box = styled.div`
  width: ${boxWidth}px;
  min-width: ${boxWidth}px;
  overflow: hidden;

  height: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;
