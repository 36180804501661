import React, {useRef, useEffect, useState} from 'react';

import axios from 'axios'
import styled from 'styled-components'
import Sidebar from 'arui-feather/sidebar'

import SmartFirebaseAPI from "../../api/SmartFirebaseAPI";
import ChambersEditor from "./tools/ChambersEditor";
import Select from 'react-select'
import moment from "moment";

import online_courses_and_tutorials from '../../assets/lottie/online-courses-and-tutorials.json'
import end_call from '../../assets/img/end_call.svg'
import lesson_image from '../../assets/img/mode_lesson.svg'
import editor_image from '../../assets/img/editor.svg'
import chambers_image from '../../assets/img/chambers.svg'
import whiteboard_image from '../../assets/img/whiteboard.svg'
import square_image from '../../assets/img/square.png'
import square_image_check from '../../assets/img/check-square_.png'

import close_img from '../../assets/img/black_close.svg'
import purp_chat_img from '../../assets/img/purp_chat.svg'

import SyncDrawableImage from "../canvas/SyncDrawableImage";

import SimpleInteractiveCommutePanel from "../commute/panels/SimpleInteractiveCommutePanel";
import LottieLoader from "../loaders/LottieLoader";
import CommonHelper from "../../helpers/CommonHelper";
import {API_ENDPOINT} from "../../constants/config";
import LessonTasksEditor from "../lessons/tools/LessonTasksEditor";
import FreeSyncVideoPlayer from "../players/FreeSyncVideoPlayer";
import RealtimeEditor from "../tiny/RealtimeEditor";
import Tabs, {TabItem} from "../ui/Tabs";
import DeadlineCountdownPanel from "../countdown/panels/DeadlineCountdownPanel";
import DeadlineCountdownEditorPanel from "../countdown/panels/DeadlineCountdownEditorPanel";
import KvdbTasksFeedPanel from "./panels/KvdbTasksFeedPanel";
import NiceModal from "../modals/NiceModal";
import RoomChatPanel from "../chat/panels/RoomChatPanel";

import './style.css'

import ls from 'local-storage'

import forest_img from '../../assets/img/forest_background.jpg'

import {Rnd} from 'react-rnd';
import ReactHelper from "../../helpers/ReactHelper";

export const getOnlineName = () => {
    console.log('getOnlineName occured! ');
    let s = `АНГЛИЙСКИЙ ПАЦИЕНТ`;
    try {
        let href = window.location.href;
        if (href.indexOf('ru-tsu') > -1 || href.indexOf('rus') > -1) {
            s = 'SpeakRus'
        }
    } catch (exc) {

    }
    return s;
}

const getPosSettingsByName = name => {
    let val = ls(`rnd_${name}`);
    if (val == undefined) {
        if (name == 'working_area') {
            return {
                width: 720,
                minWidth: 620,
                height: window.innerHeight,
                x: 70,
                y: 0
            }
        }
        if (name == 'jitsi') {
            return {
                width: window.innerWidth - 70 - 720,
                minWidth: 400,
                minHeight: 300,
                height: window.innerHeight * 0.65,
                x: 800,
                y: 0
            }
        }
        if (name == 'chat') {
            return {
                width: (window.innerWidth - 70 - 720) * 0.6,
                minWidth: 240,
                minHeight: window.innerHeight * 0.3,
                height: window.innerHeight * 0.3,
                x: 800,
                y: window.innerHeight * 0.7
            }
        }
        if (name == 'sync_player') {
            return {
                width: (window.innerWidth - 70 - 720) * 0.6,
                minWidth: 400,
                minHeight: window.innerHeight * 0.3,
                height: window.innerHeight * 0.7,
                x: 800,
                y: window.innerHeight * 0.1
            }
        }
    }
    return val;
}

const setPosSettingsByName = (name, d) => {
    console.log('setPosSettingsByName: name, d = ', name, d);
    let val = getPosSettingsByName(name);
    val = JSON.parse(JSON.stringify(val));
    console.log('old val = ', val);
    for (let key in d) {
        let v = d[key];
        v = `${v}`.replace('px', '');
        console.log('key, v = ', key, v);
        if (v != undefined && +v > 0) {
            val[key] = +v;
        }
    }
    console.log('-->> val = ', val);
    ls(`rnd_${name}`, val);
    return val;
}

const hideWidget = () => {
    try {
        let items = document.getElementsByClassName('b24-widget-button-wrapper');
        for (let i in items) {
            items[i].style.display = 'none';
        }
    } catch (exc) {

    }

}

const OnlineLesson = (props) => {

    const {
        currentUser,
        allLessons = [],
        allTasks = [],
        groupId,
        onCurrentLessonChange = newId => {

        },
        updT = 0
    } = props;

    // let {currentUser} = useMappedState(useCallback(state => {
    //     let currentUserId = state.users.currentUserId;
    //     let currentUser = state.users.usersMap.get(currentUserId);
    //     return {
    //         currentUser: currentUser
    //     }
    // }, []));
    const lessonsOptions = allLessons.map(a => ({
        value: a.id,
        label: `[${moment(a.startTimestamp).format('DD.MM.YYYY HH:mm')}]: ${a.name}`
    }));
    console.log('lessons options = ', lessonsOptions);

    // const currentUser = useSelector(state => state.userReducer.user);

    const roomName = `room_${groupId}`;

    const [loaded, setLoaded] = useState(false);
    const [groupInfo, setGroupInfo] = useState(undefined);
    // const [allLessons, setAllLessons] = useState([])
    const [chambersEditorVisible, setChambersEditorVisible] = useState(false);
    // const dispatch = useDispatch();
    const [fakeLoaderVisible, setFakeLoaderVisible] = useState(true);
    let currentUserId = currentUser?.id;
    let userRole = currentUser?.userRole;
    let isTeacher = (userRole == 'teacher');
    useEffect(() => {
        hideWidget();
        SmartFirebaseAPI.initFirebase();
        axios.get(`${API_ENDPOINT}/classes/${groupId}`).then(d => d.data).then(x => {
            setGroupInfo(x);
            setLoaded(true);
            SmartFirebaseAPI.subscribeLessonZoomOnChanges(roomName, chData => {
                if (chData == undefined) {
                    return;
                }
                console.log('-> CHANGE! chData = ', chData);
                setZoomState(chData);
                onCurrentLessonChange(chData?.lessonSettings?.currentLessonId)
            }).then(d => {
                if (d == undefined) {
                    setLoaded(true);
                    setTimeout(() => {
                        setFakeLoaderVisible(false);
                    }, 2800);
                    return;
                }
                setZoomState(d);
                setLoaded(true);
                onCurrentLessonChange(d?.lessonSettings?.currentLessonId)
                setTimeout(() => {
                    setFakeLoaderVisible(false);
                }, 2800);
            });
        });
        // lessonAPI.getLessons(groupId).then(d => {
        //     setAllLessons(d.data.result)
        // })
        // if (!isTeacher) {
        //     dispatch(getAllAnswers(currentUserId))
        // }
        return () => {
            SmartFirebaseAPI.unsubscribeFromLessonZoomChanges(roomName);
        }
    }, [groupId]);
    const [zoomState, setZoomState] = useState(undefined);

    let chambers = (zoomState == undefined || zoomState.chambers == undefined) ? [] : zoomState.chambers;
    let chamberMode = (zoomState == undefined || zoomState.chamberMode == undefined) ? false : zoomState.chamberMode;

    let activeTab = (zoomState == undefined || zoomState.lessonSettings == undefined) ? 'whiteboard' : zoomState.lessonSettings.activeTab;
    let openEyesIds = (zoomState == undefined || zoomState.lessonSettings == undefined || zoomState.lessonSettings.openEyesIds == undefined) ? [] : zoomState.lessonSettings.openEyesIds;


    let lessonSettings_ = (zoomState == undefined || zoomState.lessonSettings == undefined) ? {} : zoomState.lessonSettings;

    let endOnlineLesson = (zoomState == undefined || zoomState.lessonSettings == undefined) ? false : zoomState.lessonSettings.endOnlineLesson;
    let currentLesson = (zoomState?.lessonSettings?.currentLessonId) ? allLessons.filter(xx => (xx.id == zoomState?.lessonSettings?.currentLessonId))[0] : allLessons && allLessons.length !== 0 && allLessons[0];
    let currentLessonTab = (zoomState?.lessonSettings?.currentLessonTab == undefined) ? 'items' : zoomState?.lessonSettings?.currentLessonTab;
    let currentTaskId = (zoomState == undefined || zoomState.lessonSettings == undefined) ? 'false' : zoomState.lessonSettings.currentTaskId;
    let currentScrollBlockId = (zoomState?.lessonSettings?.currentScrollBlockId == undefined) ? 'none' : zoomState?.lessonSettings?.currentScrollBlockId;
    let hiddenItemsIds = (zoomState?.lessonSettings?.hiddenItemsIds == undefined) ? [] : zoomState?.lessonSettings?.hiddenItemsIds;
    let translatableModalVisible = (zoomState?.lessonSettings?.translatableModalVisible == undefined) ? 0 : zoomState?.lessonSettings?.translatableModalVisible;

    let syncVideoUrl = (zoomState == undefined || zoomState?.lessonSettings?.syncVideoUrl == undefined || zoomState?.lessonSettings?.syncVideoUrl == '') ? undefined : zoomState?.lessonSettings?.syncVideoUrl;

    let users = (groupInfo == undefined || groupInfo.users == undefined) ? [] : groupInfo.users;
    const activeChambers = chambers.filter(x => (x != undefined && x.usersIds != undefined && x.usersIds.length > 0));

    console.log("allUsers", users)

    // console.log('--->>> userRole = ', userRole);
    let myChamber = chambers.filter(x => (x != undefined && x.usersIds != undefined)).filter(xx => (xx.usersIds.indexOf(currentUserId) > -1))[0];

    let videoRoomName = (chamberMode == true && myChamber != undefined) ? `room_${roomName}_${myChamber == undefined ? undefined : myChamber.id}` : `room_${roomName}`;

    useEffect(() => {
        try {
            if (!isTeacher && currentScrollBlockId != 'none') {
                let element = document.getElementById(currentScrollBlockId);
                element.scrollIntoView({behavior: "smooth", block: "center"});
            }
        } catch (exc) {

        }
    }, [currentScrollBlockId]);

    useEffect(() => {
        if (zoomState?.lessonSettings?.currentLessonId != undefined) {
            onCurrentLessonChange(zoomState?.lessonSettings?.currentLessonId);
        }
    }, [zoomState?.lessonSettings?.currentLessonId]);

    const divRef = useRef();

    const drag = ReactHelper.useDrag(divRef, [], {
        onDrag: () => {

        },
    });

    useEffect(() => {
        try {
            if (endOnlineLesson) {
                CommonHelper.linkTo(`/groups/${groupId}`)
                SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                    ...lessonSettings_,
                    endOnlineLesson: false
                });
            }
        } catch (exc) {

        }
    }, [endOnlineLesson]);

    let tasks = allTasks.filter(xx => (xx.lessonId == currentLesson?.id));
    console.log('tasks = ', tasks);
    console.log('currentLesson = ', currentLesson);
    console.log('zoomState?.lessonSettings = ', zoomState?.lessonSettings);

    console.log('drag.isDragging = ', drag.isDragging);

    return (
        <Wrapper ref={divRef}>

            {loaded == false ? null :
                <React.Fragment>
                    <NavBar>
                        <NavTopLeft>
                            <EngPatSpan onClick={() => {
                                CommonHelper.linkTo(`/classes/${groupId}`)
                            }}>
                                {getOnlineName()}
                            </EngPatSpan>
                        </NavTopLeft>

                        <div style={{display: 'flex', alignItems: "center", justifyContent: "center"}}>
                            <div>
                                {groupInfo.name}
                                {chamberMode == false ? null :
                                    <span style={{marginLeft: 10}}>
                                        <>
                                            {userRole == 'teacher' ?
                                                <DeadlineCountdownEditorPanel
                                                    name={videoRoomName}
                                                />
                                                : <DeadlineCountdownPanel
                                                    name={videoRoomName}
                                                />
                                            }

                                        </>
                                    </span>
                                }
                            </div>
                            {currentUser?.userRole != 'teacher' ? null :
                                <div style={{width: 280, marginLeft: 10}}>
                                    <Select
                                        placeholder={'Выберите урок'}
                                        options={lessonsOptions}
                                        value={lessonsOptions.filter(xx => (xx?.value == currentLesson?.id))[0]}
                                        onChange={a => {
                                            console.log('a.value == ', a.value);
                                            SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                                                ...lessonSettings_,
                                                currentLessonId: a.value
                                            });
                                        }}
                                    />
                                </div>
                            }
                        </div>

                        <NavTopRight>
                            {currentUser.email}
                        </NavTopRight>
                    </NavBar>

                    <ContentPlaceholder>

                        <Rnd
                            dragHandleClassName={'rnd_handle'}
                            default={{
                                ...getPosSettingsByName('working_area')
                            }}
                            minWidth={getPosSettingsByName('working_area').minWidth}
                            minHeight={getPosSettingsByName('working_area').minHeight}
                            bounds="window"
                            onDragStop={(e, d) => {
                                setPosSettingsByName('working_area', {x: d.x, y: d.y})
                            }}
                            onResizeStop={(e, direction, ref, delta, position) => {
                                setPosSettingsByName('working_area', {
                                    width: ref.style.width,
                                    height: ref.style.height,
                                    x: position.x,
                                    y: position.y
                                })
                            }}
                        >
                            <LeftPlaceholder style={{
                                width: '100%', height: '100%', maxHeight: '100%',
                                overflowY: 'hidden', boxSizing: "border-box", padding: 8, paddingTop: 15,
                                backgroundColor: "#614C2E",
                                borderRadius: 4,
                                position: 'relative'
                            }}>
                                <div className={'rnd_handle'}>

                                </div>

                                <div style={{
                                    height: '100%',
                                    maxHeight: '100%',
                                    overflowY: 'auto',
                                    width: '100%',
                                    backgroundColor: 'whitesmoke',
                                    padding: 5
                                }}>
                                    {activeTab != 'editor_mode' ? null :
                                        <div style={{width: '100%'}}>
                                            <SyncHeading>
                                                Синхронный редактор
                                            </SyncHeading>
                                            <RealtimeEditor
                                                roomId={videoRoomName}
                                            />
                                        </div>
                                    }

                                    <LeftInnerWrapper>
                                        {activeTab != 'whiteboard' ? null :
                                            <div style={{height: 'calc(100vh - 100px)', width: '100%'}}>
                                                <SyncDrawableImage
                                                    roomName={`whiteboard_${groupId}`}
                                                    src={'https://www.englishpatient.org/english-patient-files/b449ce7f-fb7b-499d-8972-c7088922b196.jpeg'}
                                                />
                                            </div>
                                        }


                                        {activeTab != 'lesson_mode' ? null :
                                            <VeryLeftPlaceholder
                                                key={`${currentLesson && allLessons && allLessons[0] ? currentLesson?.id : 'lesson'}_${0}`}>

                                                {chamberMode == true ?
                                                    <React.Fragment>
                                                        <KvdbTasksFeedPanel
                                                            name={videoRoomName}
                                                            canEdit={userRole == 'teacher'}
                                                        />
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        {currentLesson != undefined ? null :
                                                            <div>
                                                                Выберите урок
                                                            </div>
                                                        }
                                                        <LessonTasksEditor
                                                            isTeacher={isTeacher}
                                                            onlineHiddenItemsIds={hiddenItemsIds}
                                                            isOnlineMode={true}

                                                            forceTab={currentLessonTab}
                                                            isForceTab={true}

                                                            hasTabs={isTeacher}
                                                            canEdit={false}
                                                            tasks={tasks}
                                                            selectedTaskId={zoomState?.lessonSettings?.selectedTaskId}

                                                            onTaskToggle={tId => {
                                                                if (isTeacher == false) {
                                                                    return;
                                                                }
                                                                SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                                                                    ...lessonSettings_,
                                                                    selectedTaskId: tId
                                                                });
                                                            }}
                                                            onSelectedTaskTabChange={tb => {
                                                                if (isTeacher == false) {
                                                                    return;
                                                                }
                                                                console.log('onSelectedLessonTabChange: tb = ', tb);
                                                                SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                                                                    ...lessonSettings_,
                                                                    currentLessonTab: tb
                                                                })
                                                            }}
                                                            showMoveToMeButton={isTeacher}
                                                            onMoveToBlockClick={blockDivId => {
                                                                SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                                                                    ...lessonSettings_,
                                                                    currentScrollBlockId: blockDivId,
                                                                });
                                                            }}
                                                            onOnlineEyeToggle={eId => {
                                                                console.log('!!!=> onOnlineEyeToggle: eId = ', eId);
                                                                let newIds = hiddenItemsIds.indexOf(eId) > -1 ? hiddenItemsIds.filter(xx => (xx != eId)) : hiddenItemsIds.concat([eId]);
                                                                console.log('onlineHiddenItemsIds: newIds = ', newIds);
                                                                SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                                                                    ...lessonSettings_,
                                                                    hiddenItemsIds: newIds,
                                                                });
                                                            }}
                                                            onTranscriptOnlineEyeToggle={eId => {
                                                                console.log('!!!=> onOnlineEyeToggle: eId = ', eId);
                                                                let trId = `tr-${eId}`;
                                                                let newIds = hiddenItemsIds.indexOf(eId) > -1 ? hiddenItemsIds.filter(xx => (xx != trId)) : hiddenItemsIds.concat([trId]);
                                                                console.log('onlineHiddenItemsIds: newIds = ', newIds);
                                                                SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                                                                    ...lessonSettings_,
                                                                    hiddenItemsIds: newIds,
                                                                });
                                                            }}

                                                            onSyncVideoSelect={url => {
                                                                SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                                                                    ...lessonSettings_,
                                                                    syncVideoUrl: url
                                                                });
                                                            }}

                                                        />
                                                    </React.Fragment>
                                                }


                                            </VeryLeftPlaceholder>
                                        }

                                    </LeftInnerWrapper>
                                </div>

                            </LeftPlaceholder>
                        </Rnd>


                        <Rnd
                            dragHandleClassName={'rnd_handle'}
                            default={{
                                ...getPosSettingsByName('jitsi')
                            }}
                            minWidth={getPosSettingsByName('jitsi').minWidth}
                            minHeight={getPosSettingsByName('jitsi').minHeight}
                            bounds="window"
                            onDragStop={(e, d) => {
                                setPosSettingsByName('jitsi', {x: d.x, y: d.y})
                            }}
                            onResizeStop={(e, direction, ref, delta, position) => {
                                setPosSettingsByName('jitsi', {
                                    width: ref.style.width,
                                    height: ref.style.height,
                                    x: position.x,
                                    y: position.y
                                })
                            }}
                        >
                            <div style={{
                                width: '100%', height: '100%', boxSizing: "border-box", padding: 8,
                                paddingTop: 15,
                                background: "#614C2E", borderRadius: 4, position: 'relative'
                            }} key={videoRoomName}>

                                <div className={'rnd_handle'}>

                                </div>

                                {chambersEditorVisible == true ? null :
                                    <SimpleInteractiveCommutePanel
                                        roomId={videoRoomName}
                                    />
                                }
                            </div>
                        </Rnd>

                        <Rnd
                            dragHandleClassName={'rnd_handle'}
                            default={{
                                ...getPosSettingsByName('chat')
                            }}
                            minWidth={getPosSettingsByName('chat').minWidth}
                            minHeight={getPosSettingsByName('chat').minHeight}
                            bounds="window"
                            onDragStop={(e, d) => {
                                setPosSettingsByName('chat', {x: d.x, y: d.y})
                            }}
                            onResizeStop={(e, direction, ref, delta, position) => {
                                console.log('chat : onResizeStop : (e, direction, ref, delta, position) = ', e, direction, ref, delta, position);
                                setPosSettingsByName('chat', {
                                    width: ref.style.width,
                                    height: ref.style.height,
                                    x: position.x,
                                    y: position.y
                                })
                            }}
                        >
                            <div style={{
                                width: '100%',
                                height: '100%',
                                maxHeight: '100%',
                                overflowY: 'hidden',
                                boxSizing: "border-box",
                                padding: 8,
                                paddingTop: 15,
                                paddingBottom: 10,
                                backgroundColor: "#614C2E",
                                borderRadius: 4,
                                position: 'relative'
                            }}>
                                <div className={'rnd_handle'}>

                                </div>

                                <div style={{
                                    height: '100%',
                                    maxHeight: '100%',
                                    overflowY: 'auto',
                                    width: '100%',
                                    backgroundColor: 'whitesmoke',
                                    padding: 5
                                }}>
                                    <RoomChatPanel roomId={groupId}/>
                                </div>
                            </div>
                        </Rnd>

                        {syncVideoUrl &&
                        <Rnd
                            dragHandleClassName={'rnd_handle'}
                            default={{
                                ...getPosSettingsByName('sync_player')
                            }}
                            minWidth={getPosSettingsByName('sync_player').minWidth}
                            minHeight={getPosSettingsByName('chat').minHeight}
                            bounds="window"
                            onDragStop={(e, d) => {
                                setPosSettingsByName('sync_player', {x: d.x, y: d.y})
                            }}
                            onResizeStop={(e, direction, ref, delta, position) => {
                                setPosSettingsByName('sync_player', {
                                    width: ref.style.width,
                                    height: ref.style.height,
                                    x: position.x,
                                    y: position.y
                                })
                            }}
                        >
                            <div style={{
                                width: '100%', height: '100%', maxHeight: '100%',
                                overflowY: 'hidden', boxSizing: "border-box", padding: 8, paddingTop: 15,
                                backgroundColor: "#614C2E",
                                borderRadius: 4,
                                position: 'relative'
                            }}>
                                <div className={'rnd_handle'}>

                                </div>

                                <div style={{
                                    height: '100%',
                                    maxHeight: '100%',
                                    overflowY: 'auto',
                                    width: '100%',
                                    backgroundColor: 'whitesmoke',
                                    padding: 5,
                                    position: 'relative'
                                }}>

                                    <FreeSyncVideoPlayer
                                        roomPrefix={roomName}
                                        url={syncVideoUrl}
                                    />

                                    {isTeacher &&
                                    <img src={close_img} style={{
                                        position: 'absolute',
                                        top: 5,
                                        right: 10,
                                        cursor: 'pointer',
                                        width: 24,
                                        height: 24
                                    }} onClick={() => {
                                        SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                                            ...lessonSettings_,
                                            syncVideoUrl: ''
                                        });
                                    }}/>
                                    }

                                </div>

                            </div>
                        </Rnd>
                        }

                        {isTeacher == false ? <VeryRightPlaceholder>
                                <React.Fragment>
                                    {/*<SideButton onClick={() => {*/}

                                    {/*}}>*/}
                                    {/*    <SideImg src={end_call}/>*/}
                                    {/*</SideButton>*/}
                                </React.Fragment>
                            </VeryRightPlaceholder>

                            :
                            <VeryRightPlaceholder>


                                <React.Fragment>

                                    <SideButton
                                        selected={(activeTab == 'editor_mode')}
                                        onClick={() => {
                                            SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                                                ...lessonSettings_,
                                                activeTab: 'editor_mode'
                                            });
                                        }}>
                                        <SideImg src={editor_image}/>
                                    </SideButton>

                                    <SideButton
                                        selected={(activeTab == 'lesson_mode')}
                                        onClick={() => {
                                            SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                                                ...lessonSettings_,
                                                activeTab: 'lesson_mode'
                                            });
                                        }}>
                                        <SideImg src={lesson_image}/>
                                    </SideButton>

                                    <SideButton
                                        style={{
                                            marginBottom: 40
                                        }}
                                        selected={(activeTab == 'whiteboard')}
                                        onClick={() => {
                                            SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                                                ...lessonSettings_,
                                                activeTab: 'whiteboard'
                                            });
                                        }}>
                                        <SideImg src={whiteboard_image}/>
                                    </SideButton>

                                    {/*<SideButton*/}
                                    {/*    style={{*/}
                                    {/*        marginBottom: 40*/}
                                    {/*    }}*/}
                                    {/*    selected={(activeTab == 'whiteboard')}*/}
                                    {/*    onClick={() => {*/}
                                    {/*        SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {*/}
                                    {/*            ...lessonSettings_,*/}
                                    {/*            translatableModalVisible: 1*/}
                                    {/*        });*/}
                                    {/*    }}>*/}
                                    {/*    <SideImg src={purp_chat_img}/>*/}
                                    {/*</SideButton>*/}


                                    <SideButton onClick={() => {
                                        setChambersEditorVisible(true);
                                    }}>
                                        <SideImg src={chambers_image}/>
                                    </SideButton>


                                    {chamberMode == false ? null :
                                        <React.Fragment>
                                            {activeChambers.map((x, i) => {
                                                return (
                                                    <SideButton key={x.id}
                                                                selected={(myChamber != undefined) && (myChamber.id == x.id)}
                                                                onClick={() => {
                                                                    let wChambers = chambers.map(a => ({
                                                                        ...a,
                                                                        usersIds: (a.usersIds == undefined ? [] : a.usersIds).filter(c => (c != currentUser.id))
                                                                    }));
                                                                    let newChambers = wChambers.map((qq, w) => (qq.id == x.id) ? ({
                                                                        ...qq,
                                                                        usersIds: qq.usersIds.concat([currentUser.id])
                                                                    }) : qq);
                                                                    SmartFirebaseAPI.updateLessonZoomFireState(roomName, newChambers, chamberMode, {
                                                                        ...lessonSettings_,
                                                                        activeTab: activeTab
                                                                    });
                                                                }}>
                                                        {`${x.label}`}
                                                    </SideButton>
                                                )
                                            })}
                                        </React.Fragment>
                                    }
                                    <SideButton onClick={() => {
                                        SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                                            ...lessonSettings_,
                                            endOnlineLesson: true
                                        });
                                    }}>
                                        <SideImg src={end_call}/>
                                    </SideButton>

                                </React.Fragment>


                            </VeryRightPlaceholder>
                        }

                    </ContentPlaceholder>

                    <Sidebar visible={chambersEditorVisible}
                             width={Math.min(1200, window.innerWidth)}
                             onCloserClick={() => {
                                 setChambersEditorVisible(false);
                             }}>

                        {chambersEditorVisible == false ? null :
                            <div>

                                <div style={{
                                    marginBottom: 20,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>

                                    <img
                                        style={{width: 24, height: 24, marginRight: 10}}
                                        src={chamberMode == true ? square_image_check : square_image}
                                        onClick={() => {
                                            SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, !chamberMode);
                                        }}
                                    />

                                    <span>
                                        Разбить по группам
                                    </span>


                                </div>

                                {chamberMode == false ? null :
                                    <Tabs
                                        tabs={[
                                            {
                                                label: 'Settings',
                                                content: (
                                                    <TabItem>
                                                        <ChambersEditor
                                                            chambers={chambers}
                                                            allUsers={users}
                                                            onChange={arr => {
                                                                SmartFirebaseAPI.updateLessonZoomFireState(roomName, arr, chamberMode);
                                                            }}
                                                        />
                                                    </TabItem>
                                                )
                                            },
                                            {
                                                label: 'View groups',
                                                content: (
                                                    <TabItem>
                                                        <OnlineGrid>
                                                            {activeChambers.map((a, i) => {
                                                                let gridCellName = `room_${roomName}_${a.id}`;
                                                                return (
                                                                    <GridCell key={i}>
                                                                        <SimpleInteractiveCommutePanel
                                                                            roomId={gridCellName}
                                                                            muted={true}
                                                                        />
                                                                    </GridCell>
                                                                )
                                                            })}
                                                        </OnlineGrid>

                                                    </TabItem>
                                                )
                                            },
                                            ...activeChambers.map((a, i) => {
                                                let gridCellName = `room_${roomName}_${a.id}`;
                                                return (
                                                    {
                                                        label: `Group ${+i + 1}`,
                                                        content: (
                                                            <TabItem key={`${gridCellName}`}>
                                                                <KvdbTasksFeedPanel name={gridCellName} canEdit={true}/>
                                                            </TabItem>
                                                        )
                                                    }
                                                )
                                            })
                                        ]}
                                    />
                                }

                            </div>
                        }

                    </Sidebar>
                </React.Fragment>
            }

            {fakeLoaderVisible == false ? null :
                <FakeLoaderPlaceholder>
                    <LottieLoader
                        json={online_courses_and_tutorials}
                    />
                </FakeLoaderPlaceholder>
            }


            {translatableModalVisible != 1 ? null :
                <NiceModal onClose={() => {
                    SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                        ...lessonSettings_,
                        translatableModalVisible: 0
                    });
                }}>

                    <TransInner>
                        <RoomChatPanel roomId={groupId}/>
                    </TransInner>

                </NiceModal>
            }

        </Wrapper>

    );
};

export default OnlineLesson;

const topBarHeight = 48;

const TransInner = styled.div`
  width: 620px;
  height: calc(80vh - 40px);
`;

const OnlineGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const GridCell = styled.div`
  height: 50vh;
  box-sizing: border-box;
  padding: 5px;
`;

const SyncHeading = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: bold;
  margin-top: 20px;
`;

const SyncVideoOverlay = styled.div`
  position: fixed;
  top: 48px;
  bottom: 0px;
  left: 0px;
  //right: 0px;
  width: calc(100vw - 720px);
  z-index: 1000;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SyncVideoInnerPlaceholder = styled.div`
  height: min(620px, 80vh - 50px);
  width: calc(100vw - 720px);
  box-sizing: border-box;
  background: white;
  border-radius: 10px;
  position: relative;

  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  @media (max-width: 920px) {
    width: calc(100vw - 20px);
  }

`;

const LeftInnerWrapper = styled.div`
  //padding: 10px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  //@media (max-width: 1200px) {
  //  width: min(calc(100vw - 520px - 50px), 740px);
  //  margin: 0 auto;
  //}
`;

const NavTopLeft = styled.div`
  font-weight: bold;
  padding-left: 20px;
  box-sizing: border-box;
`;

const EngPatSpan = styled.span`
  cursor: pointer;
`;

const NavTopRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding-right: 20px;
`;


const VeryLeftPlaceholder = styled.div`
  height: calc(98vh - ${topBarHeight}px);
  max-width: calc(100vw - 720px);
  overflow: auto;
`;

const VeryRightPlaceholder = styled.div`
  height: calc(100vh - ${topBarHeight}px);
  background: #F2EDF4;
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const SideImg = styled.img`
  height: 32px;
  box-sizing: border-box;
`;

const SideButton = styled.div`
  box-sizing: border-box;
  width: 70px;
  height: 70px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-weight: ${props => (props.selected == true ? 'bold' : 'default')};
  background: ${props => (props.selected == true ? 'lightyellow' : 'transparent')};

  :hover {
    opacity: 0.8;
  }
`;

const NavBar = styled.div`
  height: ${topBarHeight}px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid lightgrey;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
`;

const ContentPlaceholder = styled.div`
  box-sizing: border-box;
  height: calc(100vh - 40px);
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100vw;
  background: grey;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${forest_img});
`;


const InnerWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  background: whitesmoke;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const LeftPlaceholder = styled.div`
  //flex: 1;
  //padding-left: 20px;
  //box-sizing: border-box;
  //padding-right: 20px;
`;

const RightPlaceholder = styled.div`
  width: 720px;
  background: white;
  box-sizing: border-box;
  height: calc(100vh - ${topBarHeight}px - 2px);
  @media (max-width: 1200px) {
    width: 520px;
  }
`;

const FakeLoaderPlaceholder = styled.div`
  position: absolute;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.95);
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

// const useStyles = makeStyles((theme) => ({
//     formControl: {
//         marginLeft: 20,
//         minWidth: 120,
//     },
// }));
