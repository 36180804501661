import * as types from '../ActionTypes.js'

export function showPanel (side) {
    if (side == 'left') {
        return {
            type: types.SHOW_LEFT_PANEL
        }
    }

    else if (side == 'middle') {
        return {
            type: types.SHOW_MIDDLE_PANEL
        }
    }

    else if (side == 'right') {
        return {
            type: types.SHOW_RIGHT_PANEL
        }
    }

    else {
        return {
            type: undefined
        }
    }
}

export function showPanelSelector (value) {
    if (value == 'courses') {
        return  {
            type: types.SHOW_COURSES_PANEL
        }
    }

    else if (value == 'lessons') {
        return  {
            type: types.SHOW_LESSONS_PANEL
        }
    }

    else {
        return {
            type: undefined
        }
    }
}