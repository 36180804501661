import * as types from '../ActionTypes'
import {Map} from 'immutable';

const initialState = {
    loading: false,
    slidesMap: Map(),

    selectedSlideId: undefined,
    error: undefined
}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

function getGracefullyMergedContainersMap(oldMap, newContainers) {
    let newMap = oldMap;
    for (let i in newContainers) {
        let newCont = newContainers[i];
        let oldContainer = oldMap.get(newCont.id);
        if (newCont == undefined) {
            continue;
        }
        if (oldContainer != undefined) {
            newMap = newMap.set(oldContainer.id, {...oldContainer, ...newCont});
        } else {
            newMap = newMap.set(newCont.id, newCont);
        }
    }
    return newMap;
}

const SlidesReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.CREATE_SLIDE:
        case types.UPDATE_SLIDE:
        case types.LOAD_SLIDES:
        case types.DELETE_SLIDE:
            return startLoading(state, action)

        case types.CREATE_SLIDE_FAIL:
        case types.UPDATE_SLIDE_FAIL:
        case types.LOAD_SLIDES_FAIL:
        case types.DELETE_SLIDE_FAIL:
            return stopLoading(state, action)

        case types.UPDATE_SLIDE_SUCCESS:
            return {
                ...state,
                loading: false,
                slidesMap: state.slidesMap.set(action.slide.id, action.slide)
            }

        case types.CREATE_SLIDE_SUCCESS: // todo: add refresh in containers reducer
            return {
                ...state,
                loading: false,
                slidesMap: getGracefullyMergedContainersMap(state.slidesMap, [action.slide])
            }

        case types.DELETE_SLIDE_SUCCESS:
            return {
                ...state,
                loading: false,
                slidesMap: getGracefullyMergedContainersMap(state.slidesMap, [{...state.slidesMap.get(action.id), isDeleted: true}]),
                selectedSlideId: undefined
            }

        case types.SELECT_SLIDE:
            return {
                ...state,
                selectedSlideId: action.id
            }

        case types.LOAD_SLIDES_SUCCESS:
            return {
                ...state,
                loading: false,
                slidesMap: getGracefullyMergedContainersMap(state.slidesMap, action.slides)
            }

        default:
            return state;

    }

}

export default SlidesReducer;