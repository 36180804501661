import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Spin from 'arui-feather/spin'
import NiceModal from "./NiceModal";

export default function NiceConfirm(props) {
    const {
        onConfirm = () => {

        },
        onCancel = () => {

        },
        loading = false,
        confirmText = 'ПОДТВЕРДИТЬ',
        cancelText = 'ОТМЕНИТЬ',
        heading = 'Heading',
        subHeading = 'Sub Heading'
    } = props;

    return (
        <NiceModal onClose={() => {
            onCancel();
        }}>

            <DeleteInner>
                {heading == '' ? null :
                    <EditInnerHeader>
                        {heading}
                    </EditInnerHeader>
                }

                {subHeading == '' ? null :
                    <SubModalHeading>
                        {subHeading}
                    </SubModalHeading>
                }

                <DeleteButtonsPlaceholder>
                    <DeleteOutlineButton onClick={() => {
                        onCancel();
                    }}>
                        {cancelText}
                    </DeleteOutlineButton>
                    <DeleteActionButton onClick={async () => {
                        onConfirm();
                    }}>
                        <Spin visible={loading} theme={'alfa-on-color'}/>
                        {loading == true ? null :
                            confirmText
                        }
                    </DeleteActionButton>
                </DeleteButtonsPlaceholder>


            </DeleteInner>

        </NiceModal>
    );
}

const Wrapper = styled.div`

`;

const SubModalHeading = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.171429px;
  color: rgba(31, 32, 65, 0.7);
  margin-bottom: 43px;
`;


const DeleteOutlineButton = styled.div`
  width: 194px;
  margin: 7px;
  height: 44px;
  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 100px;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  justify-content: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: #333333;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const DeleteActionButton = styled.div`
  margin: 7px;
  width: 194px;
  height: 44px;
  background: #FF624F;
  padding-left: 36px;
  padding-right: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  cursor: pointer;
  border-radius: 1000px;
  font-size: 12px;
  line-height: 15px;
  text-align: center;

  :hover {
    opacity: 0.8;
  }
`;

const DeleteButtonsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const DeleteInner = styled.div`
  width: 420px;
  @media (max-width: 540px) {
    width: 100%;
  }
`;

const EditInnerHeader = styled.div`
  text-align: center;
  letter-spacing: 0.166667px;
  color: #333539;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 29px;
`;
