import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import Tabs, {TabItem} from "../../ui/Tabs";
import MaterialSearchPanel from "./MaterialSearchPanel";
import AllExercisesPanel from "../../exercises/panels/AllExercisesPanel";
import AllVideoMaterialsPanel from "./AllVideoMaterialsPanel";
import AllDialogsExercisesPanel from "../../dialogs/panels/AllDialogsExercisesPanel";
import AllDrochkaExercisesPanel from "../../drochka_exercise/panels/AllDrochkaExercisesPanel";
import AllTestsPanel from "../../tests/panels/AllTestsPanel";
// import AllTreadmillsPanel from "../../treadmills_exercises/panels/AllTreadmillsPanel";
import TreadmillsPanel from "../../treadmills_exercises/panels/TreadmillsPanel";
import AllQuestionnairesPanel from "../../questionnaires/panels/AllQuestionnairesPanel";
import {translate} from "../../../utils/Translate";
import BulkDndPlaceholder from "../../bulk_uploader/BulkDndPlaceholder";
import NewBulkUploader from "../../bulk_uploader/NewBulkUploader";

export default function SearchOrCreateMaterialsPanel(props) {
    const {
        pageSize = 10,
        type = 'materials',
        onSelect = m => {

        },
        hasSimpleUploader = false,
        ids = []
    } = props;
    const selSet = ids.reduce((st, x) => st.add(x), Set());
    console.log('SearchOrCreateMaterialsPanel: render: ids = ', ids);

    return (
        <Wrapper>
            <Tabs
                contentStyle={{height: 'calc(100% - 40px)', boxSizing: 'border-box'}}
                tabs={[
                    (hasSimpleUploader == false) ? undefined :
                        {
                            label: 'Upload',
                            content: (
                                <TabItem style={{height: '100%'}}>

                                    {/*<BulkDndPlaceholder*/}
                                    {/*    singleMode={true}*/}
                                    {/*    onSingleFileUploaded={d => {*/}
                                    {/*        onSelect(d);*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                    <NewBulkUploader
                                        singleMode={true}
                                        onSingleFileUploaded={d => {
                                            onSelect(d);
                                        }}
                                    />

                                </TabItem>
                            )
                        },
                    {
                        label: translate('search'),
                        content: (
                            <TabItem style={{height: '100%'}}>
                                <MaterialSearchPanel
                                    {...props}
                                    selectedIds={ids}
                                    type={type}
                                    pageSize={pageSize}
                                    selectedSet={selSet}
                                    onSelect={x => {
                                        onSelect(x);
                                    }}
                                />
                            </TabItem>
                        )
                    },
                    [].indexOf(type) > -1 ? undefined :
                        {
                            label: translate('my_library'),
                            content: (
                                <TabItem style={{height: '100%'}}>
                                    <CreateTabPlaceholder>
                                        {type != 'exercises' ? null :
                                            <AllExercisesPanel
                                                selectedId={props.exerciseId}
                                                selectedSet={selSet}
                                                mode={'my'}
                                                hasSearch={true}
                                                selectMode={true}
                                                onCreated={x => {
                                                    onSelect(x);
                                                }}
                                                onSelect={x => {
                                                    onSelect(x)
                                                }}
                                            />
                                        }
                                        {type != 'materials' ? null :
                                            <AllVideoMaterialsPanel
                                                selectedSet={selSet}
                                                mode={'my'}
                                                hasSearch={true}
                                                selectMode={true}
                                                onCreated={x => {
                                                    onSelect(x);
                                                }}
                                                onSelect={x => {
                                                    onSelect(x)
                                                }}
                                            />
                                        }
                                        {type != 'dialogs' ? null :
                                            <AllDialogsExercisesPanel
                                                selectedSet={selSet}
                                                mode={'my'}
                                                hasSearch={true}
                                                selectMode={true}
                                                onCreated={x => {
                                                    onSelect(x);
                                                }}
                                                onSelect={x => {
                                                    onSelect(x)
                                                }}
                                            />
                                        }
                                        {type != 'cards' ? null :
                                            <AllDrochkaExercisesPanel
                                                selectedSet={selSet}
                                                mode={'my'}
                                                hasSearch={true}
                                                selectMode={true}
                                                onCreated={x => {
                                                    onSelect(x);
                                                }}
                                                onSelect={x => {
                                                    onSelect(x)
                                                }}
                                            />
                                        }
                                        {type != 'tests' ? null :
                                            <AllTestsPanel
                                                selectedSet={selSet}
                                                mode={'my'}
                                                hasSearch={true}
                                                selectMode={true}
                                                onCreated={x => {
                                                    onSelect(x);
                                                }}
                                                onSelect={x => {
                                                    onSelect(x)
                                                }}
                                            />
                                        }
                                        {type != 'treadmills' ? null :
                                            <TreadmillsPanel
                                                selectedSet={selSet}
                                                mode={'my'}
                                                hasSearch={true}
                                                selectMode={true}
                                                onCreated={x => {
                                                    onSelect(x);
                                                }}
                                                onSelect={x => {
                                                    onSelect(x)
                                                }}
                                            />
                                        }
                                        {type != 'questionnaires' ? null :
                                            <AllQuestionnairesPanel
                                                selectedSet={selSet}
                                                mode={'my'}
                                                hasSearch={true}
                                                selectMode={true}
                                                onCreated={x => {
                                                    onSelect(x);
                                                }}
                                                onSelect={x => {
                                                    onSelect(x)
                                                }}
                                            />
                                        }
                                    </CreateTabPlaceholder>

                                </TabItem>
                            )
                        }
                ].filter(x => (x != undefined))}
            />
        </Wrapper>
    );
}

const CreateTabPlaceholder = styled.div`
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
`;