
import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    testsMap: Map(),
    error: undefined

}

const TestsReducer =  (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_TEST:
        case types.UPDATE_TEST:
        case types.DELETE_TEST:
        case types.LOAD_TESTS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_TEST_FAIL:
        case types.UPDATE_TEST_FAIL:
        case types.DELETE_TEST_FAIL:
        case types.LOAD_TESTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_TEST_SUCCESS:
        case types.UPDATE_TEST_SUCCESS:
            return {
                ...state,
                loading: false,
                testsMap: state.testsMap.set(action.test.id, action.test)
            }


        case types.DELETE_TEST_SUCCESS:
            return {
                ...state,
                loading: false,
                testsMap: state.testsMap.delete(action.id),
            }



        case types.LOAD_TESTS_SUCCESS:
            return {
                ...state,
                loading: false,
                testsMap: state.testsMap
                    .merge(action.tests.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default TestsReducer;
