import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import axios from "axios";
import {DEFAULT_VF_ANALYTICS_ENDPOINT} from "../../../api/TrackerAPI";

import moment from 'moment'

import {Code} from "react-content-loader";
import NiceModal from "../../modals/NiceModal";

export default function VerfactoAnalyticsPanel(props) {
    const {
        userId
    } = props;

    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (userId == undefined) {
            return;
        }
        setLoading(true);
        axios.get(`${DEFAULT_VF_ANALYTICS_ENDPOINT}/points`, {
            params: {
                userId: userId
            }
        }).then(d => d.data.result).then(arr => {
            setEvents(arr);
            setLoading(false);
        });
    }, [userId]);

    const [selectedUrl, setSelectedUrl] = useState(undefined);
    const [selectedType, setSelectedType] = useState();

    if (loading == true) {
        return (<Code/>)
    }

    let sortedEvents = events.sort((a, b) => (+b.t - +a.t));

    return (
        <Wrapper>

            <Heading>
                Журнал событий
            </Heading>

            <TablePlaceholder>
                <Table>
                    <Thead>
                        <Tr>
                            <Th style={{width: 120, boxSizing: 'border-box'}}>
                                Тип события
                            </Th>
                            <Th style={{width: 140, boxSizing: 'border-box'}}>
                                Дата и время
                            </Th>
                            <Th>
                                Детали
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>

                        {sortedEvents.map((a, i) => {
                            return (
                                <Tr key={a.id}>
                                    <Td>
                                        {a.type != 'VIDEO_PLAY' ? null : 'Проигрывание видео'}
                                        {a.type != 'EXERCISE_ANSWER' ? null : 'Ответ на упражнение'}
                                        {a.type != 'RECORDING_SAVED' ? null : 'Запись голоса'}
                                        {a.type != 'PLATFORM_VISIT' ? null : 'Заход на платформу'}
                                    </Td>
                                    <Td>
                                        {moment(a.t).format('DD.MM.YYYY HH:mm:ss')}
                                    </Td>
                                    <Td>
                                        {a.url == undefined ? null :
                                            <ViewSpan onClick={() => {
                                                setSelectedType(a.type);
                                                setSelectedUrl(a.url);
                                            }}>
                                                {a.type != 'RECORDING_SAVED' ? null : 'Прослушать'}
                                                {a.type != 'VIDEO_PLAY' ? null : 'Посмотреть'}
                                            </ViewSpan>
                                        }
                                    </Td>
                                </Tr>
                            )
                        })}

                    </Tbody>
                </Table>

            </TablePlaceholder>

            {selectedType == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedType(undefined);
                    setSelectedUrl(undefined);
                }}>

                    <VideoInnerModal>

                        <Video controls={true} src={selectedUrl} >

                        </Video>

                    </VideoInnerModal>

                </NiceModal>
            }

        </Wrapper>
    );
}

const Video = styled.video`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const VideoInnerModal = styled.div`
  width: 720px;
  box-sizing: border-box;
  height: min(520px, 80vh);
  @media (max-width: 760px) {
    width: 100%;
  }
`;

const ViewSpan = styled.div`
  font-size: 12px;
  opacity: 0.75;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const TablePlaceholder = styled.div`
  max-height: 80vh;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  background: white;
`;

const Heading = styled.div`
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  padding: 15px;
`;


const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const Tr = styled.tr`

`;

const Th = styled.th`
  border: 1px solid lightgrey;
  text-align: left;
  padding: 3px;
`;

const Td = styled.td`
  box-sizing: border-box;
  border: 1px solid lightgrey;
  padding: 5px;
`;


const Thead = styled.thead`

`;

const Tbody = styled.tbody`

`;