import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";
import GroupStatsAPI from "../../../api/GroupStatsAPI";
import BrutalOverlayModal from "../../modals/BrutalOverlayModal";

import * as actions from '../../../redux/actions/TasksActions'
import LessonTasksEditor from "../../lessons/tools/LessonTasksEditor";
import GroupLedgerPanel from "../../ledger/panels/GroupLedgerPanel";
import NewGroupLedgerPanel from "../../ledger/panels/NewGroupLedgerPanel";

let gn = (x, fld) => (x == undefined || x[fld] == undefined ? [] : x[fld]);


export default function GroupStatsPanel(props) {
    const {
        students = [],
        tasks = [],
        groupId
    } = props;
    const [data, setData] = useState(undefined);

    const {
        tasksMap = Map()
    } = useMappedState(useCallback(state => {
        return {
            tasksMap: state.tasks.tasksMap
        }
    }))

    useEffect(() => {
        if (groupId != undefined) {
            GroupStatsAPI.getGroupAnswersMap(groupId).then(x => {
                console.log('ans data = ', x);
                setData(x);
            })
        }
    }, [groupId]);


    const dispatch = useDispatch();
    const [selectedUser, setSelectedUser] = useState(undefined);
    const [selectedTask, setSelectedTask] = useState(undefined);

    useEffect(() => {
        if (selectedTask != undefined) {
            dispatch(actions.getLessonTasks(selectedTask?.lessonId)); // todo: optimize - fetch only task by id
        }
    }, [selectedTask?.id]);

    if (data == undefined) {
        return <Code/>
    }

    let richTask = (selectedTask == undefined) ? undefined : (selectedTask || tasksMap.get(selectedTask?.id));

    return (
        <Wrapper>

            <GroupLedgerPanel
                id={groupId}
            />

            {/*<NewGroupLedgerPanel*/}
            {/*    id={groupId}*/}
            {/*/>*/}

            <InnerPlaceholder>
                <Table>

                    <Thead>
                        <Tr>
                            <Th style={{width: 240}}>
                                Студент/Задания
                            </Th>
                            {tasks.map((a, i) => {
                                return (<Th key={i}>{a.name || moment(a.deadlineTimestamp).format('DD.MM.YYYY')}</Th>)
                            })}
                        </Tr>
                    </Thead>

                    <Tbody>
                        {students.map((b, i) => {
                            let answers = (data[b.id] == undefined ? [] : data[b.id]);
                            let marksMap = answers.reduce((mp, x) => ({...mp, [x.exerciseId]: x.mark}), {});
                            return (
                                <Tr key={b.id}>
                                    <Td style={{width: 240}}>
                                        {CommonHelper.getUserName(b)}
                                    </Td>
                                    {tasks.map((a, i) => {
                                        // let exesIds = gn(a.exercisesIds).concat(gn(a.testsIds)).concat(gn(a.drochkaExercisesIds));
                                        let exes = gn(a, 'exercisesIds').map(xx => ({id: xx, type: 'EX'}))
                                            .concat(gn(a, 'testsIds').map(xx => ({id: xx, type: 'T'})))
                                            .concat(gn(a, 'drochkaExercisesIds').map(xx => ({
                                                id: xx,
                                                type: 'C'
                                            })));
                                        let marks = exes.map(ex => ({
                                            exerciseId: ex.id,
                                            mark: marksMap[ex.id],
                                            type: ex.type
                                        })).filter(x => (x.mark != undefined));
                                        let avr = 0;
                                        if (marks.length > 0) {
                                            avr = marks.reduce((sm, x) => (+sm + +x.mark), 0) / marks.length;
                                        }

                                        return (
                                            <Td key={i} style={{textAlign: 'center'}} onClick={() => {
                                                setSelectedTask(a);
                                                setSelectedUser(b);
                                            }}>
                                                {avr < 0 ?
                                                    <span style={{fontSize: 10}}>{'✔️'}</span> : `${Math.round(avr)}`}
                                                <PointsSpan>
                                                    {marks.length == 0 ? null :
                                                        <span>
                                                            ({marks.map((z, q) => <ZSpan
                                                            key={q}>{`${z.type}:${z.mark < 0 ? '✔️' : Math.round(z.mark)},`}</ZSpan>)})
                                                        </span>
                                                    }
                                                </PointsSpan>
                                            </Td>)
                                    })}

                                </Tr>)
                        })}
                    </Tbody>

                </Table>
            </InnerPlaceholder>

            {(selectedTask == undefined || selectedUser == undefined) ? null :
                <BrutalOverlayModal onClose={() => {
                    setSelectedTask(undefined);
                    setSelectedUser(undefined);
                }}>

                    <InnerModalPlaceholder>

                        <Heading>
                            {CommonHelper.getUserName(selectedUser)}
                        </Heading>

                        {(selectedTask.name == undefined || selectedTask.name == '') ? null :
                            <SubHeading>
                                {(selectedTask.name == undefined ? '' : selectedTask.name)}
                            </SubHeading>
                        }

                        <DeadlineTimestamp>
                            {`${moment(selectedTask.deadlineTimestamp).format('DD.MM.YYYY')}`}
                        </DeadlineTimestamp>

                        <LessonTasksEditor
                            canEdit={false}
                            userId={selectedUser?.id}
                            selectedTaskId={selectedTask?.id}
                            tasks={[richTask]}
                        />
                    </InnerModalPlaceholder>


                </BrutalOverlayModal>
            }

        </Wrapper>
    );
}

const Heading = styled.div`
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 24px;
`;

const DeadlineTimestamp = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
  opacity: 0.7;
`;

const SubHeading = styled.div`
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 18px;
  opacity: 0.7;
`;


const InnerModalPlaceholder = styled.div`
  width: 820px;
  box-sizing: border-box;
  margin: 0 auto;
  @media (max-width: 840px) {
    width: 100%;
  }
`;

const ZSpan = styled.span`
  margin-left: 3px;
  margin-right: 3px;
`;

const PointsSpan = styled.span`
  font-size: 12px;
  opacity: 0.5;
  line-height: 16px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const Wrapper = styled.div`
  padding: 20px;
`;

const InnerPlaceholder = styled.div`
  background: white;
  padding: 5px;
  border-radius: 4px;
`;

const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const Tr = styled.tr`

`;

const Th = styled.th`
  border: 1px solid lightgrey;
`;

const Td = styled.td`
  box-sizing: border-box;
  border: 1px solid lightgrey;
  padding: 3px;
`;


const Thead = styled.thead`

`;

const Tbody = styled.tbody`

`;