import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import MaterialsAPI from "../../../api/MaterialsAPI";
import './style.css'
import NiceModal from "../../modals/NiceModal";
import {Input} from "../../ira/ui/Inputs";
import ViewIdiomTool from "../tools/ViewIdiomTool";

export default function IdiomsPanel(props) {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedName, setSelectedName] = useState(undefined);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        setLoading(true);
        MaterialsAPI.getIdiomsMaterials().then(arr => {
            setItems(arr);
            setLoading(false);
        })
    }, []);

    let se = (searchQuery == undefined) ? '' : searchQuery;
    se = se.trim().toLowerCase();

    const names = useMemo(() => {
        let getClearName = (name) => {
            name = name.replace('a ', '').replace('to ').replace('the ');
            return name;
        }
        let arr = [];
        let map = {};
        for (let i in items) {
            let name = items[i]?.name;
            map[name] = 1;
        }
        for (let key in map) {
            arr.push(key);
        }
        arr.sort((a, b) => {
            let n1 = getClearName(a.toLowerCase());
            let n2 = getClearName(b.toLowerCase());
            if (n1 > n2) {
                return 1;
            }
            if (n1 < n2) {
                return -1
            }
            return 0;
        });
        return arr;
    }, [loading, items]);

    const getIdiomsByName = useCallback((name) => {
        return items.filter((m) => {
            if (m == undefined || m.name == undefined) {
                return false;
            }
            let nm = m.name.toLowerCase();
            return (nm.indexOf(name.toLowerCase()) > -1);
        });
    }, [loading, items]);

    return (
        <Wrapper>

            <div className={'idioms_panel'}>

                <div>
                    <Input type="text" placeholder="Поиск идиомы..."
                           value={searchQuery}
                           onChange={evt => {
                               setSearchQuery(evt.target.value);
                           }}/>
                </div>

                <div className={'list_placeholder'}>

                    {loading == false ? null :
                        <div>
                            <Code/>
                        </div>
                    }

                    <div className={'idioms_list'}>
                        {names.map((nm, k) => {
                            let key = 'nm_' + k + '_' + nm;
                            let idms = getIdiomsByName(nm).filter((im) => {
                                let nam = nm.toLowerCase();
                                return (nam.indexOf(se) > -1 || (se == undefined || se == ''));
                            });
                            if (idms.length == 0) {
                                return null;
                            }
                            let idiomName = idms[0].name;
                            let idiomDescription = idms[0].description;
                            let idiomComment = idms[0].comment;
                            if (idiomComment != undefined) {
                                idiomComment = idiomComment.replace('<b>Значение</b>', '').replace('\n', '<br/>');
                            }

                            return (
                                <div className={'idiom_item'} key={key} onClick={
                                    () => {
                                        setSelectedName(nm);
                                        // let mats = this.getIdiomsByName(nm);
                                        // selectMaterial(mats[0].id, mats.map(m => m.id))
                                    }
                                }>
                                    <div className={'name_placeholder'}>
                                        <div className={'name'}>
                                            {idiomName} <span className={'view_example'}>нажмите, чтобы посмотреть примеры</span>
                                        </div>
                                    </div>
                                    <div className={'description_placeholder'}>
                                        <div className={'description'}>
                                            <div dangerouslySetInnerHTML={{__html: idiomComment}}></div>
                                        </div>
                                    </div>

                                </div>
                            )

                        })}
                    </div>

                </div>

                {selectedName == undefined ? null :
                    <NiceModal onClose={() => {
                        setSelectedName(undefined);
                    }}>
                        <IdiomPlaceholder className={'selected_idioms_panel'}>
                            <ViewIdiomTool
                                name={selectedName}
                                items={getIdiomsByName(selectedName)}
                            />
                            {/*<GridMaterialsList materials={this.getIdiomsByName(selectedName)} onMaterialClick={(m) => {*/}
                            {/*    selectMaterial(m.id)*/}
                            {/*}}/>*/}
                        </IdiomPlaceholder>
                    </NiceModal>
                }

            </div>

        </Wrapper>
    );
}

const IdiomPlaceholder = styled.div`
  width: 820px;
  box-sizing: border-box;
  @media (max-width: 860px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`

`;