import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import TeacherTemplate from "../templates/TeacherTemplate";
import InstructionsPanel from "../instructions/panels/InstructionsPanel";

export default function StudentInstructionsApp(props) {

    return (
        <TeacherTemplate active={'instructions'}>

            <InstructionsPanel mode={'student'} />

        </TeacherTemplate>
    );
}

const Wrapper = styled.div`

`;

const ConstructionPlaceholder = styled.div`
  height: 80vh;
  text-align: center;
`;