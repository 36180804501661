import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import TeacherTemplate from "../templates/TeacherTemplate";
import GroupsPanel from "../groups/panels/GroupsPanel";
import TeacherGroupPanel from "../groups/panels/TeacherGroupPanel";
import {useMappedState} from "redux-react-hook";

export default function TeacherGroupApp(props) {
    let {id, tab} = props.match.params;
    const {
        lessons = []
    } = useMappedState(useCallback(state => {
        return {
            lessons: state.lessons.lessonsMap.toArray().filter(x => (x?.classId == id)).sort((a, b) => (+b.startTimestamp - +b.startTimestamp)),
            loading: state.lessons.loading
        }
    }));
    let rTab = (tab == undefined) ? 'tasks' : tab;
    let hasAtLeastOneLesson = (lessons.length > 0);

    return (
        <TeacherTemplate
            active={`group/${id}`}
            hasLeft={rTab != 'tasks'}
        >

            <React.Fragment key={rTab}>
                <TeacherGroupPanel
                    tab={rTab}
                    id={id}
                />
            </React.Fragment>

        </TeacherTemplate>
    );
}

const Wrapper = styled.div`

`;
