import * as types from '../ActionTypes'



const initialState = {
    leftPanelState: true,
    middlePanelState: false,
    rightPanelState: false,

    coursesPanelState: false,
    lessonsPanelState: true
}

const PanelSelectorReducer = (state = initialState, action = {}) => {
    switch (action.type){
        case types.SHOW_LEFT_PANEL:
            return {
                ...state,
                leftPanelState: true,
                middlePanelState: false,
                rightPanelState: false
            }
        case types.SHOW_MIDDLE_PANEL:
            return {
                ...state,
                leftPanelState: false,
                middlePanelState: true,
                rightPanelState: false
            }
        case types.SHOW_RIGHT_PANEL:
            return {
                ...state,
                leftPanelState: false,
                middlePanelState: false,
                rightPanelState: true
            }
        case types.SHOW_COURSES_PANEL:
            return {
                ...state,
                coursesPanelState: true,
                lessonsPanelState: false
            }
        case types.SHOW_LESSONS_PANEL:
            return {
                ...state,
                lessonsPanelState: true,
                coursesPanelState: false
            }


        default:
            return state
    }
}

export default PanelSelectorReducer;