import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import mp3_image from '../../../assets/img/mp3.png'
import UploadAPI from "../../../api/UploadAPI";

export default function DownloadMp3Span(props) {
    const {url, mp3FileName} = props;
    const [loading, setLoading] = useState(false);

    if (url == undefined) {
        return null;
    }

    let xUrl = url.replace('files.ru-spb-2.englishpatient.org', 'files.entsu.ru');
    let mp3Name = 'audio.mp3'

    if (mp3FileName == undefined){
        if (props?.content?.transcript != undefined && props?.content?.transcript.length > 2){
            mp3Name = `${props?.content?.transcript.slice(0, 60)}.mp3`;
        }
    }else{
        mp3Name = mp3FileName;
    }

    return (
        <Wrapper onClick={async () => {
            console.log('props = ', props);
            if (loading == true) {
                return;
            }
            setLoading(true);
            let mp3Url = await UploadAPI.getMp3Url(xUrl);
            await download(mp3Url, mp3Name);
            setLoading(false);
        }}>
            {loading == true ?
                <Spin visible={true}/>
                :
                <>
                    <img style={{height: 16, marginRight: 5}} src={mp3_image}/>
                    <span style={{fontSize: 12}}>
                        .mp3
                    </span>
                </>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

function download(url, filename) {
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        })
        .catch(console.error);
}