import firebase from 'firebase'

import {FIREBASE_CREDENTIALS} from "../constants/config";

const SmartFirebaseAPI = {

    initFirebase() {
        if (!firebase.apps.length) {
            firebase.initializeApp(FIREBASE_CREDENTIALS);
        }
    },

    getDeviceId() {
        let deviceId = window.localStorage.getItem('shkype_device_id');
        if (deviceId == undefined || deviceId == '' || deviceId == 'null' || deviceId == 'undefined') {
            deviceId = `${+new Date()}-${Math.round(Math.random() * 10000)}`;
            window.localStorage.setItem('shkype_device_id', deviceId);
        }
        return deviceId;
    },

    getRoom(roomName) {
        return new Promise((resolve, reject) => {
            let ref = firebase.database().ref(`/VideoRoom/${roomName}`).once('value').then((snapshot) => {
                console.log('room snapshot = ', snapshot.val());
                resolve(snapshot.val());
            });
        })
    },

    getLazyRoomCreatable(roomName) {
        return new Promise((resolve, reject) => {
            this.getRoom(roomName).then(pld => {
                if (pld == undefined) {
                    let data = {
                        participants: [{
                            deviceId: this.getDeviceId(),
                            playerState: {
                                url: '',
                                state: 'paused',
                                position: 0,
                                bufferization: 0,
                                cached: false
                            }
                        }]
                    };
                    firebase.database().ref(`/VideoRoom`).child(roomName).set(data).then(snapshot => {
                        resolve(data);
                    }).catch(err => {
                        console.log('caught error = ', err);
                        reject(err);
                    });
                } else {
                    resolve(pld);
                }
            }).catch(err => reject(err))
        })
    },

    getLazyRoom(roomName) {
        return new Promise((resolve, reject) => {
            this.getLazyRoomCreatable(roomName).then(d => {
                let deviceId = this.getDeviceId();
                if ((d.participants.map(a => a.deviceId).indexOf(deviceId) == -1)) {
                    d.participants.push({
                        deviceId: deviceId,
                        t: +new Date(),
                        playerState: {
                            url: '',
                            state: 'paused',
                            position: 0,
                            bufferization: 0,
                            cached: false
                        }
                    });
                    firebase.database().ref(`/VideoRoom/${roomName}`).set(d).then(snp => {
                        resolve(d);
                    });
                } else {
                    resolve(d);
                }
            })
        })
    },

    subscribeOnChanges(roomName, onChangeCallback) {
        console.log('!!! subscribeOnChanges occured: roomName = ', roomName);
        return new Promise((resolve, reject) => {
            this.getLazyRoom(roomName).then(pld => {
                let starCountRef = firebase.database().ref('/VideoRoom/' + roomName);
                starCountRef.on('value', function (snapshot) {
                    console.log('onUpdate: snapshot.val() = ', snapshot.val());
                    let v = snapshot.val();
                    let MAX_TIME_OFFSET = 1 * 60 * 60 * 1000;
                    if (v != undefined && v.participants != undefined) {
                        v.participants = v.participants.filter(p => (p != undefined && (p.t != undefined) && ((+new Date() - +p.t) < MAX_TIME_OFFSET)))
                    }
                    onChangeCallback(v);
                });
                resolve(pld);
            });
        })
    },

    unsubscribeOnChanges(roomName) {
        console.log('!!! unsubscribeOnChanges occured: roomName = ', roomName);
        return new Promise((resolve, reject) => {
            this.getLazyRoom(roomName).then(pld => {
                let starCountRef = firebase.database().ref('/VideoRoom/' + roomName);
                starCountRef.off('value');
                resolve(pld);
            });
        })
    },

    updatePlayerState(roomName, oldRoomState, newPlayerState, newPosition, url, bufferization, cached) {
        console.log('FirebasAPI: updatePlayerState: roomName, oldRoomState, newPlayerState, newPosition, url, bufferization, cached = ', roomName, oldRoomState, newPlayerState, newPosition, url, bufferization, cached);
        return new Promise((resolve, reject) => {
            let starCountRef = firebase.database().ref('/VideoRoom/' + roomName);
            let participants = (oldRoomState == undefined || oldRoomState.participants == undefined) ? [] : oldRoomState.participants;
            participants = JSON.parse(JSON.stringify(participants));
            let deviceId = this.getDeviceId();
            let newPt = {}, newParticipants = [], f = false;
            console.log('before cycling');
            for (let i in participants) {
                if (participants[i].deviceId == deviceId) {
                    if (newPlayerState != undefined) {
                        participants[i].playerState.state = newPlayerState;
                    }
                    if (newPosition != undefined) {
                        participants[i].playerState.position = newPosition;
                    }
                    if (url != undefined) {
                        participants[i].playerState.url = url;
                    }
                    if (cached != undefined) {
                        participants[i].playerState.cached = cached;
                    }
                    if (bufferization != undefined) {
                        participants[i].playerState.bufferization = bufferization;
                        if (bufferization != 100) {
                            participants[i].playerState.cached = false;
                        }
                    }
                    participants[i].t = +new Date();
                    newPt = participants[i];
                    f = true;
                } else {
                    newParticipants.push(participants[i]);
                }
            }
            if (f == false) {
                newPt = {
                    t: +new Date(),
                    deviceId: this.getDeviceId(),
                    playerState: {
                        cached: true,
                        bufferization: 100,
                        url: url,
                        position: 0,
                        state: newPlayerState
                    }
                }
            }
            console.log('newPt = ', newPt);
            newParticipants = [newPt].concat(newParticipants);
            let notMineParticipants = newParticipants.filter(p => (p.deviceId != deviceId));
            let myParticipants = newParticipants.filter(p => (p.deviceId == deviceId));
            if (notMineParticipants.length > 0) {
                newParticipants = myParticipants.concat([notMineParticipants[0]]);
            } else {
                newParticipants = [myParticipants[0]];
            }
            console.log('setting new participants: newParticipants = ', newParticipants);
            let newRoomState = {
                participants: newParticipants
            };
            starCountRef.set(newRoomState);
            resolve(newRoomState);
        })
    },

    getImageRoom(roomName) {
        return new Promise((resolve, reject) => {
            let ref = firebase.database().ref(`/SyncImage/${roomName}`).once('value').then((snapshot) => {
                resolve(snapshot.val());
            });
        })
    },

    getImageLazyRoom(roomName) {
        return new Promise((resolve, reject) => {
            firebase.database().ref(`/SyncImage`).child(roomName).transaction(currentImageData => {
                if (currentImageData === null) {
                    return {
                        lines: []
                    }
                }
            }, (err, commited) => {
                if (err != undefined) {
                    return reject(err)
                }
                resolve();
            })
        })
    },


    subscribeImageOnChanges(roomName, onChangeCallback) {
        console.log('!!! subscribeOnChanges occured: roomName = ', roomName);
        return new Promise((resolve, reject) => {
            this.getImageLazyRoom(roomName).then(pld => {
                let starCountRef = firebase.database().ref('/SyncImage/' + roomName);
                starCountRef.on('value', function (snapshot) {
                    let v = snapshot.val();
                    onChangeCallback(v);
                });
                resolve(pld);
            });
        })
    },

    updateImageFireState(roomName, newLines) {
        console.log('FirebaseSyncImageAPI: roomName: newLines = ', roomName, newLines);
        return new Promise((resolve, reject) => {
            let starCountRef = firebase.database().ref('/SyncImage/' + roomName);
            let newRoomState = {
                lines: newLines
            };
            starCountRef.set(newRoomState);
            resolve(newRoomState);
        })
    },

    unsubscribeOnImageChanges(roomName) {
        return new Promise((resolve, reject) => {
            this.getImageLazyRoom(roomName).then(pld => {
                let starCountRef = firebase.database().ref('/SyncImage/' + roomName);
                starCountRef.off('value');
                resolve(pld);
            });
        })
    },

    getLessonZoomRoom(roomName) {
        return new Promise((resolve, reject) => {
            let ref = firebase.database().ref(`/Zoom/${roomName}`).once('value').then((snapshot) => {
                resolve(snapshot.val());
            });
        })
    },

    getLessonZoomLazyRoom(roomName) {
        return new Promise((resolve, reject) => {
            firebase.database().ref(`/Zoom`).child(roomName).transaction(currentImageData => {
                if (currentImageData === null) {
                    return {
                        chambers: [],
                        chamberMode: false,
                        lessonSettings: {}
                    }
                }
            }, (err, commited) => {
                if (err != undefined) {
                    return reject(err)
                }
                resolve();
            })
        })
    },


    subscribeLessonZoomOnChanges(roomName, onChangeCallback) {
        return new Promise((resolve, reject) => {
            this.getLessonZoomLazyRoom(roomName).then(pld => {
                let starCountRef = firebase.database().ref('/Zoom/' + roomName);
                starCountRef.on('value', function (snapshot) {
                    let v = snapshot.val();
                    onChangeCallback(v);
                });
                resolve(pld);
            });
        })
    },

    unsubscribeFromLessonZoomChanges(roomName) {
        return new Promise((resolve, reject) => {
            this.getLessonZoomLazyRoom(roomName).then(pld => {
                let starCountRef = firebase.database().ref('/Zoom/' + roomName);
                starCountRef.off('value');
                resolve(pld);
            });
        })
    },

    updateLessonZoomFireState(roomName, chambers, chamberMode = false, lessonSettings = {}) {
        console.log('updateLessonZoomFireState: roomName, chambers, chamberMode, lessonSettings = ', roomName, chambers, chamberMode, lessonSettings);
        return new Promise((resolve, reject) => {
            let starCountRef = firebase.database().ref('/Zoom/' + roomName);
            let newRoomState = {
                chambers: chambers,
                chamberMode: chamberMode,
                lessonSettings: lessonSettings
            };
            starCountRef.set(newRoomState);
            resolve(newRoomState);
        })
    }

}

export default SmartFirebaseAPI;
