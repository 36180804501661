import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import Sidebar from 'arui-feather/sidebar'
import {BlueButton, GreenButton} from "../../ira/ui/Buttons";
import MaterialSearchPanel from "../../materials/panels/MaterialSearchPanel";

import Spin from 'arui-feather/spin'
import SimpleFileUploader from "../../uploader/tools/SimpleFileUploader";
import {Input, Textarea} from "../../ira/ui/Inputs";
import cit_img from "../../../assets/img/citation.svg";
import SimpleCitationTool from "../../citation/SimpleCitationTool";
import SearchOrCreateMaterialsPanel from "../../materials/panels/SearchOrCreateMaterialsPanel";
import AccessGroupsDropdownPanel from "../../access_groups/panels/AccessGroupsDropdownPanel";
import Select from "react-select";
import {ACCESS_OPTIONS} from "../../exercises/forms/ExerciseInfoForm";

export default function UpdateDialogForm(props) {
    const {
        loading = false,
        onSave = d => {

        }
    } = props;

    const [access, setAccess] = useState(props.access ? props.access : 'private');
    const [accessGroupsIds, setAccessGroupsIds] = useState(props?.accessGroupsIds ? props.accessGroupsIds : []);
    const [searchModalVisible, setSearchModalVisible] = useState();
    const [copyrightDescription, setCopyrightDescription] = useState(props.copyrightDescription == undefined ? '' : props.copyrightDescription);
    const [name, setName] = useState(props.name || '');
    const [url, setUrl] = useState(props.url);
    const [avatar, setAvatar] = useState(props.avatar);
    const [description, setDescription] = useState(props.description || '');
    const [firstRoleImg, setFirstRoleImg] = useState(props.firstRoleImg);
    const [secondRoleImg, setSecondRoleImg] = useState(props.secondRoleImg);
    const [firstRoleName, setFirstRoleName] = useState(props.firstRoleName);
    const [secondRoleName, setSecondRoleName] = useState(props.secondRoleName || '');
    const [studentTask, setStudentTask] = useState(props.studentTask || '');
    const [citationModalVisible, setCitationModalVisible] = useState(false);

    return (
        <Wrapper className={''}>

            {url == undefined ? null :
                <VideoPlaceholder>
                    <Video controls={true}>
                        <source src={url}/>
                    </Video>
                </VideoPlaceholder>
            }

            <Row>

                <Field
                    style={{display: 'flex', flexDirection: 'row'}}
                >
                    <BlueButton onClick={() => {
                        setSearchModalVisible(true);
                    }}>
                        Выбрать видео
                    </BlueButton>
                </Field>

                <Field>
                    <Label>
                        Название диалога
                    </Label>
                    <Input value={name} placeholder={'Название'} onChange={evt => {
                        setName(evt.target.value);
                    }}/>
                </Field>

                <Field>
                    <Label>
                        Описание диалога
                    </Label>
                    <Textarea value={description} placeholder={'Описание'} onChange={evt => {
                        setDescription(evt.target.value);
                    }}/>
                </Field>

                <Field>
                    <Label>
                        Формулировка задания для обучающегося
                    </Label>
                    <Textarea value={studentTask} placeholder={'Формулировка задания для обучающегося'} onChange={evt => {
                        setStudentTask(evt.target.value);
                    }}/>
                </Field>

                <Field>
                    <Label>
                        Аватар упражнения
                    </Label>
                    {avatar == undefined ? null :
                        <AvaImg src={avatar}/>
                    }
                    <SimpleFileUploader
                        currentImage={avatar}
                        onSave={s => {
                            setAvatar(s);
                        }}
                    />
                    {avatar == '' ? null :
                        <DelelteAvatarButton onClick={() => {
                            setAvatar(''); 
                         }}>удалить</DelelteAvatarButton>
                    }
                </Field>

                <Field>
                    <Label>
                    <span>
                        Информация об источниках
                    </span>
                        <CitImg src={cit_img} onClick={() => {
                            setCitationModalVisible(true);
                        }}/>
                    </Label>
                    <Textarea value={copyrightDescription} placeholder={'Информация об источниках'} onChange={evt => {
                        setCopyrightDescription(evt.target.value);
                    }}></Textarea>
                </Field>

                <Row className="field">
                    <Label>
                        <span>
                            Доступ
                        </span>
                    </Label>
                    <Select options={ACCESS_OPTIONS}
                            value={ACCESS_OPTIONS.filter(x => (x.value == access))[0]}
                            onChange={a => {
                                setAccess(a.value);
                            }}/>
                </Row>

                {access != 'shared' ? null :
                    <Row className="field">
                        <Label>
                        <span>
                            Группы доступа
                        </span>
                        </Label>
                        <AccessGroupsDropdownPanel
                            selectedIds={accessGroupsIds}
                            onChange={x => {
                                setAccessGroupsIds(x);
                            }}
                        />
                    </Row>
                }

            </Row>

            <Row>
                <Half>
                    <Field>
                        <Label>
                            Имя первого персонажа
                        </Label>
                        <Input value={firstRoleName} placeholder={''} onChange={evt => {
                            setFirstRoleName(evt.target.value);
                        }}/>
                    </Field>
                    <Field>
                        <Label>
                            Лицо первого персонажа
                        </Label>
                        {firstRoleImg == undefined ? null :
                            <Avatar src={firstRoleImg}/>
                        }
                        <SimpleFileUploader
                            onSave={s => {
                                setFirstRoleImg(s);
                            }}
                        />
                    </Field>
                </Half>
                <Half>
                    <Field>
                        <Label>
                            Имя второго персонажа
                        </Label>
                        <Input value={secondRoleName} placeholder={''} onChange={evt => {
                            setSecondRoleName(evt.target.value);
                        }}/>
                    </Field>
                    <Field>
                        <Label>
                            Лицо второго персонажа
                        </Label>
                        {secondRoleImg == undefined ? null :
                            <Avatar src={secondRoleImg}/>
                        }
                        <SimpleFileUploader
                            onSave={s => {
                                setSecondRoleImg(s);
                            }}
                        />
                    </Field>
                </Half>
            </Row>

            <Row>
                <Field>
                    {loading == true ? <span>...</span> :
                        <GreenButton onClick={() => {
                            onSave({
                                name: name,
                                studentTask: studentTask,
                                description: description,
                                avatar: avatar,
                                url: url,
                                secondRoleName: secondRoleName,
                                firstRoleName: firstRoleName,
                                secondRoleImg: secondRoleImg,
                                firstRoleImg: firstRoleImg,
                                copyrightDescription: copyrightDescription,
                                accessGroupsIds: accessGroupsIds,
                                access: access
                            });
                        }}>
                            <Spin visible={loading}/>
                            {loading == true ? null :
                                'Сохранить'
                            }
                        </GreenButton>
                    }

                </Field>
            </Row>

            <Sidebar
                width={Math.min(1020, window.innerWidth)}
                visible={searchModalVisible}
                onCloserClick={() => {
                    setSearchModalVisible(false);
                }}>

                {searchModalVisible == false ? null :
                    <InnerSidebar>
                        <SearchOrCreateMaterialsPanel
                            type={'materials'}
                            onSelect={item => {
                                setUrl(item.url);
                                setSearchModalVisible(false);
                            }}/>
                    </InnerSidebar>
                }

            </Sidebar>

            <Sidebar width={window.innerWidth} visible={citationModalVisible} onCloserClick={() => {
                setCitationModalVisible(false);
            }}>

                {citationModalVisible == false ? null :
                    <InnerPlaceholder>
                        <SimpleCitationTool
                            onQuote={s => {
                                setCopyrightDescription(s);
                                setCitationModalVisible(false);
                            }}
                        />
                    </InnerPlaceholder>
                }

            </Sidebar>

        </Wrapper>
    );
}

const InnerPlaceholder = styled.div`
  height: calc(100vh - 80px);
  width: calc(100vw - 100px);
`;

const CitImg = styled.img`
  opacity: 0.7;
  cursor: pointer;
  height: 20px;
  margin-left: 10px;

  :hover {
    opacity: 1;
  }
`;


const Wrapper = styled.div`
  width: 640px;
  margin: 0 auto;
  background: white;
  @media (max-width: 640px) {
    width: 75vw;
    margin: 0;
  }
`;

const Field = styled.div`
  margin-bottom: 10px;
`;

const InnerSidebar = styled.div`
  line-height: 18px;
  height: calc(80vh - 10px);
  padding-top: 10px;
  box-sizing: border-box;
`;


const Label = styled.div`
  font-weight: bold;
`;

const avaSize = 90;

const Avatar = styled.div`
  display: inline-block;
  vertical-align: top;
  width: ${props => (props.size == undefined ? avaSize : props.size)}px;
  height: ${props => (props.size == undefined ? avaSize : props.size)}px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
  border-radius: 1000px;
  background-color: lightgrey;
  cursor: pointer;
  border: 2px solid wheat;

  :hover {
    opacity: 0.8;
  }
`;

const AvaImg = styled.img`
  height: 120px;
`;

const Half = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
  box-sizing: border-box;
  padding: 5px;
`;

const Row = styled.div`
  margin-bottom: 10px;
  padding: 5px;
`;

const VideoPlaceholder = styled.div`
  height: 360px;
  width: 100%;
`;

const Video = styled.video`
  height: 100%;
  width: 100%;
  background: lightgray;
`;

const DelelteAvatarButton = styled.div`
  color: red;
  cursor: pointer;
`;