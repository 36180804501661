import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {Set} from 'immutable'

import downChevron from '../../../assets/img/downArrow.svg'
import FolderTypeImage from "./FolderTypeImage";
// import ModulesListPanel from "../panels/ModulesListPanel";
import {getSortedSlides} from "../../apps/ShareApp";
import ExercisesSimpleListPanel from "../../exercises/panels/ExercisesSimpleListPanel";
import TreadmillsSimpleListPanel from "../../treadmills_exercises/panels/TreadmillsSimpleListPanel";

export default function RecursiveFoldersTree(props) {
    const {

        showContentItems = true,
        filteredModulesIds = [],
        searchMode = false,

        showModulesList = true,

        hoverHighlight = true,

        parentFolderId,
        loadingItemId = undefined,
        expandedSet = Set(),
        folders = [],
        getChildFolders = cId => [],
        expandToggle = cId => {

        },
        onFolderClick = cId => {

        },
        renderRightBlockFunction = (cId, parId) => {

        },
        onModuleClick = id => {

        },
        isSharedTree = false
    } = props;

    if (folders == undefined || folders.length == 0) {
        return null;
    }
    let sortedFolders = getSortedSlides(folders);

    console.log('showModulesList = ', showModulesList);
    console.log('RecursiveFoldersTree: searchMode = ', searchMode);

    return (
        <Wrapper>

            <FoldersListPlaceholder>
                {sortedFolders.map((c, i) => {
                    let isExpanded = expandedSet.has(c.id);
                    let childFolders = getChildFolders(c.id);
                    let isLoading = (loadingItemId == c.id);
                    return (
                        <FolderItem
                            loading={isLoading}
                            key={c.id}>
                            <FolderInfoPlaceholder className={`${hoverHighlight && 'folder_info_placeholder'}`}>

                                <ItemCenterNameSection onClick={() => {
                                    if (c.type != 'task') {
                                        expandToggle(c.id);
                                    }
                                }}>
                                    {(c.type == 'block' || (childFolders.length == 0 && (c?.modulesIds == undefined || c?.modulesIds.length == 0) && (c?.exercisesIds == undefined || c?.exercisesIds.length == 0) && (c?.dialogsIds == undefined || c?.dialogsIds.length == 0) && (c?.drochkaExercisesIds == undefined || c?.drochkaExercisesIds.length == 0) && (c?.treadmillsIds == undefined || c?.treadmillsIds.length == 0)) || (showModulesList == false && childFolders.length == 0)) ? null :
                                        <ChevronImg src={downChevron}
                                                    style={{transform: `rotate(${isExpanded ? 180 : 0}deg)`}}
                                                    onClick={() => {
                                                        if (c.type == 'task') {
                                                            expandToggle(c.id);
                                                        }
                                                    }}
                                        />
                                    }
                                    <FolderTypeImage {...c} />

                                    <NameTextPlaceholder onClick={() => {
                                        onFolderClick(c?.id);
                                    }}>
                                        {!c?.name ? 'No name' : c?.name}
                                        {((showModulesList == false || searchMode == true) || ((c?.modulesIds == undefined ? [] : c?.modulesIds).length == 0) || (isExpanded == true)) ? null :
                                            <BubbleSpan>
                                                {`${c?.modulesIds.length}`}
                                            </BubbleSpan>
                                        }
                                    </NameTextPlaceholder>
                                </ItemCenterNameSection>

                                <ControlsPlaceholder>
                                    {renderRightBlockFunction(c?.id, parentFolderId)}
                                    {/*<span>
                                        ребята, сюда добавьте кнопку с копированием, выпадающим списком
                                    </span>*/}
                                </ControlsPlaceholder>

                            </FolderInfoPlaceholder>

                            {isExpanded == false ? null :
                                <ChildrenPlaceholder>

                                    <RecursiveFoldersTree
                                        {...props}
                                        parentFolderId={c?.id}
                                        folders={childFolders}
                                    />

                                    {showContentItems == false ? null :
                                        <>
                                            {(showModulesList == false || c?.modulesIds == undefined || c?.modulesIds.length == 0) ? null :
                                                <div style={{paddingLeft: 19}}>
                                                    {/*<ModulesListPanel*/}
                                                    {/*    {...c}*/}
                                                    {/*    modulesIds={searchMode == false ? c.modulesIds : c.modulesIds.filter(xx => (filteredModulesIds.indexOf(xx) > -1))}*/}
                                                    {/*    isSharedTree={isSharedTree}*/}
                                                    {/*    onModuleClick={onModuleClick}*/}
                                                    {/*/>*/}
                                                </div>
                                            }

                                            {(c?.exercisesIds == undefined || c?.exercisesIds.length == 0) ? null :
                                                <div key={`upd-${c?.updatedTimestamp}`} style={{paddingLeft: 19}}>
                                                    <ExercisesSimpleListPanel
                                                        {...c}
                                                    />
                                                </div>
                                            }

                                            {(c?.treadmillsIds == undefined || c?.treadmillsIds.length == 0) ? null :
                                                <div key={`upd-${c?.updatedTimestamp}`} style={{paddingLeft: 19}}>
                                                    <TreadmillsSimpleListPanel
                                                        {...c}
                                                    />
                                                </div>
                                            }
                                        </>
                                    }




                                </ChildrenPlaceholder>
                            }

                        </FolderItem>
                    )
                })}
            </FoldersListPlaceholder>

        </Wrapper>
    );
}

const BubbleSpan = styled.span`
  padding-left: 4px;
  padding-right: 4px;
  color: white;
  font-weight: bold;
  background: #FFA71B;
  border-radius: 4px;
  margin-left: 4px;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Wrapper = styled.div`

`;

const ControlsPlaceholder = styled.div`

`;

const ItemCenterNameSection = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 3px;
`;

const NameTextPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ChevronImg = styled.img`
  margin-right: 5px;
`;

const FolderInfoPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
`;

const FoldersListPlaceholder = styled.div`

`;

const FolderItem = styled.div`
  opacity: ${props => (props.loading == true ? 0.7 : 1)};
  margin-bottom: 10px;
  margin-top: 10px;

  .folder_info_placeholder {
    :hover {
      background: whitesmoke;
      border-radius: 4px;
    }
  }
`;

const ChildrenPlaceholder = styled.div`
  box-sizing: border-box;
  //padding-left: 20px;
  padding-left: 13px;
  width: 100%;
`;
