import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import KvdbAPI from "../../../api/KvdbAPI";
import ReactHelper from "../../../helpers/ReactHelper";

const gs = a => (+a < 10 ? `0${a}` : `${a}`);

export default function DeadlineCountdownPanel(props) {
    const {
        name = 'test',
        onDeadline = () => {

        }
    } = props;

    const [deadlineTimestamp, setDeadlineTimestamp] = useState(undefined);
    const [timeString, setTimeString] = useState(undefined);

    useEffect(() => {
        KvdbAPI.getValue(`countdown_${name}`, {}).then(pld => {
            if (pld.deadlineTimestamp == undefined) {
                return;
            }
            setDeadlineTimestamp(+pld.deadlineTimestamp);
        });
    }, [name]);

    ReactHelper.useInterval(() => {
        if (deadlineTimestamp == undefined) {
            return;
        }
        let delta = +deadlineTimestamp - (+new Date());
        if (delta < 0) {
            onDeadline();
            return;
        }
        let total = Math.abs(delta);
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        let s = `${gs(hours)}:${gs(minutes)}:${gs(seconds)}`;
        setTimeString(s);
    }, 1000);

    if (deadlineTimestamp == undefined || timeString == undefined) {
        return null;
    }

    return (
        <Wrapper>
            {timeString}
        </Wrapper>
    );
}

const Wrapper = styled.div`

`;