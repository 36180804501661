import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';

import axios from 'axios'

export default function SimpleRecordTool(props) {
    const {
        onVideoSave = url => {

        },
        recordedUrl = undefined,
        isPreview = false
    } = props;

    console.log('SimpleRecordTool: recordedUrl = ', recordedUrl);
    console.log('SimpleRecordTool: isPreview = ', isPreview);

    useEffect(() => {

        if (window.addEventListener) {
            window.addEventListener("message", onMessage, false);
        } else if (window.attachEvent) {
            window.attachEvent("onmessage", onMessage, false);
        }

        function onMessage(event) {
            var data = event.data;

            if (typeof (window[data.func]) == "function") {
                window[data.func].call(null, data.message);
            }
        }

        window.videoRecordHandler = (message) => {
            let videoUrl = message;
            onVideoSave(videoUrl);
        }
    }, []);

    if (isPreview == true && recordedUrl != undefined) {
        return (
            <PreviewWrapper>
                <Video control={true}>
                    <source src={recordedUrl}/>
                </Video>
            </PreviewWrapper>
        )
    }



    return (
        <Wrapper>

            {recordedUrl == undefined ? null :
                <PreviewWrapper key={recordedUrl} >
                    <Video controls={true}>
                        <source src={recordedUrl}/>
                    </Video>
                </PreviewWrapper>
            }

            <CameraPlaceholder>
                <CameraIframe src={`https://record.wave1.sabir.pro/?t=${+new Date()}`}
                              allow={'camera;microphone'}
                />
            </CameraPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const PreviewWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CameraPlaceholder = styled.div`
  height: 440px;
  width: 600px;
  background: grey;
  position: relative;
  margin: 0 auto;
`;

const Video = styled.video`
  width: 100%;
  height: 480px;
`;


const CameraIframe = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
`;