import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import ExercisesAPI from "../../../api/ExercisesAPI";
import TestViewTool from "../tools/TestViewTool";
import ExerciseViewTool from "../../exercises/tools/ExerciseViewTool";
import TestsAPI from "../../../api/TestsAPI";

export default function TestPanel(props) {
    const {
        userId,
        id,
        isPreview = false
    } = props;

    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [answersLoading, setAnswersLoading] = useState(false);
    const [userAnswers, setUserAnswers] = useState([]);
    const [answering, setAnswering] = useState(false);

    useEffect(() => {
        setLoading(true);
        TestsAPI.getExercise(id).then(d => {
            setData(d);
            setLoading(false);
            if (isPreview == true) {
                return;
            }
            setAnswersLoading(true);
            TestsAPI.getExerciseAnswers(id, userId).then(a => {
                setAnswersLoading(false);
                setUserAnswers(a);
            });
        });
    }, [id]);

    if (data == undefined && loading == true) {
        return (
            <div style={{padding: 10}}>
                <Code/>
            </div>
        )
    }

    if (data == undefined) {
        return null;
    }

    let isFinished = (userAnswers.filter(x => (x.cardId == id)).length > 0);

    return (
        <Wrapper>
            <TestViewTool
                {...data}
                userAnswers={userAnswers}
                loading={answering || answersLoading || loading}
                isPreview={isPreview || isFinished}
                isFinished={isFinished}
                showIfCorrect={isFinished}
                onAnswer={async d => {
                    // console.log('TestViewTool: onAnswer: d = ', d);
                    setAnswering(true);
                    let ans = await TestsAPI.saveExerciseAnswer(id, d.cardId, d.answer, d.isCorrect);
                    // let newAnswers = userAnswers.map(xx => (xx.cardId == ans.cardId ? ans : xx));
                    let newAnswers = userAnswers.filter(xx => (xx.cardId != ans.cardId)).concat([ans]);
                    setUserAnswers(newAnswers);
                    setAnswering(false);
                }}
                onFinish={async d => {
                    console.log('TestViewTool: onFinish: d = ', d);
                    setAnswering(true);
                    let ans = await TestsAPI.saveExerciseAnswer(id, id, 'finished'); //
                    // let newAnswers = userAnswers.map(xx => (xx.cardId == ans.cardId ? ans : xx));
                    let newAnswers = userAnswers.filter(xx => (xx.cardId != ans.cardId)).concat([ans]);
                    setUserAnswers(newAnswers);
                    setAnswering(false);
                }}

            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;