import axios from "axios";
import {API_ENDPOINT} from "../constants/config";

const SmartFoldersAPI = {

    async getSmartFolders(userId) {
        let pld = (await axios.get(`${API_ENDPOINT}/smart-folders`, {
            params: {
                userId: userId
            }
        })).data;
        return pld;
    },

    async createSmartFolder(data) {
        let pld = (await axios.post(`${API_ENDPOINT}/smart-folders`, {
            ...data
        })).data;
        return pld;
    },

    async updateSmartFolder(data) {
        let pld = (await axios.put(`${API_ENDPOINT}/smart-folders`, {
            ...data
        })).data;
        return pld;
    },

    async deleteSmartFolder(id) {
        let pld = (await axios.delete(`${API_ENDPOINT}/smart-folders/${id}`)).data;
        return pld;
    },



}

export default SmartFoldersAPI;
