import React, {PropTypes} from 'react';
import styled from 'styled-components';


class Word extends React.Component {

    static defaultProps = {
        word: '',
        selectedWord: undefined,
        wordColor: 'black',

        onWordClick: (w) => {

        }

    }

    static propTypes = {}

    state = {}

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    componentWillReceiveProps() {

    }

    render = () => {
        let {word, selectedWord, onWordClick, wordColor} = this.props;
        let isSelected = (word == selectedWord);

        return (
            <Wrapper selected={isSelected} wordColor={wordColor} className={''} onClick={() => {
                console.log('clicking on word');
                onWordClick(word);
            }} >
                {` ${word} `}
            </Wrapper>
        )
    }

}

const Wrapper = styled.div`
    display: inline-block;
    box-sizing: border-box;
    // margin-left: 5px;
    cursor: pointer;
    border-radius: 3px;
    padding-left: ${props => (props.selected == true ? 3 : 0)}px;
    padding-right: ${props => (props.selected == true ? 3 : 0)}px;
    background-color: ${props => (props.selected == true ? '#BFE692' : 'transparent')};
    color: ${props => (props.selected == true ? 'black' : props.wordColor)};
    border: 1px solid transparent;
    :hover{
      opacity: 0.8;
      background-color: #D5FFA6;
      border: 1px solid #84AF4E;
      color: black;
    }
`;

//const mapStateToProps = (state) => {
//    return {
//        currentUserId: state.users.currentUserId,
//        loading: state.users.loading
//    }
//}

//const mapDispatchToProps = (dispatch) => {
//    return {
//    }
//}

//Word = connect(mapStateToProps, mapDispatchToProps)(Word)

export default Word
