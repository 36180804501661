import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import ls from 'local-storage'
import {useOnClickOutside} from "../components/ui/DropdownDotsMenu";
import vert_dots_img from "../assets/img/more_vert_black_24dp.svg";

const LANGS = [
    {
        label: 'RU',
        value: 'ru'
    },
    {
        label: 'EN',
        value: 'en'
    },
    {
        label: 'FR',
        value: 'fr'
    },
    {
        label: 'CN',
        value: 'cn'
    },
    {
        label: 'DE',
        value: 'de'
    }
]

export default function LangSwitcherDropdown(props) {
    const lang = ls('lang') || 'ru';
    let selectedLang = LANGS.filter(x => (x.value == lang))[0];

    const [expanded, setExpanded] = useState(false);
    const ref = useRef();
    useOnClickOutside(ref, () => setExpanded(false));

    return (
        <Wrapper expanded={expanded}>

            <SelectedLangPlaceholder expanded={expanded} onClick={() => {
                setExpanded(!expanded);
            }}>
                {selectedLang?.label}
            </SelectedLangPlaceholder>

            {expanded == false ? null :
                <MenuItemsPlaceholder ref={ref}>
                    {LANGS.filter(xx => (xx.value != lang)).map((a, i) => {
                        return (
                            <LangItem selected={lang == a.value} key={a.value} onClick={() => {
                                ls('lang', a.value);
                                window.location.reload();
                            }}>
                                {a.label}
                            </LangItem>
                        )
                    })}
                </MenuItemsPlaceholder>
            }
        </Wrapper>
    );
}

const SelectedLangPlaceholder = styled.div`
  cursor: pointer;
`;

const MenuItemsPlaceholder = styled.div`
  position: absolute;
  left: -2px;
  top: 24px;
  min-width: 26px;
  width: 26px;
  z-index: 10000;
  box-sizing: content-box;
  background: white;
  border-radius: 4px;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

`;


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const LangItem = styled.div`
  margin-right: 10px;
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  box-sizing: border-box;
  padding: 2px;
  line-height: 18px;
`;