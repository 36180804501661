import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';

import {Map, Set} from 'immutable'

import SmartFoldersAPI from "../../../api/SmartFoldersAPI";
import * as exercisesActions from "../../../redux/actions/ExercisesActions";
import * as treadmillsActions from "../../../redux/actions/TreadmillsActions";
import * as dialogsActions from "../../../redux/actions/DialogsActions";
import * as questionnairesActions from "../../../redux/actions/QuestionnairesActions";
import * as drochkaActions from "../../../redux/actions/DrochkaExercisesActions";
import * as testsActions from "../../../redux/actions/TestsActions";
import SmartFoldersTool from "../tools/SmartFoldersTool";

import {Code} from 'react-content-loader'
import {GreenButton} from "../../ira/ui/Buttons";
import Sidebar from "arui-feather/sidebar";
import FolderForm from "../tools/FolderForm";
import toast from "react-hot-toast";
import {Input} from "../../ira/ui/Inputs";
import NiceModal from "../../modals/NiceModal";

export default function ExercisesFoldersPanel(props) {
    const {
        userId,
        type = 'treadmill',
        canAddFolders = true,
        canAddExercises = true,

        onExerciseSelect = ex => {

        },

        externalComponentsEnabled = false,

        onAddExerciseClick = () => {

        },

        onEditExerciseClick = exId => {

        },

        onCreateTreadmillFromCardClick = () => {

        },

        addExerciseComponent = (onAdded = () => {

        }) => {

        },

        editExerciseComponent = (exId, onUpdated = () => {

        }) => {

        },

        selectedExerciseSet = Set(),
        exerciseSelectMode = false,

    } = props;
    const dispatch = useDispatch();

    const [folders, setFolders] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [currentFolderId, setCurrentFolderId] = useState(undefined);
    const [movingExerciseId, setMovingExerciseId] = useState(undefined);
    const [searchText, setSearchText] = useState('');

    const [addExerciseVisible, setAddExerciseVisible] = useState(false);
    const [selectedEditExerciseId, setSelectedEditExerciseId] = useState(undefined);

    const [addVisible, setAddVisible] = useState(false);
    const [selectedEditId, setSelectedEditId] = useState(undefined);

    const {
        currentUserId,
        items = []
    } = useMappedState(useCallback(state => {
        let uId = (userId || state.users.currentUserId);
        let items = [];
        if (type == 'exercise') {
            items = state.exercises.exercisesMap.toArray().filter(x => (x != undefined && x.creatorId == uId));
        }
        if (type == 'treadmill') {
            items = state.treadmills.treadmillsMap.toArray().filter(x => (x != undefined && x.creatorId == uId));
        }
        if (type == 'dialog') {
            items = state.dialogs.dialogsMap.toArray().filter(x => (x != undefined && x.creatorId == uId));
        }
        if (type == 'questionnaire') {
            items = state.questionnaires.questionnairesMap.toArray().filter(x => (x != undefined && x.creatorId == uId));
        }
        if (type == 'drochka') {
            items = state.drochkaExercises.drochkaExercisesMap.toArray().filter(x => (x != undefined && x.ownerId == uId));
        }

        if (type == 'test') {
            items = state.tests.testsMap.toArray().filter(x => (x != undefined && x.ownerId == uId));
        }

        return {
            currentUserId: state.users.currentUserId,
            items: items
        }
    }, []));

    let uId = (userId || currentUserId);

    useEffect(() => {
        if (type == 'exercise') {
            dispatch(exercisesActions.getUserExercises(uId)).then(() => {

            });
        }
        if (type == 'treadmill') {
            dispatch(treadmillsActions.loadUserTreadmills(uId)).then(() => {

            });
        }
        if (type == 'dialog') {
            dispatch(dialogsActions.loadAllDialogs(uId)).then(() => {

            });
        }
        if (type == 'questionnaire') {
            dispatch(questionnairesActions.loadAllQuestionnaires()).then(() => {

            });
        }
        if (type == 'drochka') {
            dispatch(drochkaActions.getAllDrochkaExercises()).then(() => {

            });
        }
        if (type == 'tests') {
            dispatch(testsActions.getAllTests()).then(() => {

            });
        }


    }, []);

    useEffect(() => {
        if (type == undefined) {
            return;
        }
        setFetching(true);
        SmartFoldersAPI.getSmartFolders(uId).then(arr => {
            console.log('folders', arr);
            setFolders(arr.filter(x => (x.type == type)));
            setFetching(false)
        });

    }, [uId, type]);

    if ((items.length == 0 || folders.length == 0) && fetching == true) {
        return (
            <Code/>
        )
    }

    let editingFolder = (selectedEditId == undefined) ? undefined : folders.find(x => (x.id == selectedEditId));
    let xFolders = folders.filter(x => (x.type == type));
    const movingExercise = items.find(ex => ex.id == movingExerciseId);

    let isSearching = (searchText.length > 2);
    let filteredExercises = items.filter(x => ((searchText.length < 3) || (`${x?.name}`.toLowerCase().indexOf(searchText.toLowerCase()) > -1)));
    let editExerciseItem = (selectedEditExerciseId == undefined) ? undefined : items.find(x => (x.id == selectedEditExerciseId));

    return (
        <Wrapper>

            <TopPlaceholder>
                <TopLeft>

                    {(canAddFolders == false || isSearching == true) ? null :
                        <GreenButton style={{marginRight: 10}} onClick={() => {
                            setAddVisible(true);
                        }}>
                            Создать папку
                        </GreenButton>
                    }

                    {(canAddExercises == false || isSearching == true) ? null :
                        <GreenButton style={{marginRight: 10}} onClick={() => {
                            onAddExerciseClick();
                            setAddExerciseVisible(true);
                        }}>
                            Создать упражнение
                        </GreenButton>
                    }

                    {(canAddExercises == false || isSearching == true || type != 'treadmill') ? null :
                        <GreenButton onClick={() => {
                            onCreateTreadmillFromCardClick();
                        }}>
                            Создать тредмил из карточки
                        </GreenButton>
                    }

                </TopLeft>

                <TopRight>
                    <Input
                        placeholder={'Поиск'}
                        style={{width: 280}}
                        value={searchText}
                        onChange={evt => {
                            setSearchText(evt.target.value);
                        }}
                    />
                </TopRight>

            </TopPlaceholder>

            <SmartFoldersTool
                selectedExerciseSet={selectedExerciseSet}
                exerciseSelectMode={exerciseSelectMode}

                onExerciseSelect={ex => {
                    onExerciseSelect(ex);
                }}

                exercises={filteredExercises}
                type={type}
                loading={fetching}
                folders={isSearching == true ? [] : xFolders}
                onCurrentFolderIdChange={x => {
                    setCurrentFolderId(x);
                }}
                onEditClick={folderId => {
                    setSelectedEditId(folderId);
                }}
                onDeleteClick={async delId => {
                    setFetching(true);
                    let delFolder = folders.find(x => (x.id == delId));
                    let parentFolderId = delFolder?.parentFolderId;
                    let parentFolder = (parentFolderId == undefined) ? undefined : folders.find(x => (x.id == parentFolderId));
                    let oldExercisesIds = (delFolder?.exercisesIds || []);
                    if (parentFolder != undefined) {
                        let newExercisesIds = (parentFolder?.exercisesIds || []).concat(oldExercisesIds);
                        await SmartFoldersAPI.updateSmartFolder({
                            id: parentFolderId,
                            exercisesIds: newExercisesIds
                        });
                    }
                    setCurrentFolderId(delFolder?.parentFolderId);
                    await SmartFoldersAPI.deleteSmartFolder(delId);
                    let arr = await SmartFoldersAPI.getSmartFolders(uId);
                    setFolders(arr);
                    setFetching(false);
                    toast.success('Папка удалена')
                }}
                onExerciseMoveClick={exerciseId => {
                    setMovingExerciseId(exerciseId);
                }}
                onExerciseClick={exId => {
                    onEditExerciseClick(exId);
                    setSelectedEditExerciseId(exId);
                }}
            />

            <Sidebar visible={addVisible} onCloserClick={() => {
                setAddVisible(false);
            }}>

                {addVisible == false ? null :
                    <div>
                        <FolderForm
                            loading={fetching}
                            onSave={async d => {
                                if (fetching == true) {
                                    return;
                                }
                                setFetching(true);
                                await SmartFoldersAPI.createSmartFolder({
                                    ...d,
                                    type: type,
                                    parentFolderId: currentFolderId
                                });
                                let arr = await SmartFoldersAPI.getSmartFolders(uId);
                                setFolders(arr);
                                setFetching(false);
                                setAddVisible(false);
                                toast.success('Новая папка создана');
                            }}
                        />
                    </div>
                }
            </Sidebar>

            <Sidebar visible={(editingFolder != undefined)} onCloserClick={() => {
                setSelectedEditId(undefined);
            }}>

                {editingFolder == undefined ? null :
                    <div>
                        <FolderForm
                            {...editingFolder}
                            loading={fetching}
                            onSave={async d => {
                                if (fetching == true) {
                                    return;
                                }
                                setFetching(true);
                                await SmartFoldersAPI.updateSmartFolder({
                                    id: selectedEditId,
                                    ...d
                                });
                                let arr = await SmartFoldersAPI.getSmartFolders(uId);
                                setFolders(arr);
                                setFetching(false);
                                setSelectedEditId(undefined);
                                toast.success('Папка обновлена');
                            }}
                        />
                    </div>
                }
            </Sidebar>


            <Sidebar visible={(movingExercise != undefined)} width={800} onCloserClick={() => {
                setMovingExerciseId(undefined);
            }}>

                {movingExercise == undefined ? null :
                    <div>

                        <h3>
                            {`Выберите папку, куда вы хотите переместить упражнение "${movingExercise?.name}"`}
                        </h3>

                        {currentFolderId == undefined ? null :
                            <div style={{marginBottom: 10, marginTop: 10}}>
                                <span style={{cursor: 'pointer'}} onClick={async () => {
                                    setFetching(true);
                                    let oldFolderExercisesIds = (folders.find(x => (x.id == currentFolderId))?.exercisesIds || []).filter(x => (x != movingExerciseId));
                                    if (currentFolderId != undefined) {
                                        await SmartFoldersAPI.updateSmartFolder({
                                            id: currentFolderId,
                                            exercisesIds: oldFolderExercisesIds
                                        });
                                    }
                                    let arr = await SmartFoldersAPI.getSmartFolders(uId);
                                    setFolders(arr);
                                    setMovingExerciseId(undefined);
                                    setFetching(false);
                                }}>
                                    перенести в корневую папку
                                </span>
                            </div>
                        }

                        <SmartFoldersTool
                            selectingMode={true}
                            selectionExcludedFoldersIds={[currentFolderId].filter(x => (x != undefined))}
                            type={type}
                            folders={xFolders}
                            canEdit={false}
                            onFolderSelect={async fId => {
                                if (currentFolderId == fId) {
                                    return window.alert('Упражнение уже находится в этой папке');
                                }
                                setFetching(true);
                                let oldFolderExercisesIds = (folders.find(x => (x.id == currentFolderId))?.exercisesIds || []).filter(x => (x != movingExerciseId));
                                let newFolderExercisesIds = (folders.find(x => (x.id == fId))?.exercisesIds || []).filter(x => (x != movingExerciseId)).concat([movingExerciseId]);
                                if (currentFolderId != undefined) {
                                    await SmartFoldersAPI.updateSmartFolder({
                                        id: currentFolderId,
                                        exercisesIds: oldFolderExercisesIds
                                    });
                                }
                                if (fId != undefined) {
                                    await SmartFoldersAPI.updateSmartFolder({
                                        id: fId,
                                        exercisesIds: newFolderExercisesIds
                                    });
                                }
                                let arr = await SmartFoldersAPI.getSmartFolders(uId);
                                setFolders(arr);
                                setMovingExerciseId(undefined);
                                setFetching(false);
                            }}
                        />
                    </div>
                }
            </Sidebar>

            {(addExerciseVisible == false || externalComponentsEnabled == false) ? null :
                <NiceModal onClose={() => {
                    setAddExerciseVisible(false);
                }}>

                    <InnerPlaceholder>
                        {addExerciseComponent(() => {
                            setAddExerciseVisible(false);
                        })}
                    </InnerPlaceholder>

                </NiceModal>
            }

            {(editExerciseItem == undefined || externalComponentsEnabled == false) ? null :
                <NiceModal onClose={() => {
                    setSelectedEditExerciseId(undefined);
                }}>

                    <InnerPlaceholder>
                        {editExerciseComponent(selectedEditExerciseId, () => {
                            setSelectedEditExerciseId(undefined);
                        })}
                    </InnerPlaceholder>

                </NiceModal>
            }


        </Wrapper>
    );
}

const InnerPlaceholder = styled.div`
  width: 1080px;
  box-sizing: border-box;
  padding: 5px;
  height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  @media (max-width: 1200px) {
    width: calc(100vw - 60px);
  }

  @media (max-width: 720px) {
    width: 75vw;
    ::-webkit-scrollbar {
      width: 0;
    }
  }
`;

const TopPlaceholder = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const TopRight = styled.div`

`;

const Wrapper = styled.div`

`;

