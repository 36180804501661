import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {useDispatch, useMappedState} from "redux-react-hook";
import * as drockaExercisesActions from "../../../redux/actions/DrochkaExercisesActions";
import * as materialsActions from "../../../redux/actions/MaterialsActions";
import {Code} from "react-content-loader";
import {BlueButton, GreenButton} from "../../ira/ui/Buttons";
import NiceModal from "../../modals/NiceModal";
import Tabs, {TabItem} from "../../ui/Tabs";
import DrochkaInfoForm from "../../drochka_exercise/forms/DrochkaInfoForm";
import DrochkaItemsForm from "../../drochka_exercise/forms/DrochkaItemsForm";
import DrochkaExercisePanel from "../../drochka_exercise/panels/DrochkaExercisePanel";
import UpdateMaterialForm from "../forms/UpdateMaterialForm";
import Spin from "arui-feather/spin";
import {Input} from "../../ira/ui/Inputs";
import BulkMaterialsPanel from "./BulkMaterialsPanel";
import {translate} from "../../../utils/Translate";
import tick_img from "../../../assets/img/green_check.svg";
import {Set} from 'immutable'

export default function AllVideoMaterialsPanel(props) {
    const {
        mode = 'my',
        pageSize = 100,
        onCreated = x => {

        },
        onSelect = x => {

        },
        selectMode = false,
        selectedSet = Set(),
        creatorId
    } = props;

    const dispatch = useDispatch();
    const [selectedId, setSelectedId] = useState();
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [page, setPage] = useState(0);

    const [bulkMode, setBulkMode] = useState(false);

    const [searchText, setSearchText] = useState('');

    const {
        currentUser,
        materials = [],
        loading,
        isModerator
    } = useMappedState(useCallback(state => {
        let {currentUserId} = state.users;
        let currentUser = state.users.usersMap.get(currentUserId);
        let allMaterials = state.materials.materialsMap.toArray();
        let materials = (mode == 'my') ? allMaterials.filter(x => (x.creatorId == currentUserId)) : allMaterials;
        materials = materials.sort((a, b) => (+b.timestamp - +a.timestamp));
        if (creatorId != undefined){
            materials = materials.filter(x => (x?.creatorId == creatorId))
        }

        return {
            currentUser: currentUser,
            materials: materials,
            loading: state.materials.loading,
            isModerator: currentUser.isModerator
        }
    }, [selectedId]));

    useEffect(() => {
        dispatch(materialsActions.getMyMaterials());
    }, []);

    let selectedMaterial = (selectedId == undefined) ? undefined : materials.filter(x => (x.id == selectedId))[0];
    let xMaterials = materials;
    if (searchText.length > 2) {
        xMaterials = xMaterials.filter(x => {
            let s = `${x.name || ''} ${x.description || ''} ${x.transcript || ''}`.toLowerCase();
            return (s.indexOf(searchText.toLowerCase()) > -1);
        });
    }

    let visMaterials = xMaterials.slice(0, (+page + 1) * pageSize);

    return (
        <Wrapper>

            {!(materials.length == 0 && loading == true) ? null :
                <Code/>
            }

            {(mode != 'my' && mode != 'buffer') ? null :
                <TopPlacehodler>
                    {mode != 'my' ? null :
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} >
                            <GreenButton onClick={() => {
                                // if (loading == true) {
                                //     return;
                                // }
                                setAddModalVisible(true);
                            }}>
                                {translate('add_video')}
                                <Spin visible={loading}/>
                            </GreenButton>
                            <span style={{marginLeft: 20, cursor: 'pointer'}} onClick={() => {
                                setBulkMode(true);
                            }} >
                            массовая загрузка
                        </span>
                        </div>
                    }
                    <SearchPlaceholder>
                        <Input
                            placeholder={'Поиск'}
                            onChange={evt => {
                                let s = evt.target.value;
                                setSearchText(s);
                                setPage(0);
                            }}
                        />
                    </SearchPlaceholder>
                </TopPlacehodler>
            }

            <ItemsPlaceholder>
                {visMaterials.map((a, i) => {
                    let isSelected = selectedSet.has(a.id);
                    return (
                        <ExItem key={`ex_${a.id}_${i}`} onClick={() => {

                        }}>
                            <AvatarImage src={a.avatar} onClick={() => {
                                setSelectedId(a.id);
                            }}/>
                            <Name onClick={() => {
                                setSelectedId(a.id);
                            }}>
                                {a.name}
                            </Name>
                            {selectMode == false ? null :
                                <div style={{
                                    marginTop: 10,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    {isSelected == false ? null :
                                        <img src={tick_img} style={{height: 18, marginRight: 5}}/>
                                    }
                                    <SelectSpan onClick={() => {
                                        onSelect(a);
                                    }}>
                                        {translate('select')}
                                    </SelectSpan>
                                </div>
                            }
                        </ExItem>
                    )
                })}
            </ItemsPlaceholder>

            {loading == true || materials.length < (pageSize * (+page + 1)) ? null :
                <BottomPlaceholder>
                    <BlueButton onClick={() => {
                        setPage(+page + 1);
                    }}>
                        Загрузить еще
                    </BlueButton>
                </BottomPlaceholder>
            }

            {selectedMaterial == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedId(undefined);
                }}>

                    <InnerPlaceholder>
                        <UpdateMaterialForm
                            isCreatorMode={(window.location.href.indexOf('petr') > -1) || (currentUser?.id == selectedMaterial?.creatorId) || isModerator == true}
                            loading={loading}
                            isModerator={isModerator}
                            {...selectedMaterial}
                            canDelete={true}
                            onSave={async d => {
                                let pld = await dispatch(materialsActions.updateMaterial({
                                    ...d,
                                    id: selectedMaterial?.id
                                }));
                                setSelectedId(undefined);
                            }}
                            onDelete={async d => {
                                await dispatch(materialsActions.deleteMaterial(selectedMaterial?.id));
                                setSelectedId(undefined);
                            }}
                        />
                    </InnerPlaceholder>

                </NiceModal>
            }

            {bulkMode == false ? null :
                <NiceModal onClose={() => {
                    setBulkMode(false);
                }}>

                    <InnerPlaceholder>
                        <BulkMaterialsPanel

                        />
                    </InnerPlaceholder>

                </NiceModal>
            }

            {addModalVisible == false ? null :
                <NiceModal onClose={() => {
                    setAddModalVisible(false);
                }}>

                    <InnerPlaceholder>
                        <UpdateMaterialForm
                            loading={loading}
                            isModerator={isModerator}
                            onSave={async d => {
                                let pld = await dispatch(materialsActions.createMaterial({
                                    ...d,
                                    creatorId: currentUser?.id
                                }));
                                setAddModalVisible(false);
                                setSelectedId(pld?.material?.id);
                                onCreated(pld?.material);
                            }}
                        />
                    </InnerPlaceholder>

                </NiceModal>
            }

        </Wrapper>
    );
}

const SearchPlaceholder = styled.div`
  width: 240px;
`;

const SelectSpan = styled.span`
  cursor: pointer;
  font-size: 12px;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div`

`;

const BottomPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const TopPlacehodler = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const InnerPlaceholder = styled.div`
  width: min(1200px, 100vw - 80px);
  box-sizing: border-box;
  padding: 5px;
  height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  overflow-y: auto;
`;

const Description = styled.div`
  font-size: 12px;
  opacity: 0.7;
`;

const Name = styled.div`
  font-weight: bold;
`;

const AvatarImage = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: whitesmoke;
  height: 240px;
  width: 100%;
  margin-bottom: 5px;
  @media (max-width: 1100px) {
    height: 180px;
  }
`;

const ExItem = styled.div`
  box-sizing: border-box;
  margin: 15px;
  background: white;
  padding: 15px;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.75;
  overflow-wrap: anywhere;

  background: ${props => (props.selected == true ? 'blue' : 'white')};

  :hover {
    opacity: 1;
  }
`;

const Row = styled.div`
  margin-bottom: 15px;
`;

const ItemsPlaceholder = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px){
    grid-template-columns: 1fr;
  }
`;