import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import AccessGroupsAPI from "../../../api/AccessGroupsAPI";
import CommonHelper from "../../../helpers/CommonHelper";
import toast from "react-hot-toast";
import UsersAPI from "../../../api/UsersAPI";
import TeacherAccessAPI from "../../../api/TeacherAccessAPI";

export default function TeacherAccessesTool(props) {

    const [loading, setLoading] = useState(false);
    const [accessGroups, setAccessGroups] = useState([]);

    useEffect(() => {
        setLoading(true);
        TeacherAccessAPI.getAllTeacherAccesses().then((data) => {
            setAccessGroups(data);
            setLoading(false);
        })
    }, []);

    return (
        <Wrapper>

            <h2 style={{marginBottom: '10px'}}>
                Преподаватели, которым разрешен доступ на платформу
            </h2>

            <div>
                {accessGroups.map((a, i) => {
                    return (
                        <Item key={a.id}>
                            <div>
                                {`${CommonHelper.getUserName(a.user)} - ${a?.user?.email}`}

                            </div>
                            <div>
                                <DelSpan onClick={async () => {
                                    await TeacherAccessAPI.deleteTeacherAccess(a.id);
                                    setAccessGroups(accessGroups.filter(x => x.id != a.id));
                                    toast.success('Доступ удален');
                                }}>
                                    удалить
                                </DelSpan>
                            </div>
                        </Item>
                    )
                })}
            </div>

            <div style={{marginTop: 10}} >
                <button onClick={async () => {
                    let email = window.prompt('Введите email преподавателя');
                    if (email == undefined || email.length < 3 || email.indexOf('@') == -1) {
                        return;
                    }
                    let newUser = await UsersAPI.getUserByEmail(email);
                    if (newUser == undefined) {
                        toast.error('Пользователь не найден');
                        return;
                    }
                    let newAccessGroup = await TeacherAccessAPI.createTeacherAccess({
                        userId: newUser.id
                    });
                    let arr = await TeacherAccessAPI.getAllTeacherAccesses();
                    setAccessGroups(arr);
                    // setAccessGroups([...accessGroups, newAccessGroup]);
                }}>
                    Добавить
                </button>
            </div>

        </Wrapper>
    );
}

const DelSpan = styled.div`
  color: red;
  opacity: 0.5;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const Item = styled.div`
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 1px solid #ccc;
`;

const Wrapper = styled.div`

`;