import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {Textarea} from "../../ira/ui/Inputs";

import ch_box from '../../../assets/img/checkbox_tick.svg';
import void_ch_box from '../../../assets/img/void_checkbox.svg';
import remove_img from '../../../assets/img/cross.svg';

export default function OverlayedItemEditor(props) {
    const {
        options = [],
        onChange = arr => {

        }
    } = props;

    return (
        <Wrapper>

            {options.map((a, i) => {

                return (
                    <Row key={a.id}>
                        <RemoveImage src={remove_img}
                                     onClick={() => {
                                         let newOptions = options.filter((xx, j) => (j != i));
                                         onChange(newOptions);
                                     }}
                        />

                        <InputPlaceholder>
                            <Textarea value={a.text} onChange={evt => {
                                let s = evt.target.value;
                                let newOptions = options.map((b, j) => (i == j ? ({...b, text: s}) : b));
                                onChange(newOptions);
                            }}></Textarea>
                        </InputPlaceholder>
                        <CheckPlaceholder>
                            <ChImg
                                src={a.correct == true ? ch_box : void_ch_box}
                                onClick={() => {
                                    let newCorrect = !a.correct;
                                    let newOptions = options.map((b, j) => (i == j ? ({
                                        ...b,
                                        correct: newCorrect
                                    }) : b));
                                    onChange(newOptions);
                                }}
                            />
                            <span>
                                correct
                            </span>
                        </CheckPlaceholder>
                    </Row>
                )
            })}

            <AddPlaceholder>
                <AddSpan onClick={() => {
                    let newOptions = options.concat([{
                        id: `t-${+new Date()}`,
                        text: `option ${options.length + 1}`,
                        correct: false
                    }]);
                    onChange(newOptions);
                }}>
                    Add option
                </AddSpan>
            </AddPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const AddPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

const AddSpan = styled.div`
  opacity: 0.5;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const RemoveImage = styled.img`
  height: 16px;
  opacity: 0.4;
  cursor: pointer;
  margin-right: 10px;

  :hover {
    opacity: 1;
  }
`;

const Row = styled.div`
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid whitesmoke;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InputPlaceholder = styled.div`
  box-sizing: border-box;
  flex: 1;
`;

const CheckPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ChImg = styled.img`
  height: 16px;
  margin-left: 5px;
  cursor: pointer;
  opacity: 0.5;
  margin-right: 5px;
  
  :hover {
    opacity: 1;
  }
`;


