import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {DEFAULT_VIDEO_PLACEHOLDER} from "../../../constants/config";
import ReactPlayer from "react-player";
import {BlueButton} from "../../ira/ui/Buttons";
import CardsSlideShowTool from "./CardsSlideShowTool";
import ReactHelper from "../../../helpers/ReactHelper";

export default function DrochkaExerciseViewTool(props) {
    const {
        id,
        name,
        description,
        studentTask,
        videoUrl,
        avatar,
        items = [],
        userAnswer,
        onSave = url => {

        },
        isCheckingMode = false
    } = props;

    const [step, setStep] = useState('prepare');
    useEffect(() => {
        if (step == 'testing') {
            let ev = new CustomEvent('drochka_exercise_testing_started', {
                detail: {
                    id: id
                }
            });
            window.dispatchEvent(ev);
        }
    }, [step]);

    ReactHelper.useEventListener('drochka_exercise_testing_started', (a, b, c) => {
        if (a?.detail?.id == id) {
            return;
        }
        if (step == 'testing') {
            setStep('prepare');
        }
    });

    return (
        <Wrapper>

            <TopPlaceholder>

                <NamePlaceholder>
                    {name}
                </NamePlaceholder>

                {(description == undefined || description == '') ? null :
                    <DescriptionPlaceholder>
                        {description}
                    </DescriptionPlaceholder>
                }

                {(studentTask == undefined || studentTask == '') ? null :
                    <DescriptionPlaceholder>
                        {studentTask}
                    </DescriptionPlaceholder>
                }



            </TopPlaceholder>

            <ContentPlaceholder>

                {step != 'testing' ? null
                    :
                    <React.Fragment>
                        <BackButtonPlaceholder onClick={() => {
                            setStep('prepare');
                        }}>
                            <BackSpan>
                                Назад
                            </BackSpan>
                        </BackButtonPlaceholder>
                        <SlideshowPlaceholder>
                            <CardsSlideShowTool
                                answerUrl={userAnswer}
                                items={items}
                                onAnswer={url => {
                                    onSave(url);
                                }}
                            />
                        </SlideshowPlaceholder>
                    </React.Fragment>

                }

                {step != 'prepare' ? null :
                    <React.Fragment>

                        <VideoPlaceholder>
                            {(videoUrl == undefined || videoUrl == '') ?
                                <OptImg src={avatar || DEFAULT_VIDEO_PLACEHOLDER} />
                                :
                                <ReactPlayer
                                    width={'100%'}
                                    height={'100%'}
                                    light={avatar || DEFAULT_VIDEO_PLACEHOLDER}
                                    controls={true}
                                    playing={true}
                                    url={videoUrl}/>
                            }
                        </VideoPlaceholder>

                        {(isCheckingMode == true && userAnswer != undefined) ? null :
                            <StartTestingButtonPlaceholder>
                                <BlueButton onClick={() => {
                                    setStep('testing');
                                }}>
                                    Начать тестирование
                                </BlueButton>
                            </StartTestingButtonPlaceholder>
                        }

                    </React.Fragment>
                }

            </ContentPlaceholder>

            {isCheckingMode == false || userAnswer == undefined ? null :
                <AnswerPlaceholder>
                    <div>
                        Ответ ученика
                    </div>
                    <div>
                        <audio controls={true} src={userAnswer}></audio>
                    </div>
                </AnswerPlaceholder>
            }

            {/*{JSON.stringify(props)}*/}
        </Wrapper>
    );
}

const OptImg = styled.div`
  background-color: whitesmoke;
  width: 100%;
  height: 420px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
`;

const AnswerPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const BackButtonPlaceholder = styled.div`
  margin-bottom: 5px;
  background: whitesmoke;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const BackSpan = styled.span`

`;

const StartTestingButtonPlaceholder = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid whitesmoke;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 6px;
`;

const VideoPlaceholder = styled.div`
  background: whitesmoke;
  width: 100%;
  height: 400px;
`;

const ContentPlaceholder = styled.div`

`;

const SlideshowPlaceholder = styled.div`
  min-height: 400px;
  width: 100%;
`;

const TopPlaceholder = styled.div`
  margin-bottom: 5px;
  width: 100%;
`;

const NamePlaceholder = styled.div`
  font-weight: bold;
  padding: 10px;
`;

const DescriptionPlaceholder = styled.div`
  padding: 10px;
`;