import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import UpdateProfileForm from "../forms/UpdateProfileForm";
import * as actions from '../../../redux/actions/UsersActions'
import BrutalOverlayModal from "../../modals/BrutalOverlayModal";

function ie(s) {
    return (s == undefined || `${s}`.trim() == '');
}

export default function AbituUpdateProfileDaemonPanel(props) {
    const {
        userId
    } = props;

    const {
        user,
        loading,
        uId
    } = useMappedState(useCallback(state => {
        let uId = userId || state.users.currentUserId;
        return {
            user: state.users.usersMap.get(uId),
            loading: state.users.loading,
            uId: uId
        }
    }));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadUserById(uId));
    }, [uId]);

    console.log('AbituUpdateProfileDaemonPanel: render: user = ', user);

    if (user == undefined || user.isAbiturient != true) {
        return null;
    }

    let {city, teacherName, teacherEmail, eduOrgName, classNumber, foreignLang} = user;

    if (((ie(city) || ie(teacherEmail) || ie(teacherName) || ie(eduOrgName) || ie(classNumber) || ie(foreignLang))) == false) {
        return null;
    }

    return (


        <BrutalOverlayModal>
            <Wrapper>
                <h1>
                    Пожалуйста, заполните информацию ниже.
                </h1>
                <p>
                    Необходимо заполнить все поля без исключения.
                </p>

                <UpdateProfileForm
                    hasTitle={false}
                    {...user}
                    loading={loading}
                    onSave={async d => {
                        await dispatch(actions.updateUser({
                            id: uId,
                            ...d
                        }));
                    }}
                />
            </Wrapper>
        </BrutalOverlayModal>
    );
}

const Wrapper = styled.div`
  padding-bottom: 40px;
`;